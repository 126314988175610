import React, { useEffect } from "react";
import { connect } from "react-redux";

import { LoadingIndicator } from "../../../components";
import { useLocaleManagement } from "../../../hooks/useLocaleManagement";
import { fetchContent } from "../actions";
import * as selectors from "../selectors";

const Content = (props) => {
  const { fetchContent, id, loading, error, content } = props;
  const { locale } = useLocaleManagement();

  useEffect(() => {
    fetchContent(id);
  }, [locale]);

  if (loading) {
    return <LoadingIndicator loading={loading} />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    content: selectors.getContent(state, ownProps.id),
    loading: selectors.getLoading(state, ownProps.id),
    error: selectors.getError(state, ownProps.id),
  };
};

const ConnectedComponent = connect(mapStateToProps, { fetchContent })(Content);

export { ConnectedComponent as Content };
