import _ from "lodash";
import moment from "moment";

export const mapFilterForRequest = (filterCriteria) => {
  if (filterCriteria === null) return {};

  const makes = _.reject(filterCriteria.makes, [
    "make",
    "",
  ]); /* Fix for native empty filter on screen... ( UI ) */

  let models = [];
  _.each(makes, (make) => {
    if (make.model !== "") {
      models.push({
        make: make.make,
        model: make.model,
      });
    } else {
      models.push({
        make: make.make,
        model: null,
      });
    }
  });

  let ranges = {};
  if (filterCriteria.age.min || filterCriteria.age.max) {
    ranges = {
      ...ranges,
      age_min: filterCriteria.age.min,
      age_max: filterCriteria.age.max,
    };
  }

  if (filterCriteria.km.min || filterCriteria.km.max) {
    ranges = {
      ...ranges,
      km_min: filterCriteria.km.min,
      km_max: filterCriteria.km.max,
    };
  }

  if (filterCriteria.offeredDt_min && filterCriteria.offeredDt_max) {
    ranges = {
      ...ranges,
      dateTimeOffered_min: moment(filterCriteria.offeredDt_min).format(
        "YYYY-MM-DD"
      ),
      dateTimeOffered_max: moment(filterCriteria.offeredDt_max).format(
        "YYYY-MM-DD"
      ),
    };
  }

  if (filterCriteria.expectationDt_min && filterCriteria.expectationDt_max) {
    ranges = {
      ...ranges,
      dateTimeExpected_min: moment(filterCriteria.expectationDt_min).format(
        "YYYY-MM-DD"
      ),
      dateTimeExpected_max: moment(filterCriteria.expectationDt_max).format(
        "YYYY-MM-DD"
      ),
    };
  }

  const result = {
    listId:
      filterCriteria.list !== null && filterCriteria.list !== undefined
        ? filterCriteria.list.listId
        : null,
    companies: filterCriteria.companies,
    models: models,
    fuels: filterCriteria.fuels,
    gearboxes: filterCriteria.gearboxes,
    ranges: ranges,
    dealerStates: filterCriteria.states,
  };

  return encodeURIComponent(JSON.stringify(result));
};
