import React from "react";
import { connect } from "react-redux";

import clsx from "clsx";
import { compose } from "redux";
import { createGuid } from "src/helpers/functions";

import {
  DescriptionLabel,
  Expectation,
  Specifications,
  Top9FeatureText,
  VehicleVatRegimeBadge,
} from "../../../components";
import { withConfig } from "../../../config";
import { addLeadingZero } from "../../../helpers/utils";
import nopic from "../../../img/nopic.jpeg";
import { PrintButtons } from "../../print";
import * as selectors from "../selectors";
import { BadgeWithCopy } from "./BadgeWithCopy";
import { DealerState } from "./DealerState";

import moment from "moment";

const Header = ({
  history_vehicle_offer_date,
  location_name,
  main_company_name,
  make,
  model,
  offer_date,
  provisional_location,
  vehicle,
  vehicle_print,
  version,
}) => {
  const showLocationBlock =
    provisional_location || main_company_name || history_vehicle_offer_date;

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex justify-between">
          <hgroup className="w-1/2 flex-1 truncate">
            <h5 className="h5 truncate uppercase text-highlightColor">
              {make} {model}
            </h5>
            <p className=" truncate text-sm uppercase text-[#999]">{version}</p>
          </hgroup>
          <div className="flex w-1/2 flex-col items-end space-y-1">
            <div className="hidden md:flex md:flex-col md:items-end">
              {showLocationBlock && (
                <div className="flex flex-wrap justify-end space-x-3">
                  {provisional_location && (
                    <span className="text-right text-[#777]">
                      <i className="icon-map-pin" /> {location_name}
                    </span>
                  )}
                  {main_company_name && (
                    <span className="text-right text-[#777]">
                      <i className="icon-map-pin" /> {main_company_name}
                    </span>
                  )}
                  {history_vehicle_offer_date && (
                    <span className="text-[#777]">
                      <i className="icon-upload" />{" "}
                      {moment(offer_date).format("DD/MM/YYYY HH:MM")}
                    </span>
                  )}
                </div>
              )}
              {vehicle_print && (
                <PrintButtons listVehicleId={vehicle.list_vehicle_id} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MidSection = ({
  picture_url,
  make,
  model,
  vehicle,
  vat_regime,
  summaryFields,
  top_features_text,
  features_count,
  list_vehicle_top_feature_icons,
  history_vehicle_expectation,
}) => {
  return (
    <div className="flex space-x-3">
      <div className="w-3/12">
        <div className="relative">
          {picture_url && (
            <img
              src={picture_url}
              alt={make + " " + model}
              className="img-fluid"
              onError={(e) => (e.target.src = nopic)}
            />
          )}

          {!picture_url && (
            <img src={nopic} alt={make + " " + model} className="img-fluid" />
          )}
        </div>

        <DealerState {...vehicle} />
      </div>

      <div className="w-9/12 space-y-2">
        <div className="flex flex-wrap gap-1">
          <span className="u-label badge badge-normal !rounded-[3px]">
            #{addLeadingZero(vehicle.list_index, 3)}
          </span>

          <VehicleVatRegimeBadge vat_regime={vat_regime} />

          <span className="u-label badge badge-normal !rounded-[3px]">
            {vehicle.list_short_name}
          </span>

          <BadgeWithCopy uniqueId={createGuid()} text={vehicle.vin} />
        </div>

        <Specifications vehicle={vehicle} fields={summaryFields} />

        {!list_vehicle_top_feature_icons && (
          <div className="hidden sm:block">
            <Top9FeatureText
              features={top_features_text}
              count={features_count}
            />
          </div>
        )}

        {history_vehicle_expectation && (
          <div>
            <Expectation {...vehicle} />
          </div>
        )}
      </div>
    </div>
  );
};

const HistoryVehicleItem = (props) => {
  const onClick = () => {
    const { vehicle, onClick } = props;
    const { list_vehicle_id } = vehicle;
    onClick(list_vehicle_id);
  };

  const { vehicle, selected, config } = props;
  const {
    picture_url,
    make,
    model,
    version,
    main_company_name,
    vat_regime,
    description,
    description_plus,
    offer_date,
    top_features_text,
    features_count,
    provisional_sale,
    expected_disclose,
    location_name,
  } = vehicle;

  const {
    history_vehicle_summary_fields,
    history_vehicle_summary_provisional_fields,
    history_vehicle_summary_provisional_fields_reserved,
    list_vehicle_top_feature_icons,
    history_vehicle_offer_date,
    history_vehicle_expectation,
    vehicle_print,
  } = config;

  const summaryFields =
    vehicle.provisional_sale && !vehicle.expected_disclose
      ? vehicle.dealer_state === "RESERVED"
        ? history_vehicle_summary_provisional_fields_reserved
        : history_vehicle_summary_provisional_fields
      : history_vehicle_summary_fields;

  const provisional_location =
    (provisional_sale && expected_disclose && location_name !== "") ||
    (!provisional_sale && location_name !== null && location_name !== "");

  const headerProps = {
    history_vehicle_offer_date,
    location_name,
    main_company_name,
    make,
    model,
    offer_date,
    provisional_location,
    vehicle,
    vehicle_print,
    version,
  };

  const midSectionProps = {
    picture_url,
    make,
    model,
    vehicle,
    vat_regime,
    summaryFields,
    top_features_text,
    features_count,
    list_vehicle_top_feature_icons,
    history_vehicle_expectation,
  };

  const showImportantDescription = description && description !== "";
  const showDescriptionPlus = description_plus && description_plus !== "";
  const showDescriptions = showImportantDescription || showDescriptionPlus;
  const showBottomLocationInfo =
    main_company_name || provisional_location || history_vehicle_offer_date;

  const Descriptions = () => {
    return (
      <div className="my-1 space-y-1">
        {showDescriptionPlus && (
          <div className="w-full">
            <DescriptionLabel description={description_plus} />
          </div>
        )}
        {showImportantDescription && (
          <div className="w-full">
            <DescriptionLabel description={description} important />
          </div>
        )}
      </div>
    );
  };

  const LocationInfo = () => {
    return (
      <div className="flex w-full items-center justify-between md:hidden">
        {provisional_location && (
          <span className="text-right text-[#777]">
            <i className="icon-map-pin" /> {location_name}
          </span>
        )}
        {main_company_name && (
          <span className="text-right text-[#777]">
            <i className="icon-map-pin" /> {main_company_name}
          </span>
        )}
        {history_vehicle_offer_date && (
          <span className="text-[#777]">
            <i className="icon-upload" />{" "}
            {moment(offer_date).format("DD/MM/YYYY HH:MM")}
          </span>
        )}
      </div>
    );
  };

  return (
    <article
      className={clsx("item item--hover relative p-3", {
        "item-active": selected,
      })}
      onClick={onClick}
    >
      <div className="space-y-1">
        <div className="flex flex-col space-y-2">
          <Header {...headerProps} />
          <MidSection {...midSectionProps} />
        </div>
        {showDescriptions && <Descriptions />}
        {showBottomLocationInfo && <LocationInfo />}
      </div>
    </article>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    vehicle: selectors.getVehicleById(state, ownProps.listVehicleId),
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps)
)(HistoryVehicleItem);

export { ConnectedComponent as HistoryVehicleItem };
