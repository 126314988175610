import React from "react";
import { useTranslation } from "react-i18next";

import nopic from "../../../../img/nopic.jpeg";

import _ from "lodash";

const hasPictures = (damage) => {
  return damage.pictures && damage.pictures.length >= 1;
};

const AllDamages = ({ damages, onPictureClick }) => {
  return (
    <div className="flex">
      <div className="flex w-full flex-col gap-2 text-[#777]">
        {Object.keys(damages).map((location, i_loc) => (
          <div
            className="flex w-full flex-col sm:flex-row"
            key={`damage-location-${i_loc}`}
          >
            <div className="w-3/12 p-2">{location}</div>
            <div className="g-brd-gray-light-v3 w-full border-l sm:w-9/12">
              {damages[location].map((damage, i_damage) => (
                <div className="pl-2" key={`damage-${i_loc}-${i_damage}`}>
                  <div className="flex p-2">{_.capitalize(damage.type)}</div>
                  {damage.note && (
                    <div className="text-[14px] font-bold">{damage.note}</div>
                  )}
                  <div className="flex w-full flex-wrap">
                    {hasPictures(damage) ? (
                      <AvailableDamagePictures
                        damage={damage}
                        locationIndex={i_loc}
                        damageIndex={i_damage}
                        onDamageClick={onPictureClick}
                      />
                    ) : (
                      <UnavailablePicture
                        damage={damage}
                        locationIndex={i_loc}
                        damageIndex={i_damage}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const UnavailablePicture = ({ damage, locationIndex, damageIndex }) => {
  return (
    <img
      className="h-auto w-full p-3 sm:h-[100px] sm:w-[150px]"
      key={`damage-picture${locationIndex}-${damageIndex}-0`}
      src={nopic}
      alt={damage.location}
    />
  );
};

const AvailableDamagePictures = ({
  damage,
  locationIndex,
  damageIndex,
  onDamageClick,
}) => {
  return damage.pictures.map((picture, i_pic) => (
    <img
      className="h-[320px] w-[380px] p-3 hover:cursor-zoom-in sm:h-[100px] sm:w-[150px]"
      key={`damage-picture${locationIndex}-${damageIndex}-${i_pic}`}
      src={picture.src}
      alt={picture.description}
      onClick={() => onDamageClick(picture)}
    />
  ));
};

export const Damages = ({ vehicle, onPictureClick }) => {
  const { t } = useTranslation();
  const damages = _.groupBy(_.orderBy(vehicle.damages, "location"), "location");
  const showDamages = _.keys(damages).length !== 0;

  return showDamages ? (
    <div id="damages" className="bg-white p-3">
      <h5 className="h5 text-highlightColor">{t("Damages")}</h5>
      <AllDamages damages={damages} onPictureClick={onPictureClick} />
    </div>
  ) : null;
};
