import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { routes } from "../../../_web/navigation/routes";
import { LoadingIndicator } from "../../../components";
import { filtersFetch } from "../actions";
import * as selectors from "../selectors";

const FiltersOverview = (props) => {
  const { filters, loading } = props;
  const { t } = useTranslation();

  useEffect(() => {
    props.filtersFetch();
  }, []);

  if (loading) return <LoadingIndicator loading={loading} />;

  return (
    <div className="space-y-1">
      {Object.values(filters).map((filter, i) => {
        return (
          <article key={i} className="flex justify-between rounded border p-1">
            <span className="h5 my-auto">{filter.name}</span>
            <button className="rounded border p-1">
              <Link
                //TODO: navigate to specific filter
                to={routes.FILTERS}
              >
                {t("Edit")}
              </Link>
            </button>
          </article>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: selectors.getFilters(state),
    loading: selectors.getLoading(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  filtersFetch,
})(FiltersOverview);

export { ConnectedComponent as FiltersOverview };
