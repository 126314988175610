import React from "react";
import { useTranslation } from "react-i18next";

import { LoadingIndicator, Template } from "../components";
import { useSuppliers } from "../features/suppliers/hooks/useSuppliers";

const parseSupplierData = (data) => {
  return {
    comment: data?.comment || "",
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    locations: data?.locations || "",
    company_name: data?.company_name || "",
    mobile: data?.mobile || "",
    region: data?.region || "",
    headquarters: data?.headquarters || "",
    email: data?.email || "",
    telephone: data?.telephone || "",
  };
};

const SupplierItem = ({ supplierData }) => {
  return (
    <div className="mx-2 mb-2 rounded-xl border border-gray-100 bg-white p-4 shadow lg:mx-4">
      <div className="lg:flex">
        <div className="flex lg:w-1/2">
          <div className="w-1/2">
            <div className="text-lg font-bold md:text-2xl">
              {supplierData.company_name}
            </div>
            <div className="font-semibold text-gray-500 md:text-lg">
              {supplierData.first_name} {supplierData.last_name}
            </div>
          </div>
          <div className="flex w-1/2 items-center justify-end md:justify-start">
            <div>
              <a
                href={`mailto:${supplierData.email}`}
                className="flex items-center justify-center rounded bg-gray-100 px-8 py-2 font-semibold text-primary hover:bg-indigo-100 md:px-10 lg:ml-5 lg:py-2.5"
              >
                <i className="fa fa-envelope text-xl md:text-2xl"></i>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-1 w-full lg:hidden" />
        <div className="md:flex lg:w-1/2">
          <div className="pt-2 md:w-1/2 lg:w-full">
            <div className="min-w-full py-1 text-lg md:w-1/2">
              <i className="fa fa-mobile text-midnight pr-2"></i>
              <a href={`tel:${supplierData.mobile}`}>{supplierData.mobile}</a>
            </div>
            <div className="min-w-full py-1 text-lg md:w-1/2">
              <i className="fa fa-phone text-midnight pr-1"></i>
              <a href={`tel:${supplierData.telephone}`}>
                {supplierData.telephone}
              </a>
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="mt-3 max-w-sm rounded bg-gray-100 pl-1 text-lg font-bold text-gray-400 md:font-medium md:normal-case">
              Locations
              <span>{supplierData.locations}</span>
            </div>
            <div className="mt-2 md:w-1/2 md:py-1 md:text-base lg:w-full">
              <i className="fa fa-map-pin text-midnight pr-1"></i>
              {supplierData.region}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SuppliersList = () => {
  const { suppliersList, isLoading, isError } = useSuppliers();
  const { t } = useTranslation();

  return (
    <Template>
      {isLoading ? (
        <LoadingIndicator loading={isLoading} />
      ) : (
        <div className="w-full rounded-xl bg-white">
          <div className="h-full w-full rounded-xl bg-gray-50 p-5">
            <div className="block p-3 text-lg text-gray-400 md:text-2xl lg:mx-2">
              {t("Publishers")}
            </div>
            {isError
              ? t("An error occurred")
              : suppliersList?.map((supplier, idx) => (
                  <SupplierItem
                    key={idx}
                    supplierData={parseSupplierData(supplier)}
                  />
                ))}
          </div>
        </div>
      )}
    </Template>
  );
};
