export const getProfile = (state) =>
  state.profile.data.profile.profile_id > 0
    ? state.profile.data.profile.byId[state.profile.data.profile.profile_id]
    : null;

export const hasAllData = (state) =>
  getProfile(state) !== null; /* && getCountries(state).length > 0 */

export const getCountries = (state) =>
  state.profile.data.countries.allCountries;
export const getCountriesLoading = (state) =>
  state.profile.data.countries.loading;
export const getCountriesError = (state) => state.profile.data.countries.error;

export const getLoading = (state) =>
  !hasAllData(state) || state.profile.profile.loading;

export const getError = (state) => state.profile.profile.error;
