import React from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import format from "string-format";

import { ExpertStatisticsStates } from "../../../constants";
import { useNavigateHelper } from "../../../hooks/useNavigateHelper";
import style from "../../../styles";
import {
  updateCriteriaRange,
  updateCriteriaStates,
} from "../../listvehicles-filter";
import { StateLabel } from "../common/StateLabel";
import { NoStats } from "../NoStats";
import { BidsTable } from "./BidsTable";
import { LineChart } from "./LineChart";

import { createColumnHelper } from "@tanstack/react-table";
import { keys } from "lodash";
import moment from "moment";

export const ExpertStatistics = (props) => {
  const { data } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { goToHistory } = useNavigateHelper();

  const onStateClick = (state) => {
    dispatch(updateCriteriaStates(-1, [state]));
    goToHistory();
  };

  // TODO: Check where/how it's used
  // const format = (cell, row) => {
  //   console.log(cell);
  //   return cell + " %";
  // };

  const handleChartClick = (event, dot) => {
    if (dot.length > 0) {
      const { month, year } = dot[0].element.$context.raw;

      dispatch(updateCriteriaStates(-1, ["ACCEPTED"]));
      dispatch(
        updateCriteriaRange(
          -1,
          `${year}/${month}/01`,
          moment(`${year}/${month}/01`).add(1, "M")
        )
      );
      goToHistory();
    }
  };

  const countersMoreThanOne = () => {
    return keys(props.data.states).length > 0;
  };

  const renderStates = () => {
    return (
      <div className="flex flex-col items-stretch text-center md:h-[70px] md:flex-row">
        {Object.entries(ExpertStatisticsStates).map((stateKey) => {
          return (
            <StateLabel
              key={stateKey[1]}
              label={t(stateKey[1])}
              count={data.states?.[stateKey[0]]?.count || 0}
              onClick={() => onStateClick(stateKey[0])}
            />
          );
        })}
      </div>
    );
  };

  const renderArchived = () => {
    return (
      <div className="mt-2">
        {data.states?.ARCHIVED?.count > 0 && (
          <div>
            <span
              className="cursor-pointer underline"
              onClick={() => onStateClick("ARCHIVED")}
            >
              {format(t("{0} archived"), data.states.ARCHIVED.count)}
            </span>
          </div>
        )}
      </div>
    );
  };

  const renderNoStats = () => {
    return (
      <div className={`${countersMoreThanOne() ? "bg-white" : "bg-normal"}`}>
        <NoStats />
      </div>
    );
  };

  const renderSpeedometer = () => {
    return (
      <div className="flex flex-col items-center">
        <ReactSpeedometer
          minValue={60}
          maxValue={100}
          value={data.average_all.average}
          segments={4}
          currentValueText=" "
          width={300}
          height={180}
        />
        <p className="text-center font-bold">
          {format(
            t("Your given bids are at {0}% of the highest bid."),
            data.average_all.average
          )}
        </p>
      </div>
    );
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("make", {
      header: () => <div className="text-left">{t("Make")}</div>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("count", {
      header: () => <div className="text-right">{t("# bids")}</div>,
      cell: (info) => <div className="text-right">{info.getValue()}</div>,
    }),
    columnHelper.accessor("average", {
      header: () => (
        <div className="text-right">{t("relative to highest bid")}</div>
      ),
      cell: (info) => (
        <div className="text-right">{`${info.getValue()} %`}</div>
      ),
    }),
  ];

  const renderBidsTable = () => {
    return (
      <div className="px-2">
        <BidsTable columns={columns} tableData={data.average_make} />
      </div>
    );
  };

  const renderLineChart = () => {
    return (
      <div>
        <LineChart
          data={data.accepted}
          onClick={handleChartClick}
          stroke={style.highlightColor}
        />
        <p className="text-center font-bold">
          {t("Number of cars assigned to you")}
        </p>
      </div>
    );
  };

  return data === null ? null : (
    <div
      className={`flex flex-col space-y-6 p-2 ${
        countersMoreThanOne() ? "bg-white" : "bg-normal"
      }`}
    >
      {countersMoreThanOne() && (
        <div>
          {renderStates()}
          {renderArchived()}
        </div>
      )}

      {!data.average_all &&
        data.accepted.length === 0 &&
        data.average_make.length === 0 &&
        renderNoStats()}

      {data.average_all && data.average_all.count > 0 && renderSpeedometer()}

      {data.average_make && data.average_make.length >= 1 && renderBidsTable()}

      {data.accepted && data.accepted.length >= 1 && renderLineChart()}
    </div>
  );
};
