import { createErrorMessageSelector, createLoadingSelector } from "../ui";
import { REGISTRATION_FORM } from "./types";

const loadingSelector = createLoadingSelector([REGISTRATION_FORM]);
const errorSelector = createErrorMessageSelector([REGISTRATION_FORM]);

export const getLoading = (state) => {
  return loadingSelector(state);
};

export const getError = (state) => {
  return errorSelector(state);
};

export const getRegistrationId = (state) =>
  state.registration_form.registration_id;
