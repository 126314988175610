export const listVehiclesFilterTypes = {
  DATA_INIT: "LISTVEHICLES_FILTER_DATA_INIT",
  CRITERIA_UPDATE: "LISTVEHICLES_FILTER_CRITERIA_UPDATE",
  CRITERIA_FIELD_UPDATE: "LISTVEHICLES_FILTER_CRITERIA_FIELD_UPDATE",
  CRITERIA_RESET: "LISTVEHICLES_FILTER_CRITERIA_RESET",

  FETCH_REQUEST: "LISTVEHICLES_FILTER_FETCH_REQUEST",
  FETCH_SUCCESS: "LISTVEHICLES_FILTER_FETCH_SUCCESS",
  FETCH_FAILURE: "LISTVEHICLES_FILTER_FETCH_FAILURE",

  CLEANUP: "LISTVEHICLES_FILTER_CLEANUP",
};
