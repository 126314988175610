import React from "react";
import { useTranslation } from "react-i18next";

import { compose } from "redux";
import { Field, reduxForm } from "redux-form";

import { validators } from "../../../../../components";
import { withConfig } from "../../../../../config";
import { Colleagues } from "../../../../colleagues";
import { FiltersOverview } from "../../../../filters";
import { Combo, Multiline, PhoneNumber, TextField, Toggle } from "./FormFields";

import { capitalize, includes } from "lodash";

export const profileFields = {
  PERSONAL_TITLE: "title",
  PERSONAL_FIRST_NAME: "first_name",
  PERSONAL_LAST_NAME: "last_name",
  PERSONAL_MAIL: "email",
  PERSONAL_PHONE: "telephone",
  PERSONAL_MOBILE: "mobile",

  COMPANY_NAME: "company_name",
  COMPANY_VAT: "vat_number",
  COMPANY_ADDRESS: "address",
  COMPANY_COUNTRY: "country_code",
  COMPANY_POSTAL: "post_code",
  COMPANY_CITY: "city",
  COMPANY_MAIL: "logistics_email",

  SETTINGS_CAR_BY_CAR_NOTIFICATION: "car_by_car_notification",
  SETTINGS_CAR_BY_CAR_MAIL: "car_by_car_mail",
  SETTINGS_DAILY_DIGEST_MAIL: "daily_digest_mail",
  SETTINGS_OFFERS_MAIL: "offers_mail",
  SETTINGS_WON_MAIL: "won_mail",
};

const validate = (values, props) => {
  const { required } = props;
  const errors = {};

  if (!required) return errors;

  const phoneError =
    validators.required(values.telephone) ||
    validators.minLength10(values.telephone);
  const mobileError =
    validators.required(values.mobile) || validators.minLength10(values.mobile);

  if (phoneError !== undefined && mobileError !== undefined) {
    errors.telephone = "Telephone or mobile is required";
    errors.mobile = "Telephone or mobile is required";
  }
  return errors;
};

const ProfileForm = (props) => {
  const { countries, required, config } = props;
  const { t } = useTranslation();

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="flex flex-col lg:flex-row lg:space-x-3">
        <div className="w-full space-y-3 lg:w-8/12">
          {/*PERSONAL*/}
          <div className="space-y-3 bg-white p-5">
            <div className="u-heading-v3-1 mb-5">
              <h5 className="h5 inline-block border-b border-primary pb-3 text-highlightColor">
                {t("Personal information")}
              </h5>
            </div>

            <Field
              name={profileFields.PERSONAL_TITLE}
              component={Combo}
              label={t("Title")}
              validate={required ? [validators.required] : []}
              placeholder={t("Title")}
              options={[
                { value: "Mr", label: t("Mr") },
                { value: "Mrs", label: t("Mrs") },
                { value: "Miss", label: t("Miss") },
              ]}
            />

            <Field
              name={profileFields.PERSONAL_LAST_NAME}
              component={TextField}
              type="text"
              label={t("Lastname")}
              validate={required ? [validators.required] : []}
            />

            <Field
              name={profileFields.PERSONAL_FIRST_NAME}
              component={TextField}
              type="text"
              label={t("Firstname")}
              validate={required ? [validators.required] : []}
            />

            <Field
              name={profileFields.PERSONAL_MAIL}
              component={TextField}
              type="email"
              label={t("E-mail")}
              disabled={true}
              validate={required ? [validators.required] : []}
            />

            <Field
              name={profileFields.PERSONAL_PHONE}
              component={PhoneNumber}
              type="text"
              label={t("Telephone")}
            />

            <Field
              name={profileFields.PERSONAL_MOBILE}
              component={PhoneNumber}
              type="text"
              label={t("Mobile")}
            />
          </div>

          {/*COMPANY*/}
          <div className="space-y-3 bg-white p-5">
            <div className="u-heading-v3-1 mb-5">
              <h5 className="h5 inline-block border-b border-primary pb-3 text-highlightColor">
                {t("Company information")}
              </h5>
            </div>

            <Field
              name={profileFields.COMPANY_VAT}
              component={TextField}
              type="text"
              label={t("VAT number")}
              validate={[validators.required]}
            />

            <Field
              name={profileFields.COMPANY_NAME}
              component={TextField}
              type="text"
              label={t("Company name")}
              validate={[validators.required]}
            />

            <Field
              name={profileFields.COMPANY_ADDRESS}
              component={Multiline}
              type="text"
              label={t("Address")}
              validate={[validators.required]}
            />

            <div className="flex w-full flex-col space-y-3 md:flex-row md:space-x-2 md:space-y-0">
              <div className="md:w-5/12">
                <Field
                  name={profileFields.COMPANY_POSTAL}
                  component={TextField}
                  type="text"
                  label={t("Postal")}
                  validate={[validators.required]}
                />
              </div>
              <div className="md:w-7/12">
                <Field
                  name={profileFields.COMPANY_CITY}
                  component={TextField}
                  type="text"
                  label={t("City")}
                  validate={[validators.required]}
                />
              </div>
            </div>

            <Field
              name={profileFields.COMPANY_COUNTRY}
              component={Combo}
              label={t("Country")}
              validate={[validators.required]}
              placeholder={t("Country")}
              options={countries.map((country) => ({
                value: country.code,
                label: capitalize(country.name),
              }))}
            />

            {config.registration_company_info_logistics_email && (
              <Field
                name={profileFields.COMPANY_MAIL}
                component={TextField}
                type="text"
                label={t("Logistic e-mail")}
                validate={[]}
              />
            )}
          </div>
        </div>

        {/*SETTINGS*/}
        <div className="w-full space-y-3 lg:w-4/12">
          {!config.app_excluded_routes.includes("/filters") && (
            <div className="bg-white p-5">
              <div className="u-heading-v3-1 mb-5">
                <h5 className="h5 inline-block border-b border-primary pb-3 text-highlightColor">
                  {t("Filters")}
                </h5>
              </div>
              <FiltersOverview />
            </div>
          )}
          <div className="space-y-3 bg-white p-5">
            <div className="u-heading-v3-1 mb-5">
              <h5 className="h5 inline-block border-b border-primary pb-3 text-highlightColor">
                {t("Settings")}
              </h5>
            </div>

            <div className="flex w-full justify-center">
              <div className="flex flex-col items-end space-y-5">
                <Field
                  name={profileFields.SETTINGS_CAR_BY_CAR_NOTIFICATION}
                  component={Toggle}
                  label={t("Car by Car / by smartphone notification")}
                />

                <Field
                  name={profileFields.SETTINGS_CAR_BY_CAR_MAIL}
                  component={Toggle}
                  label={t("Car by Car / by e-mail")}
                />

                <Field
                  name={profileFields.SETTINGS_DAILY_DIGEST_MAIL}
                  component={Toggle}
                  label={t("Daily digest / by e-mail")}
                />

                {!includes(
                  config.excluded_settings,
                  profileFields.SETTINGS_OFFERS_MAIL
                ) && (
                  <Field
                    name={profileFields.SETTINGS_OFFERS_MAIL}
                    component={Toggle}
                    label={t("Bidding confirmation / by e-mail")}
                  />
                )}

                {!includes(
                  config.excluded_settings,
                  profileFields.SETTINGS_WON_MAIL
                ) && (
                  <Field
                    name={profileFields.SETTINGS_WON_MAIL}
                    component={Toggle}
                    label={t("Highest bid / by e-mail")}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="space-y-3 bg-white p-5">
            <div className="u-heading-v3-1 mb-5">
              <h5 className="h5 inline-block border-b border-primary pb-3 text-highlightColor">
                {t("Colleagues")}
              </h5>
            </div>
            <Colleagues />
          </div>
        </div>
      </div>
    </form>
  );
};

const ConnectedComponent = compose(
  withConfig,
  reduxForm({
    form: "Profile",
    destroyOnUnmount: false,
    validate,
  })
)(ProfileForm);

export { ConnectedComponent as ProfileForm };
