import { Navigate } from "react-router-dom";

import { routes } from "./navigation/routes";

export const FrontendRedirectBasedOnEnv = () => {
  if (process.env.NODE_ENV === "development") {
    return <Navigate to={routes.DASHBOARD} />;
  } else {
    return <Navigate to={routes.FRONTEND} />;
  }
};
