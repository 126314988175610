import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { profileFields } from "./_web/components/forms/ProfileForm";
import { types } from "./types";

export const fetchData = () => {
  return (dispatch) => {
    return Promise.all([dispatch(fetchProfile()), dispatch(fetchCountries())]);
  };
};

export const fetchProfile = () => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.PROFILES,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: types.PROFILE_FETCH_REQUEST,
          },
          {
            type: types.PROFILE_FETCH_SUCCESS,
            payload: (action, state, res) =>
              onFetchProfileSuccess(dispatch, res),
          },
          {
            type: types.PROFILE_FETCH_FAILURE,
          },
        ],
      },
    });
  };
};

const onFetchProfileSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    return {
      profiles: data,
    };
  });
};

export const fetchCountries = () => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.COUNTRIES,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: types.PROFILE_COUNTRIES_FETCH_REQUEST,
          },
          {
            type: types.PROFILE_COUNTRIES_FETCH_SUCCESS,
            payload: (action, state, res) =>
              onFetchCountriesSuccess(dispatch, res),
          },
          {
            type: types.PROFILE_COUNTRIES_FETCH_FAILURE,
          },
        ],
      },
    });
  };
};
const onFetchCountriesSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    return {
      countries: data,
    };
  });
};

export const submitProfile = (profileId, data) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.PROFILES}${profileId}/`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: data[profileFields.PERSONAL_FIRST_NAME],
          last_name: data[profileFields.PERSONAL_LAST_NAME],
          title: data[profileFields.PERSONAL_TITLE],
          telephone: data[profileFields.PERSONAL_PHONE],
          mobile: data[profileFields.PERSONAL_MOBILE],
          email: data[profileFields.PERSONAL_MAIL],
          sms_active: false,
          car_by_car_notification:
            data[profileFields.SETTINGS_CAR_BY_CAR_NOTIFICATION],
          car_by_car_mail: data[profileFields.SETTINGS_CAR_BY_CAR_MAIL],
          daily_digest: data[profileFields.SETTINGS_DAILY_DIGEST_MAIL],
          offers_mail: data[profileFields.SETTINGS_OFFERS_MAIL] || false,
          won_mail: data[profileFields.SETTINGS_WON_MAIL] || false,

          company: {
            vat_number: data[profileFields.COMPANY_VAT],
            logistics_email: data[profileFields.COMPANY_MAIL] || "",
            name: data[profileFields.COMPANY_NAME],
            country_code: data[profileFields.COMPANY_COUNTRY],
            address: {
              city: data[profileFields.COMPANY_CITY],
              post_code: data[profileFields.COMPANY_POSTAL],
              country_code: data[profileFields.COMPANY_COUNTRY],
              address: data[profileFields.COMPANY_ADDRESS],
            },
          },
        }),
        types: [
          {
            type: types.PROFILE_POST_REQUEST,
          },
          {
            type: types.PROFILE_POST_SUCCESS,
            payload: (action, state, res) =>
              onSubmitProfileSuccess(dispatch, res),
          },
          {
            type: types.PROFILE_POST_FAILURE,
            payload: (action, state, res) =>
              onSubmitProfileFailure(dispatch, res),
          },
        ],
      },
    });
  };
};
const onSubmitProfileSuccess = (dispatch, res) => {
  return res.json().then((result) => {
    if (result.result_code === "Ok") {
      return {
        success: true,
        error: "",
      };
    } else {
      //  const message = (result.result.length > 0) ? values(result.result)[0] : '';

      return {
        success: true,
        error: result.result_message,
      };
    }
  });
};

const onSubmitProfileFailure = (dispatch, res) => {
  return res.json().then((data) => {
    return {};
  });
};
