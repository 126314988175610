import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { submit } from "redux-form";
import { SubmissionError } from "redux-form";

import { routes } from "../../../_web/navigation/routes";
import { LoadingIndicator } from "../../../components";
import { Button } from "../../../components/web/Button";
import { createColleague } from "../actions";
import * as selectors from "../selectors";
import { InviteForm } from "./InviteForm";

const Invite = (props) => {
  const { submit, loading, error } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const invite = {
    company_key: props.inviteId,
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    mobile: "",
  };

  const handleSubmit = (values) => {
    return props.createColleague(values).then((result) => {
      if (result.payload && result.payload.status === "Ok") {
        navigate(routes.LOGIN);
      } else {
        const { payload } = result;
        if (
          payload &&
          payload.serverValidationErrors !== null &&
          payload.serverValidationErrors.length > 0
        ) {
          throw new SubmissionError(
            Object.assign({}, ...payload.serverValidationErrors)
          );
        }
      }
    });
  };

  if (loading) {
    return <LoadingIndicator loading={loading} />;
  }

  return (
    <div className="p-10">
      <h2 className="mb-4 text-primary">{t("Register")}</h2>

      <div className="flex flex-col bg-white md:flex-row">
        <div className="w-full md:w-6/12">
          <h6 className="pt-2">{t("Welcome! A colleague has invited you.")}</h6>
          <h6 className="pt-2">
            {t("You can now complete the registration.")}
          </h6>
          <p className="pt-5">
            {t(
              "Enter the company key. With this we can link your account to the right company."
            )}
          </p>
          <p className="pt-5">
            {t(
              "Then enter your personal details. With correct data we can offer you the support you expect from us."
            )}
          </p>
        </div>

        <div className="mt-3 w-full md:mt-0 md:w-6/12">
          <InviteForm
            initialValues={invite}
            required={true}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
      {error !== "" && (
        <div className="container-important mt-2 flex p-3">{t(error)}</div>
      )}
      <div className="mt-3 flex w-full justify-end">
        <Button onClick={() => submit("Invite-PersonalInfo")}>
          {t("Register")}
          <i className="fa fa-chevron-right ml-1 text-sm"></i>
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: selectors.getLoading(state),
    error: selectors.getError(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  submit,
  createColleague,
})(Invite);

export { ConnectedComponent as Invite };
