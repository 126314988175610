import React from "react";
import { useTranslation } from "react-i18next";

export const OfferError = ({ error }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-normal g-ma-0 g-mt-70 g-mb-200 flex">
      {t("An error occurred")}: {error}
    </div>
  );
};
