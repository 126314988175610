import React, { useEffect } from "react";
import { connect } from "react-redux";

import clsx from "clsx";

import { ReactSelect } from "../../../components/web/forms/ReactSelect";
import { useLocaleManagement } from "../../../hooks/useLocaleManagement";
import { updateSort } from "../actions";
import { getSortById } from "../functions";
import * as selectors from "../selectors";

const controlStyles = {
  base: "h-[35px] md:border md:border-formBorderColor rounded-formBorderRadius bg-white hover:cursor-pointer",
  focus: "border-primary-600 ring-1 ring-primary-500",
  nonFocus: "border-formBorderColor hover:border-gray-400",
};

const indicatorsContainerStyles = "p-0.5 gap-1 bg-[#f2f2f2] md:bg-white";
const indicatorSeparatorStyles = "hidden md:bg-gray-300 md:block";

const SortDropDown = (props) => {
  const { activeSorter, listId, sorters, onSortChanged, defaultSorterId } =
    props;

  const { locale } = useLocaleManagement();

  const onSort = (id) => {
    props.updateSort(listId, getSortById(sorters, id));

    if (onSortChanged) {
      onSortChanged();
    }
  };

  useEffect(() => {
    props.updateSort(listId, getSortById(sorters, defaultSorterId));
  }, []);

  useEffect(() => {
    if (activeSorter) {
      props.updateSort(listId, getSortById(sorters, activeSorter.id));
    }
  }, [locale]);

  const selectedValue = { label: activeSorter?.label, value: activeSorter?.id };

  return (
    <ReactSelect
      options={sorters.map((sorter) => ({
        label: sorter.label,
        value: sorter.id,
      }))}
      onChange={(item) => onSort(item.value)}
      value={selectedValue}
      isSearchable={false}
      classes={{
        indicatorsContainer: () => indicatorsContainerStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        control: ({ isFocused }) =>
          clsx(
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            controlStyles.base
          ),
      }}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: "unset",
        }),
        menu: (base) => ({
          ...base,
          width: "180px",
          right: 0,
        }),
        valueContainer: (base) => ({
          ...base,
          "@media (max-width: 767px)": {
            display: "none",
          },
        }),
      }}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeSorter: selectors.getActiveSorter(state, ownProps.listId),
  };
};

const ConnectedComponent = connect(mapStateToProps, { updateSort })(
  SortDropDown
);
export { ConnectedComponent as SortDropDown };
