import { useEffect, useState } from "react";

export const useVehicleFilters = (filter) => {
  const [kmError, setKmError] = useState("");
  const [ageError, setAgeError] = useState("");

  const [age, setAge] = useState(
    { min: filter.age_min, max: filter.age_max } || { min: "", max: "" }
  );

  const [km, setKm] = useState(
    { min: filter.km_min, max: filter.km_max } || { min: "", max: "" }
  );

  useEffect(() => {
    setAge({ min: filter.age_min, max: filter.age_max });
    setKm({ min: filter.km_min, max: filter.km_max });
  }, [filter]);

  return {
    age,
    setAge,
    ageError,
    setAgeError,
    km,
    setKm,
    kmError,
    setKmError,
  };
};
