import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LoadingIndicator } from "../../components";
import { fetchStatistics } from "./actions";
import { BaseStatistics } from "./base/BaseStatistics";
import { ExpertStatistics } from "./expert/ExpertStatistics";
import { NoStats } from "./NoStats";
import * as selectors from "./selectors";

export const Statistics = () => {
  const data = useSelector(selectors.getData);
  const loading = useSelector(selectors.getLoading);
  const error = useSelector(selectors.getError);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStatistics());
  }, []);

  if (loading) {
    return <LoadingIndicator loading={loading} text={t("Loading history")} />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (data === null) {
    return <NoStats />;
  }

  const componentMap = {
    BASE: <BaseStatistics data={data} />,
    EXPERT: <ExpertStatistics data={data} />,
  };

  return componentMap[data.modus] || <ExpertStatistics data={data} />;
};
