import { API_ENDPOINTS } from "../../../api/endpoints";

export const fetchApprovalsService = async (_, thunkAPI) => {
  const currentState = thunkAPI.getState();
  // Get token from redux state
  const token = currentState.session.token;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Token " + token,
    "Accept-Language": currentState.localization.locale,
  };

  const options = {
    method: "GET",
    headers: headers,
  };

  try {
    const response = await fetch(API_ENDPOINTS.COMPANIES_FOR_COMPANY, options);

    if (response.ok) {
      return await response.json();
    }
    // The rejected value will be available in the action.payload reducer
    return thunkAPI.rejectWithValue({
      status: response.status,
      statusText: response.statusText,
    });
  } catch (err) {
    throw err;
  }
};
