export const getToken = (state) => state.session.token;
export const hasToken = (state) => getToken(state) !== "";
export const getRegistrationStep = (state) => state.session.registration_step;
export const getTermsAndConditionsReviewed = (state) =>
  state.session.terms_and_conditions_reviewed;

export const isAuthenticated = (state) => state.session.authenticated;
export const isRegistrationPending = (state) =>
  getRegistrationStep(state) > 0 && getRegistrationStep(state) < 100;
//export const isRegistrationPending = (state) => false;
export const isMailActivationPending = (state) =>
  !state.session.email_validated;
//export const isMailActivationPending = (state) => false;

export const getAuthenticationCode = (state) => state.session.code;

export const getUser = (state) => state.session.user;
export const getUserId = (state) => state.session.user_id;

// Company_user_state
export const getUserState = (state) => state.session.company_user_state;
