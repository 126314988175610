import { warningTypes } from "./types";

export const warnings = (state = { data: [] }, action) => {
  switch (action.type) {
    case warningTypes.FETCH_REQUEST:
      return {
        ...state,
        data: [],
      };
    case warningTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.warnings,
      };
    case warningTypes.FETCH_FAILURE:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};
