export const listVehicleFavoriteTypes = {
  FETCH_REQUEST: "LISTVEHICLE_FAVORITES_FETCH_REQUEST",
  FETCH_SUCCESS: "LISTVEHICLE_FAVORITES_FETCH_SUCCESS",
  FETCH_FAILURE: "LISTVEHICLE_FAVORITES_FETCH_FAILURE",
  POST_REQUEST: "LISTVEHICLE_FAVORITE_POST_REQUEST",
  POST_SUCCESS: "LISTVEHICLE_FAVORITE_POST_SUCCESS",
  POST_FAILURE: "LISTVEHICLE_FAVORITE_POST_FAILURE",
  ERROR_CLEAR: "LISTVEHICLE_FAVORITE_ERROR_CLEAR",

  ADD: "LISTVEHICLE_FAVORITE_ADD",
  REMOVE: "LISTVEHICLE_FAVORITE_REMOVE",
};
