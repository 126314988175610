import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routes } from "../../../_web/navigation/routes";
import { Template } from "../../../components";
import { Button } from "../../../components/web/Button";
import logoWeb from "../../../img/logo_web.png";

export const PageNotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // TODO: discuss where button should navigate as login page doesn't make sense when the user is already logged in
  return (
    <Template headerVisible={false} footerVisible={false}>
      <div className="mt-5 flex flex-col justify-center bg-white py-10 text-center">
        <div className="flex justify-center">
          <img src={logoWeb} alt="Brand" className="img-fluid ml-10" />
        </div>

        <h1 className="py-10 text-[2.5rem]">404</h1>

        <p>
          <span className="mb-10 text-[20px]">
            {t("The page you are looking for could not be found.")}
            <br />
          </span>
          {t(
            "Make sure the address is correct and that the page hasn't moved."
          )}
        </p>

        <div className="mt-5 flex justify-center">
          <Button onClick={() => navigate(routes.LOGIN)}>
            <span>{t("Login")}</span>
            <i className="fa fa-chevron-right ml-1 text-sm" />
          </Button>
        </div>
      </div>
    </Template>
  );
};
