import React from "react";

import { Switch } from "@headlessui/react";

export const ToggleSwitch = ({ value, onChange }) => {
  return (
    <Switch
      checked={value}
      onChange={onChange}
      className={`${
        value ? "border-primary bg-primary" : "border-[#dddddd] bg-white"
      }
          inline-flex h-[26px] w-[43px] shrink-0 
          cursor-pointer rounded-full border 
          transition-colors duration-100 
          ease-in-out focus:outline-none 
          focus-visible:ring-1  focus-visible:ring-white/75`}
    >
      <span
        className={`${
          value ? "translate-x-[18px] bg-white" : "translate-x-0 bg-[#dddddd]"
        }
            pointer-events-none 
            my-auto ml-[2px] inline-block 
            size-[19px] transform rounded-full 
            ring-0 transition duration-100 ease-in-out`}
      />
    </Switch>
  );
};
