import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import { lockColleague, unlockColleague } from "../actions";
import * as selectors from "../selectors";

const ColleagueItem = (props) => {
  const { t } = useTranslation();

  const handleLock = (colleague) => {
    const { lockColleague, unlockColleague } = props;
    if (colleague.state !== "LOCKED_APPROVED") {
      lockColleague(colleague);
    } else {
      unlockColleague(colleague);
    }
  };

  const { colleague } = props;
  const color = colleague.state === "LOCKED_APPROVED" ? "red" : "#999";

  return (
    <article className="flex w-full flex-col justify-between gap-y-2 rounded border p-2 xl:flex-row">
      <div className="flex w-full xl:w-9/12">
        <i
          className="fa fa-user-circle-o m-1 my-auto text-[30px] xl:m-2 xl:text-[40px]"
          style={{ color: color }}
        />

        <div className="ml-1  xl:ml-2">
          <div>
            {colleague.state === "LOCKED_APPROVED" && (
              <i className="fa fa-lock mr-1 inline-block text-[16px] text-[#999]" />
            )}
            <h5 className="inline-block">
              {colleague.first_name} {colleague.last_name}
            </h5>
          </div>
          <p className="w-full break-words text-xs italic text-[#999] lg:w-[200px] 2xl:w-full">
            {colleague.email ? colleague.email : null}
          </p>
        </div>
      </div>
      <div className="flex w-full items-start xl:w-3/12 xl:justify-end">
        <Button
          className="w-full rounded border bg-white p-1.5 text-sm hover:bg-highlightColor hover:text-white xl:w-auto"
          onClick={() => handleLock(colleague)}
        >
          {colleague.state === "LOCKED_APPROVED" ? t("Unlock") : t("Lock")}
        </Button>
      </div>
    </article>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: selectors.getLockLoading(state, ownProps.colleague.id),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  lockColleague,
  unlockColleague,
})(ColleagueItem);

export { ConnectedComponent as ColleagueItem };
