import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { compose } from "redux";

import { withConfig } from "../../../config";
import i18n from "../../../i18n";
import {
  createFilterAndSubmit,
  currentStepUpdate,
  fetchData,
  refreshData,
  sendActivationMail,
  submitCompanyInfo,
  submitPersonalInfo,
  submitSettings,
  updateFilterAndSubmit,
  updateFilterCompaniesAndSubmit,
  uploadDocumentsAndSubmit,
} from "../actions";
import * as selectors from "../selectors";
import { Wizard } from "./components/Wizard";
import { WizardActivateMail } from "./components/WizardActivateMail";
import { WizardCompleted } from "./components/WizardCompleted";
import { WizardStepOne } from "./components/WizardStep1";
import { WizardStepTwo } from "./components/WizardStep2";
import { WizardStepThree } from "./components/WizardStep3";
import { WizardStepFour } from "./components/WizardStep4";
import { WizardStepFive } from "./components/WizardStep5";
import { WizardStepSix } from "./components/WizardStep6";

import { includes, filter as lodashFilter } from "lodash";

const documentTypes = [
  {
    id: "ID_CARD",
    description: i18n.t("Identity card", true),
    icon: "fa fa-id-card-o",
  },
  {
    id: "EMPTY_INVOICE",
    description: i18n.t("Empty invoice", true),
    icon: "fa fa-file-pdf-o",
  },
  {
    id: "STATUTES",
    description: i18n.t("Statutes", true),
    icon: "fa fa-file-o",
  },
  {
    id: "DEALERSHIP_PICTURE",
    description: i18n.t("Dealership picture", true),
    icon: "fa fa-camera-retro",
  },
];

const RegistrationWizard = (props) => {
  const {
    config,
    countries,
    createFilterAndSubmit,
    currentStepUpdate,
    error,
    filter,
    filterCompanies,
    filterMakes,
    hasAllData,
    loading,
    registration,
    sendActivationMail,
    step,
    submitCompanyInfo,
    submitPersonalInfo,
    submitSettings,
    updateFilterAndSubmit,
    updateFilterCompaniesAndSubmit,
    uploadDocumentsAndSubmit,
  } = props;

  const { registration_steps } = config;
  const { t } = useTranslation();

  const onHandlePrev = () => {
    currentStepUpdate(step - 1);
  };

  const onSendActivationMail = () => {
    sendActivationMail(registration.id);
  };

  const onHandlePersonalInfo = (data) => {
    submitPersonalInfo(registration.id, data);
  };

  const onHandleCompanyInfo = (data) => {
    submitCompanyInfo(registration.id, data);
  };

  const onHandleLegalDocuments = (documents) => {
    uploadDocumentsAndSubmit(registration.id, documents);
  };

  const onHandlePreferences = (data) => {
    submitSettings(registration.id, data);
  };

  const onHandleFilters = (makes) => {
    if (registration.filter_id === 0 || filter === null) {
      const createFilter = {
        id: -1,
        name: "Default",
        regions: [],
        excluded_companies: [],
        makes: makes,
        age_min: 0,
        age_max: 0,
        km_min: 0,
        km_max: 0,
      };

      createFilterAndSubmit(registration.id, createFilter);
    } else {
      const updateFilter = { ...filter, makes: makes };
      updateFilterAndSubmit(registration.id, updateFilter);
    }
  };

  const onHandleFilterCompanies = (companies, presentation_text) => {
    const excluded_companies = lodashFilter(
      filterCompanies.map((c) => c.id),
      (id) => !includes(companies, id)
    );
    const updateFilter = { ...filter, excluded_companies };

    updateFilterCompaniesAndSubmit(
      registration.id,
      presentation_text,
      updateFilter
    );
  };

  // Initial data fetch
  useEffect(() => {
    props.fetchData();
  }, []);

  // Refresh data on step change
  useEffect(() => {
    props.refreshData();
  }, [step]);

  if (!hasAllData) {
    return <div> {t("loading data")} ....</div>;
  }

  if (!loading && registration === null) {
    return <div> {t("No registration found.")}</div>;
  }

  const complete =
    step === registration_steps.length + 1 || registration.step === 100;

  if (complete) {
    return <WizardCompleted onComplete={() => props.onWizardCompleted()} />;
  }

  return (
    <Wizard step={step} error={error} complete={complete}>
      {registration_steps.includes(1) && (
        <WizardActivateMail
          title={t("E-mail confirmation")}
          required={true}
          registration={registration}
          onSendActivationMail={onSendActivationMail}
        />
      )}

      {registration_steps.includes(2) && (
        <WizardStepOne
          title={t("Personal info")}
          required={true}
          loading={loading}
          registration={registration}
          onNext={onHandlePersonalInfo}
          onPrev={onHandlePrev}
        />
      )}

      {registration_steps.includes(3) && (
        <WizardStepTwo
          title={t("Company info")}
          required={true}
          loading={loading}
          registration={registration}
          countries={countries}
          onNext={onHandleCompanyInfo}
          onPrev={onHandlePrev}
        />
      )}

      {registration_steps.includes(4) && (
        <WizardStepThree
          title={t("Legal documents")}
          required={false}
          loading={loading}
          registration={registration}
          documentTypes={documentTypes}
          onNext={onHandleLegalDocuments}
          onPrev={onHandlePrev}
        />
      )}

      {registration_steps.includes(5) && (
        <WizardStepFour
          title={t("Preferences")}
          required={true}
          loading={loading}
          registration={registration}
          onNext={onHandlePreferences}
          onPrev={onHandlePrev}
        />
      )}

      {registration_steps.includes(6) && (
        <WizardStepFive
          title={t("Filters")}
          required={true}
          loading={loading}
          filter={filter}
          filterMakes={filterMakes}
          onNext={onHandleFilters}
          onPrev={onHandlePrev}
        />
      )}

      {registration_steps.includes(7) && (
        <WizardStepSix
          title={t("Dealers")}
          required={true}
          loading={loading}
          filter={filter}
          registration={registration}
          filterCompanies={filterCompanies}
          onNext={onHandleFilterCompanies}
          onPrev={onHandlePrev}
        />
      )}
    </Wizard>
  );
};

const mapStateToProps = (state) => {
  const registration = selectors.getRegistration(state);

  return {
    registration,
    countries: selectors.getCountries(state),
    loading: selectors.getLoading(state),
    error: selectors.getError(state),
    step: selectors.getStep(state),
    hasAllData: selectors.hasAllData(state),
    filterMakes: selectors.getFilterMakes(state),
    filterCompanies: selectors.getFilterCompanies(state),
    filter: registration
      ? selectors.getFilterById(state, registration.filter_id)
      : 0,
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps, {
    currentStepUpdate,
    fetchData,
    refreshData,
    submitPersonalInfo,
    submitCompanyInfo,
    submitSettings,
    updateFilterAndSubmit,
    createFilterAndSubmit,
    uploadDocumentsAndSubmit,
    updateFilterCompaniesAndSubmit,
    sendActivationMail,
  })
)(RegistrationWizard);

export { ConnectedComponent as RegistrationWizard };
