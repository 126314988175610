import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { ToggleSwitch } from "../../../components/web/ToogleSwitch";
import { BasketStatesTranslate } from "../../../constants";
import { addLeadingZero } from "../../../helpers/utils";
import nopic from "../../../img/nopic.jpeg";
import { Bidding } from "../../bidding";
import * as selectors from "../selectors";
import { ConfirmHeader } from "./ConfirmHeader";

import { filter, includes } from "lodash";

const CartVehicles = (props) => {
  const { vehicles } = props;
  const { t } = useTranslation();
  const [excluded_vehicles, setExcluded_vehicles] = useState([]);

  const handleVehicle = (listVehicleId) => {
    if (!includes(excluded_vehicles, listVehicleId)) {
      setExcluded_vehicles([...excluded_vehicles, listVehicleId]);
    } else {
      setExcluded_vehicles(
        filter(excluded_vehicles, (id) => id !== listVehicleId)
      );
    }
  };

  const handleEndSelection = () => {
    props.onSelectionEnd(
      filter(
        vehicles,
        (veh) => !includes(excluded_vehicles, veh.list_vehicle_id)
      )
    );
  };

  return (
    <div className="bg-normal flex flex-col">
      <ConfirmHeader
        cancelBtnText={t("Cancel")}
        nextBtnText={t("Check out")}
        title={`01. ${t("Please verify your basket content.")}`}
        onCancel={props.onCancel}
        onNext={handleEndSelection}
      />

      <div className="grid w-full grid-cols-1 gap-2 md:grid-cols-2">
        {vehicles.map((veh, x) => {
          const {
            list_vehicle_id,
            picture_url,
            make,
            model,
            version,
            basket_state,
            list_number,
          } = veh;

          return (
            <div
              key={list_vehicle_id}
              className="cursor-pointer"
              onClick={(event) => handleVehicle(list_vehicle_id, event)}
            >
              <div className="flex bg-white p-3">
                <div className="w-4/12 text-center">
                  <div className="relative w-full">
                    {picture_url && (
                      <img src={picture_url} alt={make + " " + model} />
                    )}

                    {!picture_url && (
                      <img src={nopic} alt={make + " " + model} />
                    )}

                    <div className="absolute bottom-0 h-[22px] w-full bg-highlightColor text-highlightInvertColor">
                      {t(BasketStatesTranslate[basket_state])}
                    </div>
                  </div>
                </div>

                <div className="w-8/12">
                  <div className="flex px-4">
                    <div className="w-10/12">
                      <h5 className="h5 g-mb-0 truncate uppercase text-highlightColor">
                        #{addLeadingZero(list_number, 3)} {make} {model}
                      </h5>

                      <p className="truncate text-sm uppercase text-[#999]">
                        {version}
                      </p>

                      <div className="mt-5 w-[150px]">
                        <Bidding listVehicleId={list_vehicle_id} />
                      </div>
                    </div>

                    <div className="flex w-2/12 justify-end">
                      <ToggleSwitch
                        value={!includes(excluded_vehicles, list_vehicle_id)}
                        onChange={() => handleVehicle(list_vehicle_id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    vehicles: selectors.getVehiclesToConfirm(state, ownProps.listId),
  };
};

const ConnectedComponent = connect(mapStateToProps)(CartVehicles);

export { ConnectedComponent as CartVehicles };
