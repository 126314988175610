import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { submit } from "redux-form";

import { RegistrationWizardContentLayout } from "../../../../layouts/RegistrationWizardContentLayout";
import { ContactInfo } from "./ContactInfo";
import { PersonalInfoForm } from "./forms/PersonalInfoForm";
import { WizardButtons } from "./WizardButtons";

export const personalInfoFields = {
  TITLE: "title",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  MAIL: "email",
  PHONE: "telephone",
  MOBILE: "mobile",
};

const mapDataToFields = (data) => {
  return {
    [personalInfoFields.TITLE]: data.title,
    [personalInfoFields.FIRST_NAME]: data.first_name,
    [personalInfoFields.LAST_NAME]: data.last_name,
    [personalInfoFields.MAIL]: data.email,
    [personalInfoFields.PHONE]: data.telephone,
    [personalInfoFields.MOBILE]: data.mobile,
  };
};

const LeftSectionContent = ({ step, steps }) => {
  const { t } = useTranslation();
  return (
    <>
      <hgroup>
        <h1 className="text-[1.25rem] text-primary">
          {t("Step")}: {step}/{steps} : {t("Personal info")}
        </h1>
        <h2 className="py-2 text-base">
          {t("How can we reach you the best?")}
        </h2>
      </hgroup>
      <p>
        {t(
          "With correct information we can offer you the support you expect from us."
        )}
      </p>
      <ContactInfo />
    </>
  );
};
const WizardStepOne = (props) => {
  const { required, step, totalSteps, submit, onNext, registration, loading } =
    props;

  return (
    <RegistrationWizardContentLayout
      leftContent={<LeftSectionContent step={step} steps={totalSteps} />}
      rightContent={
        <PersonalInfoForm
          initialValues={mapDataToFields(registration)}
          required={required}
          onSubmit={(values) => onNext(values)}
        />
      }
      bottomContent={
        <WizardButtons
          currentStep={step}
          onNext={() => submit("Registration-PersonalInfo")}
        />
      }
      loading={loading}
    />
  );
};

const ConnectedComponent = connect(null, { submit })(WizardStepOne);

export { ConnectedComponent as WizardStepOne };
