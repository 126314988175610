import {
  fetchProfile,
  fetchRegistrationStep,
  fetchToken,
  fetchTokenByCode,
} from "../authentication/authenticationSlice";

import { createSlice } from "@reduxjs/toolkit";

const initialCompanyUserState = {
  error: false,
  message: "",
};

export const initialState = {
  authenticated: false,
  token: "",
  user_id: null,
  registration_step: 0,
  user: {},
  email_validated: false,
  last_login: null,
  company_user_state: initialCompanyUserState,
  terms_and_conditions_reviewed: false,
};

const updateSession = (state, action) => {
  //TODO: refactor this according to https://redux.js.org/style-guide/#reducers-should-own-the-state-shape
  return { ...state, ...action.payload };
};

const updateSessionRegistrationStep = (state, action) => {
  state.registration_step = action.payload;
  state.company_user_state = {
    error: false,
    message: "",
  };
};

const updateTermsAndConditionsReviewed = (state, action) => {
  state.terms_and_conditions_reviewed = action.payload;
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    updatedSession: updateSession,
    clearedSession: (state) => {
      return initialState;
    },
    updatedSessionRegistrationStep: updateSessionRegistrationStep,
    updatedTermsAndConditionsReviewed: updateTermsAndConditionsReviewed,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchToken.fulfilled, onFetchTokenFulfilled);
    builder.addCase(fetchTokenByCode.fulfilled, onFetchTokenFulfilled);
    builder.addCase(fetchProfile.fulfilled, onFetchProfileSuccess);
    builder.addCase(
      fetchRegistrationStep.fulfilled,
      onFetchRegistrationStepSuccess
    );
  },
});

const onFetchTokenFulfilled = (state, action) => {
  state.token = action.payload.token;
  state.user_id = action.payload.user_id || 0;
};

const onFetchProfileSuccess = (state, action) => {
  const profile = action.payload[0];

  if (profile !== undefined) {
    state.user = {
      first_name: profile.first_name,
      last_name: profile.last_name,
    };
  } else {
    state.user = { first_name: "", last_name: "" };
  }
};

const onFetchRegistrationStepSuccess = (state, action) => {
  const registration = action.payload[0];

  if (registration !== undefined) {
    //   TODO: check if immer is used here and if values are updated correctly
    state.email_validated = registration.email_validated;
    state.registration_step = registration.step;
    state.company_user_state = {
      error: registration.company_user_state.error || false,
      message: registration.company_user_state.message || "",
    };
  } else {
    return {
      ...state,
      email_validated: false,
      registration_step: 0,
      user: { first_name: "", last_name: "" },
      company_user_state: {
        error: true,
        message: "No registration found",
      },
    };
  }
};

export const {
  updatedSession,
  clearedSession,
  updatedSessionRegistrationStep,
  updatedTermsAndConditionsReviewed,
} = sessionSlice.actions;

export default sessionSlice.reducer;
