import React from "react";
import { useTranslation } from "react-i18next";

export const NoStats = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <div className="mt-10">
        <i className="fa fa-line-chart text-[180px] text-primary" />
      </div>

      <h5 className="text-highlightColorColor mb-2 mt-5 font-extrabold">
        {t("Currently no statistics available.")}
      </h5>

      <div className="my-5">
        {t(
          "As soon as you start participating to the auctions, your statistics will be visible here."
        )}
      </div>
    </div>
  );
};
