import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { Field, reduxForm } from "redux-form";

import { validators } from "../../../components";
import { Button } from "../../../components/web/Button";

export const registrationFields = {
  COMPANY_NAME: "company_name",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  EMAIL: "email",
  PASSWORD: "password",
  PASSWORD_CONFIRM: "password_confirm",
  TERMS: "terms",
};

const TextField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => {
  const { t } = useTranslation();

  return (
    <div className={"form-group flex flex-col"}>
      <label className="text-[13px] font-semibold text-[#333]">{label}</label>

      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className={clsx("form-control g-py-15 g-px-15 rounded", {
          "bg-red-100": touched && error,
        })}
      />

      {touched &&
        ((error && (
          <small className="form-control-feedback text-sm text-red-500">
            {t(error)}
          </small>
        )) ||
          (warning && <span>{t(warning)}</span>))}
    </div>
  );
};

const CheckBox = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error, warning },
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`form-group ${touched && error ? "u-has-error-v1" : ""} mb-4`}
    >
      <label className="form-check-inline cursor-pointer text-[13px] text-[#999]">
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          className="
          disabled:border-grey-400 disabled:bg-grey-400 border-1
          peer relative mr-1 size-4
          shrink-0 appearance-none
          rounded-sm border-[#ccc] bg-white checked:border-0
          checked:bg-primary focus:outline-none
          focus:ring-1
          focus:ring-blue-100
          focus:ring-offset-0
        "
        />
        {label}
      </label>

      <div>
        {touched &&
          ((error && (
            <small className="form-control-feedback text-sm text-red-500">
              {t(error)}
            </small>
          )) ||
            (warning && <span>{t(warning)}</span>))}
      </div>
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  if (values.password !== values.password_confirm) {
    errors.password_confirm = "Passwords are not equal";
  }
  return errors;
};

const RegistrationForm = (props) => {
  const { handleSubmit, submitting } = props;
  const { t } = useTranslation();

  return (
    <form className="mb-4 space-y-5" onSubmit={handleSubmit}>
      <Field
        name={registrationFields.COMPANY_NAME}
        type="text"
        component={TextField}
        label={t("Company name")}
        placeholder={t("Company name")}
        validate={[validators.required]}
      />

      <div className="flex flex-col space-y-5 sm:flex-row sm:justify-between sm:space-x-5 sm:space-y-0">
        <div className="w-full sm:w-1/2">
          <Field
            name={registrationFields.FIRST_NAME}
            type="text"
            label={t("Your firstname")}
            component={TextField}
            placeholder={t("First name")}
            validate={[validators.required]}
          />
        </div>

        <div className="w-full sm:w-1/2">
          <Field
            name={registrationFields.LAST_NAME}
            type="text"
            label={t("Your lastname")}
            component={TextField}
            placeholder={t("Lastname")}
            validate={[validators.required]}
          />
        </div>
      </div>

      <Field
        name={registrationFields.EMAIL}
        type="email"
        component={TextField}
        label={t("E-mail")}
        placeholder={t("E-mail address")}
        validate={[validators.required, validators.email]}
      />

      <div className="flex flex-col space-y-5 sm:flex-row sm:justify-between sm:space-x-5 sm:space-y-0">
        <div className="w-full sm:w-1/2">
          <Field
            name={registrationFields.PASSWORD}
            type="password"
            component={TextField}
            label={t("Password")}
            placeholder="********"
            validate={[
              validators.required,
              validators.minLength6,
              validators.hasNumber,
              validators.hasLowerCase,
              validators.hasUpperCase,
            ]}
          />
        </div>

        <div className="w-full sm:w-1/2">
          <Field
            name={registrationFields.PASSWORD_CONFIRM}
            type="password"
            component={TextField}
            label={t("Password (confirm)")}
            placeholder="********"
            validate={[
              validators.required,
              validators.minValue6,
              validators.hasNumber,
              validators.hasLowerCase,
              validators.hasUpperCase,
            ]}
          />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row sm:justify-between sm:space-x-5">
        <div className="w-full sm:w-1/2">
          <Field
            name={registrationFields.TERMS}
            type="checkbox"
            component={CheckBox}
            label={
              <div className="inline-block text-sm">
                {t("I accept the")}{" "}
                <span className="text-primary underline">
                  <Link to="../terms-and-conditions" target="_blank">
                    {t("general sales conditions")}.
                  </Link>
                </span>
              </div>
            }
            validate={[validators.required]}
          />
        </div>
        <div className="flex w-full justify-center sm:w-1/2 sm:justify-end">
          <div>
            <Button type="submit" disabled={submitting}>
              {t("Register")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const ConnectedComponent = reduxForm({
  form: "registration",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
})(RegistrationForm);

export { ConnectedComponent as RegistrationForm };
