import { config } from "../../config";

import { has, size } from "lodash";

export const getStep = (state) => state.registration_wizard.wizard.step;

export const hasAllData = (state) =>
  getRegistration(state) !== null &&
  (getFilterMakes(state).length > 0 ||
    !config.registration_steps.includes(6)) &&
  (getFilterCompanies(state).length > 0 ||
    !config.registration_steps.includes(7)) &&
  getCountries(state).length > 0;

export const getRegistration = (state) =>
  state.registration_wizard.data.registration.registration_id > 0
    ? state.registration_wizard.data.registration.byId[
        state.registration_wizard.data.registration.registration_id
      ]
    : null;
export const getRegistrationLoading = (state) =>
  state.registration_wizard.data.registration.loading;
export const getRegistrationError = (state) =>
  state.registration_wizard.data.registration.error;

export const getCountries = (state) =>
  state.registration_wizard.data.countries.allCountries;
export const getCountriesLoading = (state) =>
  state.registration_wizard.data.countries.loading;
export const getCountriesError = (state) =>
  state.registration_wizard.data.countries.error;

export const getFilters = (state) =>
  state.registration_wizard.data.filters.byId;
export const hasFilters = (state) =>
  size(state.registration_wizard.data.filters.byId) > 0;
export const getFilterById = (state, id) =>
  has(state.registration_wizard.data.filters.byId, id)
    ? state.registration_wizard.data.filters.byId[id]
    : null;
export const getFilterLoading = (state) =>
  state.registration_wizard.data.filters.loading;
export const getFilterError = (state) =>
  state.registration_wizard.data.filters.error;

export const getFilterMakes = (state) =>
  state.registration_wizard.data.filter_makes.allMakes;
export const getFilterMakesLoading = (state) =>
  state.registration_wizard.data.filter_makes.loading;
export const getFilterMakesError = (state) =>
  state.registration_wizard.data.filter_makes.error;

export const getFilterCompanies = (state) =>
  state.registration_wizard.data.filter_companies.allCompanies;
export const getFilterCompaniesLoading = (state) =>
  state.registration_wizard.data.filter_companies.loading;
export const getFilterCompaniesError = (state) =>
  state.registration_wizard.data.filter_companies.error;

export const getDocuments = (state) => state.registration_wizard.documents;

export const getLoading = (state) => {
  const step = getStep(state);
  const loading = state.registration_wizard.wizard.loading;

  if (step === 6 && !hasFilters(state)) {
    return getCreateFilterLoading(state) || loading;
  }

  if ((step === 6 || step === 7) && hasFilters(state)) {
    return getUpdateFilterLoading(state) || loading;
  }

  return loading;
};

export const getError = (state) => {
  const step = getStep(state);
  const error = state.registration_wizard.wizard.error;

  if (step === 6 && !hasFilters(state)) {
    const errorCreate = getCreateFilterError(state);
    if (errorCreate !== "") return errorCreate;
  }

  if ((step === 6 || step === 7) && hasFilters(state)) {
    const errorUpdate = getUpdateFilterError(state);
    if (errorUpdate !== "") return errorUpdate;
  }
  return error;
};

// TODO
export const getCreatedFilterId = (state) =>
  state.registration_wizard.filter_create.filter_id;
export const getCreateFilterLoading = (state) =>
  state.registration_wizard.filter_create.loading;
export const getCreateFilterSuccess = (state) =>
  state.registration_wizard.filter_create.success;
export const getCreateFilterError = (state) =>
  state.registration_wizard.filter_create.error;

export const getUpdateFilterLoading = (state) =>
  state.registration_wizard.filter_update.loading;
export const getUpdateFilterSuccess = (state) =>
  state.registration_wizard.filter_update.success;
export const getUpdateFilterError = (state) =>
  state.registration_wizard.filter_update.error;
export const getUpdatedFilterId = (state) =>
  state.registration_wizard.filter_update.filter_id;

export const getStepLoading = (state, id) =>
  state.registration_wizard.steps.byId[id].loading;
export const getStepError = (state, id) =>
  state.registration_wizard.steps.byId[id].error;
