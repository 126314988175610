import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { gettext } from "../../translations/index";
import { mapCompanies, mapFilters, mapMakes } from "./mappers";
import { filterCompanyTypes, filterMakeTypes, filterTypes } from "./types";

export const filtersCompaniesFetch = () => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.FILTERS_COMPANIES,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: filterCompanyTypes.FETCH_REQUEST,
          },
          {
            type: filterCompanyTypes.FETCH_SUCCESS,
            payload: (action, state, res) =>
              res.json().then((data) => mapCompanies(data)),
          },
          {
            type: filterCompanyTypes.FETCH_FAILURE,
          },
        ],
      },
    });
  };
};

export const filtersMakesFetch = () => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.FILTERS_MAKES,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: filterMakeTypes.FETCH_REQUEST,
          },
          {
            type: filterMakeTypes.FETCH_SUCCESS,
            payload: (action, state, res) =>
              res.json().then((data) => mapMakes(data)),
          },
          {
            type: filterMakeTypes.FETCH_FAILURE,
          },
        ],
      },
    });
  };
};

export const filtersFetch = (selectedIdAfterRefresh = 0) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.FILTERS,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: filterTypes.FETCH_REQUEST,
          },
          {
            type: filterTypes.FETCH_SUCCESS,
            payload: (action, state, res) =>
              onFetchSuccess(dispatch, res, selectedIdAfterRefresh),
          },
          {
            type: filterTypes.FETCH_FAILURE,
          },
        ],
      },
    });
  };
};

const onFetchSuccess = (dispatch, res, selectedIdAfterRefresh) => {
  return res.json().then((data) => {
    const result = mapFilters(data);
    if (selectedIdAfterRefresh > 0 && result[selectedIdAfterRefresh] !== null) {
      dispatch(selectFilter(result[selectedIdAfterRefresh]));
    } else if (selectedIdAfterRefresh === -1) {
      dispatch(selectFilter(null));
    }
    return result;
  });
};

export const createFilter = (filter) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.FILTERS,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filter),
        types: [
          {
            type: filterTypes.CREATE_REQUEST,
            payload: {
              action: gettext("Create"),
            },
          },
          {
            type: filterTypes.CREATE_SUCCESS,
            payload: (action, state, res) => onCreateSuccess(dispatch, res),
          },
          {
            type: filterTypes.CREATE_FAILURE,
          },
        ],
      },
    });
  };
};

const onCreateSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    dispatch(filtersFetch(data.filterId));
  });
};

export const updateFilter = (filter) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.FILTERS}${filter.id}/`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filter),
        types: [
          {
            type: filterTypes.UPDATE_REQUEST,
            payload: {
              action: gettext("Update"),
            },
          },
          {
            type: filterTypes.UPDATE_SUCCESS,
            payload: (action, state, res) => onUpdateSuccess(dispatch, res),
          },
          {
            type: filterTypes.UPDATE_FAILURE,
          },
        ],
      },
    });
  };
};

const onUpdateSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    dispatch(filtersFetch(data.filterId));
  });
};

export const deleteFilter = (filter) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.FILTERS}${filter.id}/`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        types: [
          {
            type: filterTypes.DELETE_REQUEST,
            payload: {
              action: gettext("Delete"),
            },
          },
          {
            type: filterTypes.DELETE_SUCCESS,
            payload: (action, state, res) => onDeleteSuccess(dispatch, res),
          },
          {
            type: filterTypes.DELETE_FAILURE,
          },
        ],
      },
    });
  };
};

const onDeleteSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    dispatch(filtersFetch(-1));
  });
};

export const updateSelectedFilterCompaniesSearchTerm = (searchTerm) => ({
  type: filterTypes.SEARCH_COMPANIES,
  payload: {
    searchTerm,
  },
});

export const updateSelectedFilterMakesSearchTerm = (searchTerm) => ({
  type: filterTypes.SEARCH_MAKES,
  payload: {
    searchTerm,
  },
});

export const selectFilter = (filter) => ({
  type: filterTypes.SELECT_FILTER,
  payload: {
    filter,
  },
});

export const updateSelectedFilterData = (filter) => ({
  type: filterTypes.UPDATE_FILTER_DATA,
  payload: {
    filter,
  },
});

export const selectedFilterErrorConfirm = () => ({
  type: filterTypes.ERROR_CLEAR,
});
