export const types = {
  PROFILE_FETCH_REQUEST: "PROFILE_FETCH_REQUEST",
  PROFILE_FETCH_SUCCESS: "PROFILE_FETCH_SUCCESS",
  PROFILE_FETCH_FAILURE: "PROFILE_FETCH_FAILURE",

  PROFILE_POST_REQUEST: "PROFILE_POST_REQUEST",
  PROFILE_POST_SUCCESS: "PROFILE_POST_SUCCESS",
  PROFILE_POST_FAILURE: "PROFILE_POST_FAILURE",

  PROFILE_COUNTRIES_FETCH_REQUEST: "PROFILE_COUNTRIES_FETCH_REQUEST",
  PROFILE_COUNTRIES_FETCH_SUCCESS: "PROFILE_COUNTRIES_FETCH_SUCCESS",
  PROFILE_COUNTRIES_FETCH_FAILURE: "PROFILE_COUNTRIES_FETCH_FAILURE",

  PROFILE_DOCUMENT_UPLOAD_REQUEST: "PROFILE_DOCUMENT_UPLOAD_REQUEST",
  PROFILE_DOCUMENT_UPLOAD_SUCCESS: "PROFILE_DOCUMENT_UPLOAD_SUCCESS",
  PROFILE_DOCUMENT_UPLOAD_FAILURE: "PROFILE_DOCUMENT_UPLOAD_FAILURE",
};
