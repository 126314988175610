import React from "react";

import { formatPrice } from "../../../../../../helpers/functions";

export const PriceDisplay = ({ price }) => {
  return (
    <div className="bg-highlightColor text-center font-semibold text-highlightInvertColor">
      {formatPrice(price)}
    </div>
  );
};
