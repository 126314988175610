import React from "react";
import { useTranslation } from "react-i18next";

import { compose } from "redux";
import { Field, reduxForm } from "redux-form";

import { FormFields, validators } from "../../../../../components";
import { withConfig } from "../../../../../config";
import { companyInfoFields } from "../WizardStep2";

import { capitalize } from "lodash";

// TODO: Legacy checkVat
// var checkVat = require('validate-vat');

const { TextField, Multiline, Combo } = FormFields;

const CompanyInfoForm = (props) => {
  const { countries, config } = props;
  const { t } = useTranslation();

  // TODO: Legacy comment below:
  // constructor(props) {
  //     super(props);

  //     // this.checkVat = this.checkVat.bind(this);
  //     // this.parseVat = this.parseVat.bind(this);
  // }

  // checkVat(value) {
  //     if (value.length === 12) {
  //         let country = value.substring(0, 2);
  //         let number = value.substring(2, value.length);

  //         checkVat(country, number, this.parseVat);
  //     }

  // BE0536650124
  // }

  // parseVat(err, info) {
  //     if (info && info.valid) {
  //         const {name, address} = info;
  //         const addressLine = address.split(",")[0];

  //         const postal = address.split(",")[1].split(" ")[1];
  //         const city = address.split(",")[1].split(" ")[2];

  //         this.props.change(companyInfoFields.COMPANY_NAME, name);
  //         this.props.change(companyInfoFields.ADDRESS, addressLine);
  //         this.props.change(companyInfoFields.POSTAL, postal);
  //         this.props.change(companyInfoFields.CITY, city);
  //     }
  // }

  return (
    <form onSubmit={props.handleSubmit} className="space-y-3">
      <Field
        name={companyInfoFields.COMPANY_NAME}
        component={TextField}
        type="text"
        label={t("Company name")}
        validate={[validators.required]}
      />

      <Field
        name={companyInfoFields.VAT}
        component={TextField}
        type="text"
        label={t("VAT number")}
        //    onChange={(e) => {
        //        checkVat(e.target.value)
        //    }}
        validate={[validators.required]}
      />

      <Field
        name={companyInfoFields.ADDRESS}
        component={Multiline}
        type="text"
        label={t("Address")}
        validate={[validators.required]}
      />

      <Field
        name={companyInfoFields.POSTAL}
        component={TextField}
        type="text"
        label={t("Postal")}
        validate={[validators.required]}
      />

      <Field
        name={companyInfoFields.CITY}
        component={TextField}
        type="text"
        label={t("City")}
        validate={[validators.required]}
      />

      <Field
        name={companyInfoFields.COUNTRY}
        component={Combo}
        label={t("Country")}
        validate={[validators.required]}
        placeholder={t("Country")}
        options={countries.map((country) => ({
          value: country.code,
          label: capitalize(country.name),
        }))}
      />

      {config.registration_company_info_logistics_email && (
        <Field
          name={companyInfoFields.MAIL}
          component={TextField}
          type="text"
          label={t("Logistic e-mail")}
          validate={[]}
          // additionalInfo={t("More info soon")}
        />
      )}

      <Field
        name={companyInfoFields.YEARLY_VOLUME}
        component={TextField}
        label={t("Estimated yearly volume")}
        type="number"
        // validate={[validators.required]}
        id={companyInfoFields.YEARLY_VOLUME}
        additionalInfo={t(
          "Estimation: how many vehicles will you process annually via Autralis? Thanks to this assessment, we, as an independent service provider, can better tailor our support to your needs."
        )}
      />
    </form>
  );
};

const ConnectedComponent = compose(
  withConfig,
  reduxForm({
    form: "Registration-CompanyInfo",
    destroyOnUnmount: false,
  })
)(CompanyInfoForm);

export { ConnectedComponent as CompanyInfoForm };
