import React from "react";

import { LoadingContainer } from "../../../components";
import { Modal } from "../../../components/web/Modal";
import { FilterDetailsHeader } from "./FilterDetailsHeader";
import { FilterForm } from "./FilterForm";

export const FilterModal = (props) => {
  const {
    visible,
    filterLoading,
    selectedFilter,
    companies,
    makes,
    onChange,
    onSave,
    onDelete,
    onCancel,
  } = props;

  return (
    <Modal visible={visible} onCancel={onCancel}>
      <div className="flex h-full w-full items-start justify-center p-2">
        <div className="relative w-full bg-white p-2">
          <button className="absolute right-2 top-2" onClick={onCancel}>
            <i className="fa fa-close block p-1 text-[26px]" />
          </button>
          <div className="mt-14">
            <div className="sticky top-0">
              <FilterDetailsHeader
                saveEnabled
                deleteEnabled
                onSave={onSave}
                onDelete={onDelete}
                onClose={onCancel}
              />
            </div>

            <div className="bg-white">
              <LoadingContainer loading={filterLoading}>
                <FilterForm
                  filter={selectedFilter}
                  allCompanies={companies}
                  allMakes={makes}
                  onChange={onChange}
                />
              </LoadingContainer>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
