import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { compose } from "redux";

import { Template } from "../components";
import { withConfig } from "../config";
import { listVehicleFetch } from "../entities/listvehicle/actions";
import { Bidding } from "../features/bidding/index";
import { fetchList } from "../features/lists/actions";
import { listCleanupByListVehicleId } from "../features/lists/actions";
import { getListById } from "../features/lists/index";
import { listVehiclesFavoriteFetch } from "../features/listvehicles-favorite/actions";
import { listVehiclesInterestFetch } from "../features/listvehicles-no-interest/actions";
import { listVehicleBasketFetch } from "../features/listvehiclestate/actions";
import { ListVehicleState } from "../features/listvehiclestate/index";
import { PrintButtons } from "../features/print";
import {
  getListIdFromListVehicleId,
  getVehicleIdFromListVehicleId,
} from "../helpers/functions";
import { buildUrl, withRouter } from "../helpers/utils";
import { routes } from "./navigation/routes";

const ListVehicle = (props) => {
  const { listVehicleId, config, listCleanupByListVehicleId } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBack = () => {
    listCleanupByListVehicleId(listVehicleId).then(() => {
      if (props.list && props.list.state === "OPEN") {
        navigate(buildUrl(routes.LIST, { listId: props.list.id }));
      } else {
        navigate(routes.DASHBOARD);
      }
    });
  };

  useEffect(() => {
    props.fetchList(getListIdFromListVehicleId(listVehicleId));
    props.listVehicleBasketFetch(listVehicleId);

    if (config.vehicle_interest) {
      props.listVehiclesInterestFetch(
        getListIdFromListVehicleId(listVehicleId)
      );
    }

    if (config.vehicle_favorite) {
      props.listVehiclesFavoriteFetch(
        getListIdFromListVehicleId(listVehicleId)
      );
    }

    props.listVehicleFetch(
      getListIdFromListVehicleId(listVehicleId),
      getVehicleIdFromListVehicleId(listVehicleId)
    );
  }, []);

  return (
    <Template>
      <div>
        <div className="bg-normal sticky top-0 z-10 mb-1 flex w-full flex-col items-center justify-between space-y-1 border-b md:top-[70px]">
          <div className="flex w-full items-center justify-between bg-white p-2">
            <div>
              <button
                onClick={handleBack}
                className="btn btn-primary rounded-0 flex items-center text-nowrap px-3 py-2 text-sm font-semibold"
              >
                <i className="fa fa-chevron-left mr-2 text-[12px]" />
                <span>
                  {props.list && props.list.state === "OPEN"
                    ? t("Back to list")
                    : t("Back to dashboard")}
                </span>
              </button>
            </div>
            <div className="flex items-center justify-end space-x-3 bg-white">
              <PrintButtons listVehicleId={listVehicleId} />
              <div className="mx-auto min-w-[150px] bg-highlightColor text-center font-semibold text-highlightInvertColor">
                <Bidding listVehicleId={listVehicleId} />
              </div>
            </div>
          </div>
        </div>
        <ListVehicleState
          listVehicleId={listVehicleId}
          containerId={listVehicleId}
          stickyTop={120}
        />
      </div>
    </Template>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { listVehicleId } = ownProps.router.params;
  const listId = getListIdFromListVehicleId(listVehicleId);

  return {
    listVehicleId,
    list: getListById(state, listId),
  };
};

const ConnectedComponent = compose(
  withRouter,
  withConfig,
  connect(mapStateToProps, {
    listVehicleFetch,
    fetchList,
    listVehicleBasketFetch,
    listVehiclesInterestFetch,
    listVehiclesFavoriteFetch,
    listCleanupByListVehicleId,
  })
)(ListVehicle);

export { ConnectedComponent as ListVehicle };
