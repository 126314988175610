import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import clsx from "clsx";

import { routes } from "../../_web/navigation/routes";
import { ComboMenu } from "../../components";
import { logout } from "../authentication";

import { Menu } from "@headlessui/react";

export const ProfileMenu = ({ config, user, registrationPending }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let menuItems = [];

  if (!registrationPending) {
    menuItems.push(
      {
        title: t("Home"),
        icon: "fa-home",
        onClick: () => navigate(routes.DASHBOARD),
      },
      {
        title: t("My profile"),
        icon: "fa-gears",
        onClick: () => navigate(routes.PROFILE),
      }
    );
  }

  if (
    !registrationPending &&
    !config.app_excluded_routes.includes("/filters")
  ) {
    menuItems.push({
      title: t("My filters"),
      icon: "fa-filter",
      onClick: () => navigate(routes.FILTERS),
    });
  }

  menuItems.push({
    title: t("Sign out"),
    icon: "fa-power-off",
    onClick: () => dispatch(logout()),
  });

  return (
    <ComboMenu
      renderTitle={() => (
        <span>
          <i className="fa fa-user-o"></i>
          <span className="ml-2 hidden sm:inline-block">{user.first_name}</span>
          <i className="fa fa-angle-down ml-2 hidden sm:inline-block"></i>
        </span>
      )}
    >
      <h5 className="text-primary">
        {user.first_name} {user.last_name}
      </h5>
      <div className="list-inline g-color-text mb-0">
        {menuItems.map((menuItem) => (
          /* Use the `active` state to conditionally style the active item. */
          <Menu.Item key={menuItem.title} as={Fragment}>
            {({ active }) => (
              <button
                onClick={menuItem.onClick}
                className={clsx("group flex w-full items-center p-2 text-sm", {
                  "bg-highlightColor text-white": active,
                  "text-[#333]": !active,
                })}
              >
                <i className={clsx("fa", menuItem.icon)} />
                <span className="ml-2">{menuItem.title}</span>
              </button>
            )}
          </Menu.Item>
        ))}
      </div>
    </ComboMenu>
  );
};
