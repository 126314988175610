import format from "string-format";

import { config } from "../../config";
import { numberFormat } from "../../helpers/functions";
import { gettext } from "../../translations";

import _ from "lodash";
import moment from "moment";

//import {vehicleDisabledByInterest} from '../listvehicles-no-interest/selectors';

export const getVehicles = (state) => state.entities.vehicle;
export const getVehicleById = (state, listVehicleId) =>
  getVehicles(state)[listVehicleId] || null;

export const getListVehicleId = (state, id) =>
  state.vehiclestate[id] ? state.vehiclestate[id].listVehicleId : 0;
export const getLoading = (state, id) =>
  state.vehiclestate[id] ? state.vehiclestate[id].isFetching : false;
export const getError = (state, id) =>
  state.vehiclestate[id] ? state.vehiclestate[id].error : "";
export const hasData = (state, id) => (state.vehiclestate[id] ? true : false);

export function getVehicleForContainer(state, listVehicleId) {
  if (listVehicleId === 0) return 0;

  const vehicle = getVehicleById(state, listVehicleId);

  var sortedPictures = _.flatten(
    _.values(
      _.map(config.vehicle_state_picture_type_order, function (id) {
        return _.filter(_.get(vehicle, "pictures"), { typeId: id });
      })
    )
  );

  return {
    list_vehicle_id: listVehicleId,
    id: _.get(vehicle, "id"),
    vehicle_id: _.get(vehicle, "vehicle_id"),
    vin: _.get(vehicle, "vin"),
    make: _.get(vehicle, "make"),
    model: _.get(vehicle, "model"),
    version: _.get(vehicle, "version"),
    description: _.get(vehicle, "description"),
    description_plus: _.get(vehicle, "description_plus"),
    vat_regime: _.get(vehicle, "vat_regime"),
    thumbnail_url: _.get(vehicle, "thumbnail_url"),
    main_company_name: _.get(vehicle, "main_company_name"),
    seller_first_name: _.get(vehicle, "seller_first_name"),
    seller_last_name: _.get(vehicle, "seller_last_name"),
    expected_date: _.get(vehicle, "expected_date"),
    expected_km: _.get(vehicle, "expected_km"),

    specifications: [
      {
        label: gettext("vin"),
        value: _.get(vehicle, "vin"),
      },
      {
        label: gettext("kilometers"),
        value: format(
          gettext("{0} km"),
          numberFormat(_.get(vehicle, "kilometers"))
        ),
      },
      {
        label: gettext("first registration"),
        value: moment(_.get(vehicle, "first_registration")).format(
          "DD/MM/YYYY"
        ),
      },
      {
        label: gettext("color interior"),
        value: _.get(vehicle, "color_interior"),
      },
      {
        label: gettext("color exterior"),
        value: _.get(vehicle, "color_exterior"),
      },
      {
        label: gettext("power_kw"),
        value: format(gettext("{0} kw"), _.get(vehicle, "power_kw")),
      },
      {
        label: gettext("engine cc"),
        value: format(gettext("{0} cc"), _.get(vehicle, "engine_cc")),
      },
      {
        label: gettext("doors"),
        value: _.get(vehicle, "doors"),
      },
      {
        label: gettext("seats"),
        value: _.get(vehicle, "seats"),
      },
      {
        label: gettext("fuel"),
        value: _.get(vehicle, "fuel"),
      },
      {
        label: gettext("emission co2"),
        value: _.get(vehicle, "emission_co2"),
      },
    ],

    features: _.orderBy(
      _.map(_.get(vehicle, "features"), (item, key) => {
        return {
          id: item.id,
          name: item.name,
          icon: item.icon,
        };
      }),
      [(item) => item.name.toLowerCase()],
      ["asc"]
    ),

    damages: _.map(_.get(vehicle, "damages"), (damage) => {
      return {
        location: damage.type,
        type: damage.type,
        note: damage.note,
        pictures: damage.pictures.map((p) => {
          return {
            url: p.src,
            description: p.title,
          };
        }),
      };
    }),

    /* sort pictures based on client setting*/
    pictures: _.map(sortedPictures, (picture) => {
      return {
        url: picture.src,
        description: picture.title,
      };
    }),

    checks: _.orderBy(
      _.get(vehicle, "checks"),
      [(check) => check.name],
      ["asc"]
    ),
  };
}
