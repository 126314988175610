import { combineReducers } from "redux";

import { errorReducer, loadingReducer } from "./reducers";

export const reducer = combineReducers({
  loading: loadingReducer,
  error: errorReducer,
});

export { createLoadingSelector, createErrorMessageSelector } from "./selectors";
