import React from "react";

export const MainCompanyNameOnMobile = ({ mainCompanyName }) => {
  return (
    <div className="mt-1 flex w-full items-center justify-between md:hidden">
      <span className="truncate text-[#777]">
        <i className="icon-map-pin" /> {mainCompanyName}
      </span>
    </div>
  );
};
