import React, { useState } from "react";

import clsx from "clsx";

import { FilterItem } from "./FilterItem";

export const FilterDropDown = (props) => {
  const { onClick, onOpen, onClose, title, items, makeActive } = props;
  const [panelOpen, setPanelOpen] = useState(false);

  const handleHeaderClick = () => {
    setPanelOpen((prevVal) => !prevVal);
    !panelOpen ? onOpen(title) : onClose(title);
  };

  const handleItemClick = (item) => {
    onClick(item);
  };

  return (
    <div className="bg-white p-3">
      <div className="flex justify-between" onClick={handleHeaderClick}>
        <div className="cursor-pointer truncate">
          <h6 className="font-extrabold text-highlightColor">{title}</h6>
        </div>
        <div
          className={clsx(
            "filter-item-check flex items-center justify-center text-center",
            {
              "filter-item-counter-active": makeActive,
            }
          )}
        >
          {makeActive ? (
            <i className="fa fa-check cursor-pointer text-sm text-white" />
          ) : (
            <i className="fa fa-caret-down cursor-pointer text-[18px] text-[#777] hover:text-primary" />
          )}
        </div>
      </div>

      {panelOpen && (
        <ul className="list-unstyled space-y-1">
          {items.map((item, i) => (
            <FilterItem
              key={`make-${title}-model-${item.model}`}
              onClick={handleItemClick}
              item={item}
              showCounters={props.showCounters}
            />
          ))}
        </ul>
      )}
    </div>
  );
};
