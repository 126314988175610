import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { useWindowSize } from "../../../hooks/useWindowSize";
import i18n from "../../../i18n";
import { Template } from "../../../layouts/Default";
import { getLocale } from "../../localization";
import { CollapsePanel } from "./CollapsePanel";
import { Video } from "./Video";

const types = {
  SUBSCRIBE: "subscribe",
  PASSWORD: "password",
  HOMEPAGE: "homepage",
  VEHICLE_DETAILS: "vehicle_details",
  HISTORY: "history",
  PROFILE_AND_FILTERS: "profile_and_filters",
  EYE_AND_SORT: "eye_and_sort",
};

const videos = [
  {
    type: types.SUBSCRIBE,
    title: i18n.t("How do I register?"),
    urls: {
      nl: "https://player.vimeo.com/video/318705754",
      fr: "https://player.vimeo.com/video/318595618",
      en: "https://player.vimeo.com/video/320151532",
    },
  },
  {
    type: types.PASSWORD,
    title: i18n.t("How can I change my password?"),
    urls: {
      nl: "https://player.vimeo.com/video/318723531",
      fr: "https://player.vimeo.com/video/318721331",
      en: "https://player.vimeo.com/video/320155289",
    },
  },
  {
    type: types.HOMEPAGE,
    title: i18n.t("What is the dashboard?"),
    urls: {
      nl: "https://player.vimeo.com/video/318943356",
      fr: "https://player.vimeo.com/video/318729366",
      en: "https://player.vimeo.com/video/320164137",
    },
  },
  {
    type: types.VEHICLE_DETAILS,
    title: i18n.t("How can I make offers on the cars?"),
    urls: {
      nl: "https://player.vimeo.com/video/318965635",
      fr: "https://player.vimeo.com/video/318963319",
      en: "https://player.vimeo.com/video/320151828",
    },
  },
  {
    type: types.HISTORY,
    title: i18n.t("Where can I find an overview of the cars I bought?"),
    urls: {
      nl: "https://player.vimeo.com/video/318957880",
      fr: "https://player.vimeo.com/video/318955639",
      en: "https://player.vimeo.com/video/320153612",
    },
  },
  {
    type: types.PROFILE_AND_FILTERS,
    title: i18n.t("Where can I change my personal information and filters?"),
    urls: {
      nl: "https://player.vimeo.com/video/318721152",
      fr: "https://player.vimeo.com/video/318708311",
      en: "https://player.vimeo.com/video/320156617",
    },
  },
  {
    type: types.EYE_AND_SORT,
    title: i18n.t("How can I filter/sort the vehicles?"),
    urls: {
      nl: "https://player.vimeo.com/video/320167197",
      fr: "https://player.vimeo.com/video/318967491",
      en: "https://player.vimeo.com/video/320167544",
    },
  },
];

const Faq = (props) => {
  const { locale } = props;
  const { t } = useTranslation();
  const [currentVideo, setCurrentVideo] = useState("");
  const [windowWidth] = useWindowSize();

  return (
    <Template>
      <div className="flex flex-col space-y-3 bg-white px-3 py-10 md:px-36">
        <div className="flex w-full flex-col">
          <h1 className="h2 text-primary">{t("How can we help? ")}</h1>
          <p>
            {t("Do you have a question? Check out our instructional videos.")}
          </p>
        </div>

        {videos.map((video, key) => (
          <React.Fragment key={key}>
            <CollapsePanel
              title={t(video.title)}
              collapsed={currentVideo !== video.type}
              onClick={() =>
                setCurrentVideo(video.type !== currentVideo ? video.type : "")
              }
            >
              <Video video={video} locale={locale} windowWidth={windowWidth} />
            </CollapsePanel>
          </React.Fragment>
        ))}

        <div className="w-full">
          <div className="text-light-gr flex h-[60px] w-full items-center justify-between bg-[#edf8f5] p-2 text-base md:px-3 md:py-6 md:text-xl">
            <span>
              {t("Other question? Mail ")}
              <span className="underline">support@usedcars.center</span>
            </span>
          </div>
        </div>
      </div>
    </Template>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: getLocale(state),
  };
};

const ConnectedComponent = connect(mapStateToProps)(Faq);

export { ConnectedComponent as Faq };
