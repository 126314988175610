import i18n from "../../i18n";
import { INITIAL_FILTERCRITERIA, INITIAL_FILTERDATA } from "./state";

import dayjs from "dayjs";
import _ from "lodash";
import moment from "moment";

export const getCriteria = (state, id) =>
  state.listvehicles_filter[id]
    ? state.listvehicles_filter[id].criteria
    : INITIAL_FILTERCRITERIA;
export const getCriteriaCopy = (state, id) =>
  state.listvehicles_filter[id]
    ? _.cloneDeep(state.listvehicles_filter[id].criteria)
    : _.cloneDeep(INITIAL_FILTERCRITERIA);
export const getData = (state, id) =>
  state.listvehicles_filter[id]
    ? state.listvehicles_filter[id].data
    : INITIAL_FILTERDATA;

export const getLoading = (state, id) =>
  state.listvehicles_filter[id]
    ? state.listvehicles_filter[id].isFetching
    : false;
export const getError = (state, id) =>
  state.listvehicles_filter[id] ? state.listvehicles_filter[id].error : "";

export const defaultRanges = {
  "This month": [moment().startOf("month"), moment().endOf("month")],
  "This month + 1": [
    moment().add(1, "M").startOf("month"),
    moment().add(1, "M").endOf("month"),
  ],
  "This month + 2": [
    moment().add(2, "M").startOf("month"),
    moment().add(2, "M").endOf("month"),
  ],
  "This month + 3": [
    moment().add(3, "M").startOf("month"),
    moment().add(3, "M").endOf("month"),
  ],
  "This month + 4": [
    moment().add(4, "M").startOf("month"),
    moment().add(4, "M").endOf("month"),
  ],
  "This month + 5": [
    moment().add(5, "M").startOf("month"),
    moment().add(5, "M").endOf("month"),
  ],
  "This month + 6": [
    moment().add(6, "M").startOf("month"),
    moment().add(6, "M").endOf("month"),
  ],
};

export const defaultRangePresets = [
  {
    label: i18n.t("This month"),
    value: [dayjs().startOf("M"), dayjs().endOf("M")],
  },
  {
    label: `${i18n.t("This month")} + 1`,
    value: [dayjs().add(1, "M").startOf("M"), dayjs().add(1, "M").endOf("M")],
  },
  {
    label: `${i18n.t("This month")} + 2`,
    value: [dayjs().add(2, "M").startOf("M"), dayjs().add(2, "M").endOf("M")],
  },
  {
    label: `${i18n.t("This month")} + 3`,
    value: [dayjs().add(3, "M").startOf("M"), dayjs().add(3, "M").endOf("M")],
  },
  {
    label: `${i18n.t("This month")} + 4`,
    value: [dayjs().add(4, "M").startOf("M"), dayjs().add(4, "M").endOf("M")],
  },
  {
    label: `${i18n.t("This month")} + 5`,
    value: [dayjs().add(5, "M").startOf("M"), dayjs().add(5, "M").endOf("M")],
  },
  {
    label: `${i18n.t("This month")} + 6`,
    value: [dayjs().add(6, "M").startOf("M"), dayjs().add(6, "M").endOf("M")],
  },
];
