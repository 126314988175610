import React from "react";
import { useTranslation } from "react-i18next";

import { SearchableList } from "../../../components";
import { Toggle } from "../../../components/web/forms/FormFields";
import {
  addCompanies,
  addCompany,
  removeCompanies,
  removeCompany,
} from "../functions/companies";

import { includes } from "lodash";

export const Companies = (props) => {
  const { t } = useTranslation();

  const handleCompany = (companyId) => {
    const checked = includes(props.filter.excluded_companies, companyId);

    if (checked) {
      props.onChange(addCompany(props.filter, companyId));
    } else {
      props.onChange(removeCompany(props.filter, companyId));
    }
  };

  const handleRemoveCompanies = (companies) => {
    props.onChange(removeCompanies(props.filter, companies));
  };

  const handleAddCompanies = (companies) => {
    props.onChange(addCompanies(props.filter, companies));
  };

  const renderItem = ({ id, data }) => (
    <article key={`company_${id}`} className="rounded-4 flex border p-2.5">
      <div className="w-9/12">
        <h5>{data.name}</h5>
        <ul className="list-unstyled">
          <li className="text-[13px] text-[#999]">
            <i className="icon-hotel-restaurant-235 u-line-icon-pro mr-2 align-middle"></i>
            {data.region && data.region.name}
          </li>
        </ul>
      </div>
      <div className="flex w-3/12 items-center justify-end">
        <Toggle
          input={{
            value: !includes(props.filter.excluded_companies, id),
            onChange: () => handleCompany(id),
          }}
        />
      </div>
    </article>
  );
  const data = props.companies.map((company) => ({
    id: company.id,
    searchTerms: [
      company.name,
      company.region ? company.region.name : "no-loc",
    ],
    data: company,
  }));

  return (
    <SearchableList
      data={data}
      renderItem={renderItem}
      renderItemContainerStyle={
        "grid grid-cols-1 lg:grid-cols-2 gap-4 min-h-min max-h-[50dvh] overflow-auto auto-rows-min"
      }
      onSelectAll={handleAddCompanies}
      onDeSelectAll={handleRemoveCompanies}
      placeholder={t("Search dealer or region") + " ..."}
    />
  );
};
