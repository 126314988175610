import { Menu } from "@headlessui/react";

export const ComboMenu = ({ children, renderTitle }) => {
  return (
    <Menu as="div" className="relative text-left">
      <div>
        <Menu.Button className="h-[36px] w-[36px] bg-primary px-3 text-sm text-white hover:bg-highlightColor sm:w-auto">
          {renderTitle}
        </Menu.Button>

        <Menu.Items className="absolute right-0 mt-1 origin-top-right divide-y divide-gray-100 text-nowrap border-t-2 border-primary bg-white shadow-lg">
          <div className="p-2">{children}</div>
        </Menu.Items>
      </div>
    </Menu>
  );
};
