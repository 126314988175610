import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchWarnings } from "../actions";
import { getWarnings } from "../selectors";
import { Warning } from "./Warning";

import { map } from "lodash";

export const WarningsList = () => {
  const dispatch = useDispatch();
  const warnings = useSelector(getWarnings);

  useEffect(() => {
    dispatch(fetchWarnings());
  }, []);

  if (warnings.length === 0) return null;

  return (
    <div className="w-full">
      {map(warnings, (warning, i) => (
        <Warning key={i} title={warning.title} content={warning.content} />
      ))}
    </div>
  );
};
