import _ from "lodash";

export const mapColleagues = (data) => {
  return _.keyBy(
    _.map(data, (colleague) => ({
      id: colleague.id,
      state: colleague.state,
      email: colleague.email,
      first_name: colleague.first_name,
      last_name: colleague.last_name,
      title: colleague.title,
      telephone: colleague.telephone,
      mobile: colleague.mobile,
    })),
    "id"
  );
};
