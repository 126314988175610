import React from "react";

import { Menu } from "@headlessui/react";

export const DatePresetDropdown = ({ children }) => {
  return (
    <Menu as="div" className="relative text-left">
      <div>
        <Menu.Button className="h-full w-auto px-3 sm:w-auto">
          <i className="fa fa-list-ul cursor-pointer text-[#777] hover:text-primary"></i>
        </Menu.Button>
        <Menu.Items className="absolute right-0 z-10 mt-1 origin-top-right divide-y divide-gray-100 text-nowrap border-t-2 border-primary bg-white shadow-lg">
          <Menu.Item>
            <div className="p-2">{children}</div>
          </Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  );
};
