import React from "react";

import { LoadingContainer } from "../components";

export const RegistrationWizardContentLayout = ({
  leftContent,
  rightContent,
  bottomContent,
  loading,
}) => {
  return (
    <LoadingContainer loading={loading}>
      <div className="my-3">
        <div className="flex flex-col justify-between space-y-5 bg-white p-5 lg:flex-row lg:space-x-10 lg:space-y-0">
          <div className="w-full lg:w-1/2">{leftContent}</div>
          <div className="w-full lg:w-1/2">{rightContent}</div>
        </div>
        {bottomContent && (
          <div className="flex bg-white">
            <div className="w-full">{bottomContent}</div>
          </div>
        )}
      </div>
    </LoadingContainer>
  );
};
