import React from "react";
import { useParams } from "react-router-dom";

import { Template } from "../components";
import { Activation } from "../features/registration-activation/_web/Activation";

export const Activate = () => {
  const params = useParams();
  return (
    <Template>
      <div className="bg-white">
        <section className="container">
          <Activation
            registrationId={params.registrationId}
            uniqueId={params.uniqueId}
          />
        </section>
      </div>
    </Template>
  );
};
