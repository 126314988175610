import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchSuppliers } from "../suppliersSlice";

export const useSuppliers = () => {
  const { suppliersList, isLoading, isError } = useSelector(
    (state) => state.suppliers
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSuppliers());
  }, []);

  return {
    suppliersList,
    isLoading,
    isError,
  };
};
