import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Template } from "../components";
import { Content, LegalTypes } from "../features/legal/index";
import {
  clearedSession,
  updatedTermsAndConditionsReviewed,
} from "../features/session/sessionSlice";

const TermsAndConditionsHeader = ({ handleCancel, handleAccept }) => {
  const { t } = useTranslation();
  return (
    <div className="bottom-shadow mb-5 flex justify-between space-x-2 bg-white p-2 sm:justify-end">
      <button
        type="button"
        onClick={handleCancel}
        className="btn bg-red-500 px-2 py-1 text-white hover:bg-red-300"
      >
        <i className="fa fa-remove mr-1"></i>
        {t("Cancel")}
      </button>
      <button
        type="button"
        onClick={handleAccept}
        className="btn btn-primary px-2 py-1 hover:bg-highlightColor"
      >
        <i className="fa fa-check mr-1"></i>
        {t("I accept & confirm")}
      </button>
    </div>
  );
};

export const TermsAndConditionsReview = () => {
  const dispatch = useDispatch();
  return (
    <Template>
      <div className="mt-[10px]">
        <TermsAndConditionsHeader
          handleCancel={() => dispatch(clearedSession())}
          handleAccept={() => dispatch(updatedTermsAndConditionsReviewed(true))}
        />
      </div>
      <div className="h-[70dvh] overflow-auto bg-white p-5">
        <Content id={LegalTypes.CONDITIONS} />
      </div>
    </Template>
  );
};
