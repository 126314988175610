import React from "react";

export const ItemHeader = ({ make, model, mainCompanyName, version }) => {
  return (
    <div className="w-full">
      <div className="flex">
        <hgroup className="w-1/2 flex-1 truncate">
          <h5 className="h5 truncate uppercase text-highlightColor">
            {make} {model}
          </h5>
          <p className=" g-mb-5 truncate text-sm uppercase text-[#999]">
            {version}
          </p>
        </hgroup>

        {mainCompanyName && (
          <div className="text-right">
            <span className="text-[#777]">
              <i className="icon-map-pin" /> {mainCompanyName}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
