import React from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  TimeSeriesScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

import { formatPrice } from "../../../helpers/functions";
import i18n from "../../../i18n";

ChartJS.register(
  PointElement,
  LineElement,
  Tooltip,
  LinearScale,
  TimeSeriesScale
);

ChartJS.defaults.font.family = '"Encode Sans", sans-serif';
ChartJS.defaults.font.size = 12;
ChartJS.defaults.color = "#6f6f6f";

const renderToolTipLabel = (context) => {
  return [
    `${i18n.t("Number of cars")}: ${context.raw.count}`,
    `${i18n.t("Amount")}: ${formatPrice(context.raw.amount)}`,
  ];
};

const renderToolTipTitle = (context) => {
  return `${i18n.t("Period")}: ${context[0].raw.period}`;
};

const handleOnHover = (event, chartElement) => {
  event.native.target.style.cursor = chartElement[0] ? "pointer" : "default";
};

const formatYAxis = (tickItem) => {
  return formatPrice(tickItem);
};

export const LineChart = (props) => {
  const options = {
    responsive: true,
    onHover: handleOnHover,
    onClick: props.onClick,
    layout: {
      padding: 26,
    },
    scales: {
      x: {
        type: "timeseries",
        time: {
          unit: "month",
          displayFormats: {
            month: "MM/YYYY",
          },
        },
        grid: {
          color: "#dadada",
        },
        border: {
          color: "#c1c1c1",
          width: 2,
          dash: [3, 3],
        },
      },
      y: {
        grid: {
          color: "#dadada",
        },
        border: {
          color: "#c1c1c1",
          width: 2,
          dash: [3, 3],
        },
        ticks: {
          maxTicksLimit: 6,
          callback: formatYAxis,
        },
      },
    },
    plugins: {
      hover: {
        mode: "index",
        intersect: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        displayColors: false,
        caretPadding: 25,
        callbacks: {
          label: renderToolTipLabel,
          title: renderToolTipTitle,
        },
      },
    },
  };

  const data = {
    datasets: [
      {
        data: props.data,
        parsing: {
          xAxisKey: "period",
          yAxisKey: "amount",
        },
        borderColor: props.stroke,
        borderWidth: 1.2,
        backgroundColor: "#ffffff",
        tension: 0.3,
        pointHitRadius: 8,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: props.stroke,
      },
    ],
  };
  return <Line options={options} data={data} />;
};
