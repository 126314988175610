export const updateAge = (filter, min, max) => {
  filter.age_min = min;
  filter.age_max = max;
  return filter;
};

export const updateKm = (filter, min, max) => {
  filter.km_min = min;
  filter.km_max = max;
  return filter;
};

// Allow only numbers
export const allowOnlyNumbers = (value) => value.replace(/\D/, "");
