import { types } from "./types";

const initial_company_user_state = {
  error: false,
  message: "",
};

export const initial_state = {
  authenticated: false,
  token: "",
  user_id: null,
  registration_step: 0,
  user: {},
  email_validated: false,
  last_login: null,
  company_user_state: initial_company_user_state,
  terms_and_conditions_reviewed: false,
};

const reducer = (state = initial_state, action) => {
  switch (action.type) {
    case types.SESSION_UPDATE:
      return {
        ...state,
        ...action.payload.session,
      };
    case types.SESSION_CLEAR:
    case types.SESSION_CLEAR_ALL:
      return {
        ...initial_state,
      };

    default:
      return state;
  }
};

export default reducer;
