import React from "react";
import { useTranslation } from "react-i18next";

import { Field, reduxForm } from "redux-form";

import { FormFields, validators } from "../../../../../components";
import { personalInfoFields } from "../WizardStep1";

const { TextField, PhoneNumber, Combo } = FormFields;

const validate = (values, props) => {
  const { required } = props;
  const errors = {};

  if (!required) return errors;

  const phoneError =
    validators.required(values.telephone) ||
    validators.minLength10(values.telephone);
  const mobileError =
    validators.required(values.mobile) || validators.minLength10(values.mobile);

  if (phoneError !== undefined && mobileError !== undefined) {
    errors.telephone = "Telephone or mobile is required";
    errors.mobile = "Telephone or mobile is required";
  }
  return errors;
};

const PersonalInfoForm = ({ handleSubmit, required }) => {
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <Field
        name={personalInfoFields.TITLE}
        component={Combo}
        label={t("Salutation")}
        validate={required ? [validators.required] : []}
        placeholder={t("Salutation")}
        options={[
          { value: "Mr", label: t("Mr") },
          { value: "Mrs", label: t("Mrs") },
          { value: "Miss", label: t("Miss") },
        ]}
      />

      <Field
        name={personalInfoFields.FIRST_NAME}
        component={TextField}
        type="text"
        label={t("First Name")}
        validate={required ? [validators.required] : []}
      />

      <Field
        name={personalInfoFields.LAST_NAME}
        component={TextField}
        type="text"
        label={t("Last Name")}
        validate={required ? [validators.required] : []}
      />

      <Field
        name={personalInfoFields.MAIL}
        component={TextField}
        type="email"
        label={t("E-mail address")}
        disabled={true}
        validate={required ? [validators.required] : []}
      />

      <Field
        name={personalInfoFields.PHONE}
        component={PhoneNumber}
        type="text"
        example={`${t("E.g.")} 09 360 00 00`}
        label={t("Telephone")}
      />

      <Field
        name={personalInfoFields.MOBILE}
        component={PhoneNumber}
        type="text"
        example={`${t("E.g.")} 0494 11 22 33`}
        label={t("Mobile")}
      />
    </form>
  );
};

const ConnectedComponent = reduxForm({
  form: "Registration-PersonalInfo",
  destroyOnUnmount: false,
  validate,
})(PersonalInfoForm);

export { ConnectedComponent as PersonalInfoForm };
