import React from "react";
import { connect } from "react-redux";

import clsx from "clsx";

import { toggleConfirm } from "../actions";
import * as selectors from "../selectors";

const ConfirmButtonMobile = ({ count, toggleConfirm }) => {
  const isDisabled = count === 0;

  return (
    <button
      className="aspect-square h-[35px] bg-[#f2f2f2] p-2"
      type="button"
      disabled={isDisabled}
      onClick={() => toggleConfirm(true)}
    >
      <i
        className={clsx(
          "fa fa-shopping-basket pointer-cursor font-[25px]",
          isDisabled ? "text-defaultTextColor" : "text-red-500"
        )}
      />
    </button>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    count: selectors.getBiddingsToConfirmCount(state, ownProps.listId),
  };
};

export default connect(mapStateToProps, { toggleConfirm })(ConfirmButtonMobile);
