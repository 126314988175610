import { gettext } from "../../translations";
import { historySorters, vehicleSorters } from "./sorters";

import { find, orderBy } from "lodash";

export const getSortById = (sorters, id) => {
  return find(sorters, { id });
};

export const getListVehicleSorters = () =>
  orderBy(
    vehicleSorters.map((sorter) => ({
      ...sorter,
      label: gettext(sorter.label),
    })),
    "label"
  );

export const getHistorySorters = () =>
  orderBy(
    historySorters.map((sorter) => ({
      ...sorter,
      label: gettext(sorter.label),
    })),
    "label"
  );
