import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Button } from "../../../components/web/Button";
import { download } from "../../../helpers/functions";
import { getCriteria } from "../../listvehicles-filter/selectors";
import { getUserId } from "../../session";

import { map } from "lodash";
import moment from "moment";

const ExcelDownloadByFilter = (props) => {
  const { t } = useTranslation();
  const handleClick = (e) => {
    e.stopPropagation();

    const { userId, filterCriteria } = props;

    let filters = [];

    if (
      filterCriteria &&
      filterCriteria.states &&
      filterCriteria.states.length > 0
    ) {
      filters.push(`state=${map(filterCriteria.states).join(",")}`);
    }

    if (filterCriteria && filterCriteria.expectationDt_min) {
      filters.push(
        `start=${moment(filterCriteria.expectationDt_min).format("YYYY-MM-DD")}`
      );
    }

    if (filterCriteria && filterCriteria.expectationDt_max) {
      filters.push(
        `stop=${moment(filterCriteria.expectationDt_max).format("YYYY-MM-DD")}`
      );
    }

    const filter = filters.length > 0 ? `?${map(filters).join("&")}` : "";

    // TODO: make available in dev/testing environment
    download(`sales/portal/overviewListExcel2/${userId}${filter}`);
  };

  return (
    <Button
      onClick={handleClick}
      classNames="text-nowrap text-sm font-semibold"
    >
      <i className="fa fa-file-excel-o mr-2"></i>
      {t("Download")}
    </Button>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: getUserId(state),
    filterCriteria: getCriteria(state, -1),
  };
};

const ConnectedComponent = connect(mapStateToProps)(ExcelDownloadByFilter);

export { ConnectedComponent as ExcelDownloadByFilter };
