import { listVehicleCartTypes } from "./types";

const reducer = (
  state = { confirmVisible: false, accepted: false, message: "" },
  action
) => {
  switch (action.type) {
    case listVehicleCartTypes.TOGGLE_CONFIRM:
      return {
        ...state,
        confirmVisible: action.payload.visible,
      };
    case listVehicleCartTypes.POST_REQUEST:
      return {
        ...state,
        loading: true,
        accepted: false,
        message: "",
      };
    case listVehicleCartTypes.POST_SUCCESS:
      return {
        ...state,
        loading: false,
        accepted: action.payload.accepted,
        message: action.payload.message,
      };
    case listVehicleCartTypes.POST_FAILURE:
      return {
        ...state,
        loading: false,
        accepted: false,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export default reducer;
