import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import format from "string-format";

export const VehicleDamageBadge = ({ damages = [] }) => {
  const { t } = useTranslation();
  const damagesCount = typeof damages === "number" ? damages : damages.length;
  const accent = damagesCount > 0;
  const title = damagesCount > 1 ? t("{0} damages") : t("{0} damage");
  const text = accent ? format(t(title), damagesCount) : t("No damages");

  const spanClass = clsx(
    "u-label badge !rounded-[3px]",
    accent ? "damage-highlight font-bold" : "badge-normal"
  );

  return <span className={spanClass}>{text}</span>;
};
