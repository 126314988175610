import React from "react";

export const FilterSummaryTag = ({ text, item, onClick }) => (
  <div className="flex items-center justify-between rounded border border-[#dcdcdc] bg-[#f4f4f4] p-1">
    <span className="pointer-events-none capitalize">{text}</span>
    <i
      onClick={() => onClick()}
      className="fa fa-close ml-2 mr-1 cursor-pointer text-[11px] text-[#848484] hover:text-[#212121]"
    ></i>
  </div>
);
