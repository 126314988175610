import React from "react";
import { useTranslation } from "react-i18next";

import { InfoContainer } from "../../../../components";
import { Button } from "../../../../components/web/Button";
import { withConfig } from "../../../../config";
import { RegistrationWizardContentLayout } from "../../../../layouts/RegistrationWizardContentLayout";
import { ContactInfo } from "./ContactInfo";

const LeftSectionContent = ({ step, totalSteps, text }) => {
  const { t } = useTranslation();
  return (
    <>
      <hgroup>
        <h1 className="text-[1.25rem] text-primary">
          {t("Step")}: {step}/{totalSteps} : {t("E-mail confirmation")}
        </h1>
        <h2 className="py-2 text-base">
          {t(
            "Once you confirm your e-mail address, you can complete the registration."
          )}
        </h2>
      </hgroup>
      <p>
        {t(
          "Check your mailbox and follow the instructions in the confirmation e-mail."
        ) +
          " " +
          text}
      </p>

      <ContactInfo />
    </>
  );
};

const RightSideContent = ({ handleOnClick }) => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <InfoContainer
        icon="fa fa-envelope-open"
        text={<h5>{t("No mail received?")}</h5>}
        container={true}
      />
      <div className="flex justify-center">
        <Button onClick={handleOnClick}>{t("Send again")}</Button>
      </div>
    </div>
  );
};
const WizardActivateMail = (props) => {
  const { onSendActivationMail, step, totalSteps, config } = props;
  const { registration_steps } = config;
  const { t } = useTranslation();

  const text =
    registration_steps.includes(6) && registration_steps.includes(7)
      ? t(
          "Then you can provide your personal and company data and set your preferences regarding dealers."
        )
      : t("Then you can provide your personal and company data.");

  return (
    <RegistrationWizardContentLayout
      leftContent={
        <LeftSectionContent step={step} totalSteps={totalSteps} text={text} />
      }
      rightContent={<RightSideContent handleOnClick={onSendActivationMail} />}
    />
  );
};

const ConnectedComponent = withConfig(WizardActivateMail);
export { ConnectedComponent as WizardActivateMail };
