import React from "react";

import { Template } from "../components";
import { Unsubscribe } from "../features/unsubscribe";

export const UnsubscribePage = () => (
  <Template>
    <Unsubscribe />
  </Template>
);
