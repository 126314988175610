import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import { numberFormat } from "../../../helpers/functions";

export const FiltersList = ({
  filters,
  selectedFilter,
  onSelectFilter,
  allMakesCount,
  allCompaniesCount,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {Object.values(filters).map((filter, i) => (
        <article
          key={filter.id}
          className={clsx("item item--hover cursor-pointer", {
            "item-active": selectedFilter && selectedFilter.id === filter.id,
          })}
          onClick={() => {
            onSelectFilter(filter);
          }}
        >
          <div className="mt-2 p-2">
            <h3 className="text-[16px] font-semibold">{filter.name}</h3>

            <ul>
              <li className="mb-3 border-b border-dashed pt-1">
                {t("Dealers")}:{" "}
                {allCompaniesCount - filter.excluded_companies.length} /{" "}
                {allCompaniesCount}
              </li>

              <li className="mb-3 border-b border-dashed pt-1">
                {t("Makes")}: {filter.makes.length} / {allMakesCount}
              </li>

              <li className="mb-3 border-b border-dashed pt-1">
                {filter.age_min === filter.age_max && (
                  <span>{t("All age")}</span>
                )}

                {filter.age_min !== filter.age_max && (
                  <span>
                    {filter.age_min} {t("to")} {filter.age_max} {t("months")}
                  </span>
                )}
              </li>
              <li className="mb-3 border-b border-dashed pt-1">
                {filter.km_min === filter.km_max && <span>{t("All km")}</span>}
                {filter.km_min !== filter.km_max && (
                  <span>
                    {numberFormat(filter.km_min)} {t("to")}{" "}
                    {numberFormat(filter.km_max)} {t("km")}
                  </span>
                )}
              </li>
            </ul>
          </div>
        </article>
      ))}
    </div>
  );
};
