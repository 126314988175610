import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LoadingIndicator } from "../../../../components/index";
import { useNavigateHelper } from "../../../../hooks/useNavigateHelper";
import { fetchOffers } from "./actions";
import { OffersTableLayout as DataTable } from "./OffersTableLayout";
import * as selectors from "./selectors";

import { createColumnHelper } from "@tanstack/react-table";
import { map } from "lodash";
import moment from "moment";

const DisplayWrapper = ({ children }) => {
  return (
    <div className="flex h-[150px] w-full items-center justify-center border border-[#eee] pt-5">
      {children}
    </div>
  );
};

export const OffersTable = () => {
  const data = useSelector(selectors.getData);
  const loading = useSelector(selectors.getLoading);
  const error = useSelector(selectors.getError);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { goToOffer } = useNavigateHelper();

  useEffect(() => {
    dispatch(fetchOffers());
  }, []);

  const showLoading = loading || !data;
  const showNoOffers = data?.length === 0;

  // TODO: check for any other possible expressions
  if (showLoading) {
    return (
      <DisplayWrapper>
        <LoadingIndicator loading={loading} text={t("Loading offers")} />
      </DisplayWrapper>
    );
  }

  if (error) {
    return (
      <DisplayWrapper>
        <div className="text-center">{error}</div>
      </DisplayWrapper>
    );
  }

  if (showNoOffers) {
    return (
      <DisplayWrapper>
        <div className="text-center">{t("You have no offers yet")}</div>
      </DisplayWrapper>
    );
  }

  function offerFormatter(info) {
    const row = info.row.original;
    return (
      <div className="flex">
        <div className="w-9/12">
          <div>
            {row.name !== null && row.name !== "" ? (
              <span className="font-semibold text-primary">{row.name}</span>
            ) : (
              <span className="font-semibold italic text-primary">
                {`<${t("Offer with no name")}>`}
              </span>
            )}
          </div>
          <div>{row.contact}</div>
        </div>

        <div className="w-3/12" style={{ textAlign: "right" }}>
          <div>
            <span>NR-{row.id}</span>
          </div>
          <div>{`${row.count} ${t("vehicles")}`}</div>
        </div>
      </div>
    );
  }

  let tableData = map(data, (d) => ({
    id: d.id,
    name: d.name,
    count: d.vehicle_count,
    contact: d.seller_email,
    time: moment(d.time_stamp_sent).format("DD/MM/YYYY hh:mm"),
  }));

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <h5 className="text-left">{t("Offers")}</h5>,
      cell: (info) => offerFormatter(info),
    }),
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        tableData={tableData}
        onRowClick={(row) => {
          goToOffer(row.original.id);
        }}
      />
    </div>
  );
};
