import { ListTypes } from "../../constants";
import { getListIdFromListVehicleId, isExpired } from "../../helpers/functions";
import { listById } from "../lists/selectors";
import { getCriteria } from "../listvehicles-filter/selectors";

import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

export const getVehicles = (state) => state.entities.vehicle;
export const getVehicleById = (state, listVehicleId) =>
  getVehicles(state)[listVehicleId];
export const getVehicleByListAndId = (state, listId, vehicleId) =>
  getVehicles(state)[`${listId}_${vehicleId}`];

export const getLoading = (state, id) =>
  state.listvehicles[id]
    ? state.listvehicles[id].isFetching ||
      state.listvehicles[id].isFetchingBasket
    : false;
export const getErrorMessage = (state, id) =>
  state.listvehicles[id] ? state.listvehicles[id].error : false;
export const getLimit = (state, id) =>
  state.listvehicles[id] ? state.listvehicles[id].limit : 50;
export const getOffset = (state, id) =>
  state.listvehicles[id] ? state.listvehicles[id].offset : 0;
export const getMore = (state, id) =>
  state.listvehicles[id] ? state.listvehicles[id].more : false;
export const getVehicleIds = (state, id) =>
  state.listvehicles[id] && state.listvehicles[id].vehicleIds
    ? state.listvehicles[id].vehicleIds
    : [];
export const getTotalCount = (state, id) =>
  state.listvehicles[id] ? state.listvehicles[id].totalCount : 0;
export const getLastUpdated = (state, id) =>
  state.listvehicles[id] ? state.listvehicles[id].lastUpdated : 0;
export const getInitialLoading = (state, id) =>
  getLoading(state, id) && getOffset(state, id) === 0;

export const getVehiclesByIds = createSelector(
  [getVehicleIds, getVehicles],
  (ids, vehicles) => {
    return _.map(ids, (id) => {
      return vehicles[id];
    });
  }
);

// checks for live auction

export const getListByListVehicleId = (state, listVehicleId) => {
  const listId = getListIdFromListVehicleId(listVehicleId);
  return listById(state, listId);
};
export const getVehicleByListVehicleId = (state, listVehicleId) => {
  return getVehicleById(state, listVehicleId);
};
export const getCriteriaById = (state, listVehicleId) => {
  const listId = getListIdFromListVehicleId(listVehicleId);
  return getCriteria(state, listId);
};

export const getIsHidden = createSelector(
  [getListByListVehicleId, getVehicleByListVehicleId, getCriteriaById],
  (list, vehicle, criteria) => {
    if (list && list.type !== ListTypes.LIVE_AUCTION) {
      return false;
    } else {
      const live_auction_hidden = vehicle?.live_auction_hidden;
      const expired = isExpired(list.stop, vehicle?.vehicle_stop);
      const filterActive = criteria.interestWithNoBid;

      return (
        (live_auction_hidden && !filterActive) || (expired && !filterActive)
      );
    }
  }
);
