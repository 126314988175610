import React from "react";

import clsx from "clsx";

export const Button = ({
  onClick,
  children,
  classNames,
  styleType = "default",
  ...props
}) => {
  const buttonTypeStyles = {
    default: "bg-primary text-white",
    secondary: "bg-secondary text-white",
    danger: "bg-red-500 text-white",
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        "btn flex items-center px-3 py-2",
        buttonTypeStyles[styleType],
        classNames ? `${classNames}` : ""
      )}
      {...props}
    >
      {children}
    </button>
  );
};
