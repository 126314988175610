import { contentTypes } from "./types";

const content = (
  state = { loading: false, error: "", content: null },
  action
) => {
  switch (action.type) {
    case contentTypes.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case contentTypes.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        content: action.payload.content,
      };
    case contentTypes.FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case contentTypes.FETCH_REQUEST:
    case contentTypes.FETCH_SUCCESS:
    case contentTypes.FETCH_FAILURE:
      return {
        ...state,
        [action.payload.id]: content(state[action.payload.id], action),
      };
    default:
      return state;
  }
};
