import i18n from "./i18n";

export const ListTypes = {
  TENDER: "TENDER",
  FIXED: "FIXED",
  LIVE_AUCTION: "AUCTION",
};

export const ListStates = {
  INITIAL: "INITIAL",
  IN_PREPARATION: "IN_PREPARATION",
  PREPARED: "PREPARED",
  OPEN: "OPEN",
  FINISHED: "FINISHED",
  CLOSED: "CLOSED",
};

export const ListVehicleStates = {
  INITIAL: "INITIAL",
  LOCKED: "LOCKED",
  SOLD: "SOLD",
  ACCEPTED: "ACCEPTED",
  PAYED: "PAYED",
  DELIVERED: "DELIVERED",
  REMOVED: "REMOVED",
};

export const BasketStates = {
  INITIAL: "INITIAL",
  TRANSMITTED: "TRANSMITTED",
  BOUGHT: "BOUGHT",
};

export const BasketStatesGroups = {
  ALL: "ALL",
  OFFERED: "OFFERED",
  REFUSED: "REFUSED",
  ACCEPTED: "ACCEPTED",
  ARCHIVE: "ARCHIVE",
  LATEST: "LATEST",
};

// FIXME: translated values only appear after page refresh
export const BasketStatesGroupsTranslate = {
  OFFERED: i18n.t("Offered"),
  REFUSED: i18n.t("Refused"),
  ACCEPTED: i18n.t("Accepted"),
  ARCHIVE: i18n.t("Archive"),
  LATEST: i18n.t("Last 5"),
};

export const BasketStatesGroupMapper = {
  INITIAL: BasketStatesGroups.OFFERED,
  TRANSMITTED: BasketStatesGroups.OFFERED,
  RETURNING: BasketStatesGroups.ARCHIVE,
  RETURNED: BasketStatesGroups.ARCHIVE,
  REFUSED: BasketStatesGroups.REFUSED,
  REFUSED_SENT: BasketStatesGroups.REFUSED,
  BOUGHT: BasketStatesGroups.ACCEPTED,
  ACCEPTED: BasketStatesGroups.ACCEPTED,
  ORDERING: BasketStatesGroups.ARCHIVE,
  ORDERED: BasketStatesGroups.ARCHIVE,
  PAYED: BasketStatesGroups.ARCHIVE,
  DELIVERED: BasketStatesGroups.ARCHIVE,
};

// FIXME: translated values only appear after page refresh
export const BasketStatesTranslate = {
  INITIAL: i18n.t("In Basket"),
  TRANSMITTED: i18n.t("Offered"),
  RETURNING: i18n.t("Returning"),
  RETURNED: i18n.t("Returned"),
  REFUSED: i18n.t("Not retained"),
  REFUSED_SENT: i18n.t("Not retained"),
  BOUGHT: i18n.t("Bought"),
  ACCEPTED: i18n.t("Accepted"),
  ORDERING: i18n.t("Ordering"),
  ORDERED: i18n.t("Ordered"),
  PAYED: i18n.t("Payed"),
  DELIVERED: i18n.t("Delivered"),
};

export const BaseStatisticsStates = {
  HISTORY_REFUSED: i18n.t("History without continuation"),
  OFFERED: i18n.t("Offered"),
  RESERVED: i18n.t("Reserved"),
  ORDERED: i18n.t("Ordered"),
  DELIVERY: i18n.t("Delivery"),
  DELIVERED: i18n.t("Delivered"),
  HISTORY: i18n.t("History"),
};

export const ExpertStatisticsStates = {
  OFFERED: i18n.t("Waiting"),
  ACCEPTED: i18n.t("Accepted"),
  ENTERED: i18n.t("To pickup"),
  REFUSED: i18n.t("Without continuation"),
};
