import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routes } from "../../../_web/navigation/routes";
import { LoadingIndicator } from "../../../components";
import { register } from "../actions";
import * as selectors from "../selectors";
import { RegistrationForm } from "./RegistrationForm";

const RegistrationContainer = ({
  register,
  loading,
  error,
  onRegistrationCompleted,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <LoadingIndicator loading={loading} />

      {error !== null && (
        <div className="container-important mb-5 flex flex-col p-3">
          <div className="flex w-full">{error.message}</div>

          {error.code === 409 && (
            <div className="flex">
              <div>
                <span
                  className="cursor-pointer underline"
                  onClick={() => navigate(routes.LOGIN)}
                >
                  {t("Click here")}
                </span>{" "}
                {t("to login")}.<br />
                {t("Forgot password? ")}
                <span
                  className="cursor-pointer underline"
                  onClick={() => navigate(routes.FORGOT_PASSWORD_NO_ID)}
                >
                  {t("Reset password")}.
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      <RegistrationForm
        onSubmit={(values) => {
          register({
            step: 1,
            company_name: values.company_name,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
            confirm_password: values.password_confirm,
          }).then((result) => {
            if (!result.error) {
              if (result.payload.registration_id > 0) {
                onRegistrationCompleted();
              }
            }
          });
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: selectors.getLoading(state),
    error: selectors.getError(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, { register })(
  RegistrationContainer
);

export { ConnectedComponent as RegistrationContainer };
