import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { submit } from "redux-form";

import { SearchableList } from "../../../../components/index";
import { Toggle } from "../../../../components/web/forms/FormFields";
import { RegistrationWizardContentLayout } from "../../../../layouts/RegistrationWizardContentLayout";
import { ContactInfo } from "./ContactInfo";
import { DealerPresentationForm } from "./forms/DealerPresentationForm";
import { WizardButtons } from "./WizardButtons";

import _, { includes } from "lodash";

export const dealerPresentationFields = {
  PRESENTATION_TEXT: "presentation_text",
};

const DealerPresentationLeftSectionContent = ({ step, steps }) => {
  const { t } = useTranslation();
  return (
    <>
      <hgroup>
        <h1 className="text-[1.25rem] text-primary">
          {t("Step")}: {step}/{steps} : {t("Dealers")} (1/2)
        </h1>
        <h2 className="py-2 text-base">
          {t("How would you present yourself and your company?")}
        </h2>
      </hgroup>
      <p>
        {t("The first impression is important to win the trust.")}
        <br />
        {t(
          "Describe your company briefly. The description is meant for the dealers."
        )}
      </p>

      <div className="text-light-gr pt-2">
        {t("Inspirational:")}
        <ul className="list-inside list-disc">
          <li>{t("What is the name of your company?")}</li>
          <li>{t("Which vehicles do you sell?")}</li>
          <li>{t("What can they do for you?")}</li>
          <li>{t("What are your strengths?")}</li>
        </ul>
      </div>
    </>
  );
};

const DealersLeftSectionContent = ({ step, steps }) => {
  const { t } = useTranslation();
  return (
    <>
      <hgroup>
        <h1 className="text-[1.25rem] text-primary">
          {t("Step")}: {step}/{steps} : {t("Dealers")} (2/2)
        </h1>
        <h2 className="py-2 text-base">
          {t("From which dealers do you want to receive price requests?")}
        </h2>
      </hgroup>
      <p>{t("Select the dealers you want to receive requests from")}.</p>
      <p className="pt-2">
        <span className="font-bold">! {t("Please note")}:</span>
        <br />
        {t(
          "The dealers will receive an automatic e-mail in which we inform them of your registration. The dealers must activate your company within their network. If this does not happen after a while, please contact the dealer in person."
        )}
      </p>
      <ContactInfo />
    </>
  );
};

const WizardStepSix = (props) => {
  const {
    filterCompanies,
    onNext,
    onPrev,
    required,
    step,
    submit,
    totalSteps,
    loading,
  } = props;

  const data = filterCompanies.map((company) => ({
    id: company.id,
    searchTerms: [
      company.name,
      company.region ? company.region.name : "no-loc",
    ],
    data: company,
  }));

  const { t } = useTranslation();

  let selectedCompanies;

  if (props.filter !== null) {
    selectedCompanies = _.filter(
      props.filterCompanies.map((c) => c.id),
      (id) => !includes(props.filter.excluded_companies, id)
    );
  } else {
    selectedCompanies = props.filterCompanies.map((company) => company.id);
  }

  const initialState = {
    selectedCompanies,
    dealerPresentationTextVisible: true,
    dealerPresentationText: "",
  };
  const [state, setState] = useState(initialState);

  const handleCompany = (id) => {
    const checked = includes(state.selectedCompanies, id);

    if (checked) {
      removeCompanies([id]);
    } else {
      addCompanies([id]);
    }
  };

  const removeCompanies = (companies) => {
    setState((prevState) => ({
      ...prevState,
      selectedCompanies: state.selectedCompanies.filter(function (company_id) {
        return !includes(companies, company_id);
      }),
    }));
  };

  const addCompanies = (companies) => {
    setState((prevState) => ({
      ...prevState,
      selectedCompanies: [...prevState.selectedCompanies, ...companies],
    }));
  };

  const mapDataToFields = (data) => {
    return {
      [dealerPresentationFields.PRESENTATION_TEXT]: data.presentation_text,
    };
  };

  const handleDealerPresentationTextChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      dealerPresentationText: value,
    }));
  };

  const renderItem = ({ id, data }) => (
    <article key={`company_${id}`} className="rounded-4 flex border p-2.5">
      <div className="w-9/12">
        <h5>{data.name}</h5>
        <ul className="list-unstyled">
          <li className="text-[13px] text-[#999]">
            <i className="icon-hotel-restaurant-235 u-line-icon-pro mr-2 align-middle"></i>
            {data.region && data.region.name}
          </li>
        </ul>
      </div>
      <div className="flex w-3/12 items-center justify-end">
        <Toggle
          input={{
            value: includes(state.selectedCompanies, id),
            onChange: (event) => handleCompany(id, event),
          }}
        />
      </div>
    </article>
  );

  if (state.dealerPresentationTextVisible) {
    return (
      <RegistrationWizardContentLayout
        leftContent={
          <DealerPresentationLeftSectionContent
            step={step}
            steps={totalSteps}
          />
        }
        rightContent={
          <DealerPresentationForm
            initialValues={mapDataToFields(state.dealerPresentationText)}
            required={required}
            onChange={(value) => handleDealerPresentationTextChange(value)}
            onSubmit={(values) =>
              setState((prevState) => ({
                ...prevState,
                dealerPresentationTextVisible: false,
                dealerPresentationText: values.presentation_text,
              }))
            }
          />
        }
        bottomContent={
          <WizardButtons
            currentStep={step}
            onNext={() => submit("Registration-DealerPresentation")}
            onPrevious={() => onPrev()}
          />
        }
        loading={loading}
      />
    );
  }
  return (
    <RegistrationWizardContentLayout
      leftContent={<DealersLeftSectionContent step={step} steps={totalSteps} />}
      rightContent={
        <SearchableList
          data={data}
          renderItem={renderItem}
          renderItemContainerStyle={
            "grid grid-cols-1 lg:grid-cols-2 gap-4 min-h-min max-h-[50dvh] overflow-auto auto-rows-min"
          }
          onSelectAll={addCompanies}
          onDeSelectAll={removeCompanies}
          placeholder={t("Dealer or region") + " ..."}
        />
      }
      bottomContent={
        <WizardButtons
          currentStep={step}
          onNext={() =>
            onNext(state.selectedCompanies, state.dealerPresentationText)
          }
          onPrevious={() =>
            !state.dealerPresentationTextVisible
              ? setState((prevState) => ({
                  ...prevState,
                  dealerPresentationTextVisible: true,
                }))
              : onPrev()
          }
        />
      }
      loading={loading}
    />
  );
};

const ConnectedComponent = connect(null, { submit })(WizardStepSix);

export { ConnectedComponent as WizardStepSix };
