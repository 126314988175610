import { listVehicleTypes } from "./types";

import { omit } from "lodash";

export const listVehiclesById = (state = {}, action) => {
  switch (action.type) {
    case listVehicleTypes.FETCH_REQUEST:
    case listVehicleTypes.FETCH_SUCCESS:
    case listVehicleTypes.FETCH_FAILURE:
    case listVehicleTypes.FETCH_BASKET_REQUEST:
    case listVehicleTypes.FETCH_BASKET_SUCCESS:
    case listVehicleTypes.FETCH_BASKET_FAILURE:
      return {
        ...state,
        [action.payload.listId]: listVehicles(
          state[action.payload.listId],
          action
        ),
      };
    case listVehicleTypes.CLEANUP:
      return omit(state, action.payload.invalidListIds);
    default:
      return state;
  }
};

const listVehicles = (state = {}, action) => {
  switch (action.type) {
    case listVehicleTypes.FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
        offset: action.payload.offset,
        //totalCount: 0,
      };
    case listVehicleTypes.FETCH_SUCCESS:
      const { limit, offset, ids, totalCount } = action.payload;

      let vehicleIds = ids;
      if (offset !== 0 && state.vehicleIds) {
        vehicleIds = [...state.vehicleIds, ...ids];
      }
      // const vehicleIds = (offset === 0) ? ids : [...state.vehicleIds, ...ids];

      return {
        ...state,
        isFetching: false,
        lastUpdated: action.payload.receivedAt,
        error: "",
        vehicleIds,
        limit: limit,
        offset: offset,
        more: totalCount > vehicleIds.length && ids.length > 0,
        totalCount,
      };
    case listVehicleTypes.FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
        totalCount: 0,
      };

    case listVehicleTypes.FETCH_BASKET_REQUEST:
      return {
        ...state,
        isFetchingBasket: true,
      };
    case listVehicleTypes.FETCH_BASKET_SUCCESS:
      return {
        ...state,
        isFetchingBasket: false,
      };
    case listVehicleTypes.FETCH_BASKET_FAILURE:
      return {
        ...state,
        isFetchingBasket: false,
      };
    default:
      return state;
  }
};
