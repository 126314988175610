import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

import { Template } from "../components";
import { TeaserBanner } from "../components/web/TeaserBanner";
import { withConfig } from "../config";
import { Authentication } from "../features/authentication";
import { LoginByCode } from "./LoginByCode";
import { routes } from "./navigation/routes";

const Login = ({ config }) => {
  const [searchParams] = useSearchParams();

  const { login_headlines } = config;
  const { t } = useTranslation();

  const code = searchParams.get("code");
  const hasCode = searchParams.has("code");

  if (hasCode && code !== "") {
    return <LoginByCode code={code} />;
  }

  const infoSection = [
    {
      icon: "fa fa-heart",
      header: t("Answers to"),
      content: t(
        "With this application you can deal with price requests whenever and wherever you want."
      ),
    },
    {
      icon: "fa fa-home",
      header: t("Control and follow-up"),
      content: t(
        "Manage the evolution of your quotations in one central location."
      ),
    },
    {
      icon: "fa fa-gear",
      header: t("Flexible management of your profile"),
      content: t("You can always adjust your filters according to your needs."),
    },
  ];

  return (
    <Template>
      <TeaserBanner containerClass="pt-20" />
      <div className="bg-white p-5 pb-10">
        <h1 className="my-5 text-center">{t("Welcome back!")}</h1>

        <div className="flex justify-center space-x-6">
          <div className="g-mb-70 g-ma-0 g-pa-0 w-full md:w-1/2 lg:w-4/12">
            <h3 className="mb-4 text-center">{t("Sign in to your account")}</h3>

            <Authentication />

            <div className="mt-2 flex justify-between">
              <Link to={routes.REGISTRATION} className="text-primary">
                {t("Create an account")}
              </Link>
              <Link to={routes.FORGOT_PASSWORD_NO_ID} className="text-primary">
                {t("Forgot Password")}?
              </Link>
            </div>
          </div>

          {login_headlines && (
            <div className="hidden w-5/12 lg:block">
              {infoSection.map((item, index) => {
                return (
                  <div key={index} className="mb-8 flex items-center">
                    <i
                      className={`${item.icon} m-0 my-auto text-[38px] text-defaultTextColor lg:m-8`}
                    ></i>
                    <div>
                      <h3 className="mb-1 text-xl">{item.header}</h3>
                      <p>{item.content}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Template>
  );
};

const ConnectedComponent = withConfig(Login);

export { ConnectedComponent as Login };
