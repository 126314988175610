import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

import { routes } from "../../../_web/navigation/routes";
import { Rating } from "../../../components";
import { Button } from "../../../components/web/Button";
import { withConfig } from "../../../config";
import { formatPrice } from "../../../helpers/functions";
import { VehicleInfo } from "./VehicleInfo";

const BidConfirm = (props) => {
  const [comment, setComment] = useState(props.comment);
  const [quality, setQuality] = useState(0);
  const [statePrice, setStatePrice] = useState(props.price);

  const priceInputRef = useRef();
  const { t } = useTranslation();

  const { listVehicleId, price, fixedPrice, config, vehicle } = props;
  const { vehicle_bidding_cart, vehicle_vat_regime } = config;

  const placeholder = vehicle_vat_regime
    ? t("Incl VAT")
    : vehicle.vat_regime
    ? t("Incl VAT")
    : t("Excl VAT");

  return (
    <div className="p-1">
      <VehicleInfo listVehicleId={listVehicleId} />

      <div className="my-3 flex">
        <div className="flex-auto">
          <h6 className="font-extrabold text-highlightColor">
            {t("Your bid")}:
          </h6>
          <NumericFormat
            value={statePrice}
            disabled={fixedPrice}
            getInputRef={(el) => (priceInputRef.current = el)}
            placeholder={placeholder}
            thousandSeparator={" "}
            prefix={"€ "}
            onValueChange={(values) => setStatePrice(values.value)}
            allowNegative={false}
            className="g-brd-gray-light-v1 border-x-0 border-b border-t-0 border-b-gray-500 pl-0 text-sm hover:border-b-primary focus:ring-0"
            onFocus={() =>
              priceInputRef.current
                ? priceInputRef.current.setSelectionRange(
                    0,
                    priceInputRef.current.value.length
                  )
                : null
            }
            autoFocus={!(price > 0)}
          />
        </div>
        <div className="flex-auto">
          <h6 className="font-extrabold text-highlightColor">
            {t("Inspection quality")}:
          </h6>
          <div className="mb-2">
            <Rating
              rating={quality}
              onChange={(rate) => {
                setQuality(rate);
              }}
            />
          </div>
          <p className="text-sm">{t("We value your feedback")}</p>
        </div>
      </div>

      {!vehicle_bidding_cart && (
        <div className="w-full">
          <h6 className="font-extrabold text-highlightColor">
            {t("Your comment")}:
          </h6>
          <textarea
            placeholder={t("Add your comment here")}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={2}
            autoFocus={price > 0}
            className="g-brd-gray-light-v1 w-full border-x-0 border-b border-t-0 border-b-gray-500 pl-0 text-sm hover:border-b-primary focus:ring-0"
          />
        </div>
      )}

      {!vehicle_bidding_cart && (
        <div className="bg-normal my-3 flex flex-col justify-center p-3 text-center text-sm">
          <span>
            {t("Do you confirm to post an offer of")}{" "}
            <span className="font-extrabold">{formatPrice(statePrice)} </span>
            {t("for this car?")}
          </span>

          <span>
            {t("This offer is subject to the")}{" "}
            <span className="underline">
              <Link to={routes.TERMS_AND_CONDITIONS} target="_blank">
                {t("general sales conditions")}.
              </Link>
            </span>
          </span>
        </div>
      )}

      <div className="flex justify-end space-x-2">
        <Button onClick={() => props.onConfirm(statePrice, comment, quality)}>
          {t("I Confirm")}
        </Button>
        <Button onClick={() => props.onCancel()}>{t("Cancel")}</Button>
      </div>
    </div>
  );
};

const ConnectedComponent = withConfig(BidConfirm);

export { ConnectedComponent as BidConfirm };
