import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import clsx from "clsx";

import { LoadingIndicator, validators } from "../../../components/index";
import { Button } from "../../../components/web/Button";
import { colleaguesFetch, inviteColleague } from "../actions";
import * as selectors from "../selectors";
import { ColleagueItem } from "./ColleagueItem";

import { filter, orderBy, pickBy } from "lodash";

const Colleagues = (props) => {
  const { t } = useTranslation();

  const filters = [
    { code: "ACTIVE", label: t("Active") },
    { code: "LOCKED", label: t("Locked") },
    { code: "ALL", label: t("All") },
  ];

  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState(undefined);
  const [stateFilter, setStateFilter] = useState("ACTIVE");
  const [inviteDisabled, setInviteDisabled] = useState(true);

  const handleInvite = () => {
    if (email !== "" && validationError === undefined) {
      props.inviteColleague(email);
      setEmail("");
    }
  };

  const onEmailChange = (e) => {
    const sanitisedEmail = e.target.value.replace(/\s/g, "");
    setEmail(sanitisedEmail);
    setValidationError(validators.email(sanitisedEmail));
  };

  useEffect(() => {
    props.colleaguesFetch();
  }, []);

  useEffect(() => {
    if (email !== "" && validationError === undefined) {
      setInviteDisabled(false);
    } else {
      setInviteDisabled(true);
    }
  }, [email]);

  const { colleagues, loading, error } = props;
  let showFilter = false;
  let filteredColleagues = colleagues;

  if (
    filter(colleagues, (colleague) => colleague.state === "LOCKED_APPROVED")
      .length > 0
  ) {
    showFilter = true;
    let filterState = "";
    if (stateFilter === "ACTIVE") {
      filterState = "APPROVED";
    } else if (stateFilter === "LOCKED") {
      filterState = "LOCKED_APPROVED";
    }

    filteredColleagues = pickBy(colleagues, (colleague) =>
      filterState === "" ? true : colleague.state === filterState
    );
  }

  filteredColleagues = orderBy(filteredColleagues, "first_name");

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <p>
          {t(
            "Invite your colleagues to work together in this company account. "
          )}
        </p>

        <div className="flex w-full flex-auto">
          <input
            type="text"
            value={email}
            className="form-control w-full"
            placeholder={t("Colleague e-mail address")}
            onChange={onEmailChange}
          />

          <Button
            onClick={handleInvite}
            disabled={inviteDisabled}
            classNames="flex items-center h-[calc(2.25rem+2px)] text-sm font-semibold"
          >
            {t("Send")}
            <i className="fa fa-chevron-right my-auto ml-1 text-sm font-semibold"></i>
          </Button>
        </div>

        {validationError}
      </div>

      {error && <div>{error}</div>}

      {showFilter && (
        <div className="my-2 flex w-full justify-between">
          {filters.map((filter) => {
            return (
              <button
                type="button"
                key={filter.code}
                className={clsx(
                  "stats-item w-1/3 border px-1 py-2 text-center hover:bg-highlightColor hover:text-white",
                  { "bg-primary text-white": filter.code === stateFilter }
                )}
                onClick={() => setStateFilter(filter.code)}
              >
                {t(filter.label)}
              </button>
            );
          })}
        </div>
      )}

      {loading && <LoadingIndicator loading={loading} />}

      {filteredColleagues.length > 0 && (
        <div className="h-[430px] overflow-auto">
          {filteredColleagues.map((colleague, idx) => (
            <ColleagueItem key={idx} colleague={colleague} />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    colleagues: selectors.getColleagues(state),
    loading: selectors.getLoading(state),
    error: selectors.getError(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  colleaguesFetch,
  inviteColleague,
})(Colleagues);

export { ConnectedComponent as Colleagues };
