import React from "react";

export const ListInfo = (props) => {
  const { description, value } = props;
  return (
    <div className="flex text-sm">
      <div className="min-w-[100px] text-primary sm:min-w-[150px]">
        {description}
      </div>
      <div className="w-[40px] rounded bg-primary py-0.5 text-center text-white">
        <span>{value}</span>
      </div>
    </div>
  );
};
