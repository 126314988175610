import _ from "lodash";

export const mapListVehicle = (data) => {
  return {
    list_id: _.get(data, "list_id"),
    vehicle_id: _.get(data, "vehicle_id"),
    list_vehicle_id: `${_.get(data, "list_id")}_${_.get(data, "vehicle_id")}`,
    list_number: _.get(data, "list_number") || 0,
    price: _.get(data, "price"),
    vehicle_state: _.get(data, "state"),
    expected_date: _.get(data, "expected_date"),
    expected_km: _.get(data, "expected_km"),
    vin: _.get(data, "vin"),
    thumbnail_url: _.get(data, "thumbnail_url") || "",
    make: _.get(data, "make"),
    model: _.get(data, "model"),
    version: _.get(data, "version"),
    kilometers: _.get(data, "kilometers"),
    main_company_name: _.get(data, "main_company_name"),
    location_name: _.get(data, "location_name"),
    location_distance: _.get(data, "location_distance") || 0,
    first_registration: _.get(data, "first_registration"),
    power_kw: _.get(data, "power_kw"),
    power_hp: _.get(data, "power_hp"),
    engine_cc: _.get(data, "engine_cc"),
    fuel: _.get(data, "fuel"),
    gearbox: _.get(data, "gearbox"),
    vat_regime: _.get(data, "vat_regime"),
    vehicle_stop: _.get(data, "stop"),
    interest: true,
    description: _.get(data, "description"),
    seller_first_name: _.get(data, "seller_first_name"),
    seller_last_name: _.get(data, "seller_last_name"),
    expected_km_to: _.get(data, "expected_km_to"),
    provisional_sale: _.get(data, "provisional_sale") || false,
    expected_disclose: _.get(data, "expected_disclose") || false,
  };
};
