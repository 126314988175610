import { types } from "./types";

const reducer = (
  state = { loading: false, error: "", activated: false, token: "" },
  action
) => {
  switch (action.type) {
    case types.ACTIVATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        activated: false,
      };
    case types.ACTIVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        activated: action.payload.activated,
        token: action.payload.token,
      };
    case types.ACTIVATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
};

export default reducer;
