import { clearedSession, updatedSession } from "../session/sessionSlice";
import { auth_types } from "./types";

export const authenticationMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.payload && action.payload.message === "UNAUTHORIZED") {
      dispatch(clearedSession());
      return next(action);
    }

    if (
      action.type === auth_types.FETCH_TOKEN_SUCCESS ||
      action.type === auth_types.FETCH_TOKEN_BY_CODE_SUCCESS ||
      action.type === auth_types.FETCH_REGISTRATION_SUCCESS ||
      action.type === auth_types.FETCH_PROFILE_SUCCESS
    ) {
      dispatch(updatedSession(action.payload));
    }

    if (action.type === auth_types.AUTH_BY_TOKEN) {
      dispatch(updatedSession(action.payload));
    }

    if (
      action.type === auth_types.AUTH_REQUEST ||
      action.type === auth_types.AUTH_FAILURE
    ) {
      dispatch(clearedSession());
    }

    if (action.type === auth_types.AUTH_SUCCESS) {
      dispatch(updatedSession({ authenticated: true, last_login: Date.now() }));
    }

    return next(action);
  };
