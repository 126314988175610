import React from "react";
import { connect } from "react-redux";

import { resetPasswordRequest } from "../actions";
import { getError, getLoading } from "../selectors";
import { ResetPasswordRequestForm } from "./ResetPasswordRequestForm";

const ResetPasswordRequest = (props) => {
  const { error, loading, resetPasswordRequest } = props;
  const onHandleSubmit = (data) => {
    resetPasswordRequest(data.username);
  };

  return (
    <div>
      <ResetPasswordRequestForm
        loading={loading}
        onSubmit={(data) => onHandleSubmit(data)}
      />

      {error !== null && (
        <div className="container-important my-3 flex p-3 text-center">
          {error.message}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    error: getError(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, { resetPasswordRequest })(
  ResetPasswordRequest
);

export { ConnectedComponent as ResetPasswordRequest };
