export const getLoading = (state) => state.offer.vehicles.loading;
export const getError = (state) => state.offer.vehicles.error;
export const getVehicleIds = (state) =>
  state.offer.vehicles.data && state.offer.vehicles.data.vehicleIds
    ? state.offer.vehicles.data.vehicleIds
    : [];
export const getVehicleById = (state, id) => state.entities.vehicle[id]; // NOK

export const getSelectedId = (state) => state.offer.vehicles.selectedId;
export const getVehicleStateVisible = (state) =>
  state.offer.vehicles.stateVisible;
