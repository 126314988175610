import React from "react";

import { capitalize, filter, keyBy, keys } from "lodash";

export const Tyres = ({ vehicle }) => {
  const tyres = filter(
    keys(vehicle.tyres),
    (key) => vehicle.tyres[key].length > 0
  ).map((tyreGroup) => ({
    [tyreGroup]: keyBy(vehicle.tyres[tyreGroup], "position_name"),
  }))[0];

  return (
    <div className="columns-1 text-[#777] sm:columns-4">
      {keys(tyres).map((tyreGroup, i_tyre_group) => (
        <React.Fragment key={`group_${i_tyre_group}`}>
          {keys(tyres[tyreGroup]).map((tyreLocation, i_tyre_group_location) => (
            <div key={`group-${i_tyre_group}-location${i_tyre_group_location}`}>
              <div className="flex">
                <div className="w-1/2">
                  <span className="font-medium">{capitalize(tyreGroup)}</span>
                  <span className="g-hidden-sm-down"> - </span> {tyreLocation}
                </div>
                <div className="g-brd-gray-light-v3 w-1/2 border-l pl-3">
                  <div>{tyres[tyreGroup][tyreLocation].brand}</div>
                  <div>{tyres[tyreGroup][tyreLocation].size}</div>
                  <div>{`${tyres[tyreGroup][tyreLocation].left}`}</div>
                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};
