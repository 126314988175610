import cogoToast from "cogo-toast";
import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { gettext } from "../../translations";
import { reset_pw_types } from "./types";

export const resetPassword = (uniqueId, password) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.SET_PASSWORD}?request=${uniqueId}&password=${password}/`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: false,
        },
        types: [
          {
            type: reset_pw_types.RESET_PASSWORD_REQUEST,
          },
          {
            type: reset_pw_types.RESET_PASSWORD_SUCCESS,
            payload: (action, state, res) =>
              onResetPasswordSuccess(dispatch, res),
          },
          {
            type: reset_pw_types.RESET_PASSWORD_FAILURE,
            payload: (action, state, res) => {
              return {
                success: false,
                message: `${res.status} - ${res.statusText}`,
              };
            },
          },
        ],
      },
    });
  };
};
const onResetPasswordSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    let success = data.result === "Ok";

    if (success) {
      cogoToast.success(gettext("Password successfully reset"), {
        hideAfter: 5,
      });
    } else {
      return dispatch({
        type: reset_pw_types.RESET_PASSWORD_FAILURE,
        payload: { message: data.result_message },
      });
    }

    return {
      success,
    };
  });
};
