import { isSupported } from "firebase/messaging";

export const isFirebaseMessagingSupported = async () => {
  try {
    const firebaseIsSupported = await isSupported();
    return firebaseIsSupported;
  } catch (error) {
    return false;
  }
};
