import React from "react";

import clsx from "clsx";

export const FilterItem = ({ onClick, item, showCounters = true }) => (
  <li>
    <div
      className={clsx("flex cursor-pointer justify-between truncate", {
        "filter-item": showCounters,
      })}
      onClick={() => onClick(item)}
    >
      <div className="truncate capitalize text-[#777] hover:text-primary">
        {item.name !== "" && item.name !== "undefined"
          ? item.name.toLowerCase()
          : "n/a"}
      </div>

      <span
        className={clsx(
          "filter-item-counter flex w-[30px] items-center text-sm text-[#777]",
          item.count >= 0 && showCounters
            ? "justify-end pr-1"
            : "justify-center",
          {
            "filter-item-counter-active": item.active,
          }
        )}
      >
        {showCounters ? (
          item.count
        ) : item.active ? (
          <i className="fa fa-check text-sm text-white" />
        ) : null}
      </span>
    </div>
  </li>
);
