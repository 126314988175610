import React from "react";

import "./SearchInput.css";

export const SearchInput = (props) => {
  let isEmpty = !(props.value !== "");

  const clear = () => {
    props.onChange("");
  };

  const ClearButton = () => (
    <div
      className="react-simple-input-clear cursor-pointer"
      onClick={() => {
        clear();
      }}
    >
      &times;
    </div>
  );

  return (
    <div className={"react-simple-input-container " + props.classNameContainer}>
      <span className="g-color-text absolute left-[10px] top-[5px]">
        <i className="fa fa-search"></i>
      </span>
      <input
        type="text"
        value={props.value}
        className={
          "react-simple-input w-full rounded-formBorderRadius pl-[35px] " +
          props.className
        }
        placeholder={props.placeholder}
        onChange={(e) => {
          isEmpty = !(e.target.value !== "");
          props.onChange(e.target.value);
        }}
      />
      {isEmpty ? null : ClearButton()}
    </div>
  );
};
