import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { Field, reduxForm } from "redux-form";

import * as validators from "../../../components/helpers/input-validators";

export const authenticationFields = {
  USERNAME: "username",
  PASSWORD: "password",
};

const RenderField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => {
  const { t } = useTranslation();
  return (
    <div className="form-group mb-4">
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className={clsx("form-control w-full rounded py-2", {
          "bg-red-100": touched && error,
        })}
      />
      {touched &&
        ((error && (
          <span className="form-control-feedback text-sm text-red-500">
            {t(error)}
          </span>
        )) ||
          (warning && <span>{t(warning)}</span>))}
    </div>
  );
};

const AuthenticationForm = (props) => {
  const { handleSubmit, submitting, loading } = props;
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name={authenticationFields.USERNAME}
        type="text"
        component={RenderField}
        placeholder={t("Username or email address")}
        validate={[validators.required]}
      />

      <Field
        name={authenticationFields.PASSWORD}
        type="password"
        component={RenderField}
        placeholder={t("Password")}
        validate={[validators.required]}
      />

      <button
        type="submit"
        disabled={submitting}
        className="btn btn-primary relative w-full p-2 uppercase"
      >
        {loading && <div className="loader inset-y-0 my-auto -ml-[14px]"></div>}
        {t("Sign in")}
      </button>
    </form>
  );
};

const ConnectedComponent = reduxForm({
  form: "authentication",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(AuthenticationForm);

export { ConnectedComponent as AuthenticationForm };
