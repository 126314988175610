import { listsTypes } from "./types";

// TODO: check if LIST_FETCH_* action types need to be added, as this reducer
//  covers only LISTS_FETCH_* action types from listsTypes
export const currentListsReducer = (
  state = { ids: [], loading: false, error: "", lastUpdated: null },
  action
) => {
  switch (action.type) {
    case listsTypes.LISTS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case listsTypes.LISTS_FETCH_SUCCESS:
      return {
        ...state,
        ids: action.payload.ids,
        isFetching: false,
        lastUpdated: action.payload.receivedAt,
        error: "",
      };
    case listsTypes.LISTS_FETCH_FAILURE:
      return {
        ...state,
        ids: [],
        isFetching: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
};
