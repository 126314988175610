import { combineReducers } from "redux";

import { types } from "./types";

import { keyBy } from "lodash";

export const filters = (
  state = { byId: {}, loading: false, error: "" },
  action
) => {
  switch (action.type) {
    case types.REGISTRATION_FILTERS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case types.REGISTRATION_FILTERS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        byId: keyBy(action.payload.filters, "id"),
      };
    case types.REGISTRATION_FILTERS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const filter_makes = (
  state = { allMakes: [], loading: false, error: "" },
  action
) => {
  switch (action.type) {
    case types.REGISTRATION_FILTER_MAKES_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case types.REGISTRATION_FILTER_MAKES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        allMakes: action.payload.makes,
      };
    case types.REGISTRATION_FILTER_MAKES_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const filter_companies = (
  state = { allCompanies: [], error: "", loading: false },
  action
) => {
  switch (action.type) {
    case types.REGISTRATION_FILTER_COMPANIES_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case types.REGISTRATION_FILTER_COMPANIES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        allCompanies: action.payload.companies,
      };
    case types.REGISTRATION_FILTER_COMPANIES_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const filter_create = (
  state = { filter_id: 0, error: "", loading: false, success: false },
  action
) => {
  switch (action.type) {
    case types.REGISTRATION_FILTER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        filter_id: 0,
        success: false,
      };
    case types.REGISTRATION_FILTER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        filter_id: action.payload.id,
        success: true,
      };
    case types.REGISTRATION_FILTER_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: false,
      };
    default:
      return state;
  }
};

export const filter_update = (
  state = { error: "", loading: false, success: false },
  action
) => {
  switch (action.type) {
    case types.REGISTRATION_FILTER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        filter_id: 0,
        success: false,
      };
    case types.REGISTRATION_FILTER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        filter_id: action.payload.id,
        success: true,
      };
    case types.REGISTRATION_FILTER_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: false,
      };
    default:
      return state;
  }
};

export const registration = (
  state = { byId: {}, registration_id: 0, loading: false, error: "" },
  action
) => {
  switch (action.type) {
    case types.REGISTRATION_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case types.REGISTRATION_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        byId: {
          [action.payload.id]: action.payload,
        },
        registration_id: action.payload.id,
      };
    case types.REGISTRATION_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
export const countries = (
  state = { allCountries: [], error: "", loading: false },
  action
) => {
  switch (action.type) {
    case types.REGISTRATION_COUNTRIES_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case types.REGISTRATION_COUNTRIES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        allCountries: action.payload.countries,
      };
    case types.REGISTRATION_COUNTRIES_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const document = (
  state = { uploading: false, error: "", document_id: 0 },
  action
) => {
  switch (action.type) {
    case types.REGISTRATION_DOCUMENT_UPLOAD_REQUEST:
      return {
        ...state,
        uploading: true,
        error: "",
        document_id: 0,
      };
    case types.REGISTRATION_DOCUMENT_UPLOAD_SUCCESS:
      return {
        uploading: false,
        error: "",
        document_id: action.payload.document_id,
      };
    case types.REGISTRATION_DOCUMENT_UPLOAD_FAILURE:
      return {
        ...state,
        uploading: false,
        error: action.payload.message,
        document_id: 0,
      };
    default:
      return state;
  }
};

const documents = (state = {}, action) => {
  /*
     let documents: {
     typea: { loading, error, document_id}
     typeb: { loading, error, document_id}
     typec: { loading, error, document_id}
     typed: { loading, error, document_id}
     ...
     }
     */
  switch (action.type) {
    case types.REGISTRATION_DOCUMENT_UPLOAD_REQUEST:
    case types.REGISTRATION_DOCUMENT_UPLOAD_SUCCESS:
    case types.REGISTRATION_DOCUMENT_UPLOAD_FAILURE:
      return {
        ...state,
        [action.payload.key]: document(state[action.payload.key], action),
      };
    default:
      return state;
  }
};

export const wizard = (
  state = { step: 0, loading: false, error: "" },
  action
) => {
  switch (action.type) {
    case types.REGISTRATION_UPDATE_CURRENT_STEP:
      return {
        ...state,
        step: action.payload.number,
      };
    case types.REGISTRATION_POST_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case types.REGISTRATION_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case types.REGISTRATION_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
};

const data = combineReducers({
  registration,
  filters,
  filter_makes,
  filter_companies,
  countries,
});

export default combineReducers({
  data,
  wizard,
  filter_create,
  filter_update,
  documents,
});
