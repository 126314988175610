import { getSortById } from "./functions";
import { vehicleSorters } from "./sorters";

export const mapSortForRequest = (sorter) => {
  let vehicles_sort = sorter;
  if (sorter === null) {
    vehicles_sort = getSortById(vehicleSorters, 7);
  }

  const sort = vehicles_sort.sortFields.map((field) => ({
    column: field,
    order: vehicles_sort.sortMode,
  }));

  return encodeURIComponent(JSON.stringify(sort));
};
