import { useDispatch } from "react-redux";

/** Custom hook allowing .then() after the dispatch. */
export const useDispatchWithPromise = () => {
  const dispatch = useDispatch();
  return (action) => {
    return new Promise((resolve) => {
      dispatch(action);
      resolve();
    });
  };
};
