import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Phone from "react-phone-number-input";

import clsx from "clsx";

import { ToggleSwitch } from "../ToogleSwitch";
import { Tooltip } from "../Tooltip";
import { ReactSelect } from "./ReactSelect";

const LabelFieldContainer = ({
  label,
  showLabel = true,
  touched,
  error,
  warning,
  children,
  additionalInfo = "",
  name,
  example,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        className={`form-group flex flex-col lg:flex-row lg:space-x-5 ${
          touched && error ? "u-has-error-v1" : ""
        }`}
      >
        {showLabel && (
          <label className="my-auto w-[150px] font-medium lg:text-right">
            {label}
            {additionalInfo !== "" && (
              <div className="inline-block">
                <Tooltip message={additionalInfo}>
                  <i className="fa fa-info-circle text-[20px]" />
                </Tooltip>
              </div>
            )}
          </label>
        )}

        <div id={label} className="w-full">
          {children}

          {example && <small className="mr-2">{example}</small>}

          {touched &&
            ((error && (
              <small className="form-control-feedback text-sm text-red-500">
                {t(error)}
              </small>
            )) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    </React.Fragment>
  );
};

const FieldContainer = ({ touched, error, warning, children }) => (
  <div className={`${touched && error ? "u-has-error-v1" : ""}`}>
    {children}

    {touched &&
      ((error && <small className="form-control-feedback">{error}</small>) ||
        (warning && <span>{warning}</span>))}
  </div>
);

export const TextField = ({
  input,
  label,
  placeholder,
  disabled,
  type,
  meta: { touched, error, warning },
  additionalInfo,
  name,
  id,
}) => (
  <LabelFieldContainer
    label={label}
    touched={touched}
    error={error}
    warning={warning}
    additionalInfo={additionalInfo}
    name={id}
  >
    <input
      {...input}
      placeholder={placeholder && placeholder !== "" ? placeholder : label}
      type={type}
      disabled={disabled}
      className={clsx("form-control w-full rounded-formBorderRadius py-2", {
        "bg-red-100": touched && error,
      })}
    />
  </LabelFieldContainer>
);

export const TextFieldWithNoLabel = ({
  input,
  label,
  placeholder,
  disabled,
  type,
  meta: { touched, error, warning },
}) => (
  <FieldContainer touched={touched} error={error} warning={warning}>
    <input
      {...input}
      placeholder={label}
      type={type}
      disabled={disabled}
      className="form-control py-3 pr-0"
    />
  </FieldContainer>
);

export const Multiline = ({
  input,
  label,
  type,
  meta: { touched, error, valid, warning },
  showLabel = true,
  rows = 5,
  placeholder,
}) => (
  <LabelFieldContainer
    label={label}
    showLabel={showLabel}
    touched={touched}
    error={error}
    warning={warning}
  >
    <textarea
      {...input}
      placeholder={placeholder && placeholder !== "" ? placeholder : label}
      className="w-full rounded-formBorderRadius"
      rows={rows}
    />
  </LabelFieldContainer>
);

export const PhoneNumber = ({
  input,
  label,
  example,
  meta: { touched, error, valid, warning },
}) => {
  // Style the input element as the rest of the form
  useEffect(() => {
    const elements = document.getElementsByClassName("PhoneInputInput");
    if (elements) {
      Array.from(elements).forEach((element) => {
        element.classList.add(
          "form-control",
          "pr-0",
          "rounded-formBorderRadius"
        );
      });
    }
  }, []);

  return (
    <LabelFieldContainer
      label={label}
      touched={touched}
      error={error}
      warning={warning}
      example={example}
    >
      <Phone
        {...input}
        onFocus={() => {
          let parent = document.getElementById(label);
          let child = parent.getElementsByClassName("react-phone-number-input");

          if (child[0]) {
            child[0].classList.add("react-phone-number-input-focus");
          }
        }}
        onBlur={() => {
          let parent = document.getElementById(label);
          let child = parent.getElementsByClassName("react-phone-number-input");
          if (child[0]) {
            child[0].classList.remove("react-phone-number-input-focus");
          }
        }}
        defaultCountry="BE"
        placeholder={label}
        displayInitialValueAsLocalNumber={true}
      />
    </LabelFieldContainer>
  );
};

export const Combo = ({
  input,
  label,
  type,
  options,
  meta: { touched, error, valid, warning },
}) => (
  <LabelFieldContainer
    label={label}
    touched={touched}
    error={error}
    warning={warning}
  >
    <ReactSelect
      /*
                  React-select v1 allowed you to use strings for the value prop, but with v2
                  it's deprecated in favor of a value prop that is always either an array of
                  Options objects or an Options object.
                  To manage selected values as a simple string you apply a simple filter on the dataset.
                  https://react-select.com/upgrade-to-v2
                  */
      value={options.filter(({ value }) => value === input.value)}
      placeholder={label}
      onChange={(option) => input.onChange(option ? option.value : "")}
      options={options}
      maxMenuHeight={130}
    />
  </LabelFieldContainer>
);

export const Toggle = ({ input, meta = {}, label }) => {
  const { value, onChange } = input;
  const { touched, error, warning } = meta;
  const { t } = useTranslation();

  return (
    <div className="form-group flex items-center space-x-2">
      {label && <label className="w-[300px] text-right">{label}</label>}
      <ToggleSwitch value={value} onChange={onChange} />
      <div className="flex">
        {touched &&
          ((error && (
            <small className="form-control-feedback">{t(error)}</small>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};
