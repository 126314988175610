export const DEFAULT_LANGUAGE = "en";

export const LANGUAGES = {
  en: { nativeName: "English" },
  nl: { nativeName: "Nederlands" },
  fr: { nativeName: "Français" },
  de: { nativeName: "Deutsch" },
};
export const AVAILABLE_LANGUAGES = Object.keys(LANGUAGES);

export const DEVELOPMENT_MODE = false;
