import { createErrorMessageSelector, createLoadingSelector } from "../ui";
import { RESET_PASSWORD } from "./types";

const loadingSelector = createLoadingSelector([RESET_PASSWORD]);
const errorSelector = createErrorMessageSelector([RESET_PASSWORD]);

export const getLoading = (state) => {
  return loadingSelector(state);
};

export const getError = (state) => {
  return errorSelector(state);
};
