import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import clsx from "clsx";
import { compose } from "redux";

import {
  DescriptionLabel,
  Expectation,
  Specifications,
  Top9FeatureText,
  Top16FeatureIcons,
  VehicleDamageBadge,
  VehicleVatRegimeBadge,
} from "../../../components";
import { VehicleReservePriceAlmostMetBadge } from "../../../components/web/vehicle/VehicleReservePriceAlmostMetBadge";
import { VehicleReservePriceBadge } from "../../../components/web/vehicle/VehicleReservePriceBadge";
import { withConfig } from "../../../config";
import { entityActions } from "../../../entities/actions";
import nopic from "../../../img/nopic.jpeg";
import { Bidding, BiddingAuctionRowBlock } from "../../bidding";
import { FavoriteButton } from "../../listvehicles-favorite/_web/FavoriteButton";
import { InterestLabelButton } from "../../listvehicles-no-interest/_web/InterestLabelButton";
import * as interestSelectors from "../../listvehicles-no-interest/selectors";
import { PrintButtons } from "../../print";
import { selectListVehicle } from "../actions";
import * as selectors from "../selectors";
import { CatalogPrices } from "./Catalog";
import { ListVehicleCountDown } from "./ListVehicleCountDown";

import moment from "moment";

const Header = ({
  list_number,
  make,
  model,
  version,
  provisional_sale,
  expected_disclose,
  location_name,
  main_company_name,
  list_vehicle_date_online,
  list_date,
  vehicle_print,
  list_vehicle_id,
  flexible_stop,
  vehicle_stop,
  updateVehicle,
  setShowExpiring,
  setShowHidden,
}) => {
  const showLocationName =
    (provisional_sale && expected_disclose && location_name !== "") ||
    (!provisional_sale && location_name !== null && location_name !== "");

  const showLocationBlock =
    showLocationName || main_company_name || list_vehicle_date_online;

  const showCountDown = vehicle_print && flexible_stop;

  const handleOnExpired = () => {
    let data = {
      vehicle: {
        [list_vehicle_id]: {
          live_auction_hidden: true,
        },
      },
    };
    updateVehicle(data);
    setShowExpiring(false);
    setShowHidden(false);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex justify-between text-nowrap">
          <hgroup className="w-1/2 flex-1 truncate">
            <h5 className="h5 truncate uppercase text-highlightColor">
              #{String("000" + list_number).slice(-3)} {make} {model}
            </h5>
            <p className=" truncate text-sm uppercase text-[#999]">{version}</p>
          </hgroup>
          <div className="flex flex-col items-end space-y-1">
            <div className="hidden md:flex md:flex-col md:items-end">
              {showLocationBlock && (
                <div className="flex flex-wrap justify-end space-x-3">
                  {showLocationName && (
                    <span className="truncate text-right text-[#777]">
                      <i className="icon-map-pin" /> {location_name}
                    </span>
                  )}
                  {main_company_name && (
                    <span className="truncate text-right text-[#777]">
                      <i className="icon-map-pin" /> {main_company_name}
                    </span>
                  )}

                  {list_vehicle_date_online && (
                    <span className="text-[#777]">
                      <i className="icon-upload" />{" "}
                      {moment(list_date).format("DD/MM/YYYY HH:MM")}
                    </span>
                  )}
                </div>
              )}
              {vehicle_print && (
                <PrintButtons listVehicleId={list_vehicle_id} />
              )}
            </div>
            {showCountDown && (
              <div>
                <ListVehicleCountDown
                  date={vehicle_stop}
                  onExpired={handleOnExpired}
                  onExpiring={() => {
                    setShowExpiring(true);
                  }}
                  onHiding={() => {
                    setShowHidden(true);
                  }}
                  onExtended={() => {
                    setShowExpiring(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MidSection = ({
  picture_url,
  make,
  model,
  vehicle_favorite,
  list_vehicle_id,
  setBiddingInputSelected,
  reserve_price_met,
  reserve_price_almost_met,
  vat_regime,
  list_vehicle_damage_counter,
  damage_count,
  vehicle,
  summaryFields,
  catalog,
  list_vehicle_top_feature_icons,
  top_features,
  top_features_text,
  features_count,
  list_vehicle_expectation,
}) => {
  const showVehicleReservePriceBadge =
    reserve_price_met &&
    reserve_price_met !== null &&
    reserve_price_met === true;

  const showVehicleReservePriceAlmostMetBadge =
    reserve_price_almost_met &&
    reserve_price_almost_met !== null &&
    reserve_price_almost_met === true &&
    !showVehicleReservePriceBadge;

  return (
    <div className="flex space-x-3">
      <div className="w-3/12">
        <div className="relative">
          {picture_url && (
            <img
              src={picture_url}
              alt={make + " " + model}
              onError={(e) => (e.target.src = nopic)}
            />
          )}

          {!picture_url && <img src={nopic} alt={make + " " + model} />}

          {vehicle_favorite && (
            <div className="absolute left-[5px] top-[5px]">
              <FavoriteButton
                listVehicleId={list_vehicle_id}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          )}
        </div>

        <Bidding
          listVehicleId={list_vehicle_id}
          onFocus={() => setBiddingInputSelected(true)}
          onBlur={() => setBiddingInputSelected(false)}
        />
      </div>

      <div className="w-9/12 space-y-2">
        <div className="flex flex-wrap gap-1">
          {showVehicleReservePriceBadge && <VehicleReservePriceBadge />}

          {showVehicleReservePriceAlmostMetBadge && (
            <VehicleReservePriceAlmostMetBadge />
          )}
          <VehicleVatRegimeBadge vat_regime={vat_regime} />
          {list_vehicle_damage_counter && (
            <VehicleDamageBadge damages={damage_count} />
          )}
        </div>

        <Specifications vehicle={vehicle} fields={summaryFields} />
        <CatalogPrices catalog={catalog} />

        {list_vehicle_top_feature_icons ? (
          <div className="hidden sm:block">
            <Top16FeatureIcons
              listVehicleId={list_vehicle_id}
              vehicle_features={top_features}
            />
          </div>
        ) : (
          <div className="hidden sm:block">
            <Top9FeatureText
              features={top_features_text}
              count={features_count}
            />
          </div>
        )}

        {list_vehicle_expectation && (
          <div>
            <Expectation {...vehicle} />
          </div>
        )}
      </div>
    </div>
  );
};

export const AuctionOverlay = ({ text, showAsOverlay = true }) => {
  return (
    <div
      className={clsx("flex cursor-default items-center justify-center", {
        loadingContainerOverlay: showAsOverlay,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <span className="select-none text-center text-[33px] font-semibold">
        {text}
      </span>
    </div>
  );
};

const ListVehicleItem = (props) => {
  const { t } = useTranslation();
  const [biddingInputSelected, setBiddingInputSelected] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  const [showExpiring, setShowExpiring] = useState(false);

  const { vehicle, disabled, selected, hidden, config } = props;

  const onVehicleItemClick = () => {
    const { list_vehicle_id } = props.vehicle;
    props.onClick(list_vehicle_id, biddingInputSelected);
  };

  if (hidden) {
    return null;
  }
  const {
    list_vehicle_id,
    picture_url,
    make,
    model,
    version,
    main_company_name,
    list_number,
    list_date,
    vat_regime,
    description,
    description_plus,
    top_features,
    top_features_text,
    damage_count,
    vehicle_stop,
    flexible_stop,
    features_count,
    catalog,
    provisional_sale,
    expected_disclose,
    location_name,
    reserve_price_met,
    reserve_price_almost_met,
  } = vehicle;

  const {
    vehicle_interest,
    list_vehicle_date_online,
    list_vehicle_summary_fields,
    list_vehicle_summary_provisional_fields,
    list_vehicle_top_feature_icons,
    list_vehicle_expectation,
    list_vehicle_damage_counter,
    vehicle_print,
    vehicle_favorite,
  } = config;

  const summaryFields =
    vehicle.provisional_sale && !vehicle.expected_disclose
      ? list_vehicle_summary_provisional_fields
      : list_vehicle_summary_fields;

  const showImportantDescription = description && description !== "";
  const showDescriptionPlus = description_plus && description_plus !== "";
  const showDescriptions = showImportantDescription || showDescriptionPlus;
  const showCompanyNameOrInterest = main_company_name || vehicle_interest;
  const showAuctionEnding = showExpiring && !showHidden && !props.loading;
  const showAuctionEnded = showHidden && !props.loading;

  const headerProps = {
    list_number,
    make,
    model,
    version,
    provisional_sale,
    expected_disclose,
    location_name,
    main_company_name,
    list_vehicle_date_online,
    list_date,
    vehicle_print,
    list_vehicle_id,
    flexible_stop,
    vehicle_stop,
    updateVehicle: props.updateVehicle,
    setShowExpiring,
    setShowHidden,
  };

  const midSectionProps = {
    picture_url,
    make,
    model,
    vehicle_favorite,
    list_vehicle_id,
    setBiddingInputSelected,
    reserve_price_met,
    reserve_price_almost_met,
    vat_regime,
    list_vehicle_damage_counter,
    damage_count,
    vehicle,
    summaryFields,
    catalog,
    list_vehicle_top_feature_icons,
    top_features,
    top_features_text,
    features_count,
    list_vehicle_expectation,
  };

  const Descriptions = () => {
    return (
      <div className="my-1 space-y-1">
        {showDescriptionPlus && (
          <div className="w-full">
            <DescriptionLabel description={description_plus} />
          </div>
        )}
        {showImportantDescription && (
          <div className="w-full">
            <DescriptionLabel description={description} important />
          </div>
        )}
      </div>
    );
  };

  const CompanyNameAndInterest = () => {
    return (
      <div className="flex w-full items-center justify-between md:hidden">
        {main_company_name && (
          <span className="truncate">
            <i className="icon-map-pin" /> {main_company_name}
          </span>
        )}
        <span>
          <i className="icon-upload" />{" "}
          {moment(list_date).format("DD/MM/YYYY HH:MM")}
        </span>

        {vehicle_interest && (
          <div className="truncate">
            <InterestLabelButton listVehicleId={list_vehicle_id} />
          </div>
        )}
      </div>
    );
  };

  return (
    <article
      className={clsx("item item--hover relative p-3", {
        "item-disabled": disabled,
        "item-active": selected,
      })}
      onClick={onVehicleItemClick}
    >
      <div className="space-y-1">
        <div className="flex flex-col space-y-2">
          <Header {...headerProps} />
          <MidSection {...midSectionProps} />
        </div>
        {showDescriptions && <Descriptions />}
        {showCompanyNameOrInterest && <CompanyNameAndInterest />}
        {!showAuctionEnded && (
          <BiddingAuctionRowBlock
            listVehicleId={list_vehicle_id}
            onFocus={() => setBiddingInputSelected(true)}
            onBlur={() => setBiddingInputSelected(false)}
          />
        )}
      </div>
      {showAuctionEnding && (
        <AuctionOverlay text={t("This auction is ending")} />
      )}
      {showAuctionEnded && (
        <AuctionOverlay text={t("This auction has ended")} />
      )}
    </article>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    vehicle: selectors.getVehicleById(state, ownProps.listVehicleId),
    disabled: interestSelectors.listVehicleDisabledByInterest(
      state,
      ownProps.listVehicleId
    ),
    hidden: selectors.getIsHidden(state, ownProps.listVehicleId),
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps, {
    selectListVehicle,
    updateVehicle: entityActions.updateEntities,
  })
)(ListVehicleItem);
export { ConnectedComponent as ListVehicleItem };
