import React from "react";
import { connect } from "react-redux";

import { gettext } from "../../../translations";
import { logout } from "../actions";

const SignOutButton = ({ logout }) => (
  <li
    className="list-inline-item row g-ma-0 g-pa-0 cursor-pointer"
    onClick={logout}
  >
    <i
      className="fa fa-power-off g-mr-5 g-width-25 "
      style={{ alignSelf: "center" }}
    />
    <span className="text-primary--hover">{gettext("Signout")}</span>
  </li>
);

const ConnectedComponent = connect(null, { logout })(SignOutButton);

export { ConnectedComponent as SignOutButton };
