import React from "react";
import { useTranslation } from "react-i18next";

export const FiltersHeader = ({ onCreateNew }) => {
  const { t } = useTranslation();
  return (
    <div className="bottom-shadow mb-5 flex justify-between bg-white p-2">
      <h5 className="font-extrabold text-highlightColor">{t("All filters")}</h5>
      <button
        type="button"
        onClick={onCreateNew}
        className="btn btn-primary px-2 py-1 hover:bg-highlightColor"
      >
        <i className="fa fa-plus mr-1"></i>
        {t("New")}
      </button>
    </div>
  );
};
