import React from "react";
import { useTranslation } from "react-i18next";

import format from "string-format";

import moment from "moment";
import numeral from "numeral";

export const Expectation = ({
  provisional_sale,
  expected_disclose,
  expected_date,
  expected_km,
}) => {
  const { t } = useTranslation();
  if (
    provisional_sale ||
    expected_disclose ||
    ((expected_date === null ||
      expected_date === "0001-01-01" ||
      typeof expected_date === "undefined") &&
      (expected_km === null ||
        expected_km === 0 ||
        expected_km === "0" ||
        typeof expected_km === "undefined"))
  )
    return null;

  return (
    <div className="expectation">
      {format(
        t("Expected {0} with {1} km"),
        moment(expected_date).format("DD/MM/YYYY"),
        numeral(expected_km).format("0,0")
      )}
    </div>
  );
};
