import _ from "lodash";

export const mapOfferVehicle = (data) => {
  return {
    id: _.get(data, "id"),
    vehicle_id: _.get(data, "vehicle_id"),
    price: _.get(data, "price"),
    provisional_sale: _.get(data, "provisional_sale") || false,
    expected_date: _.get(data, "expected_date"),
    expected_km: _.get(data, "expected_km"),
    expected_km_to: _.get(data, "expected_km_to"),
    expected_disclose: _.get(data, "expected_disclose") || false,
    vin: _.get(data, "vin"),
    thumbnail_url: _.get(data, "thumbnail_url") || "",
    picture_url: _.get(data, "picture_url") || "",
    make: _.get(data, "make"),
    model: _.get(data, "model"),
    version: _.get(data, "version"),
    description: _.get(data, "description"),
    kilometers: _.get(data, "kilometers"),
    first_registration: _.get(data, "first_registration"),
    power_kw: _.get(data, "power_kw"),
    power_hp: _.get(data, "power_hp"),
    engine_cc: _.get(data, "engine_cc"),
    fuel: _.get(data, "fuel"),
    gearbox: _.get(data, "gearbox"),
    emission_co2: _.get(data, "emission_co2"),
    catalog: mapCatalog(_.get(data, "catalog") || {}),
    vat_regime: _.get(data, "vat_regime"),
    top_features: _.map(_.get(data, "features"), "id"),
    top_features_text: _.get(data, "features_text")
      ? _.map(_.get(data, "features_text").items, "description")
      : [],
    features_count: _.get(data, "features_text")
      ? _.get(data, "features_text").count
      : 0,
    damage_count: _.get(data, "damages"),
    location_name: _.get(data, "location_name"),
    location_distance: _.get(data, "location_distance") || 0,
    // TODO: check if main_company_name and description_plus has to be included in the offer
    //  as it was present in the component, but not in this mapper
    main_company_name: _.get(data, "main_company_name") || "",
    description_plus: _.get(data, "description_plus") || "",

    // "location_id": 1,
    // "color_interior": "$$/en/Titanium Black/Titanium Black/Titanium  Black",
    // "color_exterior": "$$/en/Deep Black Pearlescent",
    // "vehicle_comment": null,
    // "wheel_formula": null,
    // "doors": 5,
    // "seats": 5,
    // "emission_norm": "$$/en/Euro 6",
    // "depreciation": null,
  };
};

export const mapCatalog = (data) => {
  if (_.keys(data).length === 0) return {};
  return {
    catalog: _.get(data, "catalog")
      ? _.get(data, "catalog").replace(".", ",")
      : null,
    catalog_options: _.get(data, "catalog_options")
      ? _.get(data, "catalog_options").replace(".", ",")
      : null,
  };
};
