import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { Field, reduxForm } from "redux-form";

import { validators } from "../../../components/index";

export const restPasswordFields = {
  USERNAME: "username",
};

const RenderField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => {
  const { t } = useTranslation();
  return (
    <div className={"form-group flex flex-col"}>
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className={clsx("form-control g-py-15 g-px-15 rounded", {
          "bg-red-100": touched && error,
        })}
      />
      {touched &&
        ((error && (
          <small className="form-control-feedback text-sm text-red-500">
            {t(error)}
          </small>
        )) ||
          (warning && <span>{t(warning)}</span>))}
    </div>
  );
};

const ResetPasswordRequestForm = (props) => {
  const { handleSubmit, loading } = props;
  const { t } = useTranslation();

  return (
    <form className="py-3" onSubmit={handleSubmit}>
      <Field
        name={restPasswordFields.USERNAME}
        type="text"
        component={RenderField}
        placeholder={t("e-mail")}
        validate={[validators.required]}
      />

      <button
        type="submit"
        disabled={loading}
        className="btn btn-primary relative mt-3 w-full p-2 uppercase"
      >
        {loading && <div className="loader inset-y-0 my-auto -ml-[14px]"></div>}
        {t("Send")}
      </button>
    </form>
  );
};

const ConnectedComponent = reduxForm({
  form: "password-reset",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(ResetPasswordRequestForm);

export { ConnectedComponent as ResetPasswordRequestForm };
