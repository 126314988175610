import { listVehiclesAndStateTypes } from "./types";

export const listVehicleSelect = (listVehicleId, stateFullScreen = false) => {
  return {
    type: listVehiclesAndStateTypes.VEHICLE_SELECT,
    listVehicleId,
    stateVisible: stateFullScreen,
  };
};

export const toggleListVehicleState = () => {
  return {
    type: listVehiclesAndStateTypes.TOGGLE_STATE,
  };
};

export const listVehiclesAndStateCleanUp = () => {
  return (dispatch) => {
    return dispatch({
      type: listVehiclesAndStateTypes.CLEANUP,
    });
  };
};
