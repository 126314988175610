import { basketFetchService } from "./services/basketFetch";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  items: [],
  limit: 0,
  offset: 0,
  more: false,
  searchTerm: "",
  totalCount: 0,
};

function basket(state = initialState, action) {
  switch (action.type) {
    case basketFetchWebTest.pending.type:
      return Object.assign({}, state, {
        loading: true,
        error: "",
        searchTerm: action.payload.searchTerm,
        offset: action.payload.offset,
      });
    case basketFetchWebTest.fulfilled.type:
      const { limit, offset, data, totalCount } = action.payload;

      const items = offset === 0 ? data : [...state.items, ...data];

      return Object.assign({}, state, {
        loading: false,
        limit,
        offset,
        items,
        error: "",
        more: totalCount > items.length,
        totalCount,
      });
    case basketFetchWebTest.rejected.type:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        more: false,
      };
    default:
      return state;
  }
}

const runOnBasketFetch = (state, action) => {
  return Object.assign({}, state, {
    // [action.payload.key]: basket(state[action.payload.key], action),
    [action.meta.arg.key]: basket(state[action.meta.arg.key], action),
  });
};

const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === basketFetchWebTest.pending.type ||
        action.type === basketFetchWebTest.fulfilled.type ||
        action.type === basketFetchWebTest.rejected.type,
      runOnBasketFetch
    );
  },
});

export default basketSlice.reducer;

export const basketFetchWebTest = createAsyncThunk(
  "basketFetchWebTest",
  basketFetchService
);
