import React from "react";
import { useTranslation } from "react-i18next";

import { Content, LegalTypes } from "../../legal";
import { ConfirmHeader } from "./ConfirmHeader";

export const CartTermsConditions = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-normal flex flex-col">
      <ConfirmHeader
        cancelBtnText={t("Cancel")}
        nextBtnText={t("I accept & confirm")}
        title={`02. ${t("Terms & conditions.")}`}
        onCancel={onCancel}
        onNext={onConfirm}
      />

      <div className="content container-semiboxed bg-white p-4">
        <Content id={LegalTypes.BIDDING_CONFIRM_CONDITIONS} />
      </div>
    </div>
  );
};
