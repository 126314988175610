import { onAutralisSalesMessage } from "./eventHandlers";

export const initializeSocketIOListeners = (socket) => {
  socket.on("autralis_sales_message", onAutralisSalesMessage);
};

export const cleanupSocketIOListeners = (socket) => {
  // For all event cleanup use socket.off();
  socket.off("autralis_sales_message", onAutralisSalesMessage);
};
