import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

import { submit } from "redux-form";

import { LoadingIndicator } from "../../../components";
import { fetchData, submitProfile } from "../actions";
import * as selectors from "../selectors";
import { profileFields, ProfileForm } from "./components/forms/ProfileForm";

const Profile = (props) => {
  const { profile, countries, loading, error, hasAllData, submitProfile } =
    props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
  }, []);

  const formSubmit = () => {
    props.submit("Profile");
  };

  const updateProfile = (values) => {
    submitProfile(profile.id, values);
  };

  if (!hasAllData) {
    return (
      <div className="mt-70">
        <LoadingIndicator loading={loading} />
      </div>
    );
  }

  if (!loading && profile === null) {
    return <div> {t("No profile found.")}</div>;
  }

  if (hasAllData) {
    const FormValues = {
      [profileFields.PERSONAL_TITLE]: profile.title,
      [profileFields.PERSONAL_FIRST_NAME]: profile.first_name,
      [profileFields.PERSONAL_LAST_NAME]: profile.last_name,
      [profileFields.PERSONAL_PHONE]: profile.telephone,
      [profileFields.PERSONAL_MOBILE]: profile.mobile,
      [profileFields.PERSONAL_MAIL]: profile.email,

      [profileFields.COMPANY_NAME]: profile.company.name,
      [profileFields.COMPANY_VAT]: profile.company.vat_number,
      [profileFields.COMPANY_ADDRESS]: profile.company.address.address,
      [profileFields.COMPANY_COUNTRY]: profile.company.address.country_code,
      [profileFields.COMPANY_POSTAL]: profile.company.address.post_code,
      [profileFields.COMPANY_CITY]: profile.company.address.city,
      [profileFields.COMPANY_MAIL]: profile.company.logistics_email,

      [profileFields.SETTINGS_CAR_BY_CAR_NOTIFICATION]:
        profile.car_by_car_notification,
      [profileFields.SETTINGS_CAR_BY_CAR_MAIL]: profile.car_by_car_mail,
      [profileFields.SETTINGS_DAILY_DIGEST_MAIL]: profile.daily_digest,
      [profileFields.SETTINGS_OFFERS_MAIL]: profile.offers_mail,
      [profileFields.SETTINGS_WON_MAIL]: profile.won_mail,
    };

    return (
      <>
        <div className="bg-normal bottom-shadow sticky my-3 flex justify-between bg-white p-2">
          <h5 className="font-extrabold text-highlightColor">{t("Profile")}</h5>

          <button onClick={formSubmit} className="btn btn-primary px-3 py-2">
            <i className="fa fa-save mr-1"></i>
            {t("Save")}
          </button>
        </div>

        <LoadingIndicator loading={loading} />

        {error !== "" && (
          <div className=" container-important mb-10 flex bg-white p-5 text-center">
            {t(error)}
          </div>
        )}

        <ProfileForm
          initialValues={FormValues}
          required={true}
          countries={countries}
          onSubmit={updateProfile}
        />
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    profile: selectors.getProfile(state),
    countries: selectors.getCountries(state),
    loading: selectors.getLoading(state),
    error: selectors.getError(state),
    hasAllData: selectors.hasAllData(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  fetchData,
  submitProfile,
  submit,
})(Profile);

export { ConnectedComponent as Profile };
