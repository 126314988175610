import React from "react";

import format from "string-format";

import { SpecificationHelpers } from "../../../index";

export const SpecificationsTop3 = ({ vehicle, fields }) => (
  <div>
    {format(
      "{0} - {1} - {2}",
      SpecificationHelpers.getFieldData(fields[0], vehicle),
      SpecificationHelpers.getFieldData(fields[1], vehicle),
      SpecificationHelpers.getFieldData(fields[2], vehicle)
    )}
  </div>
);
