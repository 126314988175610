import React, { useState } from "react";
import { connect } from "react-redux";

import style from "../../../styles";
import { hasActiveCriteria } from "../functions/filter";
import { FilterModal } from "./FilterModal";

const FilterButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { listId, hasActiveCriteria } = props;
  return (
    <button
      className="aspect-square h-[35px] bg-[#f2f2f2] p-2"
      type="button"
      onClick={() => setModalVisible(true)}
    >
      <i
        className="fa fa-filter font-[25px]"
        style={{ color: hasActiveCriteria ? "#86d863" : style.defaultColor }}
      />

      {modalVisible && (
        <FilterModal
          visible={modalVisible}
          listId={listId}
          onCancel={() => {
            setModalVisible(false);
          }}
          onCriteriaChanged={props.onCriteriaChanged}
          onClearFilters={props.onClearFilters}
        />
      )}
    </button>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { listId } = ownProps;
  return {
    hasActiveCriteria: hasActiveCriteria(state, listId),
  };
};

const ConnectedComponent = connect(mapStateToProps)(FilterButton);

export { ConnectedComponent as FilterButton };
