import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { statisticTypes } from "./types";

import { keyBy, map, sortBy } from "lodash";

export const fetchStatistics = () => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.STATISTICS,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        types: [
          {
            type: statisticTypes.FETCH_REQUEST,
          },
          {
            type: statisticTypes.FETCH_SUCCESS,
            payload: (action, state, res) => onFetchSuccess(dispatch, res),
          },
          {
            type: statisticTypes.FETCH_FAILURE,
            payload: (action, state, res) => onFetchFailure(dispatch, res),
          },
        ],
      },
    });
  };
};

const onFetchSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    let modus = data[0] && data[0].modus ? data[0].modus : null;

    if (modus === null) {
      return null;
    } else if (modus === "BASE") {
      return {
        modus: modus,
        states: keyBy(data[0].states, "code"),
      };
    } else if (modus === "EXPERT" || modus === "") {
      return {
        modus: modus,
        accepted: sortBy(
          map(data[0].accepted, (item) => ({
            period: `${item.year}/${item.month}`,
            count: item.count,
            amount: Number(item.amount),
            month: item.month,
            year: item.year,
          })),
          ["year", "month"]
        ),
        average_all: data[0].average_all[0],
        average_make: data[0].average_make,
        states: keyBy(data[0].states, "code"),
      };
    }
  });
};

const onFetchFailure = (dispatch, res) => {
  return {
    message: res.statusText,
  };
};
