import React, { useState } from "react";

import "./Rating.css";

export const Rating = (props) => {
  const [stateRating, setStateRating] = useState(props.rating || null);
  const [hoverRating, setHoverRating] = useState(null);

  const handleClick = (rating) => {
    setStateRating(rating);
    setHoverRating(rating);
    props.onChange(rating + 1);
  };

  const handleMouseOver = (rating) => {
    setStateRating(rating);
    setHoverRating(hoverRating);
  };

  const handleMouseOut = () => {
    setStateRating(hoverRating);
  };

  let stars = [];

  for (let i = 0; i < 5; i++) {
    let rateClass = "rating-star";

    if (stateRating >= i && stateRating != null) {
      rateClass += " star-selected";
    }

    stars.push(
      <label
        key={i}
        className={rateClass}
        onClick={() => handleClick(i)}
        onMouseOver={() => handleMouseOver(i)}
        onMouseOut={handleMouseOut}
      >
        ★
      </label>
    );
  }

  return <div className="star-rating">{stars}</div>;
};
