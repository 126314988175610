import React from "react";

import { Template } from "../components";
import { FiltersConfiguration } from "../features/filters/index";

export const Filters = () => (
  <Template>
    <FiltersConfiguration />
  </Template>
);
