import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Template } from "../components";
import { ResetPassword } from "../features/reset-password";
import { ResetPasswordRequest } from "../features/reset-password-request";

export const ForgotPassword = () => {
  const params = useParams();
  const { t } = useTranslation();
  const uniqueId = params["uniqueId"];

  const RenderResetPassword = () => {
    return (
      <Template>
        <div className="bg-white">
          <section className="flex justify-center">
            <div className="w-full p-5 lg:w-8/12 xl:w-6/12">
              <h2 className="h5 mb-4">{t("Forgot your password") + "?"}</h2>
              <p>
                {t(
                  "Please enter your email address below. You will receive a link to reset your password"
                )}
              </p>
              <ResetPasswordRequest />
            </div>
          </section>
        </div>
      </Template>
    );
  };

  const RenderSetPassword = () => {
    return (
      <Template>
        <div className="bg-white">
          <section className="flex justify-center">
            <div className="w-full p-5 lg:w-8/12 xl:w-6/12">
              <h2 className="h5 mb-4">{t("Reset your password")}</h2>
              <p>{t("Please enter your new password in the fields below.")}</p>
              <ResetPassword uniqueId={uniqueId} />
            </div>
          </section>
        </div>
      </Template>
    );
  };

  return uniqueId === undefined ? (
    <RenderResetPassword />
  ) : (
    <RenderSetPassword />
  );
};
