const commonStyle = {
  // In use - the global primary website color
  primaryColor: "#2d375a",
  // in use
  secondaryColor: "#f5f5f5",

  // In use
  defaultColor: "#757575",

  // In use
  highlightUnderlayColor: "#00ccfe",
  // In use
  highlightInvertColor: "#fff",

  // In use
  approvalsTextColor: "#2680C2",
  // In use
  approvalsBackgroundColor: "#DCEEFB",
  // In use
  approvalsBorderColor: "#2680C2",

  // In use
  warningTextColor: "#5c4813",
  // In use
  warningBackgroundColor: "#f9ebc5",
  // In use
  warningAccent: "#f4ca64",

  // In use
  borderHairlineColor: "#F7F6F5",
  // In use
  backgroundColor: "#fff",
  // In use
  underlayColor: "#E4E4E4",

  // In use, not fully
  formBorderColor: "#6b7280",
  // In use
  formBorderRadius: "0px",

  // In use
  badgeColor: "lightgrey",
  // In use
  badgeTextColor: "#757575",

  // In use
  fixedPriceBadge: "#e29300",
  // In use
  blindBiddingBadge: "#e2cb00",
  // In use
  liveAuctionBadge: "#e25a00",

  // Bellow text sizes are used on mobile react native app only
  smallTextSize: 12,
  regularTextSize: 14,
  bigTextSize: 16,

  // Bellow text sizes are used on mobile react native app only
  headerColor: "#fff",
  headerIconSize: 22,
  headerTextSize: 16,

  // Bellow text sizes are used on mobile react native app only
  iconSize: 20,
  iconColor: "#d1cfcf",
};

// In use
commonStyle.loadingIndicatorColor = commonStyle.primaryColor;
// In use
commonStyle.loadingIndicatorBgColor = "lightgrey";

// In use
commonStyle.highlightColor = commonStyle.primaryColor;
// In use
commonStyle.highlightDeleteColor = commonStyle.primaryColor;

export default commonStyle;
