import React from "react";

import { CheckValue } from "./CheckValue";

import _ from "lodash";

const CheckItems = ({ checks }) => {
  return Object.keys(checks)
    .sort()
    .map((group, i_group) => (
      <div
        className="flex w-full flex-col gap-3 sm:flex-row"
        key={`group-${i_group}`}
      >
        <div className="w-3/12">{group}</div>
        <div className="g-brd-gray-light-v3 w-full border-l lg:w-6/12">
          {checks[group].map((check, i_check) => (
            <div
              className="flex justify-between"
              key={`check-${i_group}-${i_check}`}
            >
              <div className="pl-3">{_.capitalize(check.name)}</div>
              <div className="text-right">
                <CheckValue type={check.typeName} value={check.value} />
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
};

export const Checks = ({ vehicle }) => {
  const checks = _.groupBy(
    _.orderBy(_.get(vehicle, "checks"), [(check) => check.name], ["asc"]),
    "groupName"
  );

  return (
    <div className="flex w-full flex-col gap-3 text-[#777]">
      {<CheckItems checks={checks} />}
    </div>
  );
};
