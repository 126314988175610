import { pictureSwiperTypes } from "./types";

export const toggleSwiperVisibility = (visible, pictures = [], index = 0) => ({
  type: pictureSwiperTypes.TOGGLE_VISIBILITY,
  payload: {
    visible,
    pictures,
    index,
  },
});
