import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import { formatPrice } from "../../../helpers/functions";
import {
  DealerStateTranslate,
  HistoryStatesHelper,
} from "../../../helpers/utils";

const applyStateStyle = (state) => {
  // TODO: Reevaluate HistoryStatesHelper.isLost() and HistoryStatesHelper.isHistory(). When the state
  //  value is "HISTORY_REFUSED", both methods are relevant, so the first matching styling is applied.
  if (HistoryStatesHelper.isSold(state)) {
    return "bg-[#48bb78] text-white";
  } else if (HistoryStatesHelper.isPending(state)) {
    return "bg-[lightgrey] text-[darkgrey]";
  } else if (HistoryStatesHelper.isHistory(state)) {
    return "bg-[lightgrey] text-black";
  } else if (HistoryStatesHelper.isLost(state)) {
    return "bg-[#c53030] text-white";
  } else {
    return "bg-[lightgrey] text-white";
  }
};

export const DealerState = (props) => {
  const { basket_price, dealer_state } = props;
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "h-min-[30px] flex flex-col items-center justify-center py-1 font-semibold",
        applyStateStyle(dealer_state)
      )}
    >
      <div className="flex flex-1 justify-center text-[11px]">
        {t(DealerStateTranslate[dealer_state])}
      </div>
      <div className="flex flex-1 justify-center">
        {formatPrice(basket_price)}
      </div>
    </div>
  );
};
