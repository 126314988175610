import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import clsx from "clsx";

import { Summary } from "../../../components/web/vehicle-state/components/Summary";
import { withConfig } from "../../../config";
import { ListTypes } from "../../../constants";
import { entityActions } from "../../../entities/actions";
import { getListIdFromListVehicleId } from "../../../helpers/functions";
import { Bidding } from "../../bidding";
import { BiddingAuctionRowBlock } from "../../bidding/_web/BiddingAuctionRowBlock";
import * as listSelectors from "../../lists/selectors";
import { NextPrevButtons } from "../../listvehicles-nextprev/_web/NextPrevButtons";
import { InterestLabelButton } from "../../listvehicles-no-interest/_web/InterestLabelButton";
import * as interestSelectors from "../../listvehicles-no-interest/selectors";
import { ListVehicleCountDown } from "../../listvehicles/_web/ListVehicleCountDown";
import { AuctionOverlay } from "../../listvehicles/_web/ListVehicleItem";
import { ListVehicleState } from "../../listvehiclestate";
import * as selectors from "../../listvehiclestate/selectors";
import { listVehicleSelect, toggleListVehicleState } from "../actions";

function ListvehicleStateContainer(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { listVehicleId, config, sendListInformationByRequest, loading } =
    props;
  const { vehicle_interest } = config;

  const vehicle = useSelector((state) =>
    selectors.getVehicleById(state, listVehicleId)
  );
  const disabled = useSelector((state) =>
    interestSelectors.listVehicleDisabledByInterest(state, listVehicleId)
  );
  const listType = useSelector((state) =>
    listSelectors.getListType(state, getListIdFromListVehicleId(listVehicleId))
  );

  const updateVehicle = (entities) => {
    dispatch(entityActions.updateEntities(entities));
  };

  const { live_auction_hidden } = vehicle;

  const [showExpiring, setShowExpiring] = useState(false);
  const [showHidden, setShowHidden] = useState(false);

  const showLiveAuctionCounter =
    listType === ListTypes.LIVE_AUCTION && listType !== "";
  const showAuctionEnding = showExpiring && !showHidden && !loading;
  const showAuctionEnded = showHidden && !loading;

  const handleBack = () => {
    dispatch(toggleListVehicleState());
  };

  const handleOnExpired = () => {
    let data = {
      vehicle: {
        [listVehicleId]: {
          live_auction_hidden: true,
        },
      },
    };
    updateVehicle(data);
    setShowExpiring(false);
    setShowHidden(false);
  };

  useEffect(() => {
    if (live_auction_hidden) {
      handleBack();
    }
  }, [live_auction_hidden]);

  return (
    <div>
      <div className="bg-normal sticky top-0 z-10 mb-1 flex w-full flex-col items-center justify-between space-y-1 border-b md:top-[70px]">
        <div className="w-full bg-white p-2">
          <div className="flex items-center justify-between">
            <div>
              <button
                onClick={handleBack}
                className="btn btn-primary rounded-0 flex items-center text-nowrap px-3 py-2 text-[12px] font-semibold"
              >
                <i className="fa fa-chevron-left mr-2 text-[12px]" />
                <span className="hidden sm:block">{t("Back to list")}</span>
                <span className="block sm:hidden">{t("Back")}</span>
              </button>
            </div>
            <div>
              <NextPrevButtons
                listVehicleId={listVehicleId}
                onClick={(listVehicleId) =>
                  dispatch(listVehicleSelect(listVehicleId, true))
                }
              />
            </div>
            <div className="hidden items-center justify-center sm:flex">
              <div className="w-[200px]">
                <Bidding listVehicleId={listVehicleId} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-end bg-white p-3 sm:hidden">
          <div className="mx-auto w-[200px]">
            <Bidding listVehicleId={listVehicleId} />
          </div>

          {vehicle_interest && (
            <div className="flex justify-end empty:hidden">
              <InterestLabelButton listVehicleId={listVehicleId} />
            </div>
          )}
        </div>

        {showLiveAuctionCounter && (
          <div className="relative w-full">
            <div className="flex justify-center bg-white p-1 empty:hidden">
              <ListVehicleCountDown
                date={vehicle.vehicle_stop}
                onExpired={handleOnExpired}
                onExpiring={() => {
                  setShowExpiring(true);
                }}
                onHiding={() => {
                  setShowHidden(true);
                }}
                onExtended={() => {
                  setShowExpiring(false);
                }}
              />
            </div>
            {!showAuctionEnded && (
              <div className="w-full bg-white p-1 empty:hidden">
                <BiddingAuctionRowBlock listVehicleId={listVehicleId} />
              </div>
            )}
            {showAuctionEnding && (
              <AuctionOverlay text={t("This auction is ending")} />
            )}
            {showAuctionEnded && (
              <div className="w-full bg-white p-1 empty:hidden">
                <AuctionOverlay
                  text={t("This auction has ended")}
                  showAsOverlay={false}
                />
              </div>
            )}
          </div>
        )}
        <div
          className={clsx("w-full", {
            "item-disabled": disabled,
          })}
        >
          <Summary
            vehicle={vehicle}
            listVehicleId={listVehicleId}
            config={config}
          />
        </div>
        <div className="bg-normal h-[5px] w-full"></div>
      </div>

      <ListVehicleState
        listVehicleId={listVehicleId}
        showStickyHeader={false}
        sendListInformationByRequest={sendListInformationByRequest}
      />
    </div>
  );
}

const ConnectedComponent = withConfig(ListvehicleStateContainer);

export { ConnectedComponent as ListVehicleStateContainer };
