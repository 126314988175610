import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { compose } from "redux";

import { routes } from "../../../_web/navigation/routes";
import { withConfig } from "../../../config";
import { LanguagePicker } from "../../../features/localization";
import { ProfileMenu } from "../../../features/profile-menu";
import {
  getUser,
  getUserState,
  isAuthenticated,
  isMailActivationPending,
  isRegistrationPending,
} from "../../../features/session";
import { VehicleSearch } from "../../../features/vehicle-search";
import { setFunctionsLocale } from "../../../helpers/functions";
import brandLogo from "../../../img/logo_web.png";

const Header = ({
  authenticated,
  registrationPending,
  user,
  userAllowed,
  locale,
  containerStyle,
  config,
}) => {
  const { t } = useTranslation();

  return (
    <header className="static top-0 z-50 w-full bg-white md:sticky">
      <div className={`${containerStyle}`}>
        <div className="m-0 flex min-h-[60px] items-center justify-between p-0">
          <div>
            <Link to={authenticated ? routes.DASHBOARD : config.front_url}>
              <img src={brandLogo} alt="Brand" />
            </Link>
          </div>

          <div className="flex space-x-2">
            {authenticated && !registrationPending && userAllowed && (
              <div className="inline-block">
                <VehicleSearch />
              </div>
            )}

            {!authenticated && config.dealerships && (
              <button
                className="h-[36px] bg-primary px-3 text-sm uppercase text-white hover:bg-highlightColor"
                onClick={() => window.location.assign(config.admin_url)}
              >
                {t("I'm a dealer")}
              </button>
            )}

            <LanguagePicker
              onChange={() => {
                setFunctionsLocale(locale);
                // TODO: Legacy function below. Some vehicle info is fetched from the server depending on
                //  the language. Therefore, page reload was implemented when switching the language.
                //  Check which content that is and implement content re-fetch in that specific component
                //  instead of reloading the whole page.
                //  setTimeout(() => navigate.reloadCurrentPage(), 10);
              }}
            />

            {authenticated && (
              <ProfileMenu
                config={config}
                user={user}
                registrationPending={registrationPending}
              />
            )}
          </div>
        </div>
      </div>
      <div className="bg-normal h-[10px] w-full border-t border-t-[#eee]"></div>
    </header>
  );
};

const mapStateToProps = (state) => {
  const userState = getUserState(state);

  return {
    authenticated: isAuthenticated(state), //&& !isRegistrationPending(state) && !isMailActivationPending(state),
    registrationPending:
      isRegistrationPending(state) || isMailActivationPending(state),
    user: getUser(state),
    userAllowed: userState !== null ? !userState.error : false,
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps)
)(Header);

export { ConnectedComponent as Header };
