import { API_ENDPOINTS } from "../../../api/endpoints";
import i18n from "../../../i18n";

export const onFetchTokenRejected = (responseValues) => {
  const { data, statusCode } = responseValues;
  let message = i18n.t("An error occurred");
  let code = 400;

  // TODO: non_field_errors are coming from Django backend
  if (data.non_field_errors && data.non_field_errors[0]) {
    const http_error = data.non_field_errors[0].split(":")[0];

    if (http_error === "401") {
      message = i18n.t("Your account is locked, contact support");
      code = 401;
    } else if (http_error === "403") {
      message = i18n.t("Login or password incorrect, please try again");
      code = 403;
    } else if (http_error === "412") {
      message = i18n.t("You haven’t confirmed your email address yet.");
      code = 412;
    } else if (http_error === "406") {
      message = i18n.t("Invalid request");
      code = 406;
    } else {
      message = data.non_field_errors;
      code = statusCode;
    }
  }

  return {
    message: message,
    code: code,
  };
};

export const fetchTokenService = async ({ username, password }, thunkAPI) => {
  const currentState = thunkAPI.getState();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": currentState.localization.locale,
  };

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  };

  try {
    const response = await fetch(API_ENDPOINTS.API_TOKEN_AUTH, options);

    if (response.ok) {
      return await response.json();
    }
    // TODO: revise the reject response in the backend
    // prepare responseValues
    const rejectedResponseValues = {
      data: await response.json(),
      statusCode: response.status,
    };
    return thunkAPI.rejectWithValue(
      onFetchTokenRejected(rejectedResponseValues)
    );
  } catch (err) {
    throw err;
  }
};
