import React from "react";
import { useTranslation } from "react-i18next";

import { equipments } from "../../../features/listvehicles/equipments";
import { Tooltip } from "../Tooltip";

import { intersection, slice } from "lodash";

const FeatureItem = ({ toolTipLocation = "top", ...props }) => {
  const { equipment, id, available } = props;
  const uniqueId = `equipment_${id}`;
  const { t } = useTranslation();

  const onClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`flex w-[45px] items-center justify-center text-[20px] ${
        available ? "text-highlightColor" : "text-[#d6d6d6]"
      }`}
    >
      <Tooltip message={t(equipment.title)}>
        <i id={uniqueId} onClick={onClick} className={equipment.icon} />
      </Tooltip>
    </div>
  );
};

export const Top16FeatureIcons = (props) => {
  const { listVehicleId, vehicle_features } = props;

  return (
    <div className="my-2 space-y-2">
      <div className="flex justify-between">
        {slice(equipments, 0, 8).map((equipment, i) => (
          <FeatureItem
            key={`${listVehicleId}_${equipment.id}`}
            id={`${listVehicleId}_${equipment.id}`}
            equipment={equipment}
            available={intersection(vehicle_features, equipment.ids).length > 0}
          />
        ))}
      </div>

      <div className="flex justify-between">
        {slice(equipments, 8, 17).map((equipment, i) => (
          <FeatureItem
            key={`${listVehicleId}_${equipment.id}`}
            id={`${listVehicleId}_${equipment.id}`}
            equipment={equipment}
            toolTipLocation="bottom"
            available={intersection(vehicle_features, equipment.ids).length > 0}
          />
        ))}
      </div>
    </div>
  );
};
