import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { combineIds } from "../../helpers/functions";
import { listVehicleInterestTypes } from "./types";

export const listVehiclesInterestFetch = (listId) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.NO_INTEREST}?listId=${listId}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: listVehicleInterestTypes.FETCH_REQUEST,
            payload: {
              listId,
            },
          },
          {
            type: listVehicleInterestTypes.FETCH_SUCCESS,
            payload: (action, state, res) =>
              onListVehiclesInterestFetchSuccess(dispatch, res, listId),
          },
          {
            type: listVehicleInterestTypes.FETCH_FAILURE,
          },
        ],
      },
    });
  };
};

const onListVehiclesInterestFetchSuccess = (dispatch, res, listId) => {
  return res.json().then((data) => {
    return {
      vehicleIds: data.map((obj) => combineIds(obj.list_id, obj.vehicle_id)),
    };
  });
};

export const listVehicleInterest = (listId, vehicleId) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.NO_INTEREST,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          list_id: listId,
          vehicle_id: vehicleId,
        }),
        types: [
          {
            type: listVehicleInterestTypes.POST_REQUEST,
          },
          {
            type: listVehicleInterestTypes.POST_SUCCESS,
            payload: (action, state, res) =>
              onListVehicleInterestSuccess(dispatch, res, listId, vehicleId),
          },
          {
            type: listVehicleInterestTypes.POST_FAILURE,
          },
        ],
      },
    });
  };
};

export const listVehicleInterestDelete = (listId, vehicleId) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.NO_INTEREST,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          list_id: listId,
          vehicle_id: vehicleId,
        }),
        types: [
          {
            type: listVehicleInterestTypes.POST_REQUEST,
          },
          {
            type: listVehicleInterestTypes.POST_SUCCESS,
            payload: (action, state, res) =>
              onListVehicleInterestDeleteSuccess(
                dispatch,
                res,
                listId,
                vehicleId
              ),
          },
          {
            type: listVehicleInterestTypes.POST_FAILURE,
          },
        ],
      },
    });
  };
};

const onListVehicleInterestSuccess = (dispatch, res, listId, vehicleId) => {
  return res.json().then((data) => {
    if (data.result_code === "Ok") {
      dispatch({
        type: listVehicleInterestTypes.ADD,
        payload: {
          vehicleId: combineIds(listId, vehicleId),
        },
      });
    }
  });
};

const onListVehicleInterestDeleteSuccess = (
  dispatch,
  res,
  listId,
  vehicleId
) => {
  return res.json().then((data) => {
    if (data.result_code === "Ok") {
      dispatch({
        type: listVehicleInterestTypes.REMOVE,
        payload: {
          vehicleId: combineIds(listId, vehicleId),
        },
      });
    }
  });
};

export const listVehicleInterestErrorConfirm = () => ({
  type: listVehicleInterestTypes.ERROR_CLEAR,
});
