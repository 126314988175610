import { fetchSuppliersService } from "./services/fetchSuppliers";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  suppliersList: null,
  error: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliers.pending, setLoading);
    builder.addCase(fetchSuppliers.fulfilled, setSuppliersAndSuccess);
    builder.addCase(fetchSuppliers.rejected, setError);
  },
});

const setLoading = (state) => {
  state.isLoading = true;
};

const setSuppliersAndSuccess = (state, action) => {
  state.suppliersList = action.payload;
  state.isLoading = false;
  state.isSuccess = true;
};

const setError = (state, action) => {
  state.error = action.payload.message;
  state.isLoading = false;
  state.isError = true;
};

export default suppliersSlice.reducer;

export const fetchSuppliers = createAsyncThunk(
  "suppliers/fetch",
  fetchSuppliersService
);
