import React from "react";
import { useTranslation } from "react-i18next";

import { formatPriceDecimal } from "../../../helpers/functions";

import { keys } from "lodash";

export const CatalogPrices = ({ catalog }) => {
  const { t } = useTranslation();

  if (keys(catalog).length === 0) return null;

  return (
    <div className="flex justify-end space-x-1 py-1">
      <span>
        {`${t("catalogus")}  ${formatPriceDecimal(catalog.catalog)}`}{" "}
      </span>
      <span>
        {`${t("catalog+")}  ${formatPriceDecimal(catalog.catalog_options)}`}{" "}
      </span>
    </div>
  );
};
