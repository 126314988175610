import React from "react";

/**
 * This hook stores the value in ref and saves it. The value is not re-computed in every render.
 */
export const usePrevPropValue = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
