import React from "react";
import { useTranslation } from "react-i18next";
import Flash from "react-reveal/Flash";

import { formatPrice } from "../../../helpers/functions";

export const BiddingAuction = (props) => {
  const {
    vehiclePrice,
    basketPrice,
    highestBidder,
    biddingAllowed,
    reserveMet,
  } = props;
  const { t } = useTranslation();

  if (biddingAllowed) {
    const bgColor = highestBidder
      ? "#48bb78"
      : basketPrice > 0 && !highestBidder
      ? "#c53030"
      : "lightgrey";
    const textColor = highestBidder
      ? "white"
      : basketPrice > 0 && !highestBidder
      ? "white"
      : "#6C6C6C";
    const text = vehiclePrice > 0 ? t("Actual bid") : t("Place your bid");

    return (
      <BiddingItem
        bgColor={bgColor}
        textColor={textColor}
        text={text}
        price={vehiclePrice}
      />
    );
  } else if (!biddingAllowed && highestBidder) {
    if (reserveMet === null || reserveMet === true) {
      return (
        <BiddingItem
          bgColor="#48bb78"
          textColor="white"
          text={t("Won")}
          price={vehiclePrice}
        />
      );
    } else if (!reserveMet) {
      return (
        <BiddingItem
          bgColor="#c9c938"
          textColor="white"
          text={t("Reserved")}
          price={vehiclePrice}
        />
      );
    }

    return (
      <BiddingItem
        bgColor="#48bb78"
        textColor="white"
        text={t("Won")}
        price={vehiclePrice}
      />
    );
  } else if (!biddingAllowed && !highestBidder) {
    return (
      <BiddingItem
        bgColor="lightgrey"
        textColor="#6C6C6C"
        text={t("Closed")}
        price={vehiclePrice}
      />
    );
  }
};

const BiddingItem = ({ bgColor, textColor, text, price }) => {
  return (
    <div
      className="flex min-h-[38px] items-center justify-center py-1 font-semibold"
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <Flash spy={price}>
        <div>
          <div className="text-center text-[11px]">{text}</div>
          {price > 0 && (
            <div className="text-center text-[14px]">{formatPrice(price)}</div>
          )}
        </div>
      </Flash>
    </div>
  );
};
