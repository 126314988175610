import React from "react";
import { useTranslation } from "react-i18next";

import calling from "../../../assets/svg/call.svg";
import collaboration from "../../../assets/svg/undraw_collaboration.svg";
import { Button } from "../../../components/web/Button";
import { Modal } from "../../../components/web/Modal";

export const ApprovalCheckModal = ({ visible, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible} onCancel={onCancel}>
      <div className="flex h-full w-full items-center justify-center">
        <div className="relative -mt-[30%] bg-white">
          <button className="absolute right-2 top-2" onClick={onCancel}>
            <i className="fa fa-close block p-1 text-[26px]" />
          </button>
          <div
            className="flex flex-col md:flex-row"
            data-testid="approvals-modal"
          >
            <div
              className="flex flex-col space-y-4 p-5 text-center md:w-7/12"
              style={{ backgroundColor: "#e4f4f9" }}
            >
              <h4 className="font-extrabold text-highlightColor">
                {t("Welcome to the Used Cars Center")}!
              </h4>

              <p>
                {t(
                  "The official car dealers need to activate your account for you to see the offers."
                )}
              </p>

              <div
                className="flex space-x-1 p-3 text-[20px]"
                style={{
                  color: "#2680C2",
                  backgroundColor: "#DCEEFB",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#2680C2",
                }}
              >
                <div className="shrink-0">
                  <img className="size-[70px]" src={calling} alt="calling" />
                </div>
                <div className="mx-auto self-center text-center text-[20px]">
                  {t(
                    "Contact the dealers so they can add you to their network."
                  )}
                </div>
              </div>

              <div className="mx-auto">
                <Button>
                  <a
                    href="https://admin.usedcars.center/sales/dealer/contact/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Contact suppliers")}
                  </a>
                </Button>
              </div>
            </div>

            <div className="hidden w-5/12 p-5 md:block">
              <img
                className="mx-auto max-w-[300px]"
                src={collaboration}
                alt="icon"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
