import React from "react";
import { connect } from "react-redux";

import { compose } from "redux";

import { withConfig } from "../../../config";
import {
  getVehicleIdFromListVehicleId,
  openAdminUrl,
} from "../../../helpers/functions";
import { getLocale } from "../../localization";

const PrintButtons = (props) => {
  const { config } = props;
  const { vehicle_print } = config;

  const onPrint2 = (e) => {
    e.stopPropagation();
    const { listVehicleId, locale } = props;
    const vehicle_id = getVehicleIdFromListVehicleId(listVehicleId);
    openAdminUrl(`sales/offer/assessment2/${vehicle_id}/0/${locale}`);
  };

  const onPrint = (e) => {
    e.stopPropagation();
    const { listVehicleId, locale } = props;
    const vehicle_id = getVehicleIdFromListVehicleId(listVehicleId);
    openAdminUrl(`sales/offer/assessment/${vehicle_id}/0/${locale}`);
  };

  if (!vehicle_print) return null;

  return (
    <div className="space-x-2">
      <i
        className="fa fa fa-globe cursor-pointer"
        style={{ fontSize: 16, color: "#016f92" }}
        onClick={onPrint2}
      />
      <i
        className="fa fa fa-print cursor-pointer"
        style={{ fontSize: 16, color: "#016f92" }}
        onClick={onPrint}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    locale: getLocale(state),
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps)
)(PrintButtons);

export { ConnectedComponent as PrintButtons };
