import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { warningTypes } from "./types";

export const fetchWarnings = () => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.WARNINGS,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: true,
        },
        types: [
          {
            type: warningTypes.FETCH_REQUEST,
          },
          {
            type: warningTypes.FETCH_SUCCESS,
            payload: (action, state, res) => onFetchSuccess(dispatch, res),
          },
          {
            type: warningTypes.FETCH_FAILURE,
          },
        ],
      },
    });
  };
};

const onFetchSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    let warnings = [];

    if (data.content && Array.isArray(data.content)) {
      warnings = data.content;
    }

    return {
      warnings,
    };
  });
};
