import _ from "lodash";

export const mapListDetail = (data) => {
  return {
    id: _.get(data, "id"),
    name: _.get(data, "name"),
    start: _.get(data, "start"),
    stop: _.get(data, "stop"),
    state: _.get(data, "state"),
    type: _.get(data, "publicationType"),
    vehicles: _.map(_.get(data, "vehicles"), mapListVehicle),
  };
};

export const mapListVehicle = (data) => {
  return {
    list_id: _.get(data, "list_id"),
    vehicle_id: _.get(data, "vehicle_id"),
    list_vehicle_id: `${_.get(data, "list_id")}_${_.get(data, "vehicle_id")}`,
    list_number: _.get(data, "list_number") || 0,
    list_date: _.get(data, "created_at"),
    price: _.get(data, "price"),
    reserve_price_met: _.get(data, "reserve_met") || null,
    reserve_price_almost_met: _.get(data, "reserve_almost_met") || null,
    vehicle_state: _.get(data, "state"),
    expected_date: _.get(data, "expected_date"),
    expected_km: _.get(data, "expected_km"),
    vin: _.get(data, "vin"),
    thumbnail_url: _.get(data, "thumbnail_url") || "",
    picture_url: _.get(data, "picture_url") || "",
    make: _.get(data, "make"),
    model: _.get(data, "model"),
    version: _.get(data, "version"),
    kilometers: _.get(data, "kilometers"),
    main_company_name: _.get(data, "main_company_name"),
    main_company_id: _.get(data, "main_company_id"),
    location_name: _.get(data, "location_name"),
    location_distance: _.get(data, "location_distance") || 0,
    first_registration: _.get(data, "first_registration"),
    power_kw: _.get(data, "power_kw"),
    power_hp: _.get(data, "power_hp"),
    engine_cc: _.get(data, "engine_cc"),
    emission_co2: _.get(data, "emission_co2"),
    fuel: _.get(data, "fuel"),
    gearbox: _.get(data, "gearbox"),
    vat_regime: _.get(data, "vat_regime"),
    vehicle_stop: _.get(data, "stop"),
    description: _.get(data, "description"),
    description_plus: _.get(data, "description_plus"),
    seller_first_name: _.get(data, "seller_first_name"),
    seller_last_name: _.get(data, "seller_last_name"),
    top_features: _.map(_.get(data, "features"), "id"),
    top_features_text: _.get(data, "features_text")
      ? _.map(_.get(data, "features_text").items, "description")
      : [],
    features_count: _.get(data, "features_text")
      ? _.get(data, "features_text").count
      : 0,
    damage_count: _.get(data, "damages"),
    provisional_sale: _.get(data, "provisional_sale") || false,
    expected_km_to: _.get(data, "expected_km_to"),
    expected_disclose: _.get(data, "expected_disclose") || false,
    flexible_stop: _.get(data, "flexible_stop") || false,
    catalog: mapCatalog(_.get(data, "catalog") || {}),
    live_auction_hidden: false,
  };
};

export const mapListBasket = (data) => {
  return {
    list_vehicle_id: `${_.get(data, "list_id")}_${_.get(data, "vehicle_id")}`,
    list_id: _.get(data, "list_id"),
    vehicle_id: _.get(data, "vehicle_id"),
    basket_state: _.get(data, "state"),
    dealer_state: _.get(data, "dealer_state"),
    basket_price: _.get(data, "price"),
    comment: _.get(data, "comment") || "",
    basket_maximum_price: _.get(data, "maximum_price") || 0,
    highest: _.get(data, "highest") || false,
    reserve_price_met: _.get(data, "reserve_met") || null,
    reserve_price_almost_met: _.get(data, "reserve_almost_met") || null,
  };
};

export const mapCatalog = (data) => {
  if (_.keys(data).length === 0) return {};
  return {
    catalog: _.get(data, "catalog")
      ? _.get(data, "catalog").replace(".", ",")
      : 0,
    catalog_options: _.get(data, "catalog_options")
      ? _.get(data, "catalog_options").replace(".", ",")
      : 0,
  };
};
