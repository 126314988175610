import React, { useState } from "react";

import { Modal } from "../../../components/web/Modal";
import { BidConfirm } from "./BidConfirm";
import { BidProcessing } from "./BidProcessing";

export const BidConfirmModal = (props) => {
  const {
    visible,
    onCancel,
    listVehicleId,
    price,
    fixedPrice,
    comment,
    vehicle,
    onConfirm,
  } = props;
  const [processingVisible, setProcessingVisible] = useState(false);

  return (
    <Modal visible={visible} onCancel={onCancel}>
      <div className="flex h-full w-full items-center justify-center">
        <div className="max-w-[435px] rounded bg-white">
          <div className="sticky top-0 flex h-[40px] items-center justify-end space-x-8 bg-highlightColor p-2">
            <button onClick={onCancel}>
              <i className="fa fa-close block text-[24px] text-white" />
            </button>
          </div>

          <div className="relative max-h-[80dvh] overflow-auto p-2">
            <BidConfirm
              listVehicleId={listVehicleId}
              price={price}
              fixedPrice={fixedPrice}
              comment={comment}
              vehicle={vehicle}
              onConfirm={(price, comment, quality) => {
                setProcessingVisible(true);
                onConfirm(price, comment, quality);
              }}
              onCancel={onCancel}
            />

            {processingVisible && (
              <BidProcessing
                onClose={onCancel}
                onReview={(e) => {
                  setProcessingVisible(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
