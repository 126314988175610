import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toggleSwiperVisibility } from "../actions";
import * as selectors from "../selectors";
import "./photoswipe_custom.css";

import "photoswipe/dist/photoswipe.css";
import PhotoSwipeLightbox from "photoswipe/lightbox";

// For swiper options ==> http://photoswipe.com/documentation/options.html

export const PictureSwiper = () => {
  const dispatch = useDispatch();

  // get props from the redux state
  const visible = useSelector(selectors.getVisible);
  const pictures = useSelector(selectors.getPictures);
  const index = useSelector(selectors.getIndex);

  const onClose = () => {
    dispatch(toggleSwiperVisibility(false));
  };

  // Remap pictures prop to have alt key instead of title to be used for the image slides and caption
  const remappedPictures = pictures.map((item) => ({
    src: item.src,
    width: item.w,
    height: item.h,
    alt: item.title,
    typeId: item.typeId,
  }));

  // PictureSwiper options
  const options = {
    dataSource: remappedPictures,

    // Disable animations
    showHideAnimationType: "none",
    zoomAnimationDuration: false,

    // Background opacity
    bgOpacity: 1,

    // Disable closing the slide on tap or click
    imageClickAction: "none",
    tapAction: "none",
    bgClickAction: "none",

    pswpModule: () => import("photoswipe"),
  };

  // Create lightbox instance with provided options
  let lightbox = new PhotoSwipeLightbox(options);

  const PictureSwipeLightboxDisplay = () => {
    // When closing the slide, trigger the toggleSwiperVisibility action
    lightbox.on("close", () => {
      onClose();
    });

    // Display custom caption
    lightbox.on("uiRegister", function () {
      lightbox.pswp.ui.registerElement({
        name: "custom-caption",
        order: 9,
        isButton: false,
        appendTo: "root",
        html: "Caption text",
        onInit: (el, pswp) => {
          lightbox.pswp.on("change", () => {
            const currSlideElement = lightbox.pswp.currSlide.data;
            let captionHTML = "";
            if (currSlideElement) {
              // get caption from alt attribute
              captionHTML = currSlideElement.alt;
            }
            el.innerHTML = captionHTML || "";
          });
        },
      });
    });

    // Display download button
    lightbox.on("uiRegister", function () {
      lightbox.pswp.ui.registerElement({
        name: "download-button",
        order: 8,
        isButton: true,
        tagName: "a",

        // SVG with outline
        html: {
          isCustomSVG: true,
          inner:
            '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
          outlineID: "pswp__icn-download",
        },

        // Or provide full svg:
        // html: '<svg width="32" height="32" viewBox="0 0 32 32" aria-hidden="true" class="pswp__icn"><path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" /></svg>',

        // Or provide any other markup:
        // html: '<i class="fa-solid fa-download"></i>'

        onInit: (el, pswp) => {
          el.setAttribute("download", "");
          el.setAttribute("target", "_blank");
          el.setAttribute("rel", "noopener");

          pswp.on("change", () => {
            el.href = pswp.currSlide.data.src;
          });
        },
      });
    });

    // Initialise lightbox instance
    lightbox.init();

    // Load and open slide at provided index number
    lightbox.loadAndOpen(index);
  };

  useEffect(() => {
    if (visible) {
      PictureSwipeLightboxDisplay();
    }

    return () => {
      // Cleanup actions on component dismount
      // Unbind all lightbox events and close PhotoSwipe if opened
      lightbox.destroy();
    };
  }, [visible]);
};
