import { types } from "./types";

const initial_state = { vehicles: [], isFetching: false, error: "" };

export const searchReducer = (state = initial_state, action) => {
  switch (action.type) {
    case types.FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case types.FETCH_SUCCESS:
      return {
        ...state,
        vehicles: action.payload.vehicles,
        isFetching: false,
        error: "",
      };
    case types.FETCH_FAILURE:
      return {
        ...state,
        vehicles: [],
        isFetching: false,
        error: action.payload.message,
      };
    case types.CLEANUP: {
      return {
        ...initial_state,
      };
    }
    default:
      return state;
  }
};
