import format from "string-format";

import i18n from "../../i18n";

export const required = (value) => (value ? undefined : "Required");

export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const minLength = (min) => (value) =>
  value && value.length < min
    ? format("Must be {0} characters or more", min)
    : undefined;

export const minLength6 = minLength(6);
export const minLength10 = minLength(10);

const minValue = (min) => (value) =>
  value && value < min ? format("Must be at least {0}", min) : undefined;

export const minValue6 = minValue(6);

export const hasNumber = (value) =>
  value && !/\d/.test(value) ? "Must contain a number" : undefined;

export const hasLowerCase = (value) => {
  if (value.toUpperCase() !== value) {
    return undefined;
  }
  return "Must contain a lowercase character";
};

export const hasUpperCase = (value) => {
  if (value.toLowerCase() !== value) {
    return undefined;
  }
  return "Must contain a uppercase character";
};
