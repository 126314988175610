import React from "react";

import { withConfig } from "../../../../config/index";

import _ from "lodash";

const GridPicture = ({ picture }) => {
  return (
    <img
      className="hover:cursor-zoom-in"
      src={picture?.src}
      alt={picture?.title}
    />
  );
};

const PicturesGrid = ({ vehicle, config, onPictureClick }) => {
  const { vehicle_state_picture_type_order } = config;

  const sortedPictures = _.flatten(
    _.values(
      _.map(vehicle_state_picture_type_order, function (id) {
        return _.filter(vehicle.pictures, { typeId: id });
      })
    )
  );

  const vehiclePictureCount =
    sortedPictures.length > 0 && sortedPictures.length - 5 > 0
      ? sortedPictures.length - 5
      : 0;
  const pictures = _.concat(
    sortedPictures,
    _.flatten(_.map(vehicle.damages, "pictures"))
  );

  const showPicturesGrid = pictures.length > 5;
  const showFirstImageOverlay =
    vehicle.list_number !== undefined && vehicle.list_number >= 0;
  const showLastImageOverlay = vehiclePictureCount > 0;

  return showPicturesGrid ? (
    <div className="space-y-[3px]">
      <div className="flex space-x-[3px]">
        <div
          className="relative w-[50%] bg-[#e6e6e6]"
          onClick={() => onPictureClick(0, pictures)}
        >
          <GridPicture picture={pictures[0]} />
          {showFirstImageOverlay && (
            <div className="absolute left-[10px] top-[10px] flex h-[20px] w-[35px] items-center justify-center rounded-md bg-black/30 text-[15px] font-semibold text-white">
              {String("000" + vehicle.list_number).slice(-3)}
            </div>
          )}
        </div>
        <div
          className="w-[50%] bg-[#e6e6e6]"
          onClick={() => onPictureClick(1, pictures)}
        >
          <GridPicture picture={pictures[1]} />
        </div>
      </div>
      <div className="flex space-x-[3px]">
        <div
          className="w-[33%] bg-[#e6e6e6]"
          onClick={() => onPictureClick(2, pictures)}
        >
          <GridPicture picture={pictures[2]} />
        </div>
        <div
          className="w-[33%] bg-[#e6e6e6]"
          onClick={() => onPictureClick(3, pictures)}
        >
          <GridPicture picture={pictures[3]} />
        </div>
        <div
          className="relative w-[33%] bg-[#e6e6e6]"
          onClick={() => onPictureClick(4, pictures)}
        >
          <GridPicture picture={pictures[4]} />
          {showLastImageOverlay && (
            <div className="absolute inset-0 flex cursor-zoom-in items-center justify-center bg-black/50 text-[60px] text-white">
              <p>+ {vehiclePictureCount}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

const ConnectedComponent = withConfig(PicturesGrid);

export { ConnectedComponent as PicturesGrid };
