import { BasketStatesGroupMapper } from "../../constants";

import _ from "lodash";

export const mapListBasket = (data) => {
  return {
    list_id: _.get(data, "list_id"),
    vehicle_id: _.get(data, "vehicle_id"),
    list_vehicle_id: `${_.get(data, "list_id")}_${_.get(data, "vehicle_id")}`,
    basket_state: _.get(data, "state"),
    dealer_state: _.get(data, "dealer_state"),
    grouped_basket_state:
      BasketStatesGroupMapper[_.get(data, "state")] || _.get(data, "state"),
    list_name: _.get(data, "list_name"),
    list_short_name: _.get(data, "list_short_name"),
    list_index: _.get(data, "list_index"),
    basket_price: _.get(data, "price"),
    vin: _.get(data, "vin"),
    thumbnail_url: _.get(data, "thumbnail_url"),
    picture_url: _.get(data, "picture_url"),
    make: _.get(data, "make"),
    model: _.get(data, "model"),
    version: _.get(data, "version"),
    kilometers: _.get(data, "kilometers"),
    first_registration: _.get(data, "first_registration"),
    color_interior: _.get(data, "color_interior"),
    color_exterior: _.get(data, "color_exterior"),
    emission_norm: _.get(data, "emission_norm"),
    power_kw: _.get(data, "power_kw"),
    power_hp: _.get(data, "power_hp"),
    engine_cc: _.get(data, "engine_cc"),
    doors: _.get(data, "doors"),
    seats: _.get(data, "seats"),
    fuel: _.get(data, "fuel"),
    gearbox: _.get(data, "gearbox"),
    emission_co2: _.get(data, "emission_co2"),
    vat_regime: _.get(data, "vat_regime"),
    offer_date: _.get(data, "dateTimeOffered"),
    expected_date: _.get(data, "expected_date"),
    expected_km: _.get(data, "expected_km"),
    expected_km_to: _.get(data, "expected_km_to"),
    description: _.get(data, "description"),
    description_plus: _.get(data, "description_plus") || "",
    main_company_name: _.get(data, "main_company_name"),
    comment: _.get(data, "comment"),
    provisional_sale: _.get(data, "provisional_sale") || false,
    expected_disclose: _.get(data, "expected_disclose") || false,
    top_features_text: _.get(data, "features_text")
      ? _.map(_.get(data, "features_text").items, "description")
      : [],
    features_count: _.get(data, "features_text")
      ? _.get(data, "features_text").count
      : 0,
    location_name: _.get(data, "location_name") || "",
  };
};
