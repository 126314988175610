import React from "react";

import format from "string-format";

import { config } from "../../../config";
import i18n from "../../../i18n";

import { get } from "lodash";
import moment from "moment";
import numeral from "numeral";

const AllFields = {
  INDEX: "INDEX",
  VIN: "VIN",
  FIRST_REGISTRATION: "FIRST_REGISTRATION",
  ENGINE_CC: "ENGINE_CC",
  KM: "KM",
  EMISSION_CO2: "EMISSION_CO2",
  EMISSION_NORM: "EMISSION_NORM",
  FUEL: "FUEL",
  POWER_KW: "POWER_KW",
  POWER_KW_PK: "POWER_KW_PK",
  GEARBOX: "GEARBOX",
  DOORS: "DOORS",
  COLOR_EXTERIOR: "COLOR_EXTERIOR",
  COLOR_INTERIOR: "COLOR_INTERIOR",
  SEATS: "SEATS",
  EXPECTED_DATE: "EXPECTED_DATE",
  EXPECTED_FROM: "EXPECTED_FROM",
  EXPECTED_KM: "EXPECTED_KM",
  EXPECTED_KM_FROM_TO: "EXPECTED_KM_FROM_TO",
  EMPTY: "",
};

export const getFieldData = (key, data) => {
  switch (key) {
    case AllFields.INDEX:
      const list_number = get(data, "list_number");
      /* vehicle on active list */
      const list_index = get(data, "list_index");
      /* historical vehicle */
      const list_short_name = get(data, "list_short_name");
      /* historical vehicle */

      if (list_number && list_number > 0)
        return `${String("000" + list_number).slice(-3)}`;
      else if (list_index && list_index > 0)
        return `${String("000" + list_index).slice(-3)} (${list_short_name})`;
      else return i18n.t("n/a");
    case AllFields.VIN:
      return get(data, "vin") || i18n.t("n/a");
    case AllFields.FIRST_REGISTRATION:
      return (
        moment(get(data, "first_registration")).format(
          config.vehicle_first_registration_format
        ) || i18n.t("n/a")
      );
    case AllFields.ENGINE_CC:
      return format(i18n.t("{0} cc"), get(data, "engine_cc")) || i18n.t("n/a");
    case AllFields.KM:
      return format(i18n.t("{0} km"), get(data, "kilometers")) || i18n.t("n/a");
    case AllFields.EMISSION_CO2:
      return format("{0} g CO2/km", get(data, "emission_co2")) || i18n.t("n/a");
    case AllFields.EMISSION_NORM:
      return get(data, "emission_norm") || i18n.t("n/a");
    case AllFields.FUEL:
      return get(data, "fuel") || i18n.t("n/a");
    case AllFields.POWER_KW:
      return format(i18n.t("{0} kw"), get(data, "power_kw")) || i18n.t("n/a");
    case AllFields.POWER_KW_PK:
      return (
        format(
          "{0} kW ({1} PK)",
          get(data, "power_kw"),
          get(data, "power_hp")
        ) || i18n.t("n/a")
      );
    case AllFields.GEARBOX:
      return get(data, "gearbox") || i18n.t("n/a");
    case AllFields.DOORS:
      return get(data, "doors") || i18n.t("n/a");
    case AllFields.COLOR_EXTERIOR:
      return get(data, "color_exterior") || i18n.t("n/a");
    case AllFields.SEATS:
      return get(data, "seats") || i18n.t("n/a");
    case AllFields.COLOR_INTERIOR:
      return get(data, "color_interior") || i18n.t("n/a");
    case AllFields.EXPECTED_FROM:
      return format(
        i18n.t("from {0}"),
        moment(get(data, "expected_date")).format("MM/YYYY")
      );
    case AllFields.EXPECTED_DATE:
      return moment(get(data, "expected_date")).format("MM/YYYY");
    case AllFields.EXPECTED_KM:
      return format(
        i18n.t("{0} - {1} km"),
        numeral(get(data, "expected_km")).format("0,0"),
        numeral(get(data, "expected_km_to")).format("0,0")
      );
    case AllFields.EXPECTED_KM_FROM_TO:
      return format(
        i18n.t("{0} to {1} km"),
        numeral(get(data, "expected_km")).format("0,0"),
        numeral(get(data, "expected_km_to")).format("0,0")
      );
    case AllFields.EMPTY:
      return <br />;
    default:
      return i18n.t("n/a");
  }
};

export const getFieldLabel = (key) => {
  switch (key) {
    case AllFields.INDEX:
      return i18n.t("index");
    case AllFields.VIN:
      return i18n.t("vin");
    case AllFields.FIRST_REGISTRATION:
      return i18n.t("first registration");
    case AllFields.ENGINE_CC:
      return i18n.t("engine cc");
    case AllFields.KM:
      return i18n.t("kilometers");
    case AllFields.EMISSION_CO2:
      return i18n.t("emission co2");
    case AllFields.EMISSION_NORM:
      return i18n.t("emission norm");
    case AllFields.FUEL:
      return i18n.t("fuel");
    case AllFields.POWER_KW:
      return i18n.t("power_kw");
    case AllFields.GEARBOX:
      return i18n.t("gearbox");
    case AllFields.DOORS:
      return i18n.t("doors");
    case AllFields.COLOR_EXTERIOR:
      return i18n.t("color exterior");
    case AllFields.SEATS:
      return i18n.t("seats");
    case AllFields.COLOR_INTERIOR:
      return i18n.t("color interior");
    case AllFields.EXPECTED_DATE:
    case AllFields.EXPECTED_FROM:
      return i18n.t("from");
    case AllFields.EXPECTED_KM:
      return i18n.t("km");
    default:
      return i18n.t("n/a");
  }
};

const SpecificationItemField = ({ field, vehicle, isLast }) => (
  <div
    className={`w-6/12 md:w-4/12 ${
      // Last row item needs to be hidden on small devices
      isLast ? "hidden w-4/12 md:block" : ""
    }`}
  >
    <div className="truncate border-b border-formBorderColor">
      {getFieldData(field, vehicle)}
    </div>
  </div>
);

export const Specifications = ({ vehicle, fields }) => (
  <div>
    <div className="flex space-x-5 pt-1">
      {fields.slice(0, 3).map((field, index) => (
        <SpecificationItemField
          key={index}
          field={field}
          vehicle={vehicle}
          isLast={index === 2}
        />
      ))}
    </div>

    <div className="flex space-x-5">
      {fields.slice(3, 6).map((field, index) => (
        <SpecificationItemField
          key={index}
          field={field}
          vehicle={vehicle}
          isLast={index === 2}
        />
      ))}
    </div>

    {fields.length === 9 && (
      <div className="flex space-x-5">
        {fields.slice(6, 9).map((field, index) => (
          <SpecificationItemField
            key={index}
            field={field}
            vehicle={vehicle}
            isLast={index === 2}
          />
        ))}
      </div>
    )}
  </div>
);
