import React from "react";

export const DateRangePickerFields = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
}) => {
  // Date value in <input type="date"> field must be normalized to the format yyyy-mm-dd
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const adjustDate = (date, days) => {
    const d = new Date(date);
    d.setDate(d.getDate() + days);
    return formatDate(d);
  };

  return (
    <div className="flex min-w-[132px] flex-1 items-center space-x-1">
      <input
        type="date"
        className="h-[26px] w-1/2 text-sm"
        value={startDate ? formatDate(startDate) : ""}
        onChange={handleStartDateChange}
        max={adjustDate(endDate, -1)}
      />
      <input
        type="date"
        className="h-[26px] w-1/2 text-sm"
        value={endDate ? formatDate(endDate) : ""}
        min={adjustDate(startDate, +1)}
        onChange={handleEndDateChange}
      />
    </div>
  );
};
