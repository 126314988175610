import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { toggleConfirm } from "../actions";
import * as selectors from "../selectors";

const ConfirmButton = ({ count, toggleConfirm }) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => toggleConfirm(true)}
      className="btn btn-primary flex h-[42px] w-full items-center justify-center text-nowrap px-3 py-2 align-middle text-[12px] font-semibold"
      disabled={count === 0}
    >
      <div className="flex-1">
        <i className="fa fa-shopping-basket mr-3 text-[16px]" />
        <span className="text-center">
          <span className="g-hidden-md-down">{t("Your basket")}: </span>
          {count} {t("vehicles")}
        </span>
      </div>
      <i className="fa fa-chevron-right text-[20px]" />
    </button>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    count: selectors.getBiddingsToConfirmCount(state, ownProps.listId),
  };
};

const ConnectedComponent = connect(mapStateToProps, { toggleConfirm })(
  ConfirmButton
);
export { ConnectedComponent as ConfirmButton };
