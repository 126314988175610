import React, { useState } from "react";
import { connect } from "react-redux";

import {
  getListIdFromListVehicleId,
  getVehicleIdFromListVehicleId,
} from "../../../helpers/functions";
import { listVehicleFavorite, listVehicleFavoriteDelete } from "../actions";
import * as selectors from "../selectors";

const FavoriteButton = (props) => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleFavorite = (e) => {
    const {
      listId,
      vehicleId,
      favorite,
      onClick,
      listVehicleFavorite,
      listVehicleFavoriteDelete,
    } = props;

    setClicked(true);

    if (!favorite) {
      // TODO: might need to apply useDispatchWithPromise hook
      listVehicleFavorite(listId, vehicleId).then(() => {
        setClicked(false);
        setHovered(false);
      });
    } else {
      listVehicleFavoriteDelete(listId, vehicleId).then(() => {
        setClicked(false);
        setHovered(false);
      });
    }

    onClick(e);
  };

  const { favorite } = props;

  return (
    <div className="flex items-center justify-center">
      <i
        className={`${
          hovered || clicked
            ? favorite
              ? "fa fa-heart-o"
              : "fa fa-heart"
            : favorite
            ? "fa fa-heart"
            : "fa fa-heart-o"
        } cursor-pointer text-[22px] text-red-600`}
        onMouseOver={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          if (!clicked) {
            setHovered(false);
          }
        }}
        onClick={handleFavorite}
      />
    </div>
  );
};

const makeMapStateToProps = () => {
  const getListVehicleFavorite = selectors.makeGetListVehicleFavorite();

  const mapStateToProps = (state, ownProps) => {
    let { listVehicleId } = ownProps;
    return {
      listId: getListIdFromListVehicleId(listVehicleId),
      vehicleId: getVehicleIdFromListVehicleId(listVehicleId),
      listVehicleId: listVehicleId,
      favorite: getListVehicleFavorite(state, listVehicleId),
    };
  };
  return mapStateToProps;
};

const ConnectedComponent = connect(makeMapStateToProps, {
  listVehicleFavorite,
  listVehicleFavoriteDelete,
})(FavoriteButton);

export { ConnectedComponent as FavoriteButton };
