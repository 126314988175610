import React from "react";
import { useTranslation } from "react-i18next";

import { InfoContainer } from "../../../../components";
import { Button } from "../../../../components/web/Button";

export const WizardCompleted = (props) => {
  const { onComplete } = props;
  const { t } = useTranslation();

  return (
    <div className="mb-5 flex flex-col items-center justify-center bg-white p-5">
      <InfoContainer
        icon="fa fa-check"
        text={<h3>{t("Congratulations!")}</h3>}
        container={true}
      />
      <p className="mb-3">{t("Registration completed.")}</p>
      <Button onClick={onComplete}>{t("Start")}</Button>
    </div>
  );
};
