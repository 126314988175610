import React from "react";

import { Button } from "../../../components/web/Button";

export const ConfirmHeader = ({
  cancelBtnText,
  nextBtnText,
  title,
  onCancel,
  onNext,
}) => (
  <div className="bg-normal sticky top-0 z-10 mb-3 md:top-[70px]">
    <div className="bottom-shadow flex justify-between bg-white p-2">
      <Button
        onClick={onCancel}
        styleType="danger"
        classNames="flex items-center text-sm font-semibold"
      >
        <i className="fa fa-chevron-left mr-2"></i>
        {cancelBtnText}
      </Button>

      <h6 className="mx-2 self-center text-center font-extrabold text-highlightColor">
        {title}
      </h6>

      <Button
        onClick={onNext}
        classNames="flex items-center text-sm font-semibold"
      >
        {nextBtnText}
        <i className="fa fa-chevron-right ml-2"></i>
      </Button>
    </div>
  </div>
);
