import React, { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { GDPR } from "../components";
import { createConfig, withConfig } from "../config";
import { PictureSwiper } from "../features/picture-swiper/_web/PictureSwiper";
import { isFirebaseMessagingSupported } from "../features/push_notifications/_web/firebase-support";
import { isAuthenticated } from "../features/session";
import {
  cleanupSocketIOListeners,
  initializeSocketIOListeners,
} from "../features/socket/eventListeners";
import { useGaTracking } from "../hooks/useGaTracking";
import { useSocketIO } from "../hooks/useSocketIO";

const NotificationsHandlerWithConfig = lazy(() =>
  import("../features/push_notifications/")
);

createConfig();

const WebAppContainer = (props) => {
  const { config } = props;
  const { app_push_notifications } = config;
  const authenticated = useSelector((state) => isAuthenticated(state));
  const [firebaseMessagingSupported, setFirebaseMessagingSupported] =
    useState(null);
  const { shouldConnectSocketIO, socket } = useSocketIO(authenticated);
  const shouldNotificationsBeDisplayed =
    app_push_notifications && authenticated && firebaseMessagingSupported;

  // Use Google Analytics
  useGaTracking();

  //Initialize notifications
  useEffect(() => {
    if (app_push_notifications && authenticated) {
      isFirebaseMessagingSupported().then((isSupported) =>
        setFirebaseMessagingSupported(isSupported)
      );
    }
  }, [app_push_notifications, authenticated]);

  // Initialize socket.io listeners
  useEffect(() => {
    if (shouldConnectSocketIO) {
      initializeSocketIOListeners(socket);
    }
    return () => {
      if (socket) {
        // cleanup all listeners
        cleanupSocketIOListeners(socket);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldConnectSocketIO]);

  return (
    <>
      <Outlet />
      {shouldNotificationsBeDisplayed ? (
        <NotificationsHandlerWithConfig authenticated={authenticated} />
      ) : null}
      <ToastContainer
        progressClassName="notifications-progress-bar"
        autoClose={7000}
        pauseOnFocusLoss
        pauseOnHover
      />
      <PictureSwiper />
      <GDPR />
    </>
  );
};

export const WebAppContainerWithConfig = withConfig(WebAppContainer);
