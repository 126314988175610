export const listVehicleInterestTypes = {
  FETCH_REQUEST: "LISTVEHICLE_INTEREST_FETCH_REQUEST",
  FETCH_SUCCESS: "LISTVEHICLE_INTEREST_FETCH_SUCCESS",
  FETCH_FAILURE: "LISTVEHICLE_INTEREST_FETCH_FAILURE",
  POST_REQUEST: "LISTVEHICLE_INTEREST_POST_REQUEST",
  POST_SUCCESS: "LISTVEHICLE_INTEREST_POST_SUCCESS",
  POST_FAILURE: "LISTVEHICLE_INTEREST_POST_FAILURE",
  ERROR_CLEAR: "LISTVEHICLE_INTEREST_ERROR_CLEAR",

  ADD: "LISTVEHICLE_INTEREST_ADD",
  REMOVE: "LISTVEHICLE_INTEREST_REMOVE",
};
