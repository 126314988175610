import { gettext } from "../../translations/index";

export const vehicleSorters = [
  {
    id: 1,
    label: gettext("Make/Model ascending", true),
    sortMode: "ASC",
    sortFields: ["make", "model"],
  },
  {
    id: 2,
    label: gettext("Make/Model descending", true),
    sortMode: "DESC",
    sortFields: ["make", "model"],
  },
  {
    id: 3,
    label: gettext("Kilometers ascending", true),
    sortMode: "ASC",
    sortFields: ["kilometers"],
  },
  {
    id: 4,
    label: gettext("Kilometers descending", true),
    sortMode: "DESC",
    sortFields: ["kilometers"],
  },
  {
    id: 5,
    label: gettext("Age ascending", true),
    sortMode: "DESC",
    sortFields: ["first_registration"],
  },
  {
    id: 6,
    label: gettext("Age descending", true),
    sortMode: "ASC",
    sortFields: ["first_registration"],
  },
  {
    id: 7,
    label: gettext("Stock ascending", true),
    sortMode: "ASC",
    sortFields: ["list_number"],
  },
  {
    id: 8,
    label: gettext("Stock descending", true),
    sortMode: "DESC",
    sortFields: ["list_number"],
  },
  {
    id: 9,
    label: gettext("Dealer ascending", true),
    sortMode: "ASC",
    sortFields: ["main_company_name"],
  },
  {
    id: 10,
    label: gettext("Dealer descending", true),
    sortMode: "DESC",
    sortFields: ["main_company_name"],
  },
];

export const historySorters = [
  {
    id: 1,
    label: gettext("Bought ascending", true),
    sortMode: "ASC",
    sortFields: ["dateTimeOffered"],
  },
  {
    id: 2,
    label: gettext("Bought descending", true),
    sortMode: "DESC",
    sortFields: ["dateTimeOffered"],
  },
  {
    id: 3,
    label: gettext("Make/Model ascending", true),
    sortMode: "ASC",
    sortFields: ["make", "model"],
  },
  {
    id: 4,
    label: gettext("Make/Model descending", true),
    sortMode: "DESC",
    sortFields: ["make", "model"],
  },
  {
    id: 5,
    label: gettext("Expected entry ascending", true),
    sortMode: "ASC",
    sortFields: ["expected_date"],
  },
  {
    id: 6,
    label: gettext("Expected entry descending", true),
    sortMode: "DESC",
    sortFields: ["expected_date"],
  },
  {
    id: 7,
    label: gettext("Expected km ascending", true),
    sortMode: "ASC",
    sortFields: ["expected_km"],
  },
  {
    id: 8,
    label: gettext("Expected km descending", true),
    sortMode: "DESC",
    sortFields: ["expected_km"],
  },
  {
    id: 9,
    label: gettext("First registration ascending", true),
    sortMode: "ASC",
    sortFields: ["first_registration"],
  },
  {
    id: 10,
    label: gettext("First registration descending", true),
    sortMode: "DESC",
    sortFields: ["first_registration"],
  },
  {
    id: 11,
    label: gettext("Kilometers ascending", true),
    sortMode: "ASC",
    sortFields: ["kilometers"],
  },
  {
    id: 12,
    label: gettext("Kilometers descending", true),
    sortMode: "DESC",
    sortFields: ["kilometers"],
  },
  {
    id: 13,
    label: gettext("Delivery place ascending", true),
    sortMode: "ASC",
    sortFields: ["location_name"],
  },
  {
    id: 14,
    label: gettext("Delivery place descending", true),
    sortMode: "DESC",
    sortFields: ["location_name"],
  },
];
