import React from "react";
import { useDispatch } from "react-redux";

import { useNavigateHelper } from "../../../hooks/useNavigateHelper";
import {
  updateCriteriaList,
  updateCriteriaStates,
} from "../../listvehicles-filter/index";
import { HistoryTable } from "./history";
import { OffersTable } from "./offers";
import { States } from "./States";

export const BaseStatistics = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const { goToHistory } = useNavigateHelper();

  const onStateClick = (state) => {
    dispatch(updateCriteriaStates(-1, [state]));
    goToHistory();
  };

  const onHistoryRowClick = (data) => {
    dispatch(updateCriteriaList(-1, data.id, data.name));
    goToHistory();
  };

  return (
    <div className="flex flex-col space-y-2 bg-white p-2">
      <States data={data.states} onClick={onStateClick} />
      <OffersTable />
      <div className="flex flex-col">
        <HistoryTable onRowClick={onHistoryRowClick} />
      </div>
    </div>
  );
};
