import { useState } from "react";
import { useTranslation } from "react-i18next";

import { splitSecondsIntoTimeUnits } from "../helpers/utils";
import { useInterval } from "./useInterval";

import moment from "moment";

/**
 * Custom hook to compute the countdown from now to the provided future target date.
 * It also provides the string representation of the remaining time as per
 * https://momentjs.com/docs/#/displaying/from/
 * The hook also respects the i18n locale.
 */
export const useCountdown = (targetDate) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  moment.locale(locale);

  const countDownDate = moment(targetDate);

  const [countDown, setCountDown] = useState(
    countDownDate.diff(moment(), "seconds")
  );

  useInterval(() => {
    setCountDown(countDownDate.diff(moment(), "seconds"));
  }, 1000);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  const remainingTimeStr = moment.unix(countDown).from(moment(0), true);

  return {
    remainingTime: splitSecondsIntoTimeUnits(countDown),
    remainingTimeStr,
  };
};
