import React from "react";
import { useTranslation } from "react-i18next";

import { allowOnlyNumbers, updateAge, updateKm } from "../functions/vehicle";
import { useVehicleFilters } from "../hooks/useVehicleFilters";

export const Vehicle = (props) => {
  const { filter, onChange } = props;
  const { t } = useTranslation();
  const { age, setAge, ageError, setAgeError, km, setKm, kmError, setKmError } =
    useVehicleFilters(filter);

  const handleUpdateAge = (_from, _to) => {
    const from = Number(_from);
    const to = Number(_to);

    if (to >= from) {
      onChange(updateAge(filter, from, to));
      setAgeError("");
    } else {
      setAgeError(t("Max age must be greater than min age"));
    }
  };

  const handleUpdateKm = (_from, _to) => {
    const from = Number(_from);
    const to = Number(_to);

    if (to >= from) {
      onChange(updateKm(filter, from, to));
      setKmError("");
    } else {
      setKmError(t("Max km must be greater than min km"));
    }
  };

  return (
    <div className="pt-5">
      <h6 className="mb-1">{t("Age (months)")}</h6>
      <div className="flex space-x-2 py-1">
        <div>
          <input
            type="text"
            placeholder={t("from")}
            value={age.min === 0 ? "" : age.min}
            onChange={(e) =>
              setAge((prevState) => ({
                ...prevState,
                min: allowOnlyNumbers(e.target.value),
              }))
            }
            onBlur={(e) => {
              handleUpdateAge(allowOnlyNumbers(e.target.value), age.max);
            }}
            onFocus={() => setAgeError("")}
            className="form-control w-[150px] rounded px-2 py-2"
          />
        </div>

        <i className="fa fa-arrow-right my-auto text-[20px]"></i>

        <div className="w-[150px]">
          <input
            type="text"
            placeholder={t("to")}
            value={age.max === 0 ? "" : age.max}
            onChange={(e) =>
              setAge((prevState) => ({
                ...prevState,
                max: allowOnlyNumbers(e.target.value),
              }))
            }
            onBlur={(e) =>
              handleUpdateAge(age.min, allowOnlyNumbers(e.target.value))
            }
            onFocus={() => setAgeError("")}
            className="form-control w-[150px] rounded px-2 py-2"
          />
        </div>
      </div>
      {ageError && (
        <div className="text-sm italic text-red-400">{ageError}</div>
      )}

      <h6 className="mb-1 mt-3">{t("Km")}</h6>
      <div className="flex space-x-2 py-1">
        <div>
          <input
            type="text"
            placeholder={t("from")}
            value={km.min === 0 ? "" : km.min}
            onChange={(e) =>
              setKm((prevState) => ({
                ...prevState,
                min: allowOnlyNumbers(e.target.value),
              }))
            }
            onBlur={(e) => {
              handleUpdateKm(allowOnlyNumbers(e.target.value), km.max);
            }}
            onFocus={() => setKmError("")}
            className="form-control w-[150px] rounded px-2 py-2"
          />
        </div>

        <i className="fa fa-arrow-right my-auto text-[20px]"></i>

        <div>
          <input
            type="text"
            placeholder={t("to")}
            value={km.max === 0 ? "" : km.max}
            onChange={(e) =>
              setKm((prevState) => ({
                ...prevState,
                max: allowOnlyNumbers(e.target.value),
              }))
            }
            onBlur={(e) =>
              handleUpdateKm(km.min, allowOnlyNumbers(e.target.value))
            }
            onFocus={() => setKmError("")}
            className="form-control w-[150px] rounded px-2 py-2"
          />
        </div>
      </div>
      {kmError && <div className="text-sm italic text-red-400">{kmError}</div>}
    </div>
  );
};
