import React from "react";
import { connect } from "react-redux";

import { resetPassword } from "../actions";
import { getError, getLoading } from "../selectors";
import { ResetPasswordForm } from "./ResetPasswordForm";

const ResetPassword = (props) => {
  const { error, loading, resetPassword, uniqueId } = props;
  const onHandleSubmit = (data) => {
    resetPassword(uniqueId, data.password);
  };

  return (
    <div>
      <ResetPasswordForm
        loading={loading}
        onSubmit={(data) => onHandleSubmit(data)}
      />

      {error !== null && (
        <div className="container-important mb-10 flex p-5 text-center">
          {error.message}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    error: getError(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, { resetPassword })(
  ResetPassword
);

export { ConnectedComponent as ResetPassword };
