import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { Field, reduxForm } from "redux-form";

import { validators } from "../../../components/index";

export const resetPasswordFields = {
  PASSWORD: "password",
  PASSWORD_CONFIRM: "password_confirm",
};

const PasswordField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => {
  const { t } = useTranslation();

  return (
    <div className={"form-group flex flex-col"}>
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className={clsx("form-control g-py-15 g-px-15 rounded", {
          "bg-red-100": touched && error,
        })}
      />

      {touched &&
        ((error && (
          <small className="form-control-feedback text-sm text-red-500">
            {t(error)}
          </small>
        )) ||
          (warning && <span>{t(warning)}</span>))}
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  if (values.password !== values.password_confirm) {
    errors.password_confirm = "Passwords are not equal";
  }
  return errors;
};

const ResetPasswordForm = (props) => {
  const { handleSubmit, loading } = props;
  const { t } = useTranslation();
  return (
    <form className="space-y-2 py-3" onSubmit={handleSubmit}>
      <Field
        name={resetPasswordFields.PASSWORD}
        type="password"
        component={PasswordField}
        label={t("Password") + ":"}
        placeholder="********"
        validate={[
          validators.required,
          validators.minLength6,
          validators.hasNumber,
          validators.hasLowerCase,
          validators.hasUpperCase,
        ]}
      />

      <Field
        name={resetPasswordFields.PASSWORD_CONFIRM}
        type="password"
        component={PasswordField}
        label={t("Confirm password") + ":"}
        placeholder="********"
        validate={[
          validators.required,
          validators.minValue6,
          validators.hasNumber,
          validators.hasLowerCase,
          validators.hasUpperCase,
        ]}
      />

      <button
        type="submit"
        disabled={loading}
        className="btn btn-primary relative mt-3 w-full p-2 uppercase"
      >
        {loading && <div className="loader inset-y-0 my-auto -ml-[14px]"></div>}
        {t("Send")}
      </button>
    </form>
  );
};

const ConnectedComponent = reduxForm({
  form: "password-set",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
})(ResetPasswordForm);

export { ConnectedComponent as ResetPasswordForm };
