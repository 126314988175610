import { entityActions } from "../../entities/actions";
import { basketCleanUp } from "../basket/actions";
import * as basketSelectors from "../basket/selectors";
import { listVehiclesFilterCleanUp } from "../listvehicles-filter/actions";
import { listVehiclesStateCleanUp } from "../listvehiclestate";
import { historyAndStateTypes } from "./types";

export const historyVehicleSelect = (
  listVehicleId,
  stateFullScreen = false
) => {
  return {
    type: historyAndStateTypes.VEHICLE_SELECT,
    listVehicleId,
    stateVisible: stateFullScreen,
  };
};

export const toggleVehicleState = () => {
  return {
    type: historyAndStateTypes.TOGGLE_STATE,
  };
};

export const historyVehiclesAndStateCleanUp = () => {
  return (dispatch) => {
    return dispatch({
      type: historyAndStateTypes.CLEANUP,
    });
  };
};

export const historyCleanUp = () => {
  return (dispatch, getState) => {
    const state = getState();

    const vehicleIds = basketSelectors.getIds(state, "ALL");

    return Promise.all([
      dispatch(basketCleanUp(["ALL"])),
      dispatch(historyVehiclesAndStateCleanUp()),
      dispatch(listVehiclesStateCleanUp(vehicleIds)),
      dispatch(listVehiclesFilterCleanUp([-1])),
      dispatch(entityActions.clearEntities([], vehicleIds)),
    ]);
  };
};
