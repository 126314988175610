import React from "react";
import { useTranslation } from "react-i18next";

export const FilterDetailsHeader = ({
  onSave,
  onDelete,
  saveEnabled,
  deleteEnabled,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bottom-shadow mb-5 flex justify-between bg-white p-2">
      <h5 className="font-extrabold text-highlightColor">
        {t("Filter details")}
      </h5>

      <div className="flex space-x-5 md:space-x-2">
        {deleteEnabled && (
          <button
            type="button"
            onClick={onDelete}
            disabled={!deleteEnabled}
            className="btn bg-red-500 px-2 py-1 text-white hover:bg-red-300"
          >
            <i className="fa fa-remove mr-1"></i>
            {t("Delete")}
          </button>
        )}

        {saveEnabled && (
          <button
            type="button"
            onClick={onSave}
            disabled={!saveEnabled}
            className="btn btn-primary px-2 py-1 hover:bg-highlightColor"
          >
            <i className="fa fa-save mr-1"></i>
            {t("Save")}
          </button>
        )}
      </div>
    </div>
  );
};
