import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { SearchInput } from "../index";

import _ from "lodash";

export const SearchableList = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const { data } = props;

  const handleSelectAll = () => {
    props.onSelectAll(filter(data, searchTerm).map((item) => item.id));
    setSearchTerm("");
  };

  const handleRemoveAll = () => {
    props.onDeSelectAll(filter(data, searchTerm).map((item) => item.id));
    setSearchTerm("");
  };

  const filter = (data, searchTerm) => {
    if (searchTerm === "") return data;

    let result = [];
    const term = searchTerm.toLowerCase();

    _.each(data, (item) => {
      _.each(item.searchTerms, (searchField) => {
        if (searchField.toLowerCase().startsWith(term)) {
          result.push(item);
          return false;
        }
      });
    });
    return result;
  };

  const filteredData = filter(data, searchTerm);

  return (
    <>
      <div className="flex flex-col py-5 md:flex-row md:space-x-2">
        <form className="g-mr-5 relative w-full lg:w-[200px] xl:w-[330px]">
          <SearchInput
            {...props}
            className="form-control w-100 g-color-text pl-35 py-3 text-[13px]"
            value={searchTerm}
            onChange={(value) => setSearchTerm(value)}
          />
        </form>

        <div className="mt-2 flex flex-auto space-x-2 md:mt-0">
          <button
            type="button"
            onClick={() => handleSelectAll()}
            className="btn btn-primary w-full whitespace-nowrap px-2 py-1 text-sm md:w-auto"
          >
            <i className="fa fa-check mr-1 text-sm"></i>
            {t("Select all")}
          </button>

          <button
            type="button"
            onClick={() => handleRemoveAll()}
            className="btn btn-primary w-full whitespace-nowrap px-2 py-1 text-sm md:w-auto"
          >
            <i className="fa fa-remove mr-1 text-sm"></i>
            {t("Select none")}
          </button>
        </div>
      </div>

      {filteredData && filteredData.length === 0 && (
        <span>{t("No results found.")} ... </span>
      )}
      <div
        {...(props.renderItemContainerStyle && {
          className: props.renderItemContainerStyle,
        })}
      >
        {filteredData.map((item) => props.renderItem(item))}
      </div>
    </>
  );
};
