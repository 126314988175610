import { createErrorMessageSelector, createLoadingSelector } from "../ui";
import { AUTH } from "./types";

const loadingSelector = createLoadingSelector([AUTH]);
const errorSelector = createErrorMessageSelector([AUTH]);

export const getLoading = (state) => {
  return loadingSelector(state);
};

export const getIsAuthenticating = (state) => {
  return loadingSelector(state);
};

export const getError = (state) => {
  return errorSelector(state);
};
