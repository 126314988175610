import {
  INITIAL_FILTERCRITERIA,
  INITIAL_FILTERDATA,
  INITIAL_STATE,
} from "./state";
import { listVehiclesFilterTypes } from "./types";

import { omit } from "lodash";

const filter = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case listVehiclesFilterTypes.FETCH_REQUEST:
      return {
        ...state,
        data: INITIAL_FILTERDATA,
        isFetching: true,
        error: "",
      };
    case listVehiclesFilterTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isFetching: false,
        lastUpdated: action.payload.receivedAt,
        error: "",
      };
    case listVehiclesFilterTypes.FETCH_FAILURE:
      return {
        ...state,
        data: INITIAL_FILTERDATA,
        isFetching: false,
        error: action.payload.message,
      };
    case listVehiclesFilterTypes.CRITERIA_RESET:
      /* Don't reset interestWithNoBid, if enabled -> enabled for whole session */
      let reset = Object.assign({}, INITIAL_FILTERCRITERIA, {
        interestWithNoBid: state.criteria.interestWithNoBid,
      });

      return {
        ...state,
        criteria: reset,
      };
    case listVehiclesFilterTypes.CRITERIA_UPDATE:
      return {
        ...state,
        criteria: action.payload.criteria,
      };
    case listVehiclesFilterTypes.CRITERIA_FIELD_UPDATE:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.fieldName]: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export const filterByListId = (state = {}, action) => {
  switch (action.type) {
    case listVehiclesFilterTypes.FETCH_REQUEST:
    case listVehiclesFilterTypes.FETCH_SUCCESS:
    case listVehiclesFilterTypes.FETCH_FAILURE:
    case listVehiclesFilterTypes.CRITERIA_RESET:
    case listVehiclesFilterTypes.CRITERIA_UPDATE:
    case listVehiclesFilterTypes.CRITERIA_FIELD_UPDATE:
      return {
        ...state,
        [action.payload.listId]: filter(state[action.payload.listId], action),
      };
    case listVehiclesFilterTypes.CLEANUP:
      return omit(state, action.payload.invalidListIds);
    default:
      return state;
  }
};
