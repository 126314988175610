import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { detailCleanup, fetchOffer } from "../actions";
import * as selectors from "../selectors";

export const OfferInfo = ({ offerId, children }) => {
  const offer = useSelector((state) => selectors.getData(state, offerId));
  const loading = useSelector(selectors.getLoading);
  const error = useSelector(selectors.getError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOffer(offerId));
    return () => {
      dispatch(detailCleanup());
    };
  }, []);

  if (error) {
    return <div className="text-center">{error}</div>;
  }

  // Pass additional props to children elements using React.cloneElement
  const additionalProps = {
    offer,
    loading,
  };
  return offer !== null ? React.cloneElement(children, additionalProps) : null;
};
