import React from "react";
import { useTranslation } from "react-i18next";

import { SearchableList } from "../../../components";
import { addMakes, removeMakes } from "../functions/makes";

import { includes } from "lodash";

export const Makes = (props) => {
  const { t } = useTranslation();

  const addRemoveMake = (id) => {
    if (includes(props.filter.makes, id)) {
      handleRemoveMakes([id]);
    } else {
      handleAddMakes([id]);
    }
  };

  const handleRemoveMakes = (makes) => {
    props.onChange(removeMakes(props.filter, makes));
  };

  const handleAddMakes = (makes) => {
    props.onChange(addMakes(props.filter, makes));
  };

  const renderItem = ({ id, data }) => (
    <button
      key={id}
      type="button"
      className="ml-1 flex space-x-1"
      onClick={() => addRemoveMake(id)}
    >
      <input
        type="checkbox"
        checked={includes(props.filter.makes, id)}
        readOnly
        className="
          disabled:border-grey-400 disabled:bg-grey-400 border-1
          peer relative mt-1 h-4 w-4 shrink-0 cursor-pointer
          appearance-none
          rounded-sm border-[#ccc]
          bg-white checked:border-0 checked:bg-primary focus:outline-none
          focus:ring-1 focus:ring-blue-100
          focus:ring-offset-0
        "
      />
      <span className="inline-block">{data.name}</span>
    </button>
  );

  const data = props.makes.map((make) => ({
    id: make.id,
    searchTerms: [make.name],
    data: make,
  }));
  return (
    <SearchableList
      data={data}
      renderItem={renderItem}
      renderItemContainerStyle={
        "grid grid-cols-3 gap-4 min-h-min max-h-[50dvh] overflow-auto auto-rows-min"
      }
      onSelectAll={handleAddMakes}
      onDeSelectAll={handleRemoveMakes}
      placeholder={t("Search make") + " ..."}
    />
  );
};
