import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import cogoToast from "cogo-toast";

import { routes } from "../../../_web/navigation/routes";
import { isAuthenticated } from "../../session/index";
import { login, requestActivationMail } from "../authenticationSlice";
import { getError, getIsAuthenticating, getLoading } from "../selectors";
import { AuthenticationForm } from "./AuthenticationForm";

export const Authentication = () => {
  const [email, setEmail] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const authenticated = useSelector(isAuthenticated);
  const authenticating = useSelector(getIsAuthenticating);
  const loading = useSelector(getLoading);
  const error = useSelector(getError);

  useEffect(() => {
    if (authenticated) {
      // Redirect to the previous private route the user was on, otherwise to dashboard
      if (location.state !== null) {
        navigate(location.state.from.pathname);
      }
      navigate(routes.DASHBOARD);
    }
  }, [authenticated]);

  // TODO: decouple dispatch from setEmail
  const onHandleSubmit = (data) => {
    dispatch(login({ username: data.username, password: data.password })).then(
      (result) => {
        if (
          result &&
          result.type === "AUTH_FAILURE" &&
          result.payload.code === 412
        ) {
          setEmail(data.username);
        }
      }
    );
  };

  const resendActivationMail = () => {
    dispatch(requestActivationMail({ email: email })).then((result) => {
      // TODO: cogoToast repository has been archived on Oct 4, 2022
      cogoToast.success(result.payload.message, { hideAfter: 5 });
    });
  };

  return (
    <div className="space-y-2">
      <AuthenticationForm
        submitting={authenticating}
        loading={loading}
        onSubmit={(data) => onHandleSubmit(data)}
      />

      {error !== null && (
        <div className="container-important flex flex-col items-center p-1">
          <div className="text-sm">{t(error.message)}</div>

          {error.code === 412 && (
            <div className="flex text-sm">
              <div>
                {t("Didn’t receive a confirmation mail? ")}
                <span
                  className="cursor-pointer underline"
                  onClick={resendActivationMail}
                >
                  {" "}
                  {t("Resend")}.
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
