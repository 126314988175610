import React from "react";

import { CircleIcon } from "./CircleIcon";

export const InfoContainer = ({
  icon,
  text,
  container = false,
  fontSize,
  smallText,
}) => (
  <div
    className={`flex flex-col items-center ${
      container ? "bg-white" : "bg-normal"
    } mb-10 text-center`}
  >
    <div className="text-center">
      <div className="mt-2 flex justify-between">
        <CircleIcon icon={icon} size={200} />
      </div>
    </div>
    <div>{text}</div>
    {smallText && <div>{smallText}</div>}
  </div>
);
