import { RSAA } from "redux-api-middleware";
import format from "string-format";

import { API_ENDPOINTS } from "../../api/endpoints";
import i18n from "../../i18n";
import { hasToken } from "../session/selectors";
import { auth_types } from "./types";

export const fetchToken = (username, password) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.API_TOKEN_AUTH,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: false,
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        types: [
          {
            type: auth_types.FETCH_TOKEN_REQUEST,
            payload: () => ({ username }),
          },
          {
            type: auth_types.FETCH_TOKEN_SUCCESS,
            payload: (action, state, res) => onFetchTokenSuccess(dispatch, res),
          },
          {
            type: auth_types.FETCH_TOKEN_FAILURE,
            payload: (action, state, res) => onFetchTokenFailure(dispatch, res),
          },
        ],
      },
    });
  };
};

export const fetchTokenByCode = (code) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.API_TOKEN_BY_CODE,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: false,
        },
        body: JSON.stringify({
          code: code,
        }),
        types: [
          {
            type: auth_types.FETCH_TOKEN_BY_CODE_REQUEST,
            payload: () => ({ code }),
          },
          {
            type: auth_types.FETCH_TOKEN_BY_CODE_SUCCESS,
            payload: (action, state, res) => onFetchTokenSuccess(dispatch, res),
          },
          {
            type: auth_types.FETCH_TOKEN_BY_CODE_FAILURE,
            payload: (action, state, res) => onFetchTokenFailure(dispatch, res),
          },
        ],
      },
    });
  };
};

const onFetchTokenSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    return {
      token: data.token,
      user_id: data.user_id || 0,
    };
  });
};

const onFetchTokenFailure = (dispatch, res) => {
  return res.json().then((data) => {
    let message = i18n.t("An error occurred");
    let code = 400;

    // TODO: non_field_errors are coming from Django backend?
    if (data.non_field_errors && data.non_field_errors[0]) {
      const http_error = data.non_field_errors[0].split(":")[0];

      if (http_error === "401") {
        message = i18n.t("Your account is locked, contact support");
        code = 401;
      } else if (http_error === "403") {
        message = i18n.t("Login or password incorrect, please try again");
        code = 403;
      } else if (http_error === "412") {
        message = i18n.t("You haven’t confirmed your email address yet.");
        code = 412;
      } else if (http_error === "406") {
        message = i18n.t("Invalid request");
        code = 406;
      } else {
        message = data.non_field_errors;
        code = res.status;
      }
    }

    return {
      message,
      code,
    };
  });
};

export const fetchProfile = () => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.PROFILES,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: auth_types.FETCH_PROFILE_REQUEST,
          },
          {
            type: auth_types.FETCH_PROFILE_SUCCESS,
            payload: (action, state, res) =>
              onFetchProfileSuccess(dispatch, res),
          },
          {
            type: auth_types.FETCH_PROFILE_FAILURE,
          },
        ],
      },
    });
  };
};
const onFetchProfileSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    const profile = data[0];

    if (profile !== undefined) {
      return {
        user: {
          first_name: profile.first_name,
          last_name: profile.last_name,
        },
      };
    } else {
      return {
        user: { first_name: "", last_name: "" },
      };
    }
  });
};

export const fetchRegistrationStep = () => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.REGISTRATIONS,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: auth_types.FETCH_REGISTRATION_REQUEST,
          },
          {
            type: auth_types.FETCH_REGISTRATION_SUCCESS,
            payload: (action, state, res) =>
              onFetchRegistrationStepSuccess(dispatch, res),
          },
          {
            type: auth_types.FETCH_REGISTRATION_FAILURE,
          },
        ],
      },
    });
  };
};
const onFetchRegistrationStepSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    const registration = data[0];

    if (registration !== undefined) {
      return {
        email_validated: registration.email_validated,
        registration_step: registration.step,
        // user: {
        //     first_name: registration.first_name,
        //     last_name: registration.last_name,
        // },
        company_user_state: {
          error: registration.company_user_state.error || false,
          message: registration.company_user_state.message || "",
        },
      };
    } else {
      return {
        email_validated: false,
        registration_step: 0,
        user: { first_name: "", last_name: "" },
        company_user_state: {
          error: true,
          message: "No registration found",
        },
      };
    }
  });
};

export const login = (username, password) => {
  return (dispatch, getState) => {
    dispatch({ type: auth_types.AUTH_REQUEST });

    return dispatch(fetchToken(username, password))
      .then((result) => {
        const token = hasToken(getState());

        if (token) {
          return Promise.all([
            dispatch(fetchProfile()),
            dispatch(fetchRegistrationStep()),

            //dispatch(fetchUserInfo()),
            //dispatch(fetchUserPreferences()),
          ]).then(() => {
            dispatch({ type: auth_types.AUTH_SUCCESS });
          });
        }
        // failed ...
        return dispatch({
          type: auth_types.AUTH_FAILURE,
          payload: result.payload,
        });
      })
      .catch((error) => {
        return dispatch({ type: auth_types.AUTH_FAILURE, payload: error });
      });
  };
};

export const loginByCode = (code) => {
  return (dispatch, getState) => {
    dispatch({ type: auth_types.AUTH_REQUEST });

    return dispatch(fetchTokenByCode(code))
      .then((result) => {
        const token = hasToken(getState());

        if (token) {
          return Promise.all([
            dispatch(fetchProfile()),
            dispatch(fetchRegistrationStep()),
          ]).then(() => {
            dispatch({ type: auth_types.AUTH_SUCCESS });
          });
        }
        // failed ...
        return dispatch({
          type: auth_types.AUTH_FAILURE,
          payload: result.payload,
        });
      })
      .catch((error) => {
        return dispatch({ type: auth_types.AUTH_FAILURE, payload: error });
      });
  };
};

export const loginByToken = (token) => {
  return (dispatch) => {
    dispatch({ type: auth_types.AUTH_BY_TOKEN, payload: { token: token } });

    return dispatch(fetchRegistrationStep()).then(() => {
      return dispatch({ type: auth_types.AUTH_SUCCESS });
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    return dispatch({ type: "CLEAR_STATE" });
  };
};

export const requestActivationMail = (email) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.REGISTRATION_CONFIRMATION,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: false,
        },
        body: JSON.stringify({
          email: email,
        }),
        types: [
          {
            type: auth_types.REQUEST_ACTIVATION_MAIL_REQUEST,
          },
          {
            type: auth_types.REQUEST_ACTIVATION_MAIL_SUCCESS,
            payload: (action, state, res) =>
              onRequestActivationMailSuccess(dispatch, res, email),
          },
          {
            type: auth_types.REQUEST_ACTIVATION_MAIL_FAILURE,
            payload: (action, state, res) =>
              onRequestActivationMailFailure(dispatch, res, email),
          },
        ],
      },
    });
  };
};

const onRequestActivationMailSuccess = (dispatch, res, email) => {
  return res.json().then((data) => {
    return {
      success: true,
    };
  });
};

// TODO: this does not seem to be fired as the PUT request always returns REQUEST_ACTIVATION_MAIL_SUCCESS
const onRequestActivationMailFailure = (dispatch, res, email) => {
  return res.json().then((data) => {
    const message = format(
      i18n.t("A new confirmation e-mail has been sent to {0}."),
      email
    );
    return {
      success: false,
      message,
    };
  });
};
