import cogoToast from "cogo-toast";
import { RSAA } from "redux-api-middleware";
import format from "string-format";

import { API_ENDPOINTS } from "../../api/endpoints";
import { gettext } from "../../translations";
import { reset_pw_types } from "./types";

export const resetPasswordRequest = (email) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.RESET_PASSWORD}?email=${email}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: false,
        },
        types: [
          {
            type: reset_pw_types.RESET_PASSWORD_REQ_REQUEST,
          },
          {
            type: reset_pw_types.RESET_PASSWORD_REQ_SUCCESS,
            payload: (action, state, res) =>
              onResetPasswordRequestSuccess(dispatch, res, email),
          },
          {
            type: reset_pw_types.RESET_PASSWORD_REQ_FAILURE,
            payload: (action, state, res) => {
              return {
                message: `${res.status} - ${res.statusText}`,
              };
            },
          },
        ],
      },
    });
  };
};
const onResetPasswordRequestSuccess = (dispatch, res, email) => {
  return res.json().then((data) => {
    if (data.result_code === "Ok") {
      cogoToast.success(
        format(gettext("Mail successfully send to {0}"), email),
        { hideAfter: 5 }
      );
    } else {
      dispatch({
        type: reset_pw_types.RESET_PASSWORD_REQ_FAILURE,
        payload: { message: data.result_message },
      });
    }

    return {
      email,
      success: data.result_code === "Ok",
    };
  });
};
