import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { CircleIcon, LoadingIndicator } from "../../../components";
import { Button } from "../../../components/web/Button";
import { toggleConfirm } from "../actions";
import * as selectors from "../selectors";

const Result = (props) => {
  const { message, loading, accepted, toggleConfirm } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (accepted) {
      toggleConfirm(false);
    }
  }, [accepted]);

  const renderProcessing = () => {
    return (
      <div className="justify-center">
        <div className="mt-10 flex justify-center text-center">
          <CircleIcon icon="fa fa-gavel" size={200} />
        </div>

        <div className="mt-5 flex justify-center text-center">
          <LoadingIndicator loading={loading} />
        </div>

        <div className="mt-5 text-center">{t("Processing biddings")}</div>
        <div className="text-center">{t("Please wait") + "..."}</div>
      </div>
    );
  };

  const renderError = () => {
    return (
      <div className="justify-center">
        <div className="mt-10 flex justify-center text-center">
          <CircleIcon icon="fa fa-bolt" size={200} />
        </div>

        <div className="mt-5 text-center">
          {message !== ""
            ? message
            : t("Something went wrong. Please try again")}
        </div>
        <div className="mt-5 flex justify-center">
          <Button onClick={() => toggleConfirm(false)}>{t("Try again")}</Button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-normal mt-70 flex w-full justify-center">
      {loading && renderProcessing()}
      {!loading && !accepted && renderError()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: selectors.getLoading(state),
    accepted: selectors.getAccepted(state),
    message: selectors.getMessage(state),
  };
};
const ConnectedComponent = connect(mapStateToProps, { toggleConfirm })(Result);

export { ConnectedComponent as Result };
