import React from "react";

import { Template } from "../components";
import { Profile } from "../features/profile/_web/Profile";

export const MyProfile = () => (
  <Template>
    <Profile />
  </Template>
);
