import React from "react";
import { useTranslation } from "react-i18next";

import { take } from "lodash";

export const Top9FeatureText = ({ features, count }) => {
  const { t } = useTranslation();
  const itemsToShow = features.length >= 9 ? 8 : 9;

  return (
    <div className="grid grid-cols-3 gap-x-3 text-[12px] text-[#999]">
      {take(features, itemsToShow).map((feature, i_feature) => (
        <div className="truncate" key={`featuretext_${i_feature}`}>
          {feature}
        </div>
      ))}

      {features.length >= 9 && (
        <div className="truncate text-primary">
          {`+ ${count - itemsToShow} ${t("more")} ...`}
        </div>
      )}
    </div>
  );
};
