import React from "react";

export const CollapsePanel = (props) => {
  return (
    <div className="w-full">
      <div
        className="text-light-gr flex h-[60px] w-full  cursor-pointer items-center justify-between bg-[#edf8f5] p-2 text-base md:px-3 md:py-6 md:text-xl"
        onClick={props.onClick}
      >
        {props.title}

        <i
          className={`${
            props.collapsed ? "fa fa-chevron-right" : "fa fa-chevron-down"
          } text-[18px]`}
        />
      </div>

      {!props.collapsed && <div className="w-full">{props.children}</div>}
    </div>
  );
};
