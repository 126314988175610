import React from "react";
import "react-phone-number-input/style.css";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { PersistGate } from "redux-persist/lib/integration/react";

import { WebApp } from "./_web/WebApp";
import "./assets/vendor/autralis/icons.css";
import "./assets/vendor/icon-awesome/css/font-awesome.min.css";
import { LoadingIndicator, NotSupported } from "./components";
import "./components/web/not-supported/style.css";
import "./index.css";
import { persistor, store } from "./store";

import "./features/statistics/custom.css";

const { detect } = require("detect-browser");
export const browser = detect();

export function RootApp() {
  let supported = false;

  // TODO: confirm which browsers should be supported
  switch (browser && browser.name) {
    case "chrome":
    case "firefox":
    case "edge-chromium":
    case "safari":
    case "ios":
    case "crios":
      supported = true;
      break;
    default:
      supported = false;
  }

  if (!supported) {
    return <NotSupported name={browser.name} />;
  }

  return (
    <Provider store={store}>
      <PersistGate
        loading={<LoadingIndicator loading={true} />}
        persistor={persistor}
      >
        <WebApp />
      </PersistGate>
    </Provider>
  );
}
