import { types } from "./types";

const reducer = (state = { registration_id: 0 }, action) => {
  switch (action.type) {
    case types.POST_REQUEST:
    case types.POST_FAILURE:
      return {
        ...state,
        registration_id: 0,
      };
    case types.POST_SUCCESS:
      return {
        ...state,
        registration_id: action.payload.registration_id,
      };
    default:
      return state;
  }
};

export default reducer;
