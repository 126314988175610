import { API_ENDPOINTS } from "../../../api/endpoints";
import i18n from "../../../i18n";

const onRequestActivationMailFulfilled = (responseData) => {
  const { result_message } = responseData;
  return {
    message: result_message,
  };
};

const onRequestActivationMailFailure = (responseData) => {
  const { email } = responseData;

  const message = i18n.t(
    `A new confirmation e-mail has been sent to ${email}.`
  );
  return {
    message: message,
  };
};

export const requestActivationMailService = async ({ email }, thunkAPI) => {
  const currentState = thunkAPI.getState();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": currentState.localization.locale,
  };
  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify({
      email: email,
    }),
  };

  try {
    const response = await fetch(
      API_ENDPOINTS.REGISTRATION_CONFIRMATION,
      options
    );

    // TODO: refactor backend to provide consistent response structure
    const jsonResponse = await response.json();

    if (response.ok && jsonResponse["result"] === "Ok") {
      return onRequestActivationMailFulfilled(jsonResponse);
    }
    // prepare responseValues
    const rejectResponseValues = {
      email: email,
    };
    return thunkAPI.rejectWithValue(
      onRequestActivationMailFailure(rejectResponseValues)
    );
  } catch (err) {
    throw err;
  }
};
