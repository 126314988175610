import _ from "lodash";

export const updateName = (filter, name) => {
  filter.name = name;
  return filter;
};

export const createNewFilter = (allMakes) => {
  return {
    id: -1,
    name: "",
    regions: [],
    excluded_companies: [],
    makes: _.map(allMakes, "id"),
    age_min: 0,
    age_max: 0,
    km_min: 0,
    km_max: 0,
  };
};
