import React from "react";

export const NotSupported = () => {
  // TODO: check if messages need to be provided in multiple languages as translations are not available
  return (
    <div
      className="g-flex-centered"
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{ width: 790 }}
        className="u-shadow-v2 g-brd-around g-brd-gray-light-v4 g-line-height-2 g-brd-2  g-brd-highlight-top g-pa-40 g-mb-30 g-mt-30 bg-white"
      >
        <h3 className="h2 g-mb-20 font-light">
          Helaas, je browser wordt niet ondersteund
        </h3>
        <p>
          Het spijt ons, maar je surft met een verouderde browser of met een
          browser die niet wordt ondersteund. Enkel de browsers Chrome, Firefox,
          Edge en Safari worden ondersteund{" "}
          <strong>
            {" "}
            Gebruik steeds de meest recente versie. <strong></strong>
          </strong>
        </p>

        <div className="browser_options">
          <a
            className="browser_button button--chrome"
            href="https://www.google.com/chrome/browser/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome
          </a>

          <a
            className="browser_button button--firefox"
            href="http://www.getfirefox.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox
          </a>

          <a
            className="browser_button button--edge"
            href="https://www.microsoft.com/nl-be/windows/microsoft-edge"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge
          </a>

          <a
            className="browser_button button--safari"
            href="https://www.apple.com/safari/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari
          </a>
        </div>
      </div>
    </div>
  );
};
