import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Template } from "../../../components";
import { InfoContainer } from "../../../components";
import { Button } from "../../../components/web/Button";
import { logout } from "../../authentication";
import { getUserState } from "../../session/index";

export const UserNotAllowed = () => {
  const userState = useSelector(getUserState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const message =
    userState.message !== null && userState.message !== undefined
      ? userState.message
      : t("Something went wrong");

  if (message === "") {
    dispatch(logout());
  }

  return (
    <Template>
      <div className="bg-normal mt-70 text-center">
        <InfoContainer text={message} icon="fa fa-hourglass-half" />
        <div className="flex justify-center">
          <Button onClick={() => dispatch(logout())}>{t("Logout")}</Button>
        </div>
      </div>
    </Template>
  );
};
