import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { config } from "../config";

export let shouldUseAnalytics;

/**
 * Hook for tracking page views using Google Analytics. This hook automatically initializes Google Analytics if
 * Google tag ID (**analytics_id**) is provided in the **config.json** file.
 *
 * @returns {void}
 */
export const useGaTracking = () => {
  const location = useLocation();

  const googleTagId = config.analytics_id;

  // A boolean that controls whether the Google Analytics should be used.
  shouldUseAnalytics =
    googleTagId !== null && googleTagId !== undefined && googleTagId !== "";

  // The approach below is based on https://javascript.plainenglish.io/the-ultimate-guide-to-google-analytics-ua-ga4-on-react-or-anything-else-ec9dc84c0211
  useEffect(() => {
    if (shouldUseAnalytics) {
      if (!window.gtag) {
        loadGoogleAnalyticsScript(googleTagId).then(() => {
          initializeGoogleAnalytics(googleTagId);
          trackPageView(location);
        });
      } else {
        trackPageView(location);
      }
    }
  }, [location]);
};

// Loads the Google tag snippet immediately after the opening <head> HTML tag of the webpage.
// Read more: https://developers.google.com/tag-platform/gtagjs/install
const loadGoogleAnalyticsScript = (googleTagId) => {
  return new Promise((resolve) => {
    const gTagUrl = `https://www.googletagmanager.com/gtag/js?id=${googleTagId}`;
    const script = document.createElement("script");
    script.async = true;
    script.src = gTagUrl;
    script.onload = resolve;

    // Get the first child of the <head> element (which is the opening <head> tag).
    const firstHeadChild = document.head.firstChild;

    // Insert the script right after the opening <head> tag.
    document.head.insertBefore(script, firstHeadChild);
  });
};

// Initializes Google Analytics.
// Custom parameters and defaults can also be configured here
const initializeGoogleAnalytics = (googleTagId) => {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag("js", new Date());
  // Disable automatic page tracking with default tracker as we use our own trackPageView tacker.
  window.gtag("config", `${googleTagId}`, { send_page_view: false });
};

// Extendable params builder. It builds the params from the provided location to be later passed to an event.
const buildEventParams = (location) => {
  const eventParams = {};

  // Handle location when passed as a location object from useLocation hook.
  if (typeof location === "object") {
    eventParams.page_path = location.pathname + location.search + location.hash;
    eventParams.page_search = location.search;
    eventParams.page_hash = location.hash;
    // Handle location when path is passed as a string (without any parameters).
  } else if (typeof location === "string") {
    eventParams.page_path = location;
  }

  return eventParams;
};

// Page view event tracker.
export const trackPageView = (location) => {
  const eventParams = buildEventParams(location);

  if (Object.keys(eventParams).length === 0) {
    // If location is not an object or a string, exit early to prevent unnecessary events.
    return;
  }
  window.gtag("event", "page_view", eventParams);
};
