export const filterTypes = {
  FETCH_REQUEST: "FILTERS_FETCH_REQUEST",
  FETCH_SUCCESS: "FILTERS_FETCH_SUCCESS",
  FETCH_FAILURE: "FILTERS_FETCH_FAILURE",

  SEARCH_COMPANIES: "FILTERS_COMPANIES_SEARCH_TERM",
  SEARCH_MAKES: "FILTERS_MAKES_SEARCH_TERM",

  SELECT_FILTER: "FILTERS_SELECT_FILTER",
  UPDATE_FILTER_DATA: "FILTERS_UPDATE_FILTER_DATA",

  CREATE_REQUEST: "FILTERS_CREATE_REQUEST",
  CREATE_SUCCESS: "FILTERS_CREATE_SUCCESS",
  CREATE_FAILURE: "FILTERS_CREATE_FAILURE",

  UPDATE_REQUEST: "FILTERS_UPDATE_REQUEST",
  UPDATE_SUCCESS: "FILTERS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "FILTERS_UPDATE_FAILURE",

  DELETE_REQUEST: "FILTERS_DELETE_REQUEST",
  DELETE_SUCCESS: "FILTERS_DELETE_SUCCESS",
  DELETE_FAILURE: "FILTERS_DELETE_FAILURE",

  ERROR_CLEAR: "FILTER_FILTER_ERROR_CLEAR",
};

export const filterCompanyTypes = {
  FETCH_REQUEST: "FILTERS_FETCH_COMPANIES_REQUEST",
  FETCH_SUCCESS: "FILTERS_FETCH_COMPANIES_SUCCESS",
  FETCH_FAILURE: "FILTERS_FETCH_COMPANIES_FAILURE",
};

export const filterMakeTypes = {
  FETCH_REQUEST: "FILTERS_FETCH_MAKES_REQUEST",
  FETCH_SUCCESS: "FILTERS_FETCH_MAKES_SUCCESS",
  FETCH_FAILURE: "FILTERS_FETCH_MAKES_FAILURE",
};
