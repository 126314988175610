import React, { useState } from "react";

import clsx from "clsx";

import bullseye from "../../../assets/svg/bullseye-pointer-light.svg";
import { WarningModal } from "./WarningModal";

const WarningIcon = ({ content }) => {
  const iconSize = 22;

  const isContentAvailable = content && content !== "";

  return (
    <div className="flex size-[38px] items-center justify-center rounded-full bg-warningAccent p-1">
      {isContentAvailable ? (
        <>
          <img src={bullseye} alt="" />
        </>
      ) : (
        <i className={`fa fa-info absolute text-[${iconSize}px]`}></i>
      )}
    </div>
  );
};

export const Warning = (props) => {
  const { title, content } = props;
  const [visible, setVisible] = useState(false);

  const isContentAvailable = content && content !== "";

  const handleClick = () => {
    if (!content || content === "") {
      return;
    }
    setVisible(true);
  };

  return (
    <div
      className={clsx(
        "flex items-center space-x-1 border-b-2 border-warningAccent bg-warningBgColor text-lg text-warningTextColor sm:text-[20px]",
        {
          "cursor-pointer": isContentAvailable,
        }
      )}
      onClick={handleClick}
    >
      <div className="flex items-center">
        <div className="self-start p-2">
          <WarningIcon content={content} />
        </div>
        <div className="p-1">{title}</div>
      </div>

      <WarningModal
        visible={visible}
        data={content}
        onCancel={() => setVisible(false)}
      />
    </div>
  );
};
