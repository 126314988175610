import React from "react";

import { Field, reduxForm } from "redux-form";

import { FormFields, validators } from "../../../../../components";
import { dealerPresentationFields } from "../WizardStep6";

const { Multiline } = FormFields;

const DealerPresentationForm = ({ handleSubmit, onChange }) => (
  <form onSubmit={handleSubmit}>
    <Field
      name={dealerPresentationFields.PRESENTATION_TEXT}
      component={Multiline}
      showLabel={false}
      type="text"
      rows={20}
      onChange={onChange}
      validate={[validators.required]}
    />
  </form>
);

const ConnectedComponent = reduxForm({
  form: "Registration-DealerPresentation",
  destroyOnUnmount: false,
})(DealerPresentationForm);

export { ConnectedComponent as DealerPresentationForm };
