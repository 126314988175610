import { API_ENDPOINTS } from "../../../api/endpoints";

export const fetchSuppliersService = async (_, thunkAPI) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const options = {
    method: "GET",
    headers: headers,
  };

  try {
    const response = await fetch(API_ENDPOINTS.SUPPLIERS, options);

    if (response.ok) {
      return await response.json();
    }
    // The rejected value will be available in the action.payload reducer
    return thunkAPI.rejectWithValue({
      status: response.status,
      statusText: response.statusText,
    });
  } catch (err) {
    throw err;
  }
};
