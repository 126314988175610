import React from "react";
import { useTranslation } from "react-i18next";

import { BaseStatisticsStates } from "../../../constants";
import { StateLabel } from "../common/StateLabel";

import { filter, find, map, sortBy } from "lodash";

export const States = (props) => {
  const { data, onClick } = props;
  const { t } = useTranslation();

  const states = sortBy(
    filter(data, (state) => state.index <= 4),
    "index"
  );
  const stateLabelLeft = find(data, (state) => state.index === 5);
  const stateLabelRight = find(data, (state) => state.index === 6);

  const isStateLabelLeftVisible = stateLabelLeft?.count > 0;
  const isStateLabelRightVisible = stateLabelRight?.count > 0;

  const BottomStateLabel = ({ state, onClick }) => (
    <div
      className="cursor-pointer text-primary underline"
      onClick={() => onClick(state.code)}
    >
      {`${state.count} ${t(BaseStatisticsStates[state.code])}`}
    </div>
  );

  return (
    <div>
      <div className="flex flex-col items-stretch text-center md:h-[70px] md:flex-row">
        {map(states, (state, index) => (
          <StateLabel
            key={index}
            label={t(BaseStatisticsStates[state.code])}
            count={state.count}
            onClick={() => onClick(state.code)}
          />
        ))}
      </div>

      {(isStateLabelLeftVisible || isStateLabelRightVisible) && (
        <div className="flex justify-between border p-2">
          {isStateLabelLeftVisible && (
            <BottomStateLabel state={stateLabelLeft} onClick={onClick} />
          )}

          {isStateLabelRightVisible && (
            <BottomStateLabel state={stateLabelRight} onClick={onClick} />
          )}
        </div>
      )}
    </div>
  );
};
