import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

export const VehicleReservePriceBadge = () => {
  const spanClass = clsx("u-label !rounded-[3px] badge text-white font-bold");
  const { t } = useTranslation();

  return (
    <span className={spanClass} style={{ backgroundColor: "#48bb78" }}>
      {t("Reserve price met")}
    </span>
  );
};
