import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const BadgeWithCopy = (props) => {
  const initialState = {
    tooltip: false,
    copy: false,
    minWidth: 140,
  };
  const { text, uniqueId } = props;
  const [state, setState] = useState(initialState);
  const { copy, minWidth } = state;
  const { t } = useTranslation();
  const vinRef = useRef();

  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { text } = props;
    try {
      navigator.clipboard.writeText(text);
    } catch (ex) {}

    setState({
      copy: true,
      tooltip: false,
      minWidth: vinRef.current.getBoundingClientRect().width,
    });
  };

  const onMouseUp = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (copy) {
      setTimeout(function () {
        setState({
          copy: false,
          tooltip: false,
        });
      }, 500);
    }
  };

  return (
    <div key={`id_${uniqueId}`}>
      <div
        id={uniqueId}
        ref={vinRef}
        className="u-label badge badge-normal g-mb-5 !rounded-[3px]"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        onClick={(e) => e.stopPropagation()}
        style={{
          minWidth: minWidth,
          backgroundColor: copy ? "#2fcc70" : "",
          color: copy ? "white" : "black",
        }}
      >
        {copy ? t("Copied") : text}
      </div>
    </div>
  );
};
