import React from "react";
import { useTranslation } from "react-i18next";

import { settingsFields } from "./SettingsForm";

import { filter, keys } from "lodash";

export const Confirm = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  const getItemText = (item) => {
    switch (item) {
      case settingsFields.WON_MAIL:
        return t("highest bid and delivery information");
      case settingsFields.CAR_BY_CAR_MAIL:
        return t("requests car by car ");
      case settingsFields.OFFERS_MAIL:
        return t("emails after offers");
      default:
        return "";
    }
  };

  const unsubscribe_all = data[settingsFields.NO_MAIL] === true;
  const unsubscribedOptions = filter(
    keys(data),
    (key) => data[key] === false && key !== settingsFields.NO_MAIL
  );
  const subscribedOptions = filter(
    keys(data),
    (key) => data[key] === true && key !== settingsFields.NO_MAIL
  );

  const renderUnsubscribeAll = () => {
    return (
      <div>
        <span>{t("Are you sure you want to unsubscribe entirely? ")}</span>
      </div>
    );
  };

  const renderUnsubscribedOptions = () => {
    return (
      <div>
        <h5>{t("You want to unsubscribe for:")}</h5>
        <ul className="list-inside list-disc">
          {unsubscribedOptions.map((item, key) => (
            <li key={key}>{getItemText(item)}</li>
          ))}
        </ul>
      </div>
    );
  };

  const renderSubscribedOptions = () => {
    return (
      <div>
        <h5>{t("You will still be receiving the following communication:")}</h5>
        <ul className="list-inside list-disc">
          {subscribedOptions.map((item, key) => (
            <li key={key}>{getItemText(item)}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <h4 className="h4 text-primary">{t("Are you sure?")}</h4>
      {unsubscribe_all && renderUnsubscribeAll()}
      {unsubscribedOptions.length > 0 &&
        !unsubscribe_all &&
        renderUnsubscribedOptions()}
      {subscribedOptions.length > 0 && renderSubscribedOptions()}
    </>
  );
};
