import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LoadingIndicator } from "../../../components";
import { Button } from "../../../components/web/Button";
import {
  ClearFilters,
  FilterButton,
  FilterSummary,
  hasActiveCriteria,
} from "../../listvehicles-filter";
import { EyeButton } from "../../listvehicles-filter/_web/EyeButton";
import { Favorite } from "../../listvehicles-filter/_web/Favorite";

export const LiveAuctionListVehiclesHeader = (props) => {
  const { listId, loading, onBack, onFilterChanged } = props;
  const { t } = useTranslation();
  const showFilterSummary = useSelector((state) =>
    hasActiveCriteria(state, listId)
  );

  return (
    <div className="bg-normal sticky top-0 z-10 mb-3 md:top-[70px]">
      <div className="bottom-shadow bg-white p-2">
        <div className="flex items-center justify-between">
          <div className="xl:hidden">
            <Button
              onClick={onBack}
              classNames="flex items-center text-nowrap text-sm font-semibold"
            >
              <i className="fa fa-chevron-left mr-2"></i>
              <span>{t("Back")}</span>
            </Button>
          </div>

          <div className="flex space-x-5">
            <div className="hidden uppercase sm:block">{t("Live auction")}</div>
            <div className="hidden xl:block">
              <ClearFilters listId={listId} onClearFilters={onFilterChanged} />
            </div>
          </div>

          <div className="flex gap-3">
            <div className="empty:hidden">
              <Favorite listId={listId} onFavoriteChanged={onFilterChanged} />
            </div>

            <div>
              <EyeButton
                listId={listId}
                onInterestWithNoBidChanged={onFilterChanged}
              />
            </div>

            <div className="xl:hidden">
              <FilterButton
                listId={listId}
                onCriteriaChanged={onFilterChanged}
                onClearFilters={onFilterChanged}
              />
            </div>
          </div>
        </div>
        {showFilterSummary && (
          <div className="mt-2 hidden xl:flex">
            <FilterSummary
              listId={listId}
              onCriteriaChanged={onFilterChanged}
            />
          </div>
        )}
      </div>
      <LoadingIndicator loading={loading} />
    </div>
  );
};
