import _ from "lodash";

export const normalizeListBasket = (data) => {
  let normalizedBasket = _.mapKeys(data, function (value) {
    return value.list_id + "_" + value.vehicle_id;
  });

  return {
    result: Object.keys(normalizedBasket),
    entities: { vehicle: normalizedBasket },
  };
};
