import { setLocale } from "../../translations/index";
import { types } from "./types";

export const localizationMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === "persist/REHYDRATE") {
      if (
        action.payload &&
        action.payload.localization &&
        action.payload.localization.locale
      ) {
        setLocale(action.payload.localization.locale);
      }
    }

    if (action.type === types.SET_LOCALE) {
      setLocale(action.payload.locale);
    }

    return next(action);
  };
