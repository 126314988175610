import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import { entities } from "./entities/reducer";
import { reducer as approvalCheckReducer } from "./features/approval-check";
import { default as approvalCheckSliceReducer } from "./features/approval-check/approvalCheckSlice";
import { default as basketSliceReducer } from "./features/basket/basketSlice";
import { basketByGroups } from "./features/basket/reducer";
import { bidding } from "./features/bidding/reducer";
import { reducer as listCartReducer } from "./features/cart";
import { reducer as colleaguesReducer } from "./features/colleagues";
import { reducer as filtersReducer } from "./features/filters";
import { reducer as historyAndStateReducer } from "./features/history";
import { reducer as contentReducer } from "./features/legal";
import { currentListsReducer } from "./features/lists/reducer";
import { reducer as listVehiclesAndStateReducer } from "./features/listvehicles-and-state";
import { listVehicleFavorites } from "./features/listvehicles-favorite/reducer";
import { filterByListId } from "./features/listvehicles-filter/reducer";
import { listVehicleInterest } from "./features/listvehicles-no-interest/reducer";
import { sorterById as listVehiclesSorter } from "./features/listvehicles-sort/reducer";
import { listVehiclesById } from "./features/listvehicles/reducer";
import { reducer as listVehicleStateReducer } from "./features/listvehiclestate";
import { reducer as localizationReducer } from "./features/localization";
import { reducer as offerReducer } from "./features/offer";
import { swiper } from "./features/picture-swiper/reducer";
import { reducer as profileReducer } from "./features/profile";
import { reducer as registrationActivationReducer } from "./features/registration-activation";
import { reducer as registrationFormReducer } from "./features/registration-form";
import { reducer as registrationWizardReducer } from "./features/registration-wizard";
import { initial_state as session_initial } from "./features/session";
import { default as sessionSliceReducer } from "./features/session/sessionSlice";
import { reducer as statisticsReducer } from "./features/statistics";
import { default as suppliersReducer } from "./features/suppliers/suppliersSlice";
//import {reducer as versionReducer} from './features/version'
import { reducer as uiReducer } from "./features/ui";
import { default as uiSliceReducer } from "./features/ui/uiSlice";
import { reducer as unsubscribeReduer } from "./features/unsubscribe";
import { searchReducer } from "./features/vehicle-search/reducer";
import { reducer as warningsReducer } from "./features/warnings";

import { each, includes, keys } from "lodash";

const appReducer = combineReducers({
  entities,
  currentLists: currentListsReducer,
  // lists: listById,
  listvehicles: listVehiclesById,
  listvehicles_filter: filterByListId,

  listvehicles_sort: listVehiclesSorter,
  vehiclestate: listVehicleStateReducer,

  history_and_state: historyAndStateReducer,
  listvehicles_and_state: listVehiclesAndStateReducer,
  bidding,
  listvehicles_no_interest: listVehicleInterest,
  listvehicles_favorites: listVehicleFavorites,
  basket: basketByGroups,
  // basket: basketSliceReducer,

  picture_swiper: swiper,

  form: formReducer,

  registration_wizard: registrationWizardReducer,
  registration_activation: registrationActivationReducer,
  registration_form: registrationFormReducer,
  filters: filtersReducer,
  session: sessionSliceReducer,

  vehicles_search: searchReducer,
  localization: localizationReducer,
  profile: profileReducer,
  content: contentReducer,
  statistics: statisticsReducer,
  list_cart: listCartReducer,
  offer: offerReducer,
  colleagues: colleaguesReducer,
  warnings: warningsReducer,
  // ui: uiReducer,
  ui: uiSliceReducer,
  // approval_check: approvalCheckReducer,
  approval_check: approvalCheckSliceReducer,
  unsubscribe: unsubscribeReduer,
  // version: versionReducer,
  suppliers: suppliersReducer,
});

export const types = {
  CLEAR_STATE: "CLEAR_STATE",
};

export const rootReducer = (state, action) => {
  if (action.type === "CLEAR_STATE") {
    return appReducer(
      {
        localization: state.localization,
      },
      action
    );
  } else if (action.type === "persist/REHYDRATE") {
    if (action.payload && action.payload.session) {
      const currentSessionKeys = keys(action.payload.session);
      const requiredSessionKeys = keys(session_initial);

      let invalid = false;
      each(requiredSessionKeys, (key) => {
        if (!includes(currentSessionKeys, key)) {
          invalid = true;
        }
      });

      if (invalid) {
        action.payload.session = session_initial;
      }
    }
    return appReducer(state, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
