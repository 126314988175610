import React from "react";

import { Modal } from "../../../components/web/Modal";

import DOMPurify from "dompurify";

export const WarningModal = ({ visible, data, onCancel }) => {
  const sanitizedData = {
    __html: DOMPurify.sanitize(decodeURIComponent(data)),
  };

  return (
    <Modal visible={visible} onCancel={onCancel}>
      <div className="flex h-full w-full items-center justify-center">
        <div className="relative -mt-[30%] max-w-xl rounded bg-white p-6">
          <button className="absolute right-2 top-2" onClick={onCancel}>
            <i className="fa fa-close block p-1 text-[26px]" />
          </button>
          <div className="mt-6">
            <div
              className="text-gray-500"
              dangerouslySetInnerHTML={sanitizedData}
            ></div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
