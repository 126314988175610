import React from "react";
import { connect } from "react-redux";

import {
  getListIdFromListVehicleId,
  getVehicleIdFromListVehicleId,
} from "../../../helpers/functions";
import { listVehicleInterest, listVehicleInterestDelete } from "../actions";
import * as selectors from "../selectors";

const InterestThumb = ({
  listId,
  vehicleId,
  interest,
  listVehicleInterest,
  listVehicleInterestDelete,
  onClick,
}) => {
  const icon = "fa fa-thumbs-down";

  return (
    <div
      className="text-center"
      onClick={(e) => {
        interest
          ? listVehicleInterest(listId, vehicleId)
          : listVehicleInterestDelete(listId, vehicleId);
        onClick(e);
      }}
    >
      <i
        className={`${icon} text-[16px] ${
          interest ? "color-btn-icon" : "text-primary"
        } hover:text-primary`}
      />
    </div>
  );
};

const makeMapStateToProps = () => {
  const getListVehicleInterest = selectors.makeGetListVehicleInterest();
  const getListVehicleInterestVisible =
    selectors.makeGetListVehicleInterestVisible();

  const mapStateToProps = (state, ownProps) => {
    let { listVehicleId } = ownProps;
    return {
      listId: getListIdFromListVehicleId(listVehicleId),
      vehicleId: getVehicleIdFromListVehicleId(listVehicleId),
      listVehicleId: listVehicleId,
      interest: getListVehicleInterest(state, listVehicleId),
      visible: getListVehicleInterestVisible(state, listVehicleId),
    };
  };
  return mapStateToProps;
};

const ConnectedComponent = connect(makeMapStateToProps, {
  listVehicleInterest,
  listVehicleInterestDelete,
})(InterestThumb);

export { ConnectedComponent as InterestThumb };
