import React from "react";
import { useTranslation } from "react-i18next";

import { addLeadingZero } from "../../../helpers/utils";
import { useCountdown } from "../../../hooks/useCountdown";

export const ListCountDown = (props) => {
  const targetDate = props.date;

  const { remainingTime } = useCountdown(targetDate);
  const { days, hours, minutes, seconds } = remainingTime;
  const { t } = useTranslation();

  return (
    <div className="flex flex-initial justify-center text-center font-light uppercase text-black ">
      <div>
        <p className="g-color-normal text-[16px]">{addLeadingZero(days)}</p>
        <p className="text-[8px]">{t("Days")}</p>
      </div>

      <div className="mx-1 text-[16px]">:</div>

      <div>
        <p className="g-color-normal text-[16px]">{addLeadingZero(hours)}</p>
        <p className="text-[8px]">{t("Hours")}</p>
      </div>

      <div className="mx-1 text-[16px]">:</div>

      <div>
        <p className="g-color-normal text-[16px]">{addLeadingZero(minutes)}</p>
        <p className="text-[8px]">{t("Minutes")}</p>
      </div>

      <div className="mx-1 text-[16px]">:</div>

      <div>
        <p className="g-color-normal text-[16px]">{addLeadingZero(seconds)}</p>
        <p className="text-[8px]">{t("Seconds")}</p>
      </div>
    </div>
  );
};
