import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import clsx from "clsx";

import { useDispatchWithPromise } from "../../../hooks/useDispatchWithPromise";
import { updateCriteria } from "../actions";
import * as criteriaHelpers from "../functions/criteria";
import * as listVehiclesFilterSelectors from "../selectors";

const EyeButton = (props) => {
  const {
    listId,
    criteria,
    updateCriteria,
    onInterestWithNoBidChanged,
    showText,
  } = props;
  const { t } = useTranslation();
  const dispatchWithPromise = useDispatchWithPromise();

  const handleOnClick = () => {
    dispatchWithPromise(
      updateCriteria(listId, criteriaHelpers.toggleInterestWithNoBid(criteria))
    ).then(() => onInterestWithNoBidChanged());
  };

  return (
    <button
      type="button"
      className={clsx("h-[35px] text-nowrap p-2", {
        "aspect-square": !showText,
      })}
      style={{
        backgroundColor: criteria.interestWithNoBid ? "#96db79" : "#f2f2f2",
        color: criteria.interestWithNoBid ? "white" : "black",
      }}
      onClick={handleOnClick}
    >
      <i
        className="fa fa-eye-slash font-[16px]"
        style={{ color: criteria.interestWithNoBid ? "white" : "black" }}
      />{" "}
      {showText ? t("All") : ""}
    </button>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    criteria: listVehiclesFilterSelectors.getCriteriaCopy(
      state,
      ownProps.listId
    ),
  };
};

EyeButton.defaultProps = {
  showText: true,
};

const ConnectedComponent = connect(mapStateToProps, { updateCriteria })(
  EyeButton
);
export { ConnectedComponent as EyeButton };
