import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import clsx from "clsx";
import format from "string-format";

import { routes } from "../../../_web/navigation/routes";
import { Expectation } from "../../../components";
import { config } from "../../../config";
import { numberFormat } from "../../../helpers/functions";
import { DealerStateTranslate } from "../../../helpers/utils";
import { buildUrl } from "../../../helpers/utils";
import nopic from "../../../img/nopic.jpeg";
import { vehicleSearchCleanUp, vehiclesFetch } from "../actions";
import * as selectors from "../selectors";

import moment from "moment";
import numeral from "numeral";

const getSuggestionValue = (suggestion) => "";

const VehicleSearch = (props) => {
  const {
    vehicles,
    loading,
    vehiclesFetch,
    vehicleSearchCleanUp,
    autoFocusInput,
  } = props;
  const { t } = useTranslation();

  const [currentVIN, setCurrentVIN] = useState("");
  const [previousVIN, setPreviousVIN] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setPreviousVIN(currentVIN);
    if (currentVIN.length >= 6 && previousVIN !== currentVIN) {
      vehiclesFetch(currentVIN);
    }
  }, [currentVIN]);

  const onChange = (event, { newValue, method }) => {
    setCurrentVIN(newValue.replace(/\s/g, ""));
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    // Redundant function, but needs to kept as required by Autosuggest component.
    // Fetching suggestions is handled by useEffect
  };

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    goToVehicle(suggestion);
    vehicleSearchCleanUp();
  };

  const onSuggestionsClearRequested = () => {
    vehicleSearchCleanUp();
  };

  const renderSuggestion = (suggestion, { isHighlighted }) => {
    const {
      make,
      model,
      vin,
      picture_url,
      first_registration,
      km,
      provisional_sale,
      expected_date,
      expected_km,
      expected_km_to,
      expected_disclose,
      main_company_name,
      list_name,
      dealer_state,
    } = suggestion;
    const { vehicle_first_registration_format, list_vehicle_expectation } =
      config;

    return (
      <div
        className={clsx("w-[320px] sm:w-[400px]", {
          highlight: isHighlighted,
        })}
      >
        <h5 className="inline-block truncate uppercase text-highlightColor">
          {make} {model}
        </h5>

        <div className="flex space-x-2">
          <div className="flex w-[100px] shrink-0 flex-col justify-between">
            {picture_url ? (
              <img
                src={picture_url}
                alt={make + " " + model}
                className="aspect-[130/97] w-auto"
                onError={(e) => (e.target.src = nopic)}
              />
            ) : (
              <img
                src={nopic}
                className="aspect-[130/97] w-auto"
                alt={make + " " + model}
              />
            )}

            {dealer_state && (
              <div className="bg-highlightColor text-center text-highlightInvertColor">
                {t(DealerStateTranslate[dealer_state])}
              </div>
            )}
          </div>
          {provisional_sale === expected_disclose && (
            <div className="max-w-[220px] text-sm sm:max-w-[270px]">
              <div className="truncate">{list_name} </div>
              <div className="truncate">{main_company_name}</div>
              <div className="truncate">
                {moment(first_registration).format(
                  vehicle_first_registration_format
                )}
                - {format(t("{0} km"), numberFormat(km))}
              </div>
              <div>{vin}</div>
              {list_vehicle_expectation && (
                <div>
                  <Expectation {...suggestion} />
                </div>
              )}
            </div>
          )}

          {provisional_sale && !expected_disclose && (
            <div className="max-w-[220px] text-sm sm:max-w-[270px]">
              <div className="truncate">{list_name} </div>
              <div className="truncate">{main_company_name} </div>
              <div className="truncate">
                {format(t("from {0}"), moment(expected_date).format("MM/YYYY"))}
                -{" "}
                {format(
                  t("{0} to {1} km"),
                  numeral(expected_km).format("0,0"),
                  numeral(expected_km_to).format("0,0")
                )}
              </div>
              <div>{vin}</div>
              <div>
                <Expectation {...suggestion} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderInputComponent = (inputProps) => {
    return (
      <div id="vin-search" className="relative text-defaultTextColor">
        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-2">
          <i className="fa fa-search"></i>
        </div>
        <input {...inputProps} />
      </div>
    );
  };

  const goToVehicle = (suggestion) => {
    const { list_id, vehicle_id } = suggestion;
    const navigateToId = `${list_id}_${vehicle_id}`;
    navigate(buildUrl(routes.LIST_VEHICLE, { listVehicleId: navigateToId }));
  };

  const inputProps = {
    placeholder: t("Search VIN") + " ...",
    value: currentVIN,
    onChange: onChange,
    autoFocus: autoFocusInput || false,
  };

  let suggestions = [];

  if (vehicles.length > 0 && currentVIN.length >= 6 && !loading) {
    suggestions = vehicles;
  }

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        highlightFirstSuggestion={true}
        focusInputOnSuggestionClick={false}
        inputProps={inputProps}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: selectors.getLoading(state),
    vehicles: selectors.getVehicles(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  vehiclesFetch,
  vehicleSearchCleanUp,
})(VehicleSearch);

export { ConnectedComponent as VehicleSearch };
