import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";

import { LoadingContainer, LoadingIndicator } from "../../../components";
import { ListTypes } from "../../../constants";
import { ServerDate } from "../../../scripts/ServerDate";
import * as listSelectors from "../../lists/selectors";
import { getCriteria } from "../../listvehicles-filter/selectors";
import { getActiveSorter } from "../../listvehicles-sort/selectors";
import {
  listVehiclesClosed,
  listVehiclesFetchAndBasket,
  listVehiclesFetchMore,
} from "../actions";
import * as selectors from "../selectors";
import { ListVehicleItem } from "./ListVehicleItem";

const ListVehicles = (props) => {
  const {
    fetchData,
    filter,
    listId,
    listType,
    listVehicleIds,
    listVehiclesFetchAndBasket,
    listVehiclesFetchMore,
    loading,
    more,
    offset,
    selectedListVehicle: selectedFromProps,
    sorter,
  } = props;

  const [selectedId, setSelectedId] = useState(0);
  const [auctionTimeSynced, setAuctionTimeSynced] = useState(false);

  let intervalId = undefined;
  let syncTries = 0;

  const fetchListVehicles = () => {
    if (fetchData) {
      listVehiclesFetchAndBasket(listId);
    }
  };

  const onListVehicleClick = (listVehicleId, blockNavigation) => {
    setSelectedId(listVehicleId);
    props.onClick(listVehicleId, blockNavigation);
  };

  const checkAuctionSync = () => {
    // TODO: outdated ServerDate library, discuss and revise syncing functionality
    let synced = ServerDate.isSynced();
    console.log("synced = " + synced);
    syncTries += 1;

    if (synced && !auctionTimeSynced) {
      clearInterval(intervalId);
      setAuctionTimeSynced(true);
    }

    if (syncTries > 10) {
      console.log("20 = " + synced);
      clearInterval(intervalId);
      setAuctionTimeSynced(true);

      syncTries = 0;
    }
  };

  const handleActionSync = () => {
    if (listType !== undefined) {
      if (listType === ListTypes.LIVE_AUCTION) {
        if (intervalId === undefined) {
          intervalId = setInterval(checkAuctionSync, 20);
        }
      } else if (listType) {
        if (!auctionTimeSynced) {
          setAuctionTimeSynced(true);
        }
      }
    }
  };

  const loadMore = () => {
    listVehiclesFetchMore(listId);
  };

  const renderItems = !(
    !listType &&
    listType === ListTypes.LIVE_AUCTION &&
    !auctionTimeSynced
  );
  const isLoading =
    (loading && listVehicleIds.length > 0 && offset === 0) ||
    (listType === ListTypes.LIVE_AUCTION && !auctionTimeSynced);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [filter, sorter]);

  useEffect(() => {
    fetchListVehicles();
    handleActionSync();

    if (listType === ListTypes.LIVE_AUCTION && more) {
      loadMore();
    }

    return () => {
      // Anything in here is fired on component unmount.
      // componentwillunmount in functional component.
      clearInterval(intervalId);
    };
  }, [props]);

  return (
    <LoadingContainer loading={isLoading}>
      <Scrollbars style={{ height: "calc(100dvh - 240px" }}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={more}
          useWindow={true}
          threshold={800}
          loader={
            <LoadingIndicator
              key="loader"
              loading={listVehicleIds.length > 0}
            />
          }
        >
          {renderItems && (
            <div className="space-y-3">
              {listVehicleIds.map((id) => {
                return (
                  <ListVehicleItem
                    key={id}
                    listVehicleId={id}
                    loading={loading}
                    onClick={onListVehicleClick}
                    selected={
                      selectedFromProps !== 0
                        ? id === selectedFromProps
                        : id === selectedId
                    }
                  />
                );
              })}
            </div>
          )}
        </InfiniteScroll>
      </Scrollbars>
    </LoadingContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { listId } = ownProps;

  return {
    listId,
    listType: listSelectors.getListType(state, listId),
    listVehicleIds: selectors.getVehicleIds(state, listId),
    loading: selectors.getLoading(state, listId),
    error: selectors.getErrorMessage(state, listId),
    more: selectors.getMore(state, listId),
    offset: selectors.getOffset(state, listId),
    filter: getCriteria(state, listId),
    sorter: getActiveSorter(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  listVehiclesFetchAndBasket,
  listVehiclesClosed,
  listVehiclesFetchMore,
})(ListVehicles);

ListVehicles.defaultProps = {
  fetchData: true,
};

export { ConnectedComponent as ListVehicles };
