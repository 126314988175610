import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment/moment";

export const AlternativeOfferInfoDetails = ({ offer, numberOfVehicles }) => {
  const { t } = useTranslation();
  const offerTitle = `NR-${offer.id}`;

  const offerDate = offer.date
    ? moment(offer.date).format("DD/MM/YYYY hh:mm")
    : null;

  return (
    <div className="flex justify-between">
      <div>
        <h5 className="uppercase text-highlightColor">
          {offer.name !== null && offer.name !== ""
            ? offer.name
            : `<${t("Offer with no name")}>`}
        </h5>
        <div>{offer.contact}</div>
      </div>

      <div className="text-right">
        <div>
          <span>{offerTitle}</span>
          {offer.date && (
            <>
              <br />
              <span className="text-nowrap">{offerDate}</span>
            </>
          )}
        </div>
        <div className="text-nowrap">{`${numberOfVehicles} ${t(
          "vehicles"
        )}`}</div>
      </div>
    </div>
  );
};
