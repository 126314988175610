import React from "react";
import CountUp from "react-countup";

export const StateLabel = ({ label, count, onClick }) => {
  return (
    <div
      className={
        "group flex w-full cursor-pointer flex-col items-center justify-center border py-2 hover:bg-primary md:w-1/4"
      }
      onClick={count > 0 ? onClick : null}
    >
      <div className="text-xl font-bold text-primary group-hover:text-white">
        <CountUp start={0} end={count} />
      </div>
      <div className="whitespace-normal group-hover:text-white">{label}</div>
    </div>
  );
};
