import React from "react";

export const CircleWithLabel = (props) => {
  const { color, text, title, size, code } = props;
  return (
    <div
      className="flex flex-col items-center justify-center"
      onClick={() => props.onClick(code)}
    >
      <div
        className="relative mb-[5px] rounded-full"
        style={{
          border: `1px solid ${color}`,
          width: size,
          height: size,
        }}
      >
        <div className="absolute inset-0 flex items-center justify-center text-[#6f6f6f]">
          {text}
        </div>
      </div>
      <p className="text-center">{title}</p>
    </div>
  );
};
