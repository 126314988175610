import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routes } from "../../../_web/navigation/routes";
import {
  CircleIcon,
  InfoContainer,
  LoadingIndicator,
} from "../../../components";
import { Button } from "../../../components/web/Button";
import { loginByToken } from "../../authentication/authenticationSlice";
import { activate } from "../actions";
import * as selectors from "../selectors";

const Activation = (props) => {
  const {
    activate,
    activated,
    loading,
    loginByToken,
    registrationId,
    token,
    uniqueId,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    activate(registrationId, uniqueId);
  }, []);

  const ActivatedWithoutToken = () => {
    return (
      <div className="flex flex-col justify-center py-10">
        <InfoContainer
          icon="fa fa-check"
          text={<h4>{t("Your e-mail address is already confirmed!")}</h4>}
          container={true}
        />
        <p className="text-center">
          {t("You can immediately complete your account and start.")}
        </p>
        <div className="flex justify-center pt-5 text-center">
          <Button onClick={() => navigate(routes.LOGIN)}>{t("Sign up")}</Button>
        </div>
      </div>
    );
  };

  const ActivatedWithToken = () => {
    return (
      <div className="flex flex-col justify-center py-10">
        <InfoContainer
          icon="fa fa-check"
          text={<h4>{t("Your e-mail address is confirmed.")}</h4>}
          container={true}
        />
        <p className="text-center">
          {t("You can now complete the registration.")}
        </p>
        <div className="flex justify-center pt-5 text-center">
          <Button
            onClick={() =>
              loginByToken(token).then(() =>
                navigate(routes.REGISTRATION_WIZARD)
              )
            }
          >
            {t("Complete registration")}
          </Button>
        </div>
      </div>
    );
  };

  const Activating = () => {
    return (
      <div className="flex flex-col justify-center py-10">
        <div className="flex justify-center">
          <CircleIcon icon="fa fa-info" size={200} />
        </div>

        <div className="mt-10 flex w-full justify-center text-center">
          <LoadingIndicator loading={!loading} />
        </div>

        <div className="mt-10 w-full text-center">
          {t("Activating your account")}
        </div>
        <div className="w-full text-center">{t("Please wait") + "..."}</div>
      </div>
    );
  };

  if (activated && token === null) {
    return <ActivatedWithoutToken />;
  }

  if (activated && token !== null) {
    return <ActivatedWithToken />;
  }

  return <Activating />;
};

const mapStateToProps = (state) => {
  return {
    loading: selectors.getLoading(state),
    error: selectors.getError(state),
    activated: selectors.isActivated(state),
    token: selectors.getToken(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, { activate, loginByToken })(
  Activation
);
export { ConnectedComponent as Activation };
