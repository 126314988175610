import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  getListIdFromListVehicleId,
  getVehicleIdFromListVehicleId,
} from "../../../helpers/functions";
import { listVehicleInterest, listVehicleInterestDelete } from "../actions";
import * as selectors from "../selectors";

const InterestLabelButton = ({
  listId,
  vehicleId,
  interest,
  listVehicleInterest,
  listVehicleInterestDelete,
  visible,
}) => {
  //const icon = (interest) ? 'fa fa-thumbs-down' : 'fa fa-thumbs-up';
  const icon = "fa fa-thumbs-down";
  const { t } = useTranslation();

  if (visible) {
    return (
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          interest
            ? listVehicleInterest(listId, vehicleId)
            : listVehicleInterestDelete(listId, vehicleId);
        }}
      >
        <span
          className={`${icon} flex items-center justify-between text-[20px] hover:text-primary ${
            interest ? "color-btn-icon" : "text-primary"
          }`}
        >
          <span className="truncate pl-1 font-sans text-sm">
            {interest ? t("No Interest") : t("Interest")}
          </span>
        </span>
      </button>
    );
  }

  return null;
};

const makeMapStateToProps = () => {
  const getListVehicleInterest = selectors.makeGetListVehicleInterest();
  const getListVehicleInterestVisible =
    selectors.makeGetListVehicleInterestVisible();

  const mapStateToProps = (state, ownProps) => {
    let { listVehicleId } = ownProps;
    return {
      listId: getListIdFromListVehicleId(listVehicleId),
      vehicleId: getVehicleIdFromListVehicleId(listVehicleId),
      listVehicleId: listVehicleId,
      interest: getListVehicleInterest(state, listVehicleId),
      visible: getListVehicleInterestVisible(state, listVehicleId),
    };
  };
  return mapStateToProps;
};

const ConnectedComponent = connect(makeMapStateToProps, {
  listVehicleInterest,
  listVehicleInterestDelete,
})(InterestLabelButton);

export { ConnectedComponent as InterestLabelButton };
