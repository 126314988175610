import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { useDispatchWithPromise } from "../../../hooks/useDispatchWithPromise";
import { updateCriteria } from "../actions";
import * as criteriaHelpers from "../functions/criteria";
import * as listVehiclesFilterSelectors from "../selectors";

const Favorite = (props) => {
  const { listId, criteria, updateCriteria, onFavoriteChanged } = props;
  const dispatchWithPromise = useDispatchWithPromise();
  const { t } = useTranslation();

  const handleOnClick = () => {
    dispatchWithPromise(
      updateCriteria(listId, criteriaHelpers.toggleFavorites(criteria))
    ).then(() => onFavoriteChanged());
  };
  return (
    <button
      type="button"
      className="p-2"
      style={{
        display: "flex",
        alignItems: "center",
        minHeight: 28,
        backgroundColor: criteria.favorites ? "#96db79" : "#f2f2f2",
        color: criteria.favorites ? "white" : "black",
      }}
      onClick={handleOnClick}
    >
      <i
        className="fa fa-heart mr-1 font-[13px]"
        style={{ color: criteria.favorites ? "white" : "black" }}
      />{" "}
      {t("Favorites")}
    </button>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    criteria: listVehiclesFilterSelectors.getCriteriaCopy(
      state,
      ownProps.listId
    ),
  };
};

const ConnectedComponent = connect(mapStateToProps, { updateCriteria })(
  Favorite
);
export { ConnectedComponent as Favorite };
