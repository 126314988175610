import _ from "lodash";

export const addMake = (filter, id) => {
  if (!_.includes(filter.makes, id)) {
    filter.makes.push(id);
  }
  return filter;
};

export const removeMake = (filter, id) => {
  if (_.includes(filter.makes, id)) {
    filter.makes = _.pull(filter.makes, id);
  }
  return filter;
};

export const addMakes = (filter, makes) => {
  _.each(makes, (id) => {
    if (!_.includes(filter.makes, id)) {
      filter.makes.push(id);
    }
  });

  return filter;
};

export const removeMakes = (filter, makes) => {
  _.each(makes, (id) => {
    if (_.includes(filter.makes, id)) {
      filter.makes = _.pull(filter.makes, id);
    }
  });
  return filter;
};

export const filterMakes = (makes, searchTerm) => {
  if (searchTerm === "") return makes;

  let result = [];
  const term = searchTerm.toLowerCase();

  _.each(makes, (make) => {
    if (make.name.toLowerCase().startsWith(term)) {
      result.push(make);
    }
  });

  return result;
};
