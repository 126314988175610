import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LoadingIndicator } from "../../../../components/index";
import { fetchListHistory } from "./actions";
import { HeaderWithToolTip } from "./HeaderWithToolTip";
import { HistoryTableLayout as DataTable } from "./HistoryTableLayout";
import * as selectors from "./selectors";

import { createColumnHelper } from "@tanstack/react-table";
import { map, sortBy } from "lodash";
import moment from "moment";

const DisplayWrapper = ({ children }) => (
  <div className="flex h-[150px] items-center justify-center">{children}</div>
);

export const HistoryTable = (props) => {
  const loading = useSelector(selectors.getLoading);
  const error = useSelector(selectors.getError);
  const data = useSelector(selectors.getData);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showNoHistory = !data || data?.length === 0;

  useEffect(() => {
    dispatch(fetchListHistory());
  }, []);

  const headerFormatter = (info) => {
    return <HeaderWithToolTip text={t(info.name)} />;
  };

  const listFormatter = (info) => {
    const row = info.row.original;
    return (
      <span>
        <span className="font-semibold text-primary">{row.name}</span>
        <span className="whitespace-nowrap text-sm"> ({row.date}) </span>
      </span>
    );
  };

  if (loading) {
    return (
      <DisplayWrapper>
        <LoadingIndicator loading={true} text={t("Loading history data")} />
      </DisplayWrapper>
    );
  }

  if (error) {
    return <DisplayWrapper>{error}</DisplayWrapper>;
  }

  if (showNoHistory) {
    return (
      <DisplayWrapper>
        {t("Currently no history available for you")}
      </DisplayWrapper>
    );
  }

  const tableData = map(data, (d) => {
    return d.states.reduce(
      (acc, i) => ({ ...acc, [i.code]: i.count > 0 ? i.count : "" }),
      {
        id: d.id,
        name: d.name,
        date: `${moment(d.start).format("DD/MM")} - ${moment(d.stop).format(
          "DD/MM"
        )}`,
        states: d.states,
      }
    );
  });

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: () => <div className="max-w-[100px] text-left">{t("List")}</div>,
      cell: (info) => listFormatter(info),
    }),
  ];

  const stateColumns = map(sortBy(data[0].states, "index"), (state) =>
    columnHelper.accessor(state.code, {
      header: (info) => (
        <div className="max-w-[40px] text-center">{headerFormatter(state)}</div>
      ),
      cell: (info) => <div className="text-center">{info.getValue()}</div>,
    })
  );

  return (
    <DataTable
      columns={[...columns, ...stateColumns]}
      tableData={tableData}
      onRowClick={(row) => {
        props.onRowClick({ id: row.original.id, name: row.original.name });
      }}
    />
  );
};
