import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LoadingIndicator } from "../../../components";
import { Button } from "../../../components/web/Button";
import { withConfig } from "../../../config";
import { ConfirmButtonMobile } from "../../cart";
import {
  ClearFilters,
  FilterButton,
  FilterSummary,
  hasActiveCriteria,
} from "../../listvehicles-filter";
import { EyeButton } from "../../listvehicles-filter/_web/EyeButton";
import { Favorite } from "../../listvehicles-filter/_web/Favorite";
import { getListVehicleSorters, SortDropDown } from "../../listvehicles-sort";

const DefaultListVehiclesHeader = (props) => {
  const {
    listId,
    count,
    loading,
    onBack,
    onFilterChanged,
    cartConfirm,
    config,
  } = props;
  const { vehicle_favorite } = config;
  const { t } = useTranslation();
  const showFilterSummary = useSelector((state) =>
    hasActiveCriteria(state, listId)
  );
  return (
    <div className="bg-normal sticky top-0 z-10 mb-3 md:top-[70px]">
      <div className="bottom-shadow bg-white p-2">
        <div className="flex items-center justify-between space-x-1">
          <div className="xl:hidden">
            <Button
              onClick={onBack}
              classNames="flex items-center text-nowrap text-sm font-semibold"
            >
              <i className="fa fa-chevron-left mr-2"></i>
              {t("Back")}
            </Button>
          </div>

          <div className="flex space-x-3">
            <div className="uppercase">
              <span className="hidden lg:block">
                {count} {t("results")}
              </span>
              <span className="hidden sm:block lg:hidden"># {count}</span>
            </div>
            <div className="hidden md:block">
              <ClearFilters listId={listId} onClearFilters={onFilterChanged} />
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {cartConfirm && (
              <div className="xl:hidden">
                <ConfirmButtonMobile listId={listId} />
              </div>
            )}
            {vehicle_favorite && (
              <div>
                <Favorite listId={listId} onFavoriteChanged={onFilterChanged} />
              </div>
            )}
            <div>
              <EyeButton
                listId={listId}
                showText={false}
                onInterestWithNoBidChanged={onFilterChanged}
              />
            </div>
            <div className="xl:hidden">
              <FilterButton
                listId={listId}
                onCriteriaChanged={onFilterChanged}
                onClearFilters={onFilterChanged}
              />
            </div>
            <div>
              <SortDropDown
                listId={listId}
                sorters={getListVehicleSorters()}
                defaultSorterId={7}
                onSortChanged={onFilterChanged}
              />
            </div>
          </div>
        </div>
        {showFilterSummary && (
          <div className="mt-2 hidden xl:flex">
            <FilterSummary
              listId={listId}
              onCriteriaChanged={onFilterChanged}
            />
          </div>
        )}
      </div>
      <LoadingIndicator loading={loading} />
    </div>
  );
};

const ConnectedComponent = withConfig(DefaultListVehiclesHeader);

export { ConnectedComponent as DefaultListVehiclesHeader };
