import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

import { createGuid } from "../../../../helpers/functions";
import { RegistrationWizardContentLayout } from "../../../../layouts/RegistrationWizardContentLayout";
import { ContactInfo } from "./ContactInfo";
import { WizardButtons } from "./WizardButtons";

import { find, keys, map } from "lodash";

const LeftSectionContent = ({ step, steps }) => {
  const { t } = useTranslation();
  return (
    <>
      <hgroup>
        <h1 className="text-[1.25rem] text-primary">
          {t("Step")}: {step}/{steps} : {t("Legal documents")}
        </h1>
        <h2 className="py-2 text-base">
          {t("Transparency above everything.")}
        </h2>
      </hgroup>
      <p className="pb-2">
        {t("For Autralis, transparency is the highest good.")}
      </p>
      <div className="text-light-gr">
        {t("That is why we need the following (company) documents:")}
        <ul className="list-inside list-disc">
          <li>{t("Your identity card")}</li>
          <li>{t("An empty invoice")}</li>
          <li>{t("Statutes")}</li>
          <li>{t("A picture of the dealer location")}</li>
        </ul>
      </div>

      <p className="pt-3">
        {t(
          "You may hide sensitive information on images. All information is processed confidentially."
        )}
      </p>

      <ContactInfo />
    </>
  );
};

export const WizardStepThree = (props) => {
  const { registration, documentTypes, step, totalSteps, loading } = props;
  const { t } = useTranslation();
  const [documentsForUpload, setDocumentsForUpload] = useState({});

  const handleFile = (documentKey, file) => {
    setDocumentsForUpload({ ...documentsForUpload, [documentKey]: file });
  };

  const removeFileFromDocumentsForUploadList = (documentKey) => {
    const newState = { ...documentsForUpload };
    delete newState[documentKey];
    setDocumentsForUpload(newState);
  };

  const createUniqueFileName = (file) => {
    let fileExt = file.name.split(".").pop();
    console.log(`ext=${fileExt}`);

    if (fileExt && fileExt !== file.name) {
      console.log(`1`);
      return `${createGuid()}.${fileExt}`;
    } else {
      console.log(`2`);
      return createGuid();
    }
  };

  const onHandleNext = () => {
    const doc_keys = keys(documentsForUpload);
    const renamed_arr = map(doc_keys, (k) => {
      return {
        [k]: new File(
          [documentsForUpload[k]],
          createUniqueFileName(documentsForUpload[k]),
          {
            type: documentsForUpload[k].type,
          }
        ),
      };
    });

    const renamed = Object.assign({}, ...renamed_arr);
    props.onNext(renamed);
  };

  const onHandlePrev = () => {
    props.onPrev();
  };

  const DropdownSection = () => (
    <>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {documentTypes.map((docType) => {
          const document = find(registration.documents, function (doc) {
            return doc.name === `document_${docType.id}`;
          });
          return (
            <Dropzone
              accept={{
                "image/*": [".jpeg", ".jpg", ".png"],
                "application/pdf": [".pdf"],
              }}
              key={docType.id}
              maxFiles={1}
              minSize={200}
              multiple={false}
              noDrag={true}
              onDrop={(files) => {
                handleFile(docType.id, files[0]);
              }}
            >
              {({ getRootProps, getInputProps, fileRejections }) => {
                const fileToUpload = documentsForUpload[docType.id];

                return (
                  <div className="bg-[#f4f4f4f4] text-center">
                    <div className="pt-5">
                      <i
                        {...getRootProps()}
                        className={`${docType.icon} cursor-pointer text-[80px] text-[#888888]`}
                      >
                        <input {...getInputProps()} />
                      </i>
                    </div>
                    <div>
                      {t(docType.description)}
                      <div className="h-[25px] text-sm italic text-[#008e3b]">
                        {document !== undefined && t("(Already uploaded)")}
                      </div>
                    </div>
                    <div
                      className="flex text-center"
                      style={{
                        backgroundColor:
                          fileRejections.length > 0 ? "orange" : "#888888",
                        color: "white",
                        height: 35,
                        paddingTop: 6,
                        paddingLeft: 6,
                      }}
                    >
                      <div className="text-sm">
                        {t("Selected file")}
                        {": "}
                        {fileToUpload && (
                          <>
                            {fileToUpload.name}{" "}
                            <i
                              className="fa fa-trash cursor-pointer pl-3"
                              onClick={() => {
                                removeFileFromDocumentsForUploadList(
                                  docType.id
                                );
                              }}
                            ></i>
                          </>
                        )}

                        {!fileToUpload && !fileRejections.length > 0 && "-"}

                        {fileRejections.length > 0 && t("File rejected")}
                      </div>
                    </div>
                  </div>
                );
              }}
            </Dropzone>
          );
        })}
      </div>
      <p className="pt-2 text-right text-sm italic">
        {t("Click the icons to add documents from your computer files.")}
      </p>
    </>
  );

  return (
    <RegistrationWizardContentLayout
      leftContent={<LeftSectionContent step={step} steps={totalSteps} />}
      rightContent={<DropdownSection />}
      bottomContent={
        <WizardButtons
          currentStep={step}
          onNext={onHandleNext}
          onPrevious={onHandlePrev}
        />
      }
      loading={loading}
    />
  );
};
