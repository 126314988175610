import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Phone from "react-phone-number-input";

import clsx from "clsx";

import { ReactSelect } from "../../../../../components/web/forms/ReactSelect";
import { ToggleSwitch } from "../../../../../components/web/ToogleSwitch";

const LabelFieldContainer = ({ label, touched, error, warning, children }) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx("form-group flex space-x-2", {
        "u-has-error-v1": touched && error,
      })}
    >
      <label className="my-auto min-w-[100px] text-right text-[#333]">
        {label}
      </label>

      <div id={label} className="w-full">
        {children}

        {touched &&
          ((error && (
            <small className="form-control-feedback">{t(error)}</small>
          )) ||
            (warning && <span>{t(warning)}</span>))}
      </div>
    </div>
  );
};

const FieldContainer = ({ touched, error, warning, children }) => {
  const { t } = useTranslation();

  return (
    <div className={`${touched && error ? "u-has-error-v1" : ""}`}>
      {children}
      {touched &&
        ((error && (
          <small className="form-control-feedback">{t(error)}</small>
        )) ||
          (warning && <span>{t(warning)}</span>))}
    </div>
  );
};

export const TextField = ({
  input,
  label,
  placeholder,
  disabled,
  type,
  meta: { touched, error, warning },
}) => (
  <LabelFieldContainer
    label={label}
    touched={touched}
    error={error}
    warning={warning}
  >
    <input
      {...input}
      placeholder={label}
      type={type}
      disabled={disabled}
      className="form-control form-control-md rounded-0 g-py-6 w-full pr-0"
    />
  </LabelFieldContainer>
);

export const TextFieldWithNoLabel = ({
  input,
  label,
  placeholder,
  disabled,
  type,
  meta: { touched, error, warning },
}) => (
  <FieldContainer touched={touched} error={error} warning={warning}>
    <input
      {...input}
      placeholder={label}
      type={type}
      disabled={disabled}
      className="form-control form-control-md rounded-0 g-py-6 pr-0"
    />
  </FieldContainer>
);

export const Multiline = ({
  input,
  label,
  meta: { touched, error, valid, warning },
}) => (
  <LabelFieldContainer
    label={label}
    touched={touched}
    error={error}
    warning={warning}
  >
    <textarea {...input} placeholder={label} className="w-full pr-0" rows={5} />
  </LabelFieldContainer>
);

export const PhoneNumber = ({
  input,
  label,
  type,
  meta: { touched, error, valid, warning },
}) => {
  // Style the input element as the rest of the form
  useEffect(() => {
    const elements = document.getElementsByClassName("PhoneInputInput");
    if (elements) {
      Array.from(elements).forEach((element) => {
        element.classList.add("form-control", "w-full");
      });
    }
  }, []);
  return (
    <LabelFieldContainer
      label={label}
      touched={touched}
      error={error}
      warning={warning}
    >
      <Phone
        {...input}
        onFocus={() => {
          let parent = document.getElementById(label);
          let child = parent.getElementsByClassName("react-phone-number-input");

          if (child[0]) {
            child[0].classList.add("react-phone-number-input-focus");
          }
        }}
        onBlur={() => {
          let parent = document.getElementById(label);
          let child = parent.getElementsByClassName("react-phone-number-input");
          if (child[0]) {
            child[0].classList.remove("react-phone-number-input-focus");
          }
        }}
        defaultCountry="BE"
        placeholder={label}
        displayInitialValueAsLocalNumber={true}
      />
    </LabelFieldContainer>
  );
};

export const Combo = ({
  input,
  label,
  type,
  options,
  meta: { touched, error, valid, warning },
}) => (
  <LabelFieldContainer
    label={label}
    touched={touched}
    error={error}
    warning={warning}
  >
    <ReactSelect
      /* 
            React-select v1 allowed you to use strings for the value prop, but with v2
            it's deprecated in favor of a value prop that is always either an array of
            Options objects or an Options object.
            To manage selected values as a simple string you apply a simple filter on the dataset.
            https://react-select.com/upgrade-to-v2
            */
      value={options.filter(({ value }) => value === input.value)}
      placeholder={label}
      onChange={(option) => input.onChange(option ? option.value : "")}
      options={options}
      maxMenuHeight={130}
    />
  </LabelFieldContainer>
);

export const Toggle = ({ input, meta = {}, label }) => {
  const { value, onChange } = input;
  const { touched, error, warning } = meta;
  const { t } = useTranslation();

  return (
    <div className="form-group flex items-center space-x-2">
      {label && <label className="text-right">{label}</label>}
      <ToggleSwitch value={value} onChange={onChange} />
      <div className="flex">
        {touched &&
          ((error && (
            <small className="form-control-feedback">{t(error)}</small>
          )) ||
            (warning && <span>{t(warning)}</span>))}
      </div>
    </div>
  );
};
