import { createAction } from "@reduxjs/toolkit";

export const AUTH = "AUTH";
export const AUTH_BY_TOKEN = "AUTH_BY_TOKEN";
export const FETCH_TOKEN = "AUTH_FETCH_TOKEN";
export const FETCH_TOKEN_BY_CODE = "AUTH_FETCH_TOKEN_BY_CODE";
export const FETCH_REGISTRATION = "AUTH_FETCH_REGISTRATION";
export const FETCH_PROFILE = "AUTH_FETCH_PROFILE";
export const REQUEST_ACTIVATION_MAIL = "AUTH_REQUEST_ACTIVATION_MAIL";

export const auth_types = {
  AUTH_REQUEST: `${AUTH}_REQUEST`,
  AUTH_SUCCESS: `${AUTH}_SUCCESS`,
  AUTH_FAILURE: `${AUTH}_FAILURE`,

  AUTH_BY_TOKEN: `${AUTH_BY_TOKEN}`,

  FETCH_TOKEN_REQUEST: `${FETCH_TOKEN}_REQUEST`,
  FETCH_TOKEN_SUCCESS: `${FETCH_TOKEN}_SUCCESS`,
  FETCH_TOKEN_FAILURE: `${FETCH_TOKEN}_FAILURE`,

  FETCH_TOKEN_BY_CODE_REQUEST: `${FETCH_TOKEN_BY_CODE}_REQUEST`,
  FETCH_TOKEN_BY_CODE_SUCCESS: `${FETCH_TOKEN_BY_CODE}_SUCCESS`,
  FETCH_TOKEN_BY_CODE_FAILURE: `${FETCH_TOKEN_BY_CODE}_FAILURE`,

  FETCH_REGISTRATION_REQUEST: `${FETCH_REGISTRATION}_REQUEST`,
  FETCH_REGISTRATION_SUCCESS: `${FETCH_REGISTRATION}_SUCCESS`,
  FETCH_REGISTRATION_FAILURE: `${FETCH_REGISTRATION}_FAILURE`,

  FETCH_PROFILE_REQUEST: `${FETCH_PROFILE}_REQUEST`,
  FETCH_PROFILE_SUCCESS: `${FETCH_PROFILE}_SUCCESS`,
  FETCH_PROFILE_FAILURE: `${FETCH_PROFILE}_FAILURE`,

  REQUEST_ACTIVATION_MAIL_REQUEST: `${REQUEST_ACTIVATION_MAIL}_REQUEST`,
  REQUEST_ACTIVATION_MAIL_SUCCESS: `${REQUEST_ACTIVATION_MAIL}_SUCCESS`,
  REQUEST_ACTIVATION_MAIL_FAILURE: `${REQUEST_ACTIVATION_MAIL}_FAILURE`,
};

//TODO: move action creators to separate file
export const authRequest = createAction(auth_types.AUTH_REQUEST);
export const authSuccess = createAction(auth_types.AUTH_SUCCESS);
export const authFailure = createAction(auth_types.AUTH_FAILURE);

// actions automatically create payload if it's passed into the function as an argument auth("info")
// action creator can also accept the payload prepare callback as per example below
// https://redux-toolkit.js.org/api/createAction
// export const auth = createAction(auth_types.AUTH_REQUEST, (text) => {
//   return {
//     payload: {
//       info: text,
//       createdAt: new Date().toISOString(),
//     },
//   };
// });
