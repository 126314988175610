import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { useDispatchWithPromise } from "src/hooks/useDispatchWithPromise";

import { resetCriteria } from "../actions";
import { hasActiveCriteria } from "../functions/filter";

const ClearFilters = (props) => {
  const { hasActiveCriteria, fontStyle } = props;
  const { t } = useTranslation();
  const dispatchWithPromise = useDispatchWithPromise();

  return (
    <div>
      {hasActiveCriteria && (
        <p
          className="click-text uppercase text-highlightColor"
          style={fontStyle}
          onClick={() => {
            dispatchWithPromise(props.resetCriteria(props.listId, true)).then(
              () => props.onClearFilters()
            );

            if (props.onClick) {
              props.onClick();
            }
          }}
        >
          {t("Clear filters")}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    hasActiveCriteria: hasActiveCriteria(state, ownProps.listId),
  };
};

const ConnectedComponent = connect(mapStateToProps, { resetCriteria })(
  ClearFilters
);

export { ConnectedComponent as ClearFilters };
