import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { VehicleStateContainer } from "../features/listvehiclestate/_web/VehicleState";

export const Vehicle = () => {
  const params = useParams();
  const location = useLocation();
  const { vehicleId } = params.vehicleId;
  const { search } = location.search;
  const uniqueId = `PUBLIC_${vehicleId}`;

  return (
    <div>
      <VehicleStateContainer vehicleId={uniqueId} search={search} />
    </div>
  );
};
