import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../../../api/endpoints";
import { types } from "./types";

export const fetchOffers = () => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: API_ENDPOINTS.OFFERS,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        types: [
          {
            type: types.FETCH_REQUEST,
          },
          {
            type: types.FETCH_SUCCESS,
            payload: (action, state, res) => onFetchSuccess(dispatch, res),
          },
          {
            type: types.FETCH_FAILURE,
          },
        ],
      },
    });
  };
};

const onFetchSuccess = (dispatch, res) => {
  return res.json().then((data) => {
    return data;
  });
};
