import { entityActions } from "../../entities/actions";
import { combineIds } from "../../helpers/functions";
import { store } from "../../store";
import { vehicleBasketFetch } from "../listvehiclestate/actions";

import _ from "lodash";

export const onAutralisSalesMessage = (jsonData) => {
  const data = JSON.parse(jsonData);
  switch (data.methodName) {
    case "SalesVehicleOfferUpdated":
      let result = {
        vehicle: {
          [combineIds(data.listId, data.vehicleId)]: {
            price: data.price,
            vehicle_state: data.state,
            vehicle_stop: data.stop,
            last_server_update: Date.now(),
            reserve_price_met: _.get(data, "reserve_met") || null,
            reserve_price_almost_met: _.get(data, "reserve_almost_met") || null,
            live_auction_hidden: false,
          },
        },
      };

      store.dispatch(vehicleBasketFetch(data.listId, data.vehicleId));
      store.dispatch(entityActions.updateEntities(result));
      break;
    default:
      break;
  }
};
