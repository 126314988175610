import _ from "lodash";

export const getFilters = (state) => state.filters.all.data;
export const getFiltersCount = (state) =>
  Object.keys(state.filters.all.data).length;
export const getLoading = (state) => state.filters.all.loading;
export const getError = (state) => state.filters.all.error;
export const getSelectedFilterId = (state) =>
  state.filters.all.selectedFilterId;

export const getSelectedFilter = (state) =>
  _.cloneDeep(state.filters.selected.data);
export const getSelectedFilterLoading = (state) =>
  state.filters.selected.loading;
export const getSelectedFilterError = (state) => state.filters.selected.error;
export const getSelectedFilterCompaniesSearchTerm = (state) =>
  state.filters.selected.search_companies;
export const getSelectedFilterMakesSearchTerm = (state) =>
  state.filters.selected.search_makes;
export const getSelectedAction = (state) => state.filters.selected.action;

export const getCompanies = (state) => state.filters.companies.data;
export const getCompaniesLoading = (state) => state.filters.companies.loading;
export const getCompaniesError = (state) => state.filters.companies.error;

export const getMakes = (state) => state.filters.makes.data;
export const getMakesLoading = (state) => state.filters.makes.loading;
export const getMakesError = (state) => state.filters.makes.error;

export const getFilterCopyById = (state, id) => {
  return _.cloneDeep(state.filters.data[id]);
};
