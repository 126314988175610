import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { getListIdFromListVehicleId } from "../../../helpers/functions";
import { nextListVehicleId, prevListVehicleId } from "../functions";
import * as selectors from "../selectors";

const NextPrevButtons = (props) => {
  const { prevVehicleId, nextVehicleId } = props;
  const { t } = useTranslation();

  const onClick = (listVehicleId) => {
    props.onClick(listVehicleId);
  };

  let prevDisabled =
    prevVehicleId === null || typeof prevVehicleId === "undefined";
  let nextDisabled =
    nextVehicleId === null || typeof nextVehicleId === "undefined";

  return !prevDisabled || !nextDisabled ? (
    <div className="flex items-center justify-center gap-1">
      <button
        disabled={prevDisabled}
        onClick={() => onClick(prevVehicleId)}
        className="btn btn-primary flex w-[100px] items-center justify-center px-3 py-2 text-[12px] font-semibold"
      >
        <i className="fa fa-chevron-left mr-1" />
        <span className="g-hidden-xs-down">{t("Previous")}</span>
      </button>

      <button
        disabled={nextDisabled}
        onClick={() => onClick(nextVehicleId)}
        className="btn btn-primary flex w-[100px] items-center justify-center px-3 py-2 text-[12px] font-semibold"
      >
        <span className="g-hidden-xs-down mr-1">{t("Next")}</span>
        <i className="fa fa-chevron-right" />
      </button>
    </div>
  ) : null;
};

const mapStateToProps = (state, ownProps) => {
  const { listVehicleId } = ownProps;
  const listId = getListIdFromListVehicleId(listVehicleId);
  const listVehicleIds = selectors.getVehicleIds(state, listId);

  return {
    nextVehicleId: nextListVehicleId(listVehicleIds, listVehicleId),
    prevVehicleId: prevListVehicleId(listVehicleIds, listVehicleId),
  };
};

const ConnectedComponent = connect(mapStateToProps)(NextPrevButtons);
export { ConnectedComponent as NextPrevButtons };
