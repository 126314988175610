import React from "react";
import { connect } from "react-redux";

import {
  DescriptionLabel,
  Expectation,
  VehicleVatRegimeBadge,
} from "../../../components";
import { addLeadingZero } from "../../../helpers/utils";
import nopic from "../../../img/nopic.jpeg";
import { getVehicleById } from "../../listvehicles";

const VehicleInfo = ({ vehicle }) => {
  let picture = vehicle.thumbnail_url;
  if (picture === undefined) {
    if (vehicle.pictures[0]) {
      picture = vehicle.pictures[0].url;
    } else {
      picture = { nopic };
    }
  }

  return (
    <div>
      <div className="flex space-x-2">
        <div className="max-h-[150px] w-4/12 max-w-[140px] flex-auto">
          <img
            src={picture}
            alt={vehicle.make + " " + vehicle.model}
            className="img-fluid"
            onError={(e) => (e.target.src = nopic)}
          />
        </div>
        <div className="w-8/12">
          <div className="max-w-[220px] space-y-2">
            <h5 className="h5 truncate uppercase text-highlightColor">
              #{addLeadingZero(vehicle.list_number, 3)} {vehicle.make}{" "}
              {vehicle.model}
            </h5>
            <p className="truncate text-sm font-normal uppercase text-[#999]">
              {vehicle.version}
            </p>
            <div>
              <VehicleVatRegimeBadge vat_regime={vehicle.vat_regime} />
            </div>
          </div>

          <div className="mt-3">
            <Expectation {...vehicle} />
          </div>
        </div>
      </div>

      <div className="mt-3 hidden sm:flex">
        <div className="w-full">
          <DescriptionLabel description={vehicle.description_plus} />
        </div>
      </div>
      <div className="mt-3 hidden sm:flex">
        <div className="w-full">
          <DescriptionLabel description={vehicle.description} important />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { listVehicleId } = ownProps;

  return {
    vehicle: getVehicleById(state, listVehicleId),
  };
};

const ConnectedComponent = connect(mapStateToProps)(VehicleInfo);
export { ConnectedComponent as VehicleInfo };
