import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { vehicleSelect } from "../../../actions";
import * as selectors from "../../../selectors";
import { ListHeaderAlternative } from "./ListHeaderAlternative";
import { VehiclesList } from "./VehiclesList";

export const MainContent = ({
  goBack,
  detailsVisible,
  selectedId,
  offerId,
}) => {
  const loading = useSelector(selectors.getLoading);
  const vehicleIds = useSelector(selectors.getVehicleIds);
  const dispatch = useDispatch();

  const onVehicleClick = (id) => {
    if (!detailsVisible) {
      dispatch(vehicleSelect(id, true));
    } else {
      dispatch(vehicleSelect(id, false));
    }
  };

  return (
    <div className="w-full flex-1 2xl:w-5/12">
      <ListHeaderAlternative
        goBack={goBack}
        loading={loading}
        offerId={offerId}
        vehicleIds={vehicleIds}
      />
      <VehiclesList
        loading={loading}
        vehicleIds={vehicleIds}
        onVehicleClick={onVehicleClick}
        selectedId={selectedId}
      />
    </div>
  );
};
