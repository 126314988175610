import React from "react";

import style from "../../styles";

export const CircleIcon = ({ icon, size }) => (
  <div
    style={{
      backgroundColor: style.highlightColor,
      width: size,
      height: size,
      borderRadius: size / 2,
      position: "relative",
      textAlign: "center",
      marginBottom: 5,
      padding: 5,
    }}
  >
    <p
      style={{
        position: "absolute",
        transform: "translateY(-50%)",
        top: "50%",
        left: 0,
        right: 0,
      }}
    >
      <i
        className={`${icon}`}
        style={{ fontSize: size * 0.6, color: style.highlightInvertColor }}
      />
    </p>
  </div>
);
