export const types = {
  REGISTRATION_UPDATE_CURRENT_STEP: "REGISTRATION_WIZARD_UPDATE_CURRENT_STEP",

  REGISTRATION_FETCH_REQUEST: "REGISTRATION_WIZARD_FETCH_REQUEST",
  REGISTRATION_FETCH_SUCCESS: "REGISTRATION_WIZARD_FETCH_SUCCESS",
  REGISTRATION_FETCH_FAILURE: "REGISTRATION_WIZARD_FETCH_FAILURE",

  REGISTRATION_POST_REQUEST: "REGISTRATION_WIZARD_POST_REQUEST",
  REGISTRATION_POST_SUCCESS: "REGISTRATION_WIZARD_POST_SUCCESS",
  REGISTRATION_POST_FAILURE: "REGISTRATION_WIZARD_POST_FAILURE",

  REGISTRATION_FILTERS_FETCH_REQUEST: "REGISTRATION_FILTERS_FETCH_REQUEST",
  REGISTRATION_FILTERS_FETCH_SUCCESS: "REGISTRATION_FILTERS_FETCH_SUCCESS",
  REGISTRATION_FILTERS_FETCH_FAILURE: "REGISTRATION_FILTERS_FETCH_FAILURE",

  REGISTRATION_FILTER_MAKES_FETCH_REQUEST:
    "REGISTRATION_FILTER_MAKES_FETCH_REQUEST",
  REGISTRATION_FILTER_MAKES_FETCH_SUCCESS:
    "REGISTRATION_FILTER_MAKES_FETCH_SUCCESS",
  REGISTRATION_FILTER_MAKES_FETCH_FAILURE:
    "REGISTRATION_FILTER_MAKES_FETCH_FAILURE",

  REGISTRATION_FILTER_COMPANIES_FETCH_REQUEST:
    "REGISTRATION_FILTER_COMPANIES_FETCH_REQUEST",
  REGISTRATION_FILTER_COMPANIES_FETCH_SUCCESS:
    "REGISTRATION_FILTER_COMPANIES_FETCH_SUCCESS",
  REGISTRATION_FILTER_COMPANIES_FETCH_FAILURE:
    "REGISTRATION_FILTER_COMPANIES_FETCH_FAILURE",

  REGISTRATION_COUNTRIES_FETCH_REQUEST:
    "REGISTRATION_WIZARD_COUNTRIES_FETCH_REQUEST",
  REGISTRATION_COUNTRIES_FETCH_SUCCESS:
    "REGISTRATION_WIZARD_COUNTRIES_FETCH_SUCCESS",
  REGISTRATION_COUNTRIES_FETCH_FAILURE:
    "REGISTRATION_WIZARD_COUNTRIES_FETCH_FAILURE",

  REGISTRATION_FILTER_CREATE_REQUEST: "REGISTRATION_FILTER_CREATE_REQUEST",
  REGISTRATION_FILTER_CREATE_SUCCESS: "REGISTRATION_FILTER_CREATE_SUCCESS",
  REGISTRATION_FILTER_CREATE_FAILURE: "REGISTRATION_FILTER_CREATE_FAILURE",

  REGISTRATION_FILTER_UPDATE_REQUEST: "REGISTRATION_FILTER_UPDATE_REQUEST",
  REGISTRATION_FILTER_UPDATE_SUCCESS: "REGISTRATION_FILTER_UPDATE_SUCCESS",
  REGISTRATION_FILTER_UPDATE_FAILURE: "REGISTRATION_FILTER_UPDATE_FAILURE",

  REGISTRATION_DOCUMENT_UPLOAD_REQUEST: "REGISTRATION_DOCUMENT_UPLOAD_REQUEST",
  REGISTRATION_DOCUMENT_UPLOAD_SUCCESS: "REGISTRATION_DOCUMENT_UPLOAD_SUCCESS",
  REGISTRATION_DOCUMENT_UPLOAD_FAILURE: "REGISTRATION_DOCUMENT_UPLOAD_FAILURE",
};
