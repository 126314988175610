import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Button } from "../../../../../../components/web/Button";
import { ListVehicleState } from "../../../../../listvehiclestate/index";
import { PrintButtons } from "../../../../../print";
import { toggleVehicleState } from "../../../actions";

export const VehicleStateMobileView = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // TODO: check after global styling was refactored
  // componentDidMount() {
  //   window.scrollTo(0, 0);
  // }
  // componentDidUpdate() {
  //   window.scrollTo(0, 0);
  // }

  const handleBack = () => {
    dispatch(toggleVehicleState());
  };

  return (
    <div className="bg-normal">
      <div className="bg-normal sticky top-0 z-10 mb-3 flex w-full flex-col items-center border-b md:top-[70px]">
        <div className="flex w-full items-center justify-between bg-white p-2">
          <Button
            onClick={handleBack}
            classNames="text-nowrap text-sm font-semibold"
          >
            <i className="fa fa-chevron-left mr-2"></i>
            {t("Back to offer")}
          </Button>
          <PrintButtons listVehicleId={id} />
        </div>
      </div>
      <ListVehicleState listVehicleId={id} stickyTop={124} />
    </div>
  );
};
