import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { compose } from "redux";

import { CircleIcon, LoadingIndicator } from "../../../components";
import { Button } from "../../../components/web/Button";
import { withConfig } from "../../../config";
import * as selectors from "../selectors";

const BidProcessing = (props) => {
  const { visible, showProcessing, showResult, showError } = props;
  const { vehicle_bidding_cart } = props.config;

  const iconSize = vehicle_bidding_cart ? 80 : 200;
  const topSpace = vehicle_bidding_cart ? 20 : 40;

  const { t } = useTranslation();

  useEffect(() => {
    if (!visible) {
      props.onClose();
    }
  }, [visible]);

  const renderProcessing = () => {
    return (
      <div>
        <div className="flex justify-center">
          <CircleIcon icon="fa fa-gavel" size={iconSize} />
        </div>

        <div className="mt-5 flex w-full justify-center">
          <LoadingIndicator loading={showProcessing} />
        </div>
        <p className="text-center">
          {t("Processing bid")}
          <br />
          {t("Please wait") + "..."}
        </p>
      </div>
    );
  };

  const renderResult = () => {
    const { message } = props;
    return (
      <div>
        <div className="flex flex-col items-center justify-center">
          <CircleIcon icon="fa fa-warning" size={iconSize} />
          <div className="g-mt-40 g-pa-20 text-center">{message}</div>
        </div>

        <div className="mt-5 flex justify-center space-x-2">
          <Button onClick={props.onReview}>{t("Review")}</Button>
          <Button onClick={props.onClose}>{t("Cancel")}</Button>
        </div>
      </div>
    );
  };

  const renderError = () => {
    const { error } = props;
    return (
      <div>
        <div className="flex flex-col items-center justify-center">
          <CircleIcon icon="fa fa-bolt" size={iconSize} />
          <p className="mt-5 text-center">{error.message}</p>
        </div>
        <div className="mt-5 flex justify-center space-x-2">
          <Button onClick={props.onReview}>{t("Try again")}</Button>
          <Button onClick={props.onClose}>{t("Cancel")}</Button>
        </div>
      </div>
    );
  };

  if (!visible) {
    return null;
  }
  return (
    <div className="absolute inset-0 bg-white">
      <div
        className="flex h-full w-full items-start justify-center"
        style={{ paddingTop: topSpace }}
      >
        {showProcessing && renderProcessing()}
        {showResult && renderResult()}
        {showError && renderError()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const loading = selectors.getLoading(state);
  const accepted = selectors.getAccepted(state);
  const message = selectors.getMessage(state);
  const error = selectors.getError(state);

  return {
    visible: loading || message !== "" || error !== "",
    showProcessing: loading,
    showResult: !accepted && message !== "",
    accepted,
    message,
    showError: error !== "",
    error,
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps)
)(BidProcessing);
export { ConnectedComponent as BidProcessing };
