import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import { withConfig } from "../../../config";
import { Checks } from "./components/Checks";
import { Damages } from "./components/Damages";
import { Features } from "./components/Features";
import { PicturesGrid } from "./components/PicturesGrid";
import { Specifications } from "./components/Specifications";
import { Summary } from "./components/Summary";
import { Tyres } from "./components/Tyres";

import _ from "lodash";

const VehicleState = (props) => {
  const {
    vehicle,
    disabled = false,
    showStickyHeader = true,
    config,
    toggleSwiperVisibility,
    stickyTop,
    listVehicleId,
  } = props;
  const {
    vehicle_state_summary_fields,
    vehicle_state_summary_provisional_fields,
    vehicle_state_summary_provisional_fields_reserved,
    vehicle_state_checks,
    vehicle_state_tyres,
    vehicle_state_damages,
    vehicle_state_options,
  } = config;

  const summaryFields =
    vehicle.provisional_sale && !vehicle.expected_disclose
      ? vehicle.dealer_state && vehicle.dealer_state === "RESERVED"
        ? vehicle_state_summary_provisional_fields_reserved
        : vehicle_state_summary_provisional_fields
      : vehicle_state_summary_fields;
  const { t } = useTranslation();

  const onDamagePictureClick = (picture) => {
    const { vehicle, toggleSwiperVisibility, config } = props;
    const { vehicle_state_picture_type_order } = config;

    const sortedPictures = _.flatten(
      _.values(
        _.map(vehicle_state_picture_type_order, function (id) {
          return _.filter(vehicle.pictures, { typeId: id });
        })
      )
    );

    const pictures = _.concat(
      sortedPictures,
      _.flatten(_.map(vehicle.damages, "pictures"))
    );

    toggleSwiperVisibility(
      true,
      pictures,
      _.findIndex(pictures, { src: picture.src })
    );
  };

  const showChecks =
    vehicle_state_checks && vehicle.checks && vehicle.checks.length > 0;

  const showFeatures =
    vehicle_state_options &&
    ((vehicle.features && vehicle.features.length > 0) ||
      (vehicle.featuresText && vehicle.featuresText.length > 0));

  const showTyres =
    vehicle_state_tyres &&
    vehicle.tyres &&
    _.keys(vehicle.tyres).length > 0 &&
    ((vehicle.tyres["summer"] !== undefined &&
      vehicle.tyres["summer"].length > 0) ||
      (vehicle.tyres["summer"] !== undefined &&
        vehicle.tyres["winter"].length > 0));

  return (
    <div className="space-y-3">
      {showStickyHeader && (
        <div
          className={clsx({
            sticky: false,
            "item-disabled": disabled,
          })}
          style={{ top: stickyTop ? stickyTop : 0 }}
        >
          <Summary
            vehicle={vehicle}
            listVehicleId={listVehicleId}
            config={config}
          />
        </div>
      )}

      <div className="space-y-3">
        <div>
          <PicturesGrid
            vehicle={vehicle}
            onPictureClick={(index, pictures) =>
              toggleSwiperVisibility(true, pictures, index)
            }
          />
          <div className="bg-white p-3">
            <Specifications vehicle={vehicle} fields={summaryFields} />
          </div>
        </div>

        {showChecks && (
          <div className="bg-white p-3">
            <h5 className="h5 text-highlightColor">{t("Checks")}</h5>
            <Checks vehicle={vehicle} />
          </div>
        )}

        {showFeatures && (
          <div className="bg-white p-3">
            <h5 className="h5 text-highlightColor">{t("Features")}</h5>
            <Features vehicle={vehicle} />
          </div>
        )}

        {showTyres && (
          <div className="bg-white p-3">
            <h5 className="h5 text-highlightColor">{t("Tyres")}</h5>
            <Tyres vehicle={vehicle} />
          </div>
        )}

        {vehicle_state_damages && (
          <Damages vehicle={vehicle} onPictureClick={onDamagePictureClick} />
        )}
      </div>
    </div>
  );
};

const ConnectedComponent = withConfig(VehicleState);

export { ConnectedComponent as VehicleState };
