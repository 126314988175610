import React from "react";
import { useTranslation } from "react-i18next";

import { withConfig } from "../../../../config";

const ContactInfo = ({ config }) => {
  const {
    registration_contact_info,
    registration_contact_info_mail,
    registration_contact_info_tel,
  } = config;

  const { t } = useTranslation();

  return registration_contact_info ? (
    <div className="pt-5">
      <h6 className="text-primary">{t("Need help?")}</h6>
      <div>
        <p>{t("Contact us")}</p>
        <p>{registration_contact_info_tel}</p>
        <p>{registration_contact_info_mail}</p>
      </div>
    </div>
  ) : null;
};

const ConnectedComponent = withConfig(ContactInfo);

export { ConnectedComponent as ContactInfo };
