import React, { useEffect } from "react";
import { connect } from "react-redux";
import { PropagateLoader } from "react-spinners";

import { compose } from "redux";

import { InfoContainer, VehicleState } from "../../../components";
import { withConfig } from "../../../config";
import style from "../../../styles";
import { gettext } from "../../../translations";
import { toggleSwiperVisibility } from "../../picture-swiper/actions";
import { listVehicleStateClosed, vehicleStateFetch } from "../actions";
import * as selectors from "../selectors";

const VehicleStateContainer = (props) => {
  const {
    error,
    hasData,
    listVehicleId,
    loading,
    search,
    stickyTop,
    toggleSwiperVisibility,
    vehicle,
    vehicleId,
  } = props;

  useEffect(() => {
    loadVehicleState(vehicleId, search);
  }, []);

  const loadVehicleState = (listVehicleId, search) => {
    if (listVehicleId !== 0) {
      props.vehicleStateFetch(listVehicleId, search);
    }
  };

  if (error !== "") {
    return <InfoContainer text={error} icon="fa fa-wrench" />;
  }

  if (loading || listVehicleId === 0 || !vehicle || !hasData)
    return (
      <div className="g-pa-10 g-mb-10 g-min-height-250 text-center">
        {(loading || !vehicle) && (
          <div className="g-relative-centered--x g-relative-centered--y">
            <PropagateLoader
              color={style.highlightColor}
              loading={props.loading}
            />
          </div>
        )}

        {listVehicleId === 0 && <div>{gettext("No vehicle selected")}</div>}
      </div>
    );

  return (
    <div className="content container-semiboxed">
      <VehicleState
        vehicle={vehicle}
        stickyTop={stickyTop}
        showStickyHeader={true}
        toggleSwiperVisibility={toggleSwiperVisibility}
        disabled={false}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { vehicleId } = ownProps;

  return {
    vehicle: selectors.getVehicleById(state, vehicleId),
    loading: selectors.getLoading(state, vehicleId),
    error: selectors.getError(state, vehicleId),
    hasData: selectors.hasData(state, vehicleId),
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps, {
    vehicleStateFetch,
    listVehicleStateClosed,
    toggleSwiperVisibility,
  })
)(VehicleStateContainer);

export { ConnectedComponent as VehicleStateContainer };
