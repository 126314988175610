import React from "react";

import _ from "lodash";

const GroupedVehicleFeatures = ({ vehicle }) => {
  const featureGroups = _.sortBy(vehicle.featureGroups, "name");
  const allVehicleFeatures = _.sortBy(vehicle.features, "name");

  return (
    <div className="flex flex-col">
      {featureGroups.map((group, i_feature_group) => (
        <div className="w-full" key={`feature-group-${i_feature_group}`}>
          <h5 className="h5 mt-3">{group.name}</h5>
          <div id="vehicleFeatures" className="columns-2">
            {allVehicleFeatures
              .filter((feature) => feature.group === group.id)
              .map((feature, i_feature) => (
                <div className="text-[#777]" key={`feature-${i_feature}`}>
                  <i className={`${feature.icon} pr-2 text-[16px]`} />
                  {feature.name}
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const VehicleTextFeatures = ({ featuresText }) => {
  let features = _.sortBy(featuresText, "key");

  return (
    <div className="flex">
      <div className="w-full">
        <div id="vehicleFeatures" className="grid grid-cols-2">
          {features.map((feature, i_feature) => (
            <div className="truncate text-[#777]" key={`feature-${i_feature}`}>
              <span className="font-semibold">{feature.key}:</span>
              &nbsp;{feature.description}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const Features = ({ vehicle }) => {
  return (
    <div className="space-y-2">
      <GroupedVehicleFeatures vehicle={vehicle} />
      <VehicleTextFeatures featuresText={vehicle.featuresText} />
    </div>
  );
};
