import { entityTypes } from "./types";

import { keys, merge, omit } from "lodash";

const INITIAL_STATE = { list: {}, vehicle: {} };

export const entities = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case entityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.entities) {
        // TODO refactor with single action for 1 vehicle update
        if (
          action.payload.entities["vehicle"] &&
          keys(action.payload.entities["vehicle"]).length === 1
        ) {
          const id = keys(action.payload.entities["vehicle"])[0];
          let x = {
            ...state,
            vehicle: {
              ...state.vehicle,
              [id]: {
                ...state.vehicle[id],
                ...action.payload.entities["vehicle"][id],
              },
            },
          };

          return x;
        } else {
          return merge({}, state, action.payload.entities);
        }
      }
      return state;
    case entityTypes.CLEAR_ENTITIES:
      return {
        list: omit(state.list, action.payload.invalidListIds),
        vehicle: omit(state.vehicle, action.payload.invalidVehicleIds),
      };
    default:
      return state;
  }
};
