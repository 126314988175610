import React from "react";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "../../../../../../components/index";
import { Button } from "../../../../../../components/web/Button";
import { OfferInfo } from "../../../../detail";
import { AlternativeOfferInfoDetails } from "../../../../detail/components/AlternativeOfferInfo/AlternativeOfferInfoDetails";

export const ListHeaderAlternative = ({
  goBack,
  loading,
  offerId,
  vehicleIds,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-normal sticky top-0 w-full md:top-[70px]">
      <div className="bottom-shadow mb-3 bg-white p-2.5">
        <div className="flex items-center">
          <div className="w-2/12">
            <Button
              onClick={goBack}
              classNames="text-nowrap text-sm font-semibold"
            >
              <i className="fa fa-chevron-left mr-2"></i>
              {t("Back")}
            </Button>
          </div>

          <div className="w-10/12">
            {loading ? (
              <LoadingIndicator
                loading={loading}
                text={t("Loading offer info")}
              />
            ) : (
              <OfferInfo offerId={offerId}>
                <AlternativeOfferInfoDetails
                  numberOfVehicles={vehicleIds.length}
                />
              </OfferInfo>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
