import React from "react";
import { useTranslation } from "react-i18next";

import { BasketStatesTranslate } from "../../../constants";
import { formatPrice } from "../../../helpers/functions";
import { withData } from "../withData";
import { BiddingAuction } from "./BiddingAuction";
import { BiddingFixedTender } from "./BiddingFixedTender";

const Bidding = (props) => {
  const {
    isAuction,
    biddingAllowed,
    price,
    offered,
    basket_state,
    basket_state_visible,
  } = props;
  const { t } = useTranslation();

  if (!isAuction) {
    // Tender en fixed
    if (biddingAllowed) {
      return <BiddingFixedTender {...props} />;
    } else if (!basket_state_visible) {
      const text = offered ? formatPrice(price) : t("Closed");
      return (
        <div className="g-min-width-65 flex items-center justify-center bg-highlightColor text-[14px] font-semibold text-highlightInvertColor">
          {text}
        </div>
      );
    } else {
      const text = offered ? formatPrice(price) : t("Closed");
      return (
        <div className="flex">
          {basket_state && basket_state_visible && (
            <span className="u-label flex items-center justify-center !rounded-[3px] bg-highlightColor">
              {t(BasketStatesTranslate[basket_state])}
            </span>
          )}
          <div className="g-min-width-65 flex items-center justify-center bg-highlightColor text-[14px] font-semibold text-highlightInvertColor">
            {text}
          </div>
        </div>
      );
    }
  } else {
    // Auction veiling
    return <BiddingAuction {...props} />;
  }
};

const ConnectedComponent = withData(Bidding);
export { ConnectedComponent as Bidding };
