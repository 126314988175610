import { createSlice, isAsyncThunkAction, isRejected } from "@reduxjs/toolkit";

const updateLoadingState = (state, action) => {
  const { type } = action;
  const matches =
    // Extracts the action name from the action type
    /(.*)([/_])(pending|fulfilled|rejected|REQUEST|SUCCESS|FAILURE)/.exec(type);
  const [, actionName] = matches;

  const isActionLoading =
    action.type.endsWith("pending") || action.type.endsWith("REQUEST");

  // Store whether a request is happening at the moment or not
  // e.g. will be true when receiving GET_TODOS_REQUEST or GET_TODOS/pending
  // and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE or other
  state.loading[actionName] = isActionLoading;
};

const updateErrorState = (state, action) => {
  const { type } = action;
  const matches = /(.*)([/_])(rejected|REQUEST|FAILURE)/.exec(type);
  // ignore rejected actions without the payload
  if (!action.payload) {
    return;
  }
  const [, actionName] = matches;

  state.error[actionName] = {
    message: action.payload.message,
    code: action.payload.code,
  };
};

const isThunkLoading = (action) => {
  const { type } = action;
  const hasMatches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
  return Boolean(hasMatches);
};

const hasThunkFailed = (action) => {
  const { type } = action;
  const hasMatches = /(.*)_(REQUEST|FAILURE)/.exec(type);
  return Boolean(hasMatches);
};

/**
 *  This slice tracks the global loading and error state of the thunks. It detects the asyncThunk actions and
 *  thunks if _(REQUEST|SUCCESS|FAILURE) is present in their action type.
 *  The core idea is taken from:
 *  https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
 *
 */
const uiSlice = createSlice({
  name: "ui",
  initialState: { loading: {}, error: {} },
  reducers: {},
  extraReducers: (builder) => {
    // accommodate asyncThunks
    builder.addMatcher(isAsyncThunkAction, updateLoadingState);
    builder.addMatcher(isRejected, updateErrorState);
    // accommodate existing thunks
    builder.addMatcher(isThunkLoading, updateLoadingState);
    builder.addMatcher(hasThunkFailed, updateErrorState);
  },
});

export default uiSlice.reducer;
