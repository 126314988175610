import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { DescriptionLabel } from "../../../components";
import { toggleConfirm } from "../actions";
import * as selectors from "../selectors";

const ConfirmReminder = ({ count }) => {
  const { t } = useTranslation();

  if (count <= 0) return null;

  return (
    <DescriptionLabel description={t("Don’t forget to check out.")} important />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    count: selectors.getBiddingsToConfirmCount(state, ownProps.listId),
  };
};

const ConnectedComponent = connect(mapStateToProps, { toggleConfirm })(
  ConfirmReminder
);
export { ConnectedComponent as ConfirmReminder };
