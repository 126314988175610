import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { combineIds } from "../../helpers/functions";
import { entityActions } from "../actions";
import { mapListVehicle } from "./mappers";
import { listVehicleTypes } from "./types";

export const listVehicleFetch = (listId, vehicleId) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.LIST_VEHICLE}${vehicleId}/?listId=${listId}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: listVehicleTypes.FETCH_REQUEST,
          },
          {
            type: listVehicleTypes.FETCH_SUCCESS,
            payload: (action, state, res) =>
              onListVehicleFetchSuccess(dispatch, res, listId, vehicleId),
          },
          {
            type: listVehicleTypes.FETCH_FAILURE,
          },
        ],
      },
    });
  };
};

const onListVehicleFetchSuccess = (dispatch, res, listId, vehicleId) => {
  return res.json().then((data) => {
    let result = {
      vehicle: {
        [combineIds(listId, vehicleId)]: mapListVehicle(data),
      },
    };
    dispatch(entityActions.updateEntities(result));
  });
};
