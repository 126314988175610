import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { compose } from "redux";
import { Field, reduxForm } from "redux-form";

import { Toggle } from "../../profile/_web/components/forms/FormFields";

export const settingsFields = {
  CAR_BY_CAR_MAIL: "car_by_car_mail",
  OFFERS_MAIL: "offers_mail",
  WON_MAIL: "won_mail",
  NO_MAIL: "no_mail",
};

const SettingsForm = (props) => {
  const { onSubmit, initialValues, change } = props;
  const { t } = useTranslation();
  const [unsubscribeAll, setUnsubscribeAll] = useState(
    initialValues.no_mail || false
  );
  const handleUnsubscribeAll = () => {
    change(settingsFields.CAR_BY_CAR_MAIL, unsubscribeAll);
    change(settingsFields.OFFERS_MAIL, unsubscribeAll);
    change(settingsFields.WON_MAIL, unsubscribeAll);

    setUnsubscribeAll((prevState) => !prevState);
  };

  return (
    <form
      className="flex w-full flex-col items-end space-y-3"
      onSubmit={onSubmit}
    >
      <Field
        name={settingsFields.CAR_BY_CAR_MAIL}
        disabled={unsubscribeAll}
        component={Toggle}
        label={t("I want to receive request emails car by car.")}
      />

      <Field
        name={settingsFields.OFFERS_MAIL}
        disabled={unsubscribeAll}
        component={Toggle}
        label={t(
          "I want to receive a confirmation email each time I make an offer."
        )}
      />

      <Field
        name={settingsFields.WON_MAIL}
        disabled={unsubscribeAll}
        component={Toggle}
        label={t(
          "I want to receive an email when I make the highest bid and the corresponding delivery information for this car."
        )}
      />

      <hr className="!my-5 w-full border-dashed text-[#ccc]" />

      <Field
        name={settingsFields.NO_MAIL}
        onChange={handleUnsubscribeAll}
        component={Toggle}
        label={t("I don’t want to receive any emails.")}
      />
    </form>
  );
};

export const ConnectedComponent = compose(
  reduxForm({
    form: "unsubscribe-settings",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
  })
)(SettingsForm);

export { ConnectedComponent as SettingsForm };
