import React, { useState } from "react";
import Iframe from "react-iframe";
import Modal from "react-modal";

import { withConfig } from "../../config";
import { LoadingIndicator } from "./LoadingIndicator";

import moment from "moment-timezone";

const TeaserBanner = (props) => {
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const expires = moment("2020-05-05T00:00:00");
  const now = moment();
  const bannerExpired = now > expires;
  const { teaser_banner } = props.config;

  Modal.setAppElement("#app");

  if (!teaser_banner || bannerExpired) {
    return null;
  }

  return (
    <div>
      <img
        className="img-fluid"
        src={require("../../assets/temp/auction.png")}
        alt="Live auction banner"
        style={{ marginBottom: 5 }}
        onClick={() => setModalVisible(true)}
      />

      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onClick={(e) => e.stopPropagation()}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 500,
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: window.innerWidth > 800 ? 600 : 0,
            maxWidth: window.innerWidth - 10,
          },
        }}
      >
        <React.Fragment>
          <div
            className="d-flex justify-content-end cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setModalVisible(false);
            }}
          >
            <i className="fa fa-close text-[22px]" />
          </div>

          <div
            className="w-100 justify-content-center"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="row g-pa-0 g-ma-0 w-100 justify-content-center g-pt-10"
              style={{ position: "absolute" }}
            >
              <LoadingIndicator loading={loading} />
            </div>

            <Iframe
              url="https://player.vimeo.com/video/394248807"
              width={window.innerWidth > 800 ? "700px" : "440px"}
              height={window.innerWidth > 800 ? "400px" : "260px"}
              id="Action video"
              display="initial"
              position="relative"
              allowFullScreen
              onLoad={() => {
                setLoading(false);
              }}
            />
          </div>
        </React.Fragment>
      </Modal>
    </div>
  );
};

const ConnectedComponent = withConfig(TeaserBanner);

export { ConnectedComponent as TeaserBanner };
