import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

const allLists = (state) => state.entities.list;
export const listById = (state, id) => allLists(state)[id];
export const getListType = (state, id) =>
  listById(state, id) ? listById(state, id).type : undefined;

export const currentListsIds = (state) => state.currentLists.ids;
export const currentListsLoading = (state) => state.currentLists.isFetching;
export const currentListsError = (state) => state.currentLists.error;

export const listLoading = (state, id) =>
  state.lists[id] ? state.lists[id].isFetching : false;
export const listError = (state, id) =>
  state.lists[id] ? state.lists[id].error : "";
export const listLastUpdated = (state, id) =>
  state.lists[id] ? state.lists[id].lastUpdated : "";

export const allListsVehicleCount = createSelector(
  [currentListsIds, allLists],
  (ids, lists) => {
    return _.sumBy(
      _.map(ids, (id) => lists[id]),
      "vehicle_count"
    );
  }
);

/*

export const getLists = createSelector(
    [getListsIds, listEntitySelectors.getAllListEntities],
    (listIds, allLists) => {
        let lists = _.map(listIds, (id) => {
            return allLists[id];
        });

        return  lists;
    }
);

*/
