import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import calling from "../../../assets/svg/call.svg";
import { fetchApprovals } from "../approvalCheckSlice";
import { getApprovalCount } from "../selectors";
import { ApprovalCheckModal } from "./ApprovalCheckModal";

export const ApprovalCheck = () => {
  const [visible, setVisible] = useState(true);
  const approvalCount = useSelector(getApprovalCount);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApprovals());
  }, []);

  // Do not render the approvals banner while loading or when already approved.
  if (approvalCount === null || approvalCount > 0) {
    return null;
  }

  return (
    <div className="w-full" data-testid="approvals-banner">
      <div
        className="cursor-pointer space-x-1 border-b-2 border-approvalsBorder bg-approvalsBgColor text-base text-approvalsTextColor sm:text-[20px]"
        onClick={() => setVisible(true)}
      >
        <div className="flex items-center">
          <div className="self-start p-2">
            <img
              className="inline-block w-[38px] p-1 text-highlightColor"
              src={calling}
              alt="calling"
            />
          </div>
          <div className="p-1">
            {t("Contact the dealers so they can add you to their network.")}
          </div>
        </div>
        <ApprovalCheckModal
          visible={visible}
          onCancel={() => setVisible(false)}
        />
      </div>
    </div>
  );
};
