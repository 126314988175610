import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { formatPrice } from "../../../helpers/functions";
import { getListIdFromListVehicleId } from "../../../helpers/functions";
import { listCleanupByListId } from "../../lists/actions";
import { ListVehicleState } from "../../listvehiclestate";
import { PrintButtons } from "../../print";
import { historyVehiclesAndStateCleanUp, toggleVehicleState } from "../actions";
import * as selectors from "../selectors";

const HistoryState = (props) => {
  const { listVehicleId, listCleanupByListId, toggleVehicleState, vehicle } =
    props;

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      // componentwillunmount in functional component.
      listCleanupByListId(getListIdFromListVehicleId(listVehicleId));
      historyVehiclesAndStateCleanUp();
    };
  }, []);

  const handleBack = () => {
    toggleVehicleState();
  };

  return (
    <div>
      <div className="bg-normal sticky top-0 z-10 mb-1 flex w-full flex-col items-center justify-between space-y-1 border-b md:top-[70px]">
        <div className="flex w-full items-center justify-between bg-white p-2">
          <div>
            <button
              onClick={handleBack}
              className="btn btn-primary rounded-0 flex items-center text-nowrap px-3 py-2 text-sm font-semibold"
            >
              <i className="fa fa-chevron-left mr-2 text-[12px]" />
              <span className="hidden sm:block">{t("Back to list")}</span>
              <span className="block sm:hidden">{t("Back")}</span>
            </button>
          </div>
          <div className="flex items-center justify-end space-x-3 bg-white">
            <PrintButtons listVehicleId={vehicle.list_vehicle_id} />
            <div className="mx-auto w-[200px] bg-highlightColor text-center font-semibold text-highlightInvertColor">
              {formatPrice(vehicle.basket_price)}
            </div>
          </div>
        </div>
      </div>

      <ListVehicleState listVehicleId={listVehicleId} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    vehicle: selectors.getVehicleById(state, ownProps.listVehicleId),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  toggleVehicleState,
  listCleanupByListId,
  historyVehiclesAndStateCleanUp,
})(HistoryState);

export { ConnectedComponent as HistoryState };
