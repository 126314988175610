import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { compose } from "redux";
import { DateRangePicker } from "src/features/datepicker/DateRangePicker";
import { useDispatchWithPromise } from "src/hooks/useDispatchWithPromise";

import { LoadingIndicator } from "../../../components";
import { withConfig } from "../../../config";
import { numberFormat } from "../../../helpers/functions";
import style from "../../../styles";
import {
  fetchListVehiclesFilter,
  resetCriteria,
  updateCriteria,
} from "../actions";
import * as criteriaHelpers from "../functions/criteria";
import { defaultRangePresets } from "../selectors";
import * as listVehiclesFilterSelectors from "../selectors";
import { FilterDropDown } from "./FilterDropDown";
import { FilterItem } from "./FilterItem";

import _ from "lodash";
import { sortBy } from "lodash";
import moment from "moment";

const KmFilter = ({ km, handleKm }) => {
  const { max_selected, min_selected } = km;
  const [kmValues, setKmValues] = useState({
    max: max_selected,
    min: min_selected,
  });

  useEffect(() => {
    setKmValues({ max: max_selected, min: min_selected });
  }, [max_selected, min_selected]);

  const handleKmState = (value) => {
    setKmValues({ max: value[1], min: value[0] });
  };

  return (
    <div>
      <h6 className="mb-2 font-extrabold text-highlightColor">
        {numberFormat(kmValues.min)} - {numberFormat(kmValues.max)} km <br />
      </h6>
      <Slider
        range
        min={km.min}
        max={km.max}
        value={[kmValues.min, kmValues.max]}
        step={1000}
        allowCross={false}
        onChange={(value) => handleKmState(value)}
        onChangeComplete={(value) => handleKm(value)}
        trackStyle={[{ backgroundColor: style.highlightColor }]}
        handleStyle={[
          { borderColor: style.highlightColor },
          { borderColor: style.highlightColor },
        ]}
      />
    </div>
  );
};

const AgeFilter = ({ age, handleAge }) => {
  const { t } = useTranslation();
  const { max_selected, min_selected } = age;
  const [ageValues, setAgeValues] = useState({
    max: max_selected,
    min: min_selected,
  });

  // TODO: also needs to update when filter tag is removed
  useEffect(() => {
    setAgeValues({ max: max_selected, min: min_selected });
  }, [max_selected, min_selected]);

  const handleAgeState = (value) => {
    setAgeValues({ max: value[1], min: value[0] });
  };

  return (
    <div>
      <h6 className="mb-2 font-extrabold text-highlightColor">
        {ageValues.min} - {ageValues.max} {t("months")}
      </h6>
      <Slider
        range
        min={age.min}
        max={age.max}
        value={[ageValues.min, ageValues.max]}
        step={1}
        allowCross={false}
        onChange={(value) => handleAgeState(value)}
        onChangeComplete={(value) => handleAge(value)}
        trackStyle={[{ backgroundColor: style.highlightColor }]}
        handleStyle={[
          { borderColor: style.highlightColor },
          { borderColor: style.highlightColor },
        ]}
      />
    </div>
  );
};

const Filter = (props) => {
  const { companies, makes, modelsByMake, fuels, gearboxes, km, age, states } =
    props.data;

  const { showCounters, config } = props;
  const { list_vehicle_filter_make_models } = config;
  const { t } = useTranslation();

  const dispatchWithPromise = useDispatchWithPromise();

  useEffect(() => {
    props.fetchListVehiclesFilter(props.listId);
  }, []);

  const setCriteria = (criteria) => {
    dispatchWithPromise(props.updateCriteria(props.listId, criteria)).then(
      () => {
        props.onCriteriaChanged();
      }
    );
  };

  const handleCompany = (company) => {
    if (company.active) {
      setCriteria(criteriaHelpers.removeCompany(props.criteria, company.id));
    } else {
      setCriteria(criteriaHelpers.addCompany(props.criteria, company.id));
    }
  };

  const handleMake = (make) => {
    if (make.active) {
      setCriteria(criteriaHelpers.removeMake(props.criteria, make.id));
    } else {
      setCriteria(criteriaHelpers.addMake(props.criteria, make.id));
    }
  };

  const handleMakeFromModel = (make) => {
    if (criteriaHelpers.containsMake(props.criteria, make)) {
      setCriteria(criteriaHelpers.removeMake(props.criteria, make));
    } else {
      setCriteria(criteriaHelpers.addMake(props.criteria, make));
    }
  };

  const handleMakeModel = (makeModel) => {
    if (criteriaHelpers.containsMake(props.criteria, makeModel.make)) {
      setCriteria(criteriaHelpers.removeMake(props.criteria, makeModel.make));
    }

    if (makeModel.active) {
      setCriteria(criteriaHelpers.removeMakeModel(props.criteria, makeModel));
    } else {
      setCriteria(criteriaHelpers.addMakeModel(props.criteria, makeModel));
    }
  };

  const handleMakeModelClose = (make) => {
    setCriteria(criteriaHelpers.removeAllMakeModels(props.criteria, make));
  };

  const handleFuel = (fuelType) => {
    if (fuelType.active) {
      setCriteria(criteriaHelpers.removeFuel(props.criteria, fuelType.id));
    } else {
      setCriteria(criteriaHelpers.addFuel(props.criteria, fuelType.id));
    }
  };

  const handleGearbox = (gearbox) => {
    if (gearbox.active) {
      setCriteria(criteriaHelpers.removeGearbox(props.criteria, gearbox.id));
    } else {
      setCriteria(criteriaHelpers.addGearbox(props.criteria, gearbox.id));
    }
  };

  const handleKm = (value) => {
    if (props.loading) return;
    const { km } = props.data;

    const updateKmFilter = km.min !== value[0] || km.max !== value[1];
    const resetKmFilter = km.min === value[0] && km.max === value[1];

    if (updateKmFilter) {
      setCriteria(
        criteriaHelpers.updateKm(props.criteria, {
          min: value[0],
          max: value[1],
        })
      );
    } else if (resetKmFilter) {
      setCriteria(criteriaHelpers.updateKm(props.criteria, {}));
    }
  };

  const handleState = (state) => {
    if (state.active) {
      setCriteria(criteriaHelpers.removeState(props.criteria, state.id));
    } else {
      setCriteria(criteriaHelpers.addState(props.criteria, state.id));
    }
  };

  const sanitiseDates = (dates) => {
    if (!dates) {
      dates = ["", ""];
    }
    return dates;
  };

  const handleOfferedDt = (dates) => {
    dates = sanitiseDates(dates);

    const startDate = dates[0];
    const endDate = dates[1];
    setCriteria(
      criteriaHelpers.updateOfferedDt(props.criteria, startDate, endDate)
    );
  };

  const handleExpectationDt = (dates) => {
    dates = sanitiseDates(dates);

    const startDate = dates[0];
    const endDate = dates[1];
    setCriteria(
      criteriaHelpers.updateExpectationDt(props.criteria, startDate, endDate)
    );
  };

  const handleOfferedDtCancel = () => {
    handleOfferedDt("", "");
  };

  const handleExpectationDtCancel = () => {
    handleExpectationDt("", "");
  };

  const handleAge = (value) => {
    if (props.loading) return;
    const { age } = props.data;

    const updateAge = age.min !== value[0] || age.max !== value[1];
    const resetAge = age.min === value[0] && age.max === value[1];

    if (updateAge) {
      setCriteria(
        criteriaHelpers.updateAge(props.criteria, {
          min: value[0],
          max: value[1],
        })
      );
    } else if (resetAge) {
      setCriteria(criteriaHelpers.updateAge(props.criteria, {}));
    }
  };

  if (props.loading)
    return (
      <div className="h-100 mb-5 bg-white p-3 text-center">
        <LoadingIndicator loading={props.loading} />
      </div>
    );

  const kmFilterProps = {
    km,
    handleKm,
  };

  const ageFilterProps = {
    age,
    handleAge,
  };

  return (
    <div className="space-y-3">
      {states && states.length > 0 && (
        <div className="bg-white p-3">
          <h6 className=" mb-2 font-extrabold text-highlightColor">
            {t("States")}
          </h6>
          <ul className="list-unstyled space-y-1">
            {sortBy(states, "index").map((state, i) => (
              <FilterItem
                key={`state-${i}`}
                onClick={() => handleState(state)}
                item={state}
                showCounters={showCounters}
              />
            ))}
          </ul>
        </div>
      )}

      <div className="space-y-2 bg-white p-3">
        <h6 className=" mb-2 font-extrabold text-highlightColor">
          {t("Dates")}
        </h6>
        {states && states.length > 0 && (
          <div>
            {t("Offered")}
            <DateRangePicker
              dateValues={[props.data.offeredDt_min, props.data.offeredDt_max]}
              onChange={handleOfferedDt}
              onCancel={handleOfferedDtCancel}
              presets={defaultRangePresets}
            />
          </div>
        )}
        <div>
          {t("Delivery")}
          <DateRangePicker
            dateValues={[
              props.data.expectationDt_min,
              props.data.expectationDt_max,
            ]}
            onChange={handleExpectationDt}
            onCancel={handleExpectationDtCancel}
            presets={defaultRangePresets}
          />
        </div>
      </div>

      <div className="space-y-5 bg-white p-3">
        <KmFilter {...kmFilterProps} />

        <AgeFilter {...ageFilterProps} />

        {companies.length > 0 && (
          <div>
            <h6 className="font-extrabold text-highlightColor">
              {t("Dealer")}
            </h6>
            <ul className="list-unstyled space-y-1">
              {companies.map((company, i) => (
                <FilterItem
                  key={`dealer-${i}`}
                  onClick={() => handleCompany(company)}
                  item={company}
                  showCounters={showCounters}
                />
              ))}
            </ul>
          </div>
        )}

        <div className="mb-2">
          <h6 className="font-extrabold text-highlightColor">{t("Fuel")}</h6>
          <ul className="list-unstyled space-y-1">
            {fuels.map((fuel, i) => (
              <FilterItem
                key={`fuel-${i}`}
                onClick={() => handleFuel(fuel)}
                item={fuel}
                showCounters={showCounters}
              />
            ))}
          </ul>
        </div>

        <div className="mb-2">
          <h6 className="font-extrabold text-highlightColor">
            {t("Transmission")}
          </h6>
          <ul className="list-unstyled space-y-1">
            {gearboxes.map((gearbox, i) => (
              <FilterItem
                key={`gearbox-${i}`}
                onClick={() => handleGearbox(gearbox)}
                item={gearbox}
                showCounters={showCounters}
              />
            ))}
          </ul>
        </div>

        {!list_vehicle_filter_make_models && (
          <div className="mb-2">
            <h6 className="font-extrabold text-highlightColor">{t("Make")}</h6>
            <ul className="list-unstyled space-y-1">
              {makes.map((make, i) => (
                <FilterItem
                  key={`make-${i}`}
                  onClick={() => handleMake(make)}
                  item={make}
                  showCounters={showCounters}
                />
              ))}
            </ul>
          </div>
        )}
      </div>

      {list_vehicle_filter_make_models &&
        _.keys(modelsByMake).map((make, i) => (
          <FilterDropDown
            title={make}
            key={`make-models-container-${i}`}
            items={modelsByMake[make]}
            onClick={(makeModel) => handleMakeModel(makeModel)}
            onOpen={(makeModel) => handleMakeFromModel(makeModel)}
            onClose={(make) => handleMakeModelClose(make)}
            showCounters={showCounters}
            makeActive={criteriaHelpers.containsMake(props.criteria, make)}
          />
        ))}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { listId } = ownProps;
  const data = listVehiclesFilterSelectors.getData(state, listId);
  const currentCriteria = listVehiclesFilterSelectors.getCriteria(
    state,
    listId
  );

  const makes = _.sortBy(
    _.uniqBy(
      _.map(data.models, (model) => ({
        id: model.make,
        name: model.make,
        active:
          _.findIndex(currentCriteria.makes, function (mm) {
            return mm.make === model.make;
          }) > -1,
        count: _.sumBy(
          _.filter(data.models, (m) => m.make === model.make),
          "count"
        ),
      })),
      "name"
    ),
    "name"
  );

  const modelsByMake = _.groupBy(
    _.uniqBy(
      _.map(data.models, (vehicle) => ({
        make: vehicle.make,
        model: vehicle.model,
        name: vehicle.model,
        active:
          _.findIndex(currentCriteria.makes, function (mm) {
            return mm.make === vehicle.make && mm.model === vehicle.model;
          }) > -1,
        count: vehicle.count,
      })),
      "model"
    ),
    "make"
  );

  const companies = _.map(data.companies, (company) => ({
    id: company.id,
    name: company.name,
    active: _.includes(currentCriteria.companies, company.id),
    count: company.count,
  }));

  const fuels = _.sortBy(
    _.map(data.fuels, (fuel) => ({
      id: fuel.key,
      name: fuel.name,
      active: _.includes(currentCriteria.fuels, fuel.key),
      count: fuel.count,
    })),
    "name"
  );

  const gearboxes = _.sortBy(
    _.map(data.gearboxes, (gearbox) => ({
      id: gearbox.key,
      name: gearbox.name,
      active: _.includes(currentCriteria.gearboxes, gearbox.key),
      count: gearbox.count,
    })),
    "name"
  );

  const km = {
    min: Math.round(data.ranges.km_min / 1000) * 1000 || 0,
    max: Math.ceil(data.ranges.km_max / 1000) * 1000 || 1000000,
    min_selected:
      currentCriteria.km.min ||
      Math.round(data.ranges.km_min / 1000) * 1000 ||
      0,
    max_selected:
      currentCriteria.km.max ||
      Math.ceil(data.ranges.km_max / 1000) * 1000 ||
      1000000,
  };

  const age = {
    min: data.ranges.age_max ? moment().diff(data.ranges.age_max, "months") : 0,
    max: data.ranges.age_min
      ? moment().diff(data.ranges.age_min, "months")
      : 1000,
    min_selected:
      currentCriteria.age.min ||
      (data.ranges.age_max ? moment().diff(data.ranges.age_max, "months") : 0),
    max_selected:
      currentCriteria.age.max ||
      (data.ranges.age_min
        ? moment().diff(data.ranges.age_min, "months")
        : 1000),
  };

  const states = _.sortBy(
    _.map(data.states, (state) => ({
      id: state.code,
      name: state.name,
      active: _.includes(currentCriteria.states, state.code),
      count: state.count,
      index: state.index,
    })),
    "name"
  );

  const basketOnly = false;

  // Calculate offered dates min/max and range
  let offeredDt_min = "";
  let offeredDt_max = "";
  let offered_range = "";

  if (currentCriteria.offeredDt_min && currentCriteria.offeredDt_min !== "") {
    offeredDt_min = moment(currentCriteria.offeredDt_min);
  }

  if (currentCriteria.offeredDt_max && currentCriteria.offeredDt_max !== "") {
    offeredDt_max = moment(currentCriteria.offeredDt_max);
  }

  if (
    currentCriteria.offeredDt_min &&
    currentCriteria.offeredDt_min !== "" &&
    currentCriteria.offeredDt_max &&
    currentCriteria.offeredDt_max !== ""
  ) {
    offered_range =
      moment(currentCriteria.offeredDt_min).format("DD/MM/YYYY") +
      " - " +
      moment(currentCriteria.offeredDt_max).format("DD/MM/YYYY");
    if (currentCriteria.offeredDt_min === currentCriteria.offeredDt_max) {
      offered_range = moment(currentCriteria.offeredDt_min).format(
        "DD/MM/YYYY"
      );
    }
  }

  // Calculate expectation dates min/max and range
  let expectationDt_min = "";
  let expectationDt_max = "";
  let expectation_range = "";

  // Check if there's a value in currentCriteria and if it's valid, set expectationDate to it
  if (
    currentCriteria.expectationDt_min &&
    currentCriteria.expectationDt_min !== ""
  ) {
    expectationDt_min = moment(currentCriteria.expectationDt_min);
  }

  if (
    currentCriteria.expectationDt_max &&
    currentCriteria.expectationDt_max !== ""
  ) {
    expectationDt_max = moment(currentCriteria.expectationDt_max);
  }

  if (
    currentCriteria.expectationDt_min &&
    currentCriteria.expectationDt_min !== "" &&
    currentCriteria.expectationDt_max &&
    currentCriteria.expectationDt_max !== ""
  ) {
    expectation_range =
      moment(currentCriteria.expectationDt_min).format("DD/MM/YYYY") +
      " - " +
      moment(currentCriteria.expectationDt_max).format("DD/MM/YYYY");
    if (
      currentCriteria.expectationDt_min === currentCriteria.expectationDt_max
    ) {
      expectation_range = moment(currentCriteria.expectationDt_min).format(
        "DD/MM/YYYY"
      );
    }
  }

  return {
    loading: listVehiclesFilterSelectors.getLoading(state, listId),
    criteria: listVehiclesFilterSelectors.getCriteriaCopy(state, listId),
    data: {
      makes,
      modelsByMake,
      companies,
      fuels,
      gearboxes,
      km,
      age,
      states,
      basketOnly,
      offeredDt_min,
      offeredDt_max,
      offered_range,
      expectationDt_min,
      expectationDt_max,
      expectation_range,
    },
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps, {
    fetchListVehiclesFilter,
    updateCriteria,
    resetCriteria,
  })
)(Filter);

export { ConnectedComponent as Filter };
