import "./sentry";

import React from "react";
import ReactDOM from "react-dom/client";

import { RootApp } from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);
root.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>
);

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_USE_MSW_IN_BROWSER === "true"
) {
  const { worker } = require("./mocks/browser/browser");
  const handleUnhandledRequest = process.env.REACT_APP_MSW_UNHANDLED_REQUEST;
  worker.start({
    onUnhandledRequest: handleUnhandledRequest,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
