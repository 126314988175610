import { Tooltip } from "../../../../components/web/Tooltip";

export const HeaderWithToolTip = ({ text }) => {
  const getHeaderText = (text, maxLength = 3) => {
    let tempText = text;
    if (tempText.includes(" ")) {
      tempText = tempText.split(" ")[1];
    }
    return tempText.substring(0, maxLength);
  };

  return (
    <div>
      <Tooltip message={text} position={"bottom-left"}>
        <span className="capitalize">{getHeaderText(text)}.</span>
      </Tooltip>
    </div>
  );
};
