import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { submit } from "redux-form";

import { RegistrationWizardContentLayout } from "../../../../layouts/RegistrationWizardContentLayout";
import { ContactInfo } from "./ContactInfo";
import { CompanyInfoForm } from "./forms/CompanyInfoForm";
import { WizardButtons } from "./WizardButtons";

const LeftSectionContent = ({ step, steps }) => {
  const { t } = useTranslation();
  return (
    <>
      <hgroup>
        <h1 className="text-[1.25rem] text-primary">
          {t("Step")}: {step}/{steps} : {t("Company info")}
        </h1>
        <h2 className="py-2 text-base">{t("Tell us about your company.")}</h2>
      </hgroup>
      <p>
        {t(
          "All information is processed confidentially and serves to support you as well as possible."
        )}
      </p>

      <ContactInfo />
    </>
  );
};

export const companyInfoFields = {
  COMPANY_NAME: "company_name",
  VAT: "vat_number",
  ADDRESS: "address",
  COUNTRY: "country_code",
  POSTAL: "post_code",
  CITY: "city",
  MAIL: "logistics_email",
  YEARLY_VOLUME: "volume_year",
};

const WizardStepTwo = (props) => {
  const {
    required,
    step,
    totalSteps,
    submit,
    onNext,
    onPrev,
    registration,
    countries,
    loading,
  } = props;

  const mapDataToFields = (data) => {
    return {
      [companyInfoFields.COMPANY_NAME]: data.company_name,
      [companyInfoFields.VAT]: data.vat_number,
      [companyInfoFields.ADDRESS]: data.address,
      [companyInfoFields.COUNTRY]: data.country_code,
      [companyInfoFields.POSTAL]: data.post_code,
      [companyInfoFields.CITY]: data.city,
      [companyInfoFields.MAIL]: data.logistics_email,
      [companyInfoFields.YEARLY_VOLUME]: data.volume_year,
    };
  };

  return (
    <RegistrationWizardContentLayout
      leftContent={<LeftSectionContent step={step} steps={totalSteps} />}
      rightContent={
        <CompanyInfoForm
          initialValues={mapDataToFields(registration)}
          required={required}
          countries={countries}
          onSubmit={(values) => onNext(values)}
        />
      }
      bottomContent={
        <WizardButtons
          currentStep={step}
          onNext={() => submit("Registration-CompanyInfo")}
          onPrevious={() => onPrev()}
        />
      }
      loading={loading}
    />
  );
};

const ConnectedComponent = connect(null, { submit })(WizardStepTwo);

export { ConnectedComponent as WizardStepTwo };
