import i18n from "../../i18n";

export const equipments = [
  {
    title: i18n.t("Airconditioning"),
    icon: "icon-airco",
    id: 752701,
    ids: [752702, 752701, 752704, 5, 30],
  },
  {
    title: i18n.t("Alloy wheels"),
    icon: "icon-alloy-wheels",
    id: 754908,
    ids: [
      754908, 754909, 754910, 754911, 754916, 754918, 754919, 754920, 754922,
      754915, 754921, 15, 999, 1001, 1002, 1003, 1004, 1005, 1006,
    ],
  },
  {
    title: i18n.t("Bluetooth"),
    icon: "icon-bluetooth",
    id: 752206,
    ids: [752206, 122],
  },
  {
    title: i18n.t("Cruise control"),
    icon: "icon-cruise-control",
    id: 752601,
    ids: [752601, 752602, 38, 133],
  },
  {
    title: i18n.t("heated seats"),
    icon: "icon-heated-seats",
    id: 751143,
    ids: [751143, 751144, 34],
  },
  {
    title: i18n.t("Leather"),
    icon: "icon-leather",
    id: 754501,
    ids: [754501, 998],
  },
  {
    title: i18n.t("MP3"),
    icon: "icon-mp3",
    id: 754312,
    ids: [754312, 43],
  },
  {
    title: i18n.t("Navigation"),
    icon: "icon-navigation",
    id: 754305,
    ids: [754305, 23],
  },
  {
    title: i18n.t("Open roof"),
    icon: "icon-open-roof",
    id: 754002,
    ids: [
      754099, 754030, 754028, 754006, 754003, 754029, 754023, 754025, 754020,
      754002, 754005, 754001, 754004, 754007, 4, 50,
    ],
  },
  {
    title: i18n.t("Park distance control"),
    icon: "icon-pdc",
    id: 751603,
    ids: [751603, 751602, 129, 128],
  },
  {
    title: i18n.t("Pneumatic suspension"),
    icon: "icon-pneumatic",
    id: 751637,
    ids: [751637, 144],
  },
  {
    title: i18n.t("Power steering"),
    icon: "icon-power-steering",
    id: 753201,
    ids: [753201, 12],
  },
  {
    title: i18n.t("Rear view camera"),
    icon: "icon-rearview-camera",
    id: 751512,
    ids: [751512, 130],
  },
  {
    title: i18n.t("Sparewheel"),
    icon: "icon-spare-wheel",
    id: 754903,
    ids: [754903, 25],
  },
  {
    title: i18n.t("Tow bar"),
    icon: "icon-tow-bar",
    id: 751801,
    ids: [751801, 751802, 20],
  },
  {
    title: i18n.t("Xenon"),
    icon: "icon-xenon",
    id: 754401,
    ids: [754401, 754402, 39],
  },
];
