import React from "react";

import { withConfig } from "../../../../config/index";
import { PrintButtons } from "../../../../features/print";
import {
  DescriptionLabel,
  Expectation,
  VehicleDamageBadge,
  VehicleVatRegimeBadge,
} from "../../../index";
import { SpecificationsTop3 } from "./SpecificationsTop3";

const Summary = ({ vehicle, listVehicleId, config }) => {
  const {
    make,
    model,
    version,
    main_company_name,
    seller_first_name,
    seller_last_name,
    provisional_sale,
    expected_disclose,
    damages,
    vat_regime,
    description,
    description_plus,
    list_number,
  } = vehicle;
  const {
    vehicle_state_expectation,
    vehicle_state_damage_counter,
    vehicle_print,
  } = config;

  return (
    <div className="bg-white p-3 shadow-md">
      <div className="flex justify-between">
        <div className="truncate">
          <h5 className="h5 truncate uppercase text-highlightColor">
            {list_number && list_number > 0
              ? "#" + String("000" + list_number).slice(-3)
              : ""}{" "}
            {make} {model}
          </h5>
        </div>
        {vehicle_print && (
          <div className="hidden sm:block">
            <PrintButtons listVehicleId={listVehicleId} />
          </div>
        )}
      </div>

      <span className=" text-[12px] uppercase text-[#999]">{version}</span>

      <SpecificationsTop3
        vehicle={vehicle}
        fields={
          provisional_sale && !expected_disclose
            ? ["EXPECTED_FROM", "EXPECTED_KM_FROM_TO", "POWER_KW_PK"]
            : ["KM", "FIRST_REGISTRATION", "POWER_KW_PK"]
        }
      />

      {main_company_name && seller_first_name && seller_last_name && (
        <div>
          {main_company_name} - {seller_first_name} {seller_last_name}
        </div>
      )}

      {vehicle_state_expectation && <Expectation {...vehicle} />}

      <div className="my-1 flex gap-1">
        {vehicle.list_index && vehicle.list_index > 0 && (
          <span className="u-label badge badge-normal !rounded-[3px]">
            #{String("000" + vehicle.list_index).slice(-3)}
          </span>
        )}

        <VehicleVatRegimeBadge vat_regime={vat_regime} />
        {vehicle_state_damage_counter && (
          <VehicleDamageBadge damages={damages} />
        )}

        {vehicle.list_short_name && (
          <span className="u-label badge badge-normal !rounded-[3px]">
            {vehicle.list_short_name}
          </span>
        )}
      </div>

      <div className="flex flex-col space-y-1">
        <div className="w-full empty:hidden">
          <DescriptionLabel description={description_plus} />
        </div>
        <div className="w-full empty:hidden">
          <DescriptionLabel description={description} important />
        </div>
      </div>
    </div>
  );
};

const ConnectedComponent = withConfig(Summary);

export { ConnectedComponent as Summary };
