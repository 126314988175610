import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { contentTypes } from "./types";

export const fetchContent = (id) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.CONTENT}${id}/`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: false,
        },
        types: [
          {
            type: contentTypes.FETCH_REQUEST,
            payload: {
              id,
            },
          },
          {
            type: contentTypes.FETCH_SUCCESS,
            payload: (action, state, res) => onFetchSuccess(dispatch, res, id),
          },
          {
            type: contentTypes.FETCH_FAILURE,
            payload: {
              id,
            },
          },
        ],
      },
    });
  };
};

const onFetchSuccess = (dispatch, res, id) => {
  return res.json().then((data) => {
    if (data.content.length > 0) {
      return {
        id,
        content: decodeURIComponent(data.content[0].content),
      };
    } else {
      return {
        id,
        content: "No content found",
      };
    }
  });
};
