import { sortTypes } from "./types";

export const toggleSort = (id) => ({
  type: sortTypes.SORT_TOGGLE,
  payload: {
    id,
  },
});

export const updateSort = (id, sorter) => {
  return (dispatch) => {
    return dispatch({
      type: sortTypes.SORT_UPDATE,
      payload: {
        id,
        sorter,
      },
    });
  };
};

export const sortCleanUp = (invalidIds) => {
  return (dispatch) => {
    return dispatch({
      type: sortTypes.CLEANUP,
      payload: {
        invalidIds,
      },
    });
  };
};
