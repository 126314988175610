import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";

import { withConfig } from "../../config";
import { useNavigateHelper } from "../../hooks/useNavigateHelper";

const GDPR = (props) => {
  const { config } = props;
  const gdpr_cookie = `${config.name}-gdpr-cookie`;
  const { t } = useTranslation();
  const { goToPrivacy } = useNavigateHelper();

  if (Cookies.get(gdpr_cookie) !== undefined) {
    return null;
  }

  return (
    <CookieConsent
      debug={false}
      location="bottom"
      buttonText={t("Accept cookies")}
      cookieName={gdpr_cookie}
      style={{ background: "#2B373B" }}
      buttonStyle={{
        backgroundColor: "white",
        color: "black",
        fontSize: "13px",
        margin: 30,
      }}
      contentStyle={{ margin: 30 }}
      expires={150}
    >
      {t(
        "This website uses cookies. We use cookies to make our website work at the best, improving your online experience."
      )}
      <br />
      {t(
        "By continuing to navigate on this site, you agree to the use of cookies."
      )}{" "}
      <span className="g-text-underline cursor-pointer" onClick={goToPrivacy}>
        {t("Learn more")}
      </span>
    </CookieConsent>
  );
};

export const GDPRWithConfig = withConfig(GDPR);
