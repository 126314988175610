import { useEffect, useState } from "react";

import config from "../config/config.json";
import { socket } from "../features/socket";

const { socket_io_url } = config;

/**
 * A custom React hook for managing Socket.IO connections.
 *
 * @param {boolean} authenticated - Indicates whether the user is authenticated.
 * @returns {Object} - An object containing information about the Socket.IO connection.
 * @property {boolean} shouldConnectSocketIO - Indicates if the socket should be connected (true if authenticated and socket_io_url is defined).
 * @property {boolean} isSocketConnected - Indicates if the socket is currently connected.
 * @property {SocketIOClient.Socket|null} socket - The Socket.IO client socket instance or null if not required.
 */
export const useSocketIO = (authenticated) => {
  const initSocketState = socket ? socket.connected : false;
  const [isSocketConnected, setIsSocketConnected] = useState(initSocketState);

  const shouldConnectSocketIO = Boolean(
    authenticated && socket_io_url && socket_io_url !== ""
  );

  useEffect(() => {
    if (shouldConnectSocketIO) {
      if (!isSocketConnected) {
        socket.connect();
        setIsSocketConnected(true);
      }
    }
    return () => {
      if (socket) {
        setIsSocketConnected(false);
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldConnectSocketIO]);

  return {
    shouldConnectSocketIO,
    isSocketConnected,
    socket,
  };
};
