import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import { Button } from "../../components/web/Button";
import { DatePresetDropdown } from "./DatePresetDropdown";
import { DateRangePickerFields } from "./DateRangePickerFields";

const useDateRange = (initialValues) => {
  const [startDate, setStartDate] = useState(initialValues[0] || "");
  const [endDate, setEndDate] = useState(initialValues[1] || "");

  useEffect(() => {
    if (initialValues[0] === "" && initialValues[1] === "") {
      setStartDate("");
      setEndDate("");
    }
  }, [initialValues]);

  const result = {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isCancelDisabled: startDate === "" && endDate === "",
  };

  return result;
};

export const DateRangePicker = ({
  dateValues,
  onChange,
  onCancel,
  presets,
}) => {
  const { t } = useTranslation();
  const { startDate, endDate, setStartDate, setEndDate, isCancelDisabled } =
    useDateRange(dateValues);

  const handleStartDateChange = useCallback(
    (e) => {
      const newStartDate = e.target.value;
      setStartDate(newStartDate);
      if (newStartDate !== "" && endDate !== "") {
        onChange([newStartDate, endDate]);
      }
    },
    [setStartDate, endDate, onChange]
  );

  const handleEndDateChange = useCallback(
    (e) => {
      const newEndDate = e.target.value;
      setEndDate(newEndDate);
      if (startDate !== "" && newEndDate !== "") {
        onChange([startDate, newEndDate]);
      }
    },
    [setEndDate, startDate, onChange]
  );

  const handleCancel = useCallback(() => {
    setStartDate("");
    setEndDate("");
    onCancel();
  }, [onCancel, setStartDate, setEndDate]);

  const handlePresetChange = useCallback(
    (value) => {
      setStartDate(value[0]);
      setEndDate(value[1]);
      onChange([value[0], value[1]]);
    },
    [setStartDate, setEndDate, onChange]
  );

  return (
    <div className="flex h-[30px] items-center space-y-1">
      <DateRangePickerFields
        startDate={startDate}
        endDate={endDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
      />
      <DatePresetDropdown>
        <ul className="space-y-2">
          {presets.map((preset, index) => (
            <li
              className="cursor-pointer text-sm capitalize text-[#777] hover:text-primary"
              key={index}
              onClick={() => handlePresetChange(preset.value)}
            >
              {t(preset.label)}
            </li>
          ))}
        </ul>
      </DatePresetDropdown>
      <div className="flex items-center space-x-1">
        <Button
          onClick={handleCancel}
          disabled={isCancelDisabled}
          classNames="h-[26px]"
        >
          <i
            className={clsx(
              "fa fa-times text-white",
              isCancelDisabled ? "cursor-default" : "cursor-pointer"
            )}
          ></i>
        </Button>
      </div>
    </div>
  );
};
