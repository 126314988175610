import React, { useState } from "react";
import { connect } from "react-redux";

import { confirmBiddings, toggleConfirm } from "../actions";
import * as selectors from "../selectors";
import { CartTermsConditions } from "./CartTermsConditions";
import { CartVehicles } from "./CartVehicles";
import { Result } from "./Result";

import { map } from "lodash";

const Confirm = (props) => {
  const [step, setStep] = useState(1);
  const [stateVehicles, setStateVehicles] = useState();
  const { listId, toggleConfirm, confirmBiddings, vehicles } = props;

  const onConfirm = () => {
    confirmBiddings(listId, map(stateVehicles, "vehicle_id").join());
    setStep(3);
  };

  const onSelectionEnd = () => {
    setStateVehicles(vehicles);
    setStep(2);
  };

  switch (step) {
    case 1:
      return (
        <CartVehicles
          listId={listId}
          onSelectionEnd={onSelectionEnd}
          onCancel={() => toggleConfirm(false)}
        />
      );
    case 2:
      return (
        <CartTermsConditions
          onConfirm={onConfirm}
          onCancel={() => toggleConfirm(false)}
        />
      );
    case 3:
      return <Result />;
    default:
      return (
        <CartVehicles
          listId={listId}
          onSelectionEnd={onSelectionEnd}
          onCancel={() => toggleConfirm(false)}
        />
      );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    vehicles: selectors.getVehiclesToConfirm(state, ownProps.listId),
    loading: selectors.getLoading(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  toggleConfirm,
  confirmBiddings,
})(Confirm);

export { ConnectedComponent as Confirm };
