import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { LoadingContainer, LoadingIndicator } from "../../../components";
import {
  createFilter,
  deleteFilter,
  filtersCompaniesFetch,
  filtersFetch,
  filtersMakesFetch,
  selectFilter,
  updateFilter,
  updateSelectedFilterData,
} from "../actions";
import { createNewFilter } from "../functions/filter";
import * as selectors from "../selectors";
import { FilterDetailsHeader } from "./FilterDetailsHeader";
import { FilterForm } from "./FilterForm";
import { FilterModal } from "./FilterModal";
import { FiltersHeader } from "./FiltersHeader";
import { FiltersList } from "./FiltersList";

const FiltersConfiguration = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    filters,
    selectedFilter,
    makes,
    companies,
    filtersLoading,
    filterLoading,
    filtersCount,
    selectFilter,
  } = props;

  const handleCreateNew = () => {
    const { makes } = props;
    handleSelect(createNewFilter(makes));
  };

  const handleSelect = (filter) => {
    if (window.innerWidth < 992) {
      setModalVisible(true);
    }
    selectFilter(filter);
  };

  const toggleModal = () => {
    setModalVisible((prevState) => !prevState);
  };

  const handleSave = () => {
    const { selectedFilter, createFilter, updateFilter } = props;

    if (selectedFilter) {
      if (selectedFilter.id === -1) {
        createFilter(selectedFilter);
      } else {
        updateFilter(selectedFilter);
      }
    }
  };

  const handleDelete = () => {
    const { selectedFilter, deleteFilter } = props;

    if (selectedFilter) {
      deleteFilter(selectedFilter);
    }
    if (modalVisible) {
      toggleModal();
    }
  };

  const handleInput = (filter) => {
    const { updateSelectedFilterData } = props;
    updateSelectedFilterData(filter);
  };

  // Initial filter data fetch
  useEffect(() => {
    props.filtersFetch();
    props.filtersCompaniesFetch();
    props.filtersMakesFetch();
  }, []);

  // Select the first filter from the list when filters count changes
  useEffect(() => {
    if (selectedFilter === null && filtersCount > 0) {
      selectFilter(filters[Object.keys(filters).at(0)]);
    }
  }, [filtersCount]);

  return (
    <div className="flex space-x-2">
      <div className="w-full space-y-4 md:w-1/3">
        <div className="sticky top-0 z-10 md:top-[70px]">
          <FiltersHeader onCreateNew={handleCreateNew} />
          <LoadingIndicator loading={filtersLoading} />
        </div>
        <FiltersList
          filters={filters}
          selectedFilter={selectedFilter}
          onSelectFilter={handleSelect}
          allMakesCount={makes.length}
          allCompaniesCount={companies.length}
        />
      </div>

      <div className="xl:w-1/9 hidden w-full space-y-2 md:block">
        <div className="sticky top-0 z-10 md:top-[70px]">
          <FilterDetailsHeader
            saveEnabled={
              selectedFilter !== undefined && selectedFilter !== null
            }
            deleteEnabled={
              selectedFilter !== undefined &&
              selectedFilter !== null &&
              selectedFilter.id > 0
            }
            onSave={handleSave}
            onDelete={handleDelete}
          />
        </div>

        <div className="bg-white">
          <LoadingContainer loading={filterLoading}>
            <FilterForm
              filter={selectedFilter}
              allCompanies={companies}
              allMakes={makes}
              onChange={handleInput}
            />
          </LoadingContainer>
        </div>
      </div>

      <FilterModal
        {...props}
        visible={modalVisible}
        onChange={handleInput}
        onCancel={() => {
          setModalVisible(false);
        }}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: selectors.getFilters(state),
    selectedFilter: selectors.getSelectedFilter(state),
    filtersLoading: selectors.getLoading(state),
    filterLoading: selectors.getSelectedFilterLoading(state),
    filtersCount: selectors.getFiltersCount(state),
    makes: selectors.getMakes(state),
    companies: selectors.getCompanies(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  filtersFetch,
  filtersCompaniesFetch,
  filtersMakesFetch,
  selectFilter,
  updateSelectedFilterData,
  createFilter,
  updateFilter,
  deleteFilter,
})(FiltersConfiguration);

export { ConnectedComponent as FiltersConfiguration };
