import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { entityActions } from "../../entities/actions";
import { mapFilterForRequest } from "../listvehicles-filter/mappers";
import { getCriteria } from "../listvehicles-filter/selectors";
import { mapSortForRequest } from "../listvehicles-sort/mappers";
import { getActiveSorter } from "../listvehicles-sort/selectors";
import { mapListBasket } from "./mappers";
import { normalizeListBasket } from "./normalizers";
import { getLoading, getMore, getOffset } from "./selectors";
import { basketTypes } from "./types";

import _ from "lodash";

const LIMIT = 10;

export const basketFetch = (key, states, limit, offset, searchTerm) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.BASKET}?states=${states}&limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: basketTypes.FETCH_REQUEST,
            payload: {
              key,
              searchTerm,
              offset,
            },
          },
          {
            type: basketTypes.FETCH_SUCCESS,
            payload: (action, state, res) =>
              onSuccess(dispatch, res, key, states, limit, offset),
          },
          {
            type: basketTypes.FETCH_FAILURE,
            payload: (action, state, res) => {
              return {
                key,
                message: `${res.status} - ${res.statusText}`,
              };
            },
          },
        ],
      },
    });
  };
};

export const basketFetchWebTest = (key, nextBatch = false) => {
  return (dispatch, getState) => {
    const limit = LIMIT;
    const offset = nextBatch ? getOffset(getState(), key) + limit : 0;
    const filter = getCriteria(getState(), -1);
    const sorter = getActiveSorter(getState(), -1);
    const states = "";
    const searchTerm = "";

    const url = `sales/api/v2/basket/?limit=${limit}&offset=${offset}&filter=${mapFilterForRequest(
      filter
    )}&sort=${mapSortForRequest(sorter)}`;

    return dispatch({
      [RSAA]: {
        endpoint: url,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: basketTypes.FETCH_REQUEST,
            payload: {
              key,
              searchTerm,
              offset,
            },
          },
          {
            type: basketTypes.FETCH_SUCCESS,
            payload: (action, state, res) =>
              onSuccess(dispatch, res, key, states, limit, offset),
          },
          {
            type: basketTypes.FETCH_FAILURE,
            payload: (action, state, res) => {
              return {
                key,
                message: `${res.status} - ${res.statusText}`,
              };
            },
          },
        ],
      },
    });
  };
};

const onSuccess = (dispatch, res, key, states, limit, offset) => {
  let totalCount = res.headers.get("RecordCount");

  return res.json().then((data) => {
    data = normalizeListBasket(_.map(data, mapListBasket));

    dispatch(entityActions.updateEntities(data.entities));
    /*
         return {
         key,
         states,
         limit,
         offset,
         totalCount: 0,
         data: [],
         };*/

    return {
      key,
      states,
      limit,
      offset,
      totalCount,
      data: data.result,
    };
  });
};

const shouldFetchMoreBasket = (state, key) => {
  const loading = getLoading(state, key);
  const more = getMore(state, key);

  if (loading || !more) {
    return false;
  }
  return true;
};

export const basketFetchMore = (key) => {
  return (dispatch, getState) => {
    if (shouldFetchMoreBasket(getState(), key)) {
      return dispatch(basketFetchWebTest(key, true));
    }
  };
};

export const basketCleanUp = (invalidKeys) => {
  return (dispatch) => {
    return dispatch({
      type: basketTypes.CLEANUP,
      payload: {
        invalidKeys,
      },
    });
  };
};
