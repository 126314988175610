import React from "react";

import { config } from "./index";

const withConfig = (WrappedComponent) => {
  return (props) => {
    return <WrappedComponent {...props} config={config} />;
  };
};

export default withConfig;
