import { entityTypes } from "./types";

const updateEntities = (entities, cleanup = false) => {
  return {
    type: entityTypes.UPDATE_ENTITIES,
    payload: {
      entities,
      cleanup,
    },
  };
};

const clearEntities = (invalidListIds, invalidVehicleIds) => {
  return {
    type: entityTypes.CLEAR_ENTITIES,
    payload: {
      invalidListIds,
      invalidVehicleIds,
    },
  };
};

export const entityActions = {
  updateEntities,
  clearEntities,
};
