import React from "react";

import { SpecificationHelpers } from "../../../index";

import { capitalize } from "lodash";

export const Specifications = ({ vehicle, fields }) => (
  <div className="grid grid-cols-1 gap-x-3 gap-y-1 text-[#777] sm:grid-cols-2">
    {fields.map((field, i_spec) => {
      const data = SpecificationHelpers.getFieldData(field, vehicle);
      const label = SpecificationHelpers.getFieldLabel(field, vehicle);

      return (
        <div
          className="flex justify-between truncate"
          key={`specification-${i_spec}`}
        >
          <label>{capitalize(label)}</label>
          <span className="text-right font-medium">{data}</span>
        </div>
      );
    })}
  </div>
);
