export const getColleagues = (state) => state.colleagues.byId;
export const getColleague = (state, id) => getColleagues(state)[id];
export const getLoading = (state) => state.colleagues.loading;
export const getLockLoading = (state, id) =>
  state.colleagues.lock && state.colleagues.lock[id]
    ? state.colleagues.lock[id].loading
      ? state.colleagues.lock[id].loading
      : false
    : false;
export const getError = (state) => state.colleagues.error;
