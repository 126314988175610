import React from "react";
import { useTranslation } from "react-i18next";

import { Template } from "../../../layouts/Default";
import { InfoContainer } from "../InfoContainer";

import * as Sentry from "@sentry/react";

const Fallback = () => {
  const { t } = useTranslation();
  return (
    <Template>
      <div className="bg-white p-20 text-center ">
        <InfoContainer container={true} icon="fa fa-wrench" />
        <p className="text-xl text-primary">
          {t("Something went wrong")}
          <br />
          {t("Please try again")}
        </p>
      </div>
    </Template>
  );
};

export const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={Fallback}>{children}</Sentry.ErrorBoundary>
  );
};
