// TODO: Remove after refactoring
// const pushToHistory = (path) => {
//   history.push({
//     pathname: path,
//     state: { routed: true },
//   });
// };

// TODO: Remove after refactoring
// const replaceHistory = (path) => {
//   history.push({
//     pathname: path,
//     state: { routed: false },
//   });
// };

export const routes = {
  FRONTEND: "/frontend",
  DASHBOARD: "/dashboard",
  FAQ: "/faq",
  FILTERS: "/filters",
  FORGOT_PASSWORD: "/forgot-password/:uniqueId?",
  FORGOT_PASSWORD_NO_ID: "/forgot-password/",
  HISTORY: "/history",
  LIST_VEHICLE: "/list-vehicle/:listVehicleId",
  LIST: "/list/:listId",
  LOGIN: "/login",
  OFFER: "/offer/:offerId",
  PRIVACY: "/privacy",
  PROFILE: "/profile",
  REGISTER: "/register",
  REGISTRATION_WIZARD: "/registration-wizard",
  REGISTRATION: "/registration",
  ROOT: "/",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  VEHICLE: "/vehicle/:vehicleId/:search?",
  UNSUBSCRIBE: "/unsubscribe",
  USER_NOT_ALLOWED: "/403",
  STATE_LIST_VEHICLE: "/state/:listVehicleId",
  ACTIVATE: "/activate/:registrationId/:uniqueId",
  INVITE: "/invite/:inviteId",
  SUPPLIERS: "/suppliers",
};

// TODO: remove once refactored in useNavigateHelper
export const goToPrivacy = () => {
  console.log("Refactor this navigation");
};
export const goBack = () => {
  console.log("Refactor this navigation");
};
export const dashboard = () => {
  console.log("Refactor this navigation");
};
export const goToVehicleDetails = () => {
  console.log("Refactor this navigation");
};
export const goToHistory = () => {
  console.log("Refactor this navigation");
};
export const GoToOffer = () => {
  console.log("Refactor this navigation");
};
export const login = () => {
  console.log("Refactor this navigation");
};
export const reloadCurrentPage = () => {};
