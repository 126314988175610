import React, { useState } from "react";

import clsx from "clsx";

export const Tooltip = ({ message, children, position = "top" }) => {
  const [show, setShow] = useState(false);

  const tooltipPosition = {
    top: "bottom-full",
    "top-left": "bottom-full right-full",
    "top-right": "bottom-full left-full",
    bottom: "top-full",
    "bottom-left": "top-full right-full",
    "bottom-right": "top-full left-full",
    left: "right-full",
    right: "left-full",
  };

  return (
    <div className="group relative flex flex-col items-center">
      <div
        className="flex justify-center"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
        {show && (
          <div
            className={clsx(
              "absolute flex flex-col items-center whitespace-nowrap group-hover:flex",
              tooltipPosition[position]
            )}
          >
            <span className="relative z-[100] min-w-min whitespace-break-spaces rounded-md bg-gray-600 p-2 text-left text-xs leading-none text-white shadow-lg">
              {message}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
