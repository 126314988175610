import { useEffect, useRef } from "react";

/**
 * A custom hook enabling proper use of setInterval in React functional components.
 * This hook takes a callback function and a delay time (in milliseconds) for executing the callback.
 * The interval can be paused by setting the delay to null.
 *
 * @param {Function} callback - The callback function to be executed at each interval.
 * @param {number|null} delay - The delay time in milliseconds for the interval. Set to null to pause the interval.
 *
 * @example
 * // Usage example:
 * const [delay, setDelay] = useState(1000);
 * const [isRunning, setIsRunning] = useState(true);
 *
 * useInterval(() => {
 *   callback();
 * }, isRunning ? delay : null);
 *
 * More details can be found at https://overreacted.io/making-setinterval-declarative-with-react-hooks/.
 */
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
