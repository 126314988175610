import React from "react";
import { useTranslation } from "react-i18next";

import { compose } from "redux";
import { Field, reduxForm } from "redux-form";

import { FormFields } from "../../../../../components";
import { withConfig } from "../../../../../config";
import { personalInfoFields } from "../WizardStep4";

import { includes } from "lodash";

const { Toggle } = FormFields;

const SettingsForm = ({ handleSubmit, config }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      {/*<Field*/}
      {/*  name={personalInfoFields.SMS_ACTIVE}*/}
      {/*  component={CheckBox}*/}
      {/*  label={t("New list / by SMS notifications")}*/}
      {/*/>*/}

      <Field
        name={personalInfoFields.CAR_BY_CAR_NOTIFICATION}
        component={Toggle}
        label={t("Car by Car / by smartphone notification")}
      />

      <Field
        name={personalInfoFields.CAR_BY_CAR_MAIL}
        component={Toggle}
        label={t("Car by Car / by e-mail")}
      />

      <Field
        name={personalInfoFields.DAILY_DIGEST_MAIL}
        component={Toggle}
        label={t("Daily digest / by e-mail")}
      />

      {!includes(config.excluded_settings, personalInfoFields.OFFERS_MAIL) && (
        <Field
          name={personalInfoFields.OFFERS_MAIL}
          component={Toggle}
          label={t("Bidding confirmation / by e-mail")}
        />
      )}

      {!includes(config.excluded_settings, personalInfoFields.WON_MAIL) && (
        <Field
          name={personalInfoFields.WON_MAIL}
          component={Toggle}
          label={t("Highest bid / by e-mail")}
        />
      )}
    </form>
  );
};

const ConnectedComponent = compose(
  withConfig,
  reduxForm({
    form: "Registration-Settings",
    destroyOnUnmount: false,
  })
)(SettingsForm);

export { ConnectedComponent as SettingsForm };
