import React from "react";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "../../../../../../components/index";
import { VehicleItem } from "../../VehicleItem";

export const VehiclesList = ({
  vehicleIds,
  onVehicleClick,
  selectedId,
  loading,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className="flex h-[85vh] items-center justify-center bg-white">
        <LoadingIndicator
          loading={loading}
          text={t("Loading offer vehicles")}
        />
      </div>
    );
  }

  return (
    <div className="space-y-3">
      {vehicleIds.map((id) => (
        <VehicleItem
          key={id}
          id={id}
          onClick={onVehicleClick}
          selected={selectedId === id}
        />
      ))}
    </div>
  );
};
