import { isArray, mergeWith } from "lodash";

// const developmentCustomer =  process.env.REACT_APP_CUSTOMER || 'dieteren';

export let config = {};

export const createConfig = (clearBaseUrl = false) => {
  const defaultConfig = require("./default.json");
  const customerConfig = require("./config.json");

  function customizer(objValue, srcValue) {
    if (isArray(objValue)) {
      return srcValue;
    }
  }

  config = mergeWith(defaultConfig, customerConfig, customizer);

  // Only for web... for cors development shit
  config.base_url = "";
};

// export const getCustomerConfig = () => {
//     if(process.env.NODE_ENV !== "development"){
//         return require('./config.json');
//     }
//     else {
//         let devConfigJson = require('../../../fastlane/branding/' + developmentCustomer + '/app/config.json');
//         let packageJson = require('../../package.json');
//         devConfigJson.base_url = packageJson.proxy;
//         return devConfigJson;
//     }
// };

export { default as withConfig } from "./withConfig";
