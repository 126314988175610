import React from "react";
import { useParams } from "react-router-dom";

import { Template } from "../components";
import { Invite } from "../features/colleagues";

export const InviteContainer = () => {
  const params = useParams();
  return (
    <Template>
      <div className="bg-white">
        <Invite inviteId={params.inviteId} />
      </div>
    </Template>
  );
};
