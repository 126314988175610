import React from "react";
import { useTranslation } from "react-i18next";

export const WizardButtons = ({ currentStep, onNext, onPrevious }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-end bg-white pb-5 pr-3 pt-5">
      {currentStep > 2 && (
        <button
          onClick={onPrevious}
          className="btn btn-primary w-[100px] p-2 text-sm"
        >
          <i className="fa fa-chevron-left g-valign-middle mr-1 text-sm"></i>
          {t("Previous")}
        </button>
      )}

      <button
        type="button"
        onClick={onNext}
        className="btn btn-primary ml-2 w-[100px] p-2 text-sm"
      >
        {t("Next")}
        <i className="fa fa-chevron-right g-valign-middle ml-1 text-sm"></i>
      </button>
    </div>
  );
};
