import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

// import { basketFetchWebTest } from "../../basket/basketSlice";
import { Template } from "../../../components";
import { basketFetchWebTest } from "../../basket/actions";
import { historyCleanUp } from "../actions";
import * as selectors from "../selectors";
import { HistoryState } from "./HistoryState";
import { HistoryVehicles } from "./HistoryVehicles";

const HistoryAndState = (props) => {
  const { selectedVehicleId, vehicleStateVisible } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    // using regular redux
    dispatch(basketFetchWebTest("ALL"));
    // using slice
    // dispatch(basketFetchWebTest({ key: "ALL" }));
  }, []);

  return vehicleStateVisible ? (
    <Template isFullWidth={false}>
      <HistoryState listVehicleId={selectedVehicleId} />
    </Template>
  ) : (
    <Template isFullWidth={true}>
      <HistoryVehicles selectedVehicleId={selectedVehicleId} />
    </Template>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedVehicleId: selectors.getSelectedVehicleId(state),
    vehicleStateVisible: selectors.getVehicleStateVisible(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  basketFetchWebTest,
  historyCleanUp,
})(HistoryAndState);

export { ConnectedComponent as HistoryAndState };
