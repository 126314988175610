import React from "react";
import { useTranslation } from "react-i18next";

import { withConfig } from "../../../config";

const NoLists = ({ config }) => {
  const { t } = useTranslation();
  return (
    <div className="flex min-h-[250px] flex-col justify-center">
      <div className="mt-10 flex justify-center">
        <i className="fa fa-list text-[180px] text-primary" />
      </div>

      <div className="mt-5 flex justify-center">
        <h5 className="mb-2 pt-5 font-extrabold text-highlightColor">
          {t("No lists currently available.")}
        </h5>
      </div>
      {config.dealerships && (
        <div className="flex justify-center px-10 pb-20 pt-5 text-center">
          {t(
            "You can increase the number of cars offered by contacting multiple dealers."
          )}
          <br />
          {t(
            "To do this, contact the desired dealers so that they can activate you within their network."
          )}
          <br />
          {t(
            "After activation by the dealer, the cars offered will be available for you. "
          )}
        </div>
      )}
    </div>
  );
};

const ConnectedComponent = withConfig(NoLists);

export { ConnectedComponent as NoLists };
