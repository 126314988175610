import React, { useState } from "react";
import Iframe from "react-iframe";

import { LoadingIndicator } from "../../../components";

export const Video = (props) => {
  const { video, locale, windowWidth } = props;
  const [loading, setLoading] = useState(true);

  return (
    <div className="py-3">
      {loading && (
        <div className="flex w-full justify-center">
          <LoadingIndicator loading={loading} />
        </div>
      )}
      <div className="flex w-full justify-center">
        <Iframe
          url={video.urls[locale] ? video.urls[locale] : video.urls["en"]}
          width={windowWidth > 800 ? "640px" : "440px"}
          height={windowWidth > 800 ? "360px" : "260px"}
          id={video.key}
          display="initial"
          position="relative"
          allowFullScreen
          onLoad={() => {
            setLoading(false);
          }}
        />
      </div>
    </div>
  );
};
