import { colleagueTypes } from "./types";

const colleagues = (
  state = { loading: false, error: "", byId: {}, lock: {} },
  action
) => {
  switch (action.type) {
    case colleagueTypes.FETCH_REQUEST:
    case colleagueTypes.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case colleagueTypes.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        byId: action.payload,
      };
    case colleagueTypes.FETCH_FAILURE:
    case colleagueTypes.CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    //   TODO: investigate why error is returned on success
    case colleagueTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case colleagueTypes.UPDATE_STATE:
      return {
        ...state,
        byId: byId(state.byId, action),
      };
    case colleagueTypes.LOCK_REQUEST:
    case colleagueTypes.LOCK_SUCCESS:
    case colleagueTypes.LOCK_FAILURE:
    case colleagueTypes.UNLOCK_REQUEST:
    case colleagueTypes.UNLOCK_SUCCESS:
    case colleagueTypes.UNLOCK_FAILURE:
      return {
        ...state,
        lock: lock(state.lock, action),
      };
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case colleagueTypes.UPDATE_STATE:
      return {
        ...state,
        [action.payload.colleagueId]: {
          ...state[action.payload.colleagueId],
          state: action.payload.state,
        },
      };
    default:
      return state;
  }
};

const lock = (state = {}, action) => {
  switch (action.type) {
    case colleagueTypes.LOCK_REQUEST:
    case colleagueTypes.UNLOCK_REQUEST:
      return {
        ...state,
        [action.payload.id]: {
          loading: true,
        },
      };
    case colleagueTypes.LOCK_SUCCESS:
    case colleagueTypes.LOCK_FAILURE:
    case colleagueTypes.UNLOCK_SUCCESS:
    case colleagueTypes.UNLOCK_FAILURE:
      return {
        ...state,
        [action.payload.id]: {
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default colleagues;
