import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

import { Tooltip } from "../../../components/web/Tooltip";
import { formatPrice } from "../../../helpers/functions";
import style from "../../../styles";
import { InterestThumb } from "../../listvehicles-no-interest/_web/InterestThumb";
import { BidConfirmModal } from "./BidConfirmModal";

const NormalDeviceField = ({
  vehicle_interest,
  price,
  bgColor,
  brdColor,
  listVehicleId,
  comment,
  vehicle_bidding_delete,
  textColor,
  onDelete,
  hoverClass,
  statePrice,
  fixedPrice,
  priceInputRef,
  placeholder,
  setStatePrice,
  inputSelected,
  setInputSelected,
  onFocus,
  bidConfirmModalVisible,
  vehicle_bidding_cart,
  setPriceUnconfirmed,
  divElementRef,
  onBlur,
  processBid,
  priceUnconfirmed,
  confirmIcon,
}) => {
  const showInterestThumb = vehicle_interest && price <= 0;
  const showTooltip = comment && comment !== "" && !vehicle_bidding_delete;
  const showDeleteButton = vehicle_bidding_delete && price > 0;

  return (
    <>
      {showInterestThumb && (
        <div
          className="flex w-2/12 cursor-pointer items-center justify-center border-r"
          style={{
            backgroundColor: bgColor,
            borderRightColor: brdColor,
          }}
        >
          <InterestThumb
            listVehicleId={listVehicleId}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}

      {showTooltip && (
        <div
          className="flex w-2/12 cursor-pointer items-center justify-center border-r text-center"
          onClick={(e) => e.stopPropagation()}
          style={{
            backgroundColor: bgColor,
            borderRightColor: brdColor,
          }}
        >
          <div>
            <Tooltip message={comment}>
              <i
                className="fa fa-commenting text-base"
                style={{ color: textColor }}
              ></i>
            </Tooltip>
          </div>
        </div>
      )}

      {showDeleteButton && (
        <div
          className="flex w-2/12 cursor-pointer items-center justify-center border-r text-center"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          style={{
            backgroundColor: bgColor,
            borderRightColor: brdColor,
          }}
        >
          <i
            className={`fa fa-remove color-btn-icon text-[16px] ${hoverClass}`}
          ></i>
        </div>
      )}

      <div className="w-8/12 flex-1">
        <NumericFormat
          value={statePrice}
          disabled={fixedPrice}
          getInputRef={(el) => (priceInputRef.current = el)}
          placeholder={placeholder}
          thousandSeparator={" "}
          prefix={"€ "}
          onValueChange={(values) => setStatePrice(values.value)}
          allowNegative={false}
          onSelect={(e) => {
            if (!inputSelected) {
              setInputSelected(true);
            }

            if (onFocus) {
              onFocus();
            }
          }}
          onBlur={() => {
            if (inputSelected) {
              setInputSelected(false);
            }

            // TODO: Legacy comment below
            /* fix when leaves the control, but not clicked to confirm the bid ==> reset to initial price */
            if (statePrice !== price) {
              setTimeout(() => {
                if (!bidConfirmModalVisible && !vehicle_bidding_cart) {
                  setPriceUnconfirmed(true);
                }
              }, 200);
            }

            if (onBlur) {
              onBlur();
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              divElementRef.current.focus();
              processBid();
            }
          }}
          onFocus={onFocus}
          style={{
            border: "none",
            backgroundColor: bgColor,
            color: priceUnconfirmed ? "red" : textColor,
            outline: "none",
            outlineOffset: 0,
            textAlign: "right",
            minWidth: "100%",
            maxWidth: "100%",
            height: "100%",
            paddingRight: 10,
          }}
          onClick={(e) => {
            // Prevent the click event from propagating to the div
            e.stopPropagation();
          }}
        />
      </div>
      <div
        ref={divElementRef}
        className="flex w-2/12 cursor-pointer items-center justify-center border-l text-center"
        onClick={(e) => {
          e.stopPropagation();
          processBid();
        }}
        style={{
          backgroundColor: bgColor,
          borderLeftColor: brdColor,
        }}
      >
        <i
          className={`${confirmIcon} color-btn-icon text-[16px] ${hoverClass}`}
        ></i>
      </div>
    </>
  );
};

const SmallDeviceField = ({
  offered,
  inBasket,
  vehicle_bidding_delete,
  onDelete,
  bgColor,
  brdColor,
  hoverClass,
  textColor,
  bidConfirmModalVisible,
  setBidConfirmModalVisible,
  statePrice,
  placeholder,
}) => {
  const showCurrencyIcon = !offered && !inBasket;
  const showDeleteIcon = vehicle_bidding_delete && inBasket;

  return (
    <>
      {showCurrencyIcon && (
        <div
          className="flex w-3/12 items-center justify-center"
          style={{ backgroundColor: "lightgrey" }}
        >
          <i className="fa fa-euro color-btn-icon text-[16px] text-white" />
        </div>
      )}

      {showDeleteIcon && (
        <div
          className="flex w-3/12 cursor-pointer items-center justify-center border-r text-center"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          style={{
            backgroundColor: bgColor,
            borderRightColor: brdColor,
          }}
        >
          <i
            className={`fa fa-remove color-btn-icon text-[16px] ${hoverClass}`}
            style={{ color: textColor }}
          ></i>
        </div>
      )}

      <div
        className="flex w-full items-center justify-end p-1 text-[14px]"
        style={{
          backgroundColor: bgColor,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (!bidConfirmModalVisible) {
            setBidConfirmModalVisible(true);
          }
        }}
      >
        <p style={{ color: textColor }}>
          {statePrice ? formatPrice(statePrice) : placeholder}
        </p>
      </div>
    </>
  );
};

export const BiddingFixedTender = (props) => {
  const {
    basket_state,
    biddingAllowed,
    comment,
    config,
    fixedPrice,
    inBasket,
    listVehicleId,
    price,
    offered,
    vehicle,
  } = props;

  const getPrice = (price) => {
    return price > 0 ? price : "";
  };

  const [bidConfirmModalVisible, setBidConfirmModalVisible] = useState(false);
  const [inputSelected, setInputSelected] = useState(false);
  const [priceUnconfirmed, setPriceUnconfirmed] = useState(false);
  const [statePrice, setStatePrice] = useState(getPrice(price));
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const divElementRef = useRef();
  const priceInputRef = useRef();
  const { t } = useTranslation();

  const {
    vehicle_bidding_cart,
    vehicle_bidding_delete,
    vehicle_interest,
    vehicle_vat_regime,
  } = config;

  useEffect(() => {
    setStatePrice(getPrice(price));
  }, [price]);

  if (biddingAllowed === undefined) return null;

  const containerBorderColor = offered ? style.highlightColor : "lightgrey";
  const bgColor =
    offered && !inputSelected && statePrice === price
      ? style.highlightColor
      : style.highlightInvertColor;
  const textColor =
    offered && !inputSelected && statePrice === price
      ? style.highlightInvertColor
      : basket_state === "INITIAL" && inBasket
      ? style.highlightColor
      : "#888";
  const brdColor =
    offered && !inputSelected && statePrice === price
      ? style.highlightInvertColor
      : "lightgrey";
  const confirmIcon = "fa fa-check";

  const hoverClass =
    offered && !inputSelected && statePrice === price
      ? "hover:text-black"
      : "hover:text-primary";

  /**              |   VDFIN (FALSE)   | DIETEREN (TRUE)   -> client vat_regime setting
   *  veh_vat_reg  |-----------------------------
   *    TRUE       |      INCL         |      INCL
   *    FALSE      |      EXCL         |      INCL
   */
  const placeholder = vehicle_vat_regime
    ? t("Incl VAT")
    : vehicle.vat_regime
    ? t("Incl VAT")
    : t("Excl VAT");

  const toggle = () => {
    setTooltipOpen((prevState) => !prevState);
  };

  const onConfirm = (price, comment, quality) => {
    props.listVehicleBid(
      props.listId,
      props.vehicleId,
      price,
      comment,
      quality
    );
  };

  const onDelete = () => {
    props.listVehicleBidDelete(props.listId, props.vehicleId);
  };

  const onCancel = () => {
    setBidConfirmModalVisible(false);
    setStatePrice(getPrice(price));
  };

  const processBid = () => {
    /* vehicle_bidding_cart: TRUE => user can confirm all bids in 1 time (VDFIN) */
    /* vehicle_bidding_cart: FALSE => confirm each vehicle by modal and input comments (DIETEREN) */
    const { vehicle_bidding_cart } = props.config;

    if (vehicle_bidding_cart) {
      priceInputRef.current.blur();
      divElementRef.current.focus();
      onConfirm(statePrice, "");
    } else {
      showBidConfirmModal();
    }
  };

  const showBidConfirmModal = (e) => {
    if (statePrice && statePrice > 0) {
      setBidConfirmModalVisible(true);
      setInputSelected(false);
    }
  };

  const normalDeviceFieldProps = {
    vehicle_interest,
    price,
    bgColor,
    brdColor,
    listVehicleId,
    comment,
    vehicle_bidding_delete,
    textColor,
    tooltipOpen,
    toggle,
    onDelete,
    hoverClass,
    statePrice,
    fixedPrice,
    priceInputRef,
    placeholder,
    setStatePrice,
    inputSelected,
    setInputSelected,
    onFocus: props.onFocus,
    bidConfirmModalVisible,
    vehicle_bidding_cart,
    setPriceUnconfirmed,
    divElementRef,
    onBlur: props.onBlur,
    processBid,
    priceUnconfirmed,
    confirmIcon,
  };

  const smallDeviceProps = {
    offered,
    inBasket,
    vehicle_bidding_delete,
    onDelete,
    bgColor,
    brdColor,
    hoverClass,
    textColor,
    bidConfirmModalVisible,
    setBidConfirmModalVisible,
    statePrice,
    placeholder,
  };

  return (
    <div className="flex flex-col">
      <div
        className="hidden h-[35px] w-full border sm:flex"
        style={{
          borderColor: containerBorderColor,
          backgroundColor: bgColor,
        }}
      >
        <NormalDeviceField {...normalDeviceFieldProps} />
      </div>
      <div
        className="flex h-[35px] border sm:hidden"
        style={{
          borderColor: containerBorderColor,
          backgroundColor: bgColor,
        }}
      >
        <SmallDeviceField {...smallDeviceProps} />
      </div>

      {bidConfirmModalVisible && (
        <BidConfirmModal
          visible={bidConfirmModalVisible}
          listVehicleId={listVehicleId}
          price={statePrice}
          fixedPrice={fixedPrice}
          comment={comment}
          vehicle={vehicle}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};
