import React from "react";

export const DescriptionLabel = ({ description, important = false }) => {
  const isDescriptionUnavailable =
    description === null ||
    typeof description === "undefined" ||
    description.length <= 0;

  return isDescriptionUnavailable ? null : (
    <div>
      {!important && (
        <div className="container-normal mt-2 flex items-center">
          <div className="veh-description self-start p-2">
            <i className="fa fa-info-circle veh-description-icon-normal text-[18px]" />
          </div>
          <div className="p-1 font-medium">{description}</div>
        </div>
      )}

      {important && (
        <div className="container-important mt-2 flex items-center">
          <div className="veh-description-important self-start p-2">
            <i className="fa fa-warning veh-description-icon-important text-[18px]" />
          </div>
          <div className="p-1 font-medium">{description}</div>
        </div>
      )}
    </div>
  );
};
