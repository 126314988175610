import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

import { ListVehicleState } from "../../../../../listvehiclestate/index";

export const StateDetails = ({ selectedId }) => {
  return (
    <div className="sticky top-0 w-full md:top-[70px]">
      <Scrollbars style={{ height: "calc(100dvh - 180px" }}>
        <ListVehicleState
          containerId={selectedId}
          listVehicleId={selectedId}
          showStickyHeader={false}
        />
      </Scrollbars>
    </div>
  );
};
