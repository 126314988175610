import { apiMiddleware } from "redux-api-middleware";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";

import { authenticationMiddleware } from "./features/authentication";
import { localizationMiddleware } from "./features/localization";
import { authMiddleware } from "./middlewares/auth";
import rootReducer from "./reducer";

import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
  key: "autralis-trader",
  version: 1,
  storage: storage,
  whitelist: ["session", "localization"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [
  thunkMiddleware,
  localizationMiddleware,
  authMiddleware,
  apiMiddleware,
  authenticationMiddleware,
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
