import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Template } from "../../../../components/index";
import { fetchOfferVehicles, vehiclesCleanup } from "../actions";
import * as selectors from "../selectors";
import { VehiclesMainLayoutView } from "./VehiclesMainLayoutView";
import { VehicleStateMobileView } from "./VehicleStateMobileView";

export const OfferViews = ({ offerId }) => {
  const selectedId = useSelector(selectors.getSelectedId);
  const vehicleStateVisible = useSelector(selectors.getVehicleStateVisible);
  const dispatch = useDispatch();

  // TODO: check after global styling was refactored
  // const componentWillUpdate = () => {
  //   if (!props.vehicleStateVisible) {
  //     let scrollOffset = window.scrollY;
  //   }
  // };
  //
  // const componentDidUpdate = () => {
  //   if (!props.vehicleStateVisible && scrollOffset) {
  //     window.scrollTo(0, scrollOffset);
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchOfferVehicles(offerId));
    return () => {
      // Anything in here is fired on component unmount.
      // componentwillunmount in functional component.
      dispatch(vehiclesCleanup());
    };
  }, []);

  if (vehicleStateVisible) {
    return (
      <Template isFullWidth={false}>
        <VehicleStateMobileView id={selectedId} />
      </Template>
    );
  } else {
    return (
      <Template isFullWidth={true}>
        <VehiclesMainLayoutView offerId={offerId} />
      </Template>
    );
  }
};
