import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Template } from "../../../components";
import { trackPageView } from "../../../hooks/useGaTracking";
import { shouldUseAnalytics } from "../../../hooks/useGaTracking";
import { Confirm, getConfirmVisible } from "../../cart";
import { listCleanupByListId } from "../../lists/actions";
import { listVehiclesFetchAndBasket } from "../../listvehicles/actions";
import { listVehiclesAndStateCleanUp, listVehicleSelect } from "../actions";
import * as selectors from "../selectors";
import { ListVehiclesContainer } from "./ListvehiclesContainer";
import { ListVehicleStateContainer } from "./ListvehicleStateContainer";

const ListVehiclesAndState = (props) => {
  const {
    confirmVisible,
    listCleanupByListId,
    listId,
    listVehiclesAndStateCleanUp,
    listVehiclesFetchAndBasket,
    selectedListVehicleId,
    vehicleStateVisible,
  } = props;

  useEffect(() => {
    listVehiclesFetchAndBasket(listId);
    return () => {
      listCleanupByListId(listId);
      listVehiclesAndStateCleanUp();
    };
  }, []);

  if (confirmVisible)
    return (
      <Template isFullWidth={false}>
        <Confirm listId={listId} />
      </Template>
    );

  return vehicleStateVisible ? (
    <Template isFullWidth={false}>
      <ListVehicleStateContainer
        listVehicleId={selectedListVehicleId}
        sendListInformationByRequest={true}
      />
    </Template>
  ) : (
    <Template isFullWidth={true}>
      <ListVehiclesContainer
        listId={listId}
        sendListInformationByRequest={true}
        onSelectedVehicleChanged={(listVehicleId) => {
          // Track the selected vehicle click if Google Analytics are used
          if (shouldUseAnalytics) {
            trackPageView(`${window.location.pathname}/${listVehicleId}`);
          }
        }}
      />
    </Template>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedListVehicleId: selectors.getSelectedListVehicleId(state),
    vehicleStateVisible: selectors.getVehicleStateVisible(state),
    confirmVisible: getConfirmVisible(state),
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  listVehiclesFetchAndBasket,
  listVehicleSelect,
  listCleanupByListId,
  listVehiclesAndStateCleanUp,
})(ListVehiclesAndState);

export { ConnectedComponent as ListVehiclesAndState };
