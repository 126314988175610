export const UNSUBSCRIBE = "UNSUBSCRIBE";
export const UNSUBSCRIBE_SETTINGS = "UNSUBSCRIBE_SETTINGS";

export const unsubscribe_types = {
  SETTINGS_REQUEST: `${UNSUBSCRIBE_SETTINGS}_REQUEST`,
  SETTINGS_SUCCESS: `${UNSUBSCRIBE_SETTINGS}_SUCCESS`,
  SETTINGS_FAILURE: `${UNSUBSCRIBE_SETTINGS}_FAILURE`,

  UNSUBSCRIBE_REQUEST: `${UNSUBSCRIBE}_REQUEST`,
  UNSUBSCRIBE_SUCCESS: `${UNSUBSCRIBE}_SUCCESS`,
  UNSUBSCRIBE_FAILURE: `${UNSUBSCRIBE}_FAILURE`,
};
