import * as validators from "./helpers/input-validators";
import * as FormFields from "./web/forms/FormFields";
import { getFieldData, getFieldLabel } from "./web/vehicle/Specifications";

export { SearchInput } from "./web/search-input/SearchInput";
export { SearchableList } from "./web/SearchableList";
export { LoadingContainer } from "./web/LoadingContainer";
export { LoadingIndicator } from "./web/LoadingIndicator";
export { CircleIcon } from "./web/CircleIcon";
export { InfoContainer } from "./web/InfoContainer";
export { LoadingButton } from "./web/LoadingButton";
export { default as WheelNotOutSide } from "./web/WheelNotOutSide";

export { VehicleDamageBadge } from "./web/vehicle/VehicleDamageBadge";
export { DescriptionLabel } from "./web/vehicle/DescriptionLabel";
export { VehicleVatRegimeBadge } from "./web/vehicle/VehicleVatRegimeBadge";

export { Specifications } from "./web/vehicle/Specifications";
export { Top16FeatureIcons } from "./web/vehicle/Top16FeatureIcons";
export { Expectation } from "./web/vehicle/Expectation";
export { Top9FeatureText } from "./web/vehicle/Top9FeatureText";

export { NotSupported } from "./web/not-supported/NotSupported";
export { ComboMenu } from "./web/combomenu/ComboMenu";
export { ErrorBoundary } from "./web/application/ErrorBoundary";

export { Template } from "../layouts/Default";

export { VehicleState } from "./web/vehicle-state/VehicleState";

export { Rating } from "./web/rating/Rating";
export { GDPRWithConfig as GDPR } from "./web/GDPR";

export { validators };
export { FormFields };

export const SpecificationHelpers = {
  getFieldData,
  getFieldLabel,
};
