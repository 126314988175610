import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { ComboMenu } from "src/components";
import { AVAILABLE_LANGUAGES, LANGUAGES } from "src/settings";

import { useLocaleManagement } from "../../../hooks/useLocaleManagement";

import { Menu } from "@headlessui/react";

export const LanguagePicker = () => {
  const { t } = useTranslation();
  const { locale, changeLocale } = useLocaleManagement();

  const renderTitle = () => (
    <span>
      {locale.toUpperCase()}
      <i className="fa fa-angle-down ml-[5px] hidden sm:inline-block"></i>
    </span>
  );

  return (
    <ComboMenu renderTitle={renderTitle}>
      <h5 className="text-primary">{t("Language")}</h5>

      {AVAILABLE_LANGUAGES.map((lng) => (
        /* Use the `active` state to conditionally style the active item. */
        <Menu.Item key={lng} as={Fragment}>
          {({ active }) => (
            <button
              onClick={() => changeLocale(lng)}
              className={clsx("group flex w-full items-center p-2 text-sm", {
                "bg-highlightColor text-white": active,
                "text-gray-900": !active,
              })}
            >
              {`> ${LANGUAGES[lng].nativeName}`}
            </button>
          )}
        </Menu.Item>
      ))}
    </ComboMenu>
  );
};
