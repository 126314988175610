import React from "react";
import { connect } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { routes } from "./routes";

import { compose } from "redux";

import { withConfig } from "../../config";
import {
  getAuthenticationCode,
  getTermsAndConditionsReviewed,
  getUserState,
  isAuthenticated,
  isRegistrationPending,
} from "../../features/session/index";
import { TermsAndConditionsReview } from "../TermsAndConditionsReview";

function PrivateRoute({
  authenticated,
  authenticationCode,
  registrationPending,
  userAllowed,
  config,
  termsAndConditionsReviewed,
}) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { app_excluded_routes, terms_and_conditions_after_login } = config;

  /** Check if non-zero length code was provided in the url */
  const hasAuthCode = () => {
    if (searchParams.has("code") && searchParams.get("code").length !== 0) {
      return true;
    }
    return false;
  };

  const authenticateByCode = () => {
    const code = getCodeFromParams();
    return (
      <Navigate
        to={{ pathname: routes.LOGIN, search: `?code=${code}` }}
        state={{ from: { pathname: location.pathname } }}
      />
    );
  };

  const getCodeFromParams = () => searchParams.get("code");

  // Authenticated, provided the code that isn't the one in the redux state
  if (
    (authenticated &&
      hasAuthCode() &&
      authenticationCode !== getCodeFromParams()) ||
    (!authenticated && hasAuthCode())
  )
    return authenticateByCode();

  // Route authenticated user to appropriate location
  if (authenticated) {
    // TODO: test if all redirects work as expected, order matters

    // Pending registration and not on "registration wizard page"
    if (registrationPending && location.pathname !== "/registration-wizard") {
      return <Navigate to={routes.REGISTRATION_WIZARD} />;
    }
    // No pending registration and user is not allowed
    if (!registrationPending && !userAllowed && location.pathname !== "/403") {
      return <Navigate to={routes.USER_NOT_ALLOWED} />;
    }

    // T&Cs are not reviewed
    if (terms_and_conditions_after_login && !termsAndConditionsReviewed) {
      return <TermsAndConditionsReview />;
    }
    // Excluded routed, navigate straight to the dashboard
    if (app_excluded_routes.includes(location.pathname)) {
      return <Navigate to={routes.DASHBOARD} />;
    }

    // FIXME: when this happens?
    // Go back from routed path?
    if (
      location.state &&
      location.state.from &&
      location.state.from.pathname !== "/403"
    ) {
      return (
        <Navigate
          to={-1}
          state={{
            routed: false,
          }}
        />
      );
    }

    // Allow navigation to private component
    return <Outlet />;
  }

  // Not authenticated - return to login page
  return (
    <Navigate
      to={routes.LOGIN}
      state={{
        from:
          location && location.state && location.state.routed
            ? {
                from: {
                  pathname: "/",
                  state: { routed: false, loginByCode: true },
                },
              }
            : location,
      }}
    />
  );
}

function mapStateToProps(state) {
  return {
    authenticated: isAuthenticated(state),
    registrationPending: isRegistrationPending(state),
    authenticationCode: getAuthenticationCode(state),
    userAllowed: !getUserState(state).error,
    termsAndConditionsReviewed: getTermsAndConditionsReviewed(state),
  };
}

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps)
)(PrivateRoute);

export { ConnectedComponent as PrivateRoute };
