import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

import { LoadingIndicator } from "../../../components";
import { formatPrice } from "../../../helpers/functions";
import { withData } from "../withData";

const BiddingBlock = ({
  color,
  currentBid,
  priceInputRef,
  placeholder,
  setCurrentBid,
  handleBid,
  basketPrice,
  loading,
  handleOnClick,
  iconColor,
  highestBid,
  maxBidColor,
  maximumStatePrice,
  maximumPriceInputRef,
  setMaximumStatePrice,
  handleMaximumPrice,
  onFocus,
  onBlur,
  propsBasketPrice,
}) => {
  const { t } = useTranslation();

  const quickBids = [
    highestBid + 100,
    highestBid + 200,
    highestBid + 500,
    highestBid + 800,
  ];

  // TODO: check how "ref" attributes are used
  return (
    <div className="flex flex-1 flex-col justify-between gap-2 sm:flex-row">
      <div className="flex items-center justify-end gap-1 sm:block sm:space-y-1">
        <div>
          <span className="text-[12px] font-medium">{t("Bid directly")}</span>
        </div>

        <div
          className="flex h-[35px] min-w-[120px] border bg-white"
          style={{ borderColor: color }}
        >
          <div className="w-9/12">
            <NumericFormat
              value={currentBid ? currentBid : ""}
              disabled={false}
              getInputRef={(el) => (priceInputRef.current = el)}
              placeholder={placeholder}
              thousandSeparator={" "}
              prefix={"€ "}
              onValueChange={(values) => setCurrentBid(values.value)}
              allowNegative={false}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  //setState({loading: true}, () => handleBid(currentBid));
                  handleBid(currentBid);
                }
              }}
              className="h-full w-[90px] border-none focus:outline-none focus:ring-transparent"
              onFocus={() => {
                priceInputRef.current.setSelectionRange(
                  0,
                  priceInputRef.current.length
                );
                if (onFocus) {
                  onFocus();
                }
              }}
              autoFocus={false}
              style={{
                color:
                  currentBid > 0 && color === "lightgrey" ? "black" : color,
              }}
              onSelect={(e) => {
                if (onFocus) {
                  onFocus();
                }
              }}
              onBlur={() => {
                /* fix when leaves the control, but not clicked to confirm the bid ==> reset to initial price */
                if (currentBid !== propsBasketPrice && !loading) {
                  setTimeout(() => {
                    setCurrentBid(basketPrice);
                  }, 200);
                }

                if (onBlur) {
                  onBlur();
                }
              }}
              onClick={handleOnClick}
            />
          </div>
          <div
            ref={(divElement) => (divElement = divElement)}
            className="flex aspect-square w-3/12 cursor-pointer items-center justify-center border-l bg-white"
            onClick={(e) => {
              e.stopPropagation();
              handleBid(currentBid);
            }}
            style={{
              borderLeftColor: color,
              color: color,
            }}
          >
            <i
              className="fa fa-check color-btn-icon text-[16px]"
              style={{ color: iconColor }}
            ></i>
          </div>
        </div>
      </div>

      <div
        className="flex justify-end sm:justify-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row items-center justify-end gap-1 sm:flex-col sm:items-start">
          <div>
            <span className="text-[12px] font-medium">{t("Quick bid")}</span>
          </div>
          <div className="grid grid-cols-4 gap-2">
            {quickBids.map((bid, idx) => {
              return (
                <button
                  key={idx}
                  className="btn rounded-0 text-nowrap border border-primary bg-white p-2 text-[12px] font-semibold text-primary transition-colors hover:bg-primary hover:text-white"
                  onClick={() => handleBid(bid)}
                >
                  {formatPrice(bid)}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <div className="flex justify-end sm:items-end">
        <div className="flex items-center justify-end gap-1 sm:block sm:space-y-1">
          <div>
            <span className="text-[12px] font-medium">
              {t("Place an automatic bid")}
            </span>
          </div>
          <div
            className="flex h-[35px] min-w-[120px] border bg-white"
            style={{ color: maxBidColor }}
          >
            <div className="w-9/12">
              <NumericFormat
                value={maximumStatePrice > 0 ? maximumStatePrice : ""}
                disabled={false}
                getInputRef={(el) => (maximumPriceInputRef.current = el)}
                placeholder={placeholder}
                thousandSeparator={" "}
                prefix={"€ "}
                onValueChange={(values) => setMaximumStatePrice(values.value)}
                allowNegative={false}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    // setState({loading: true}, () => handleMaximumPrice(maximumPrice));
                    handleMaximumPrice(maximumStatePrice);
                  }
                }}
                className="h-full w-[90px] border-none focus:outline-none focus:ring-transparent"
                onFocus={() => {
                  if (maximumPriceInputRef.current) {
                    maximumPriceInputRef.current.setSelectionRange(
                      0,
                      maximumPriceInputRef.current.length
                    );
                  }
                  if (onFocus) {
                    onFocus();
                  }
                }}
                autoFocus={false}
                style={{
                  color:
                    maximumStatePrice > 0 && maxBidColor === "lightgrey"
                      ? "black"
                      : maxBidColor,
                }}
                onSelect={(e) => {
                  if (onFocus) {
                    onFocus();
                  }
                }}
                onBlur={() => {
                  if (onBlur) {
                    onBlur();
                  }
                }}
                onClick={handleOnClick}
              />
            </div>
            <div
              ref={(divElement) => (divElement = divElement)}
              className="flex aspect-square w-3/12 cursor-pointer items-center justify-center border-l bg-white"
              onClick={(e) => {
                e.stopPropagation();
                handleMaximumPrice(maximumStatePrice);
              }}
              style={{
                borderLeftColor: maxBidColor,
                color:
                  maximumStatePrice > 0 && maxBidColor === "lightgrey"
                    ? "black"
                    : maxBidColor,
              }}
            >
              <i
                className="fa fa-check color-btn-icon text-[16px]"
                style={{ color: "black" }}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BiddingAuctionRowBlock = (props) => {
  const {
    basketPrice,
    biddingAllowed,
    config,
    highestBidder,
    isAuction,
    maximumPrice,
    price,
    vehicle,
    vehiclePrice,
  } = props;
  const { vehicle_vat_regime } = config;

  const highestBid = vehiclePrice;

  const [currentBid, setCurrentBid] = useState(price);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [maximumStatePrice, setMaximumStatePrice] = useState(maximumPrice);

  const maximumPriceInputRef = useRef();
  const priceInputRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    setCurrentBid(price);
    setMaximumStatePrice(maximumPrice);
  }, [price, maximumPrice]);

  // constructor(props) {
  //   super(props);
  //
  //   state = {
  //     loading: false,
  //     currentBid: props.price,
  //     maximumPrice: props.maximumPrice,
  //     error: "",
  //   };
  //
  //   handleBid = handleBid.bind(this);
  //   handleMaximumPrice = handleMaximumPrice.bind(this);
  // }

  // const componentWillReceiveProps = (nextProps) => {
  //     if (
  //         props.listVehicleId !== nextProps.listVehicleId ||
  //         (props.listVehicleId === nextProps.listVehicleId &&
  //             props.price !== nextProps.price)
  //     ) {
  //         setState({
  //             currentBid: nextProps.price,
  //             maximumPrice: nextProps.maximumPrice,
  //         });
  //     }
  //
  //     // if (props.vehicleStop !== nextProps.vehicleStop) {
  //     //     setState({dateExtended: true})
  //     // }
  // }

  const handleBid = (price) => {
    // if (price !== null && price != props.basketPrice) {
    if (price !== null) {
      if (!loading) {
        setLoading(true);
        props
          .listVehicleBid(props.listId, props.vehicleId, price, "", 0, 0)
          .then((result) => {
            if (!result.payload.accepted) {
              setLoading(false);
              setError(result.payload.message);
            } else {
              setLoading(false);
              setError();
            }

            if (priceInputRef.current) {
              priceInputRef.current.blur();
            }
          });
      }
    }
  };

  const handleMaximumPrice = (maximumPrice) => {
    if (!loading) {
      setLoading(true);
      props
        .listVehicleBid(props.listId, props.vehicleId, 0, "", 0, maximumPrice)
        .then((result) => {
          if (!result.payload.accepted) {
            setLoading(false);
            setError(result.payload.message);
          } else {
            setLoading(false);
            setError(result.payload.message);
          }
        });
    }
  };

  const handleOnClick = (e) => {
    // Prevent the click event from propagating to the div
    e.stopPropagation();
  };

  const color = highestBidder
    ? "#48bb78"
    : basketPrice > 0 && !highestBidder
    ? "#c53030"
    : "lightgrey";
  const iconColor = highestBidder
    ? "#48bb78"
    : basketPrice > 0 && !highestBidder
    ? "#c53030"
    : "black";

  const maxBidColor = "lightgrey";

  const placeholder = vehicle_vat_regime
    ? t("Incl VAT")
    : vehicle.vat_regime
    ? t("Incl VAT")
    : t("Excl VAT");

  const hideBiddingBlock = !biddingAllowed || !isAuction;
  const showError = error && error !== "";

  const biddingBlockProps = {
    color,
    currentBid,
    priceInputRef,
    placeholder,
    setCurrentBid,
    handleBid,
    basketPrice,
    loading,
    handleOnClick,
    iconColor,
    highestBid,
    maxBidColor,
    maximumStatePrice,
    maximumPriceInputRef,
    setMaximumStatePrice,
    handleMaximumPrice,
    onFocus: props.onFocus,
    onBlur: props.onBlur,
    propsBasketPrice: props.basketPrice,
  };

  return hideBiddingBlock ? null : (
    <div
      className="relative flex w-full flex-col p-2"
      style={{ backgroundColor: "#f5f5f5" }}
      onMouseEnter={() => {
        if (props.onFocus) {
          props.onFocus();
        }
      }}
      onMouseLeave={() => {
        if (props.onBlur) {
          props.onBlur();
        }
      }}
      onClick={() => {
        setCurrentBid(null);
      }}
    >
      {showError && (
        <div
          className="w-full p-1"
          style={{
            backgroundColor: "#fed7d7",
            color: "#c53030",
          }}
        >
          {error}
        </div>
      )}
      <BiddingBlock {...biddingBlockProps} />
      {loading && (
        <div
          className="loadingContainerOverlay flex cursor-default items-center justify-center"
          onClick={(e) => e.stopPropagation()}
        >
          <LoadingIndicator loading={loading} />
        </div>
      )}
    </div>
  );
};

const ConnectedComponent = withData(BiddingAuctionRowBlock);

export { ConnectedComponent as BiddingAuctionRowBlock };
