import React, { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";

import style from "../../styles";

export const LoadingIndicator = ({
  loading,
  text,
  textDisplayDelay = 500,
  loaderWidth = "100px",
}) => {
  const [showText, setShowText] = useState(false);

  // Show text after specified time
  useEffect(() => {
    let timer;

    if (loading) {
      timer = setTimeout(() => {
        setShowText(true);
      }, textDisplayDelay);
    } else {
      // Clear the timer when loading is false
      clearTimeout(timer);
      setShowText(false);
    }

    // Clean up the timer when the component unmounts
    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  if (!loading) return null;

  // Text appearance transition
  const textStyles = {
    opacity: showText ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };

  return (
    <div className="flex justify-center">
      <div className="text-center">
        <BarLoader
          color={style.loadingIndicatorColor}
          cssOverride={{
            display: "inline-block",
            backgroundColor: style.loadingIndicatorBgColor,
            width: loaderWidth,
          }}
          loading={loading}
          speedMultiplier={1.8}
        />
        {text ? <p style={textStyles}>{text}</p> : null}
      </div>
    </div>
  );
};
