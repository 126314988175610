import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { fetchList } from "../actions";
import * as selectors from "../selectors";
import { ListCountDown } from "./ListCountDown";

import moment from "moment";

const ListDetailInfo = (props) => {
  const { list } = props;
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchList(props.listId);
  }, []);

  return !list ? null : (
    <div>
      <h5 className="text-center uppercase text-highlightColor">{list.name}</h5>

      {list.stop &&
        list.state === "OPEN" &&
        list.type !== "FIXED" &&
        list.type !== "AUCTION" &&
        moment(list.stop) > moment() && <ListCountDown date={list.stop} />}

      {(list.state === "CLOSED" || moment(list.stop) < moment()) && (
        <h5 className="text-center uppercase text-highlightColor">
          {t("Closed")}
        </h5>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    list: selectors.listById(state, ownProps.listId),
  };
};

const ConnectedComponent = connect(mapStateToProps, { fetchList })(
  ListDetailInfo
);
export { ConnectedComponent as ListDetailInfo };
