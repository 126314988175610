import React from "react";

export const WizardHeader = ({ number, title, currentPage }) => {
  let colors = "";
  let textColor = "text-primary";
  let icon = "fa fa-pencil text-[9px]";

  if (currentPage === number) {
    colors = "bg-primary text-white ";
  } else if (currentPage > number) {
    colors = "bg-green-600 text-white ";
    icon = "fa fa-check text-[9px]";
    textColor = "text-green-600";
  } else {
    colors = "bg-white text-gray-300 border-gray-300 border";
  }

  return (
    <li className="z-10 w-[100px]">
      <div className="u-block-hover text-center">
        <div
          className={`mx-auto flex size-6 items-center justify-center rounded-full  ${colors}`}
        >
          <i className={icon}></i>
        </div>
        <h3 className={`${textColor} mb-3 text-lg font-semibold`}>{title}</h3>
      </div>
    </li>
  );
};
