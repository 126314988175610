import { useLocation, useNavigate, useParams } from "react-router-dom";

import i18n from "../i18n";

import { includes } from "lodash";

/**
 * Helper function to the class components due to deprecated withRouter
 * https://reactrouter.com/en/main/start/faq#what-happened-to-withrouter-i-need-it
 */
export function withRouter(Component) {
  return (props) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  };
}

export const DealerStates = {
  HISTORY: "HISTORY",
  DELIVERY: "DELIVERY",
  DELIVERED: "DELIVERED",
  ORDERED: "ORDERED",
  RESERVED: "RESERVED",
  OFFERED: "OFFERED",
  HISTORY_REFUSED: "HISTORY_REFUSED",

  ACCEPTED: "ACCEPTED",
  REFUSED: "REFUSED",
  HIGHEST: "HIGHEST",
};

export const DealerStateTranslate = {
  [DealerStates.HISTORY]: i18n.t("History"),
  [DealerStates.DELIVERY]: i18n.t("Deliverable"),
  [DealerStates.DELIVERED]: i18n.t("DELIVERED"),
  [DealerStates.ORDERED]: i18n.t("Ordered"),
  [DealerStates.RESERVED]: i18n.t("Reserved"),
  [DealerStates.OFFERED]: i18n.t("Offered"),
  [DealerStates.HISTORY_REFUSED]: i18n.t("Refused"),

  [DealerStates.ACCEPTED]: i18n.t("Accepted"),
  [DealerStates.REFUSED]: i18n.t("Refused"),
  [DealerStates.HIGHEST]: i18n.t("Highest"),
};

class HistoryStates {
  constructor() {
    this.states = DealerStates;
  }

  soldStates() {
    return [
      this.states.DELIVERY,
      this.states.DELIVERED,
      this.states.ORDERED,
      this.states.RESERVED,
      this.states.ACCEPTED,
      this.states.HIGHEST,
    ];
  }

  pendingStates() {
    return [this.states.OFFERED];
  }

  lostStates() {
    return [this.states.HISTORY_REFUSED, this.states.REFUSED];
  }

  historyStates() {
    return [this.states.HISTORY, this.states.HISTORY_REFUSED];
  }

  isSold(state) {
    return includes(this.soldStates(), state);
  }

  isPending(state) {
    return includes(this.pendingStates(), state);
  }

  isLost(state) {
    return includes(this.lostStates(), state);
  }

  isHistory(state) {
    return includes(this.historyStates(), state);
  }
}

export const encodeUrl = (url) => {
  // encodeURIComponent - escapes all chars except A–Z a–z 0–9 - _ . ! ~ * ' ( )
  // replace -> escapes next chars . ! ~ * ' ( )
  return encodeURIComponent(url.toString()).replace(
    /[.!~*'()]/g,
    (c) => `%${c.charCodeAt(0).toString(16)}`
  );
};

export const buildUrl = (endpoint, params) => {
  let url = endpoint;

  if (params && endpoint.includes(":")) {
    Object.keys(params).forEach((key) => {
      const encodedValue = encodeUrl(params[key]);
      url = url.replace(`:${key}`, encodedValue);
    });
  } else if (endpoint.includes(":")) {
    throw new Error(`Missing required parameter for endpoint: ${endpoint}`);
  }
  return url;
};

export const currentTime = () => Date.now();

/**
 * Splits the duration time in seconds into days, hours, minutes and remaining seconds.
 */
export const splitSecondsIntoTimeUnits = (durationInSeconds) => {
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  const SECONDS_IN_DAY = 86400;
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_MINUTE = 60;

  if (durationInSeconds > 0) {
    // Calculate days and update durationInSeconds to the remaining seconds after removing whole days
    days = Math.floor(durationInSeconds / SECONDS_IN_DAY);
    durationInSeconds %= SECONDS_IN_DAY;

    // Calculate hours and update durationInSeconds to the remaining seconds after removing whole hours
    hours = Math.floor(durationInSeconds / SECONDS_IN_HOUR);
    durationInSeconds %= SECONDS_IN_HOUR;

    // Calculate minutes and update durationInSeconds to the remaining seconds after removing whole minutes
    minutes = Math.floor(durationInSeconds / SECONDS_IN_MINUTE);

    // Calculate remaining seconds using modulo operator
    seconds = durationInSeconds % SECONDS_IN_MINUTE;
  }

  return { days, hours, minutes, seconds };
};

export const addLeadingZero = (number, numberOfZeros = 2) => {
  return String(number).padStart(numberOfZeros, "0");
};

const HistoryStatesHelper = new HistoryStates();

export { HistoryStatesHelper };
