import React from "react";

import { Template } from "../components";
import { Content, LegalTypes } from "../features/legal/index";

export const Privacy = () => (
  <Template>
    <div className="bg-white px-3 py-5">
      <Content id={LegalTypes.PRIVACY} />
    </div>
  </Template>
);
