import _ from "lodash";

export const mapCompanies = (data) => {
  const regions = _.sortBy(
    _.uniqBy(
      _.map(_.reject(data, ["region", null]), (company) => company.region),
      "id"
    ),
    "name"
  );
  const companies = _.sortBy(
    _.map(data, (company) => ({
      id: company.id,
      region: company.region,
      name: company.name,
    })),
    "name"
  );

  return {
    regions,
    companies,
  };
};

export const mapMakes = (makes) =>
  _.map(makes, (make) => ({ id: make.id, name: make.name }));

export const mapFilters = (data) => {
  return _.keyBy(
    _.map(data, (filter) => ({
      id: filter.id,
      name: filter.name,
      regions: _.map(filter.regions, "id"),
      excluded_companies: _.map(filter.excluded_companies, "id"),
      makes: _.map(filter.makes, "id"),
      age_min: filter.age_min,
      age_max: filter.age_max,
      km_min: filter.km_min,
      km_max: filter.km_max,
    })),
    "id"
  );
};
