import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import { withConfig } from "../../../config";

const VehicleVatRegimeBadge = ({ vat_regime, config }) => {
  const { t } = useTranslation();
  const { vehicle_vat_regime } = config;
  const accent = vehicle_vat_regime === !vat_regime;
  const text = vat_regime ? t("VAT NON Refundable") : t("VAT Refundable");

  const spanClass = clsx(
    "u-label !rounded-[3px] badge font-bold",
    accent ? "vat-highlight" : "badge-normal"
  );

  return <span className={spanClass}>{text}</span>;
};

const ConnectedComponent = withConfig(VehicleVatRegimeBadge);

export { ConnectedComponent as VehicleVatRegimeBadge };
