import React from "react";
import { useSelector } from "react-redux";

import clsx from "clsx";

import {
  DescriptionLabel,
  Expectation,
  Specifications,
  Top9FeatureText,
} from "../../../../../../components/index";
import { withConfig } from "../../../../../../config/index";
import { PrintButtons } from "../../../../../print";
import * as selectors from "../../../selectors";
import { DealerStateWithVatRegime } from "./DealerStateWithVatRegime";
import { ItemHeader } from "./ItemHeader";
import { MainCompanyNameOnMobile } from "./MainCompanyNameOnMobile";
import { Picture } from "./Picture";
import { PriceDisplay } from "./PriceDisplay";

const VehicleItem = (props) => {
  const { id, selected, config } = props;
  const vehicle = useSelector((state) => selectors.getVehicleById(state, id));

  const {
    picture_url,
    make,
    model,
    version,
    main_company_name,
    vat_regime,
    description,
    description_plus,
    top_features_text,
    features_count,
  } = vehicle;

  const onClick = () => {
    const { onClick } = props;
    onClick(props.id);
  };

  const {
    list_vehicle_summary_fields,
    list_vehicle_summary_provisional_fields,
    list_vehicle_top_feature_icons,
  } = config;
  const summaryFields =
    vehicle.provisional_sale && !vehicle.expected_disclose
      ? list_vehicle_summary_provisional_fields
      : list_vehicle_summary_fields;

  const showImportantDescription = description && description !== "";
  const showDescriptionPlus = description_plus && description_plus !== "";
  const showDescriptions = showImportantDescription || showDescriptionPlus;

  const Descriptions = () => {
    return (
      <div className="my-1 space-y-1">
        {showDescriptionPlus && (
          <div className="w-full">
            <DescriptionLabel description={description_plus} />
          </div>
        )}
        {showImportantDescription && (
          <div className="w-full">
            <DescriptionLabel description={description} important />
          </div>
        )}
      </div>
    );
  };
  return (
    <article
      className={clsx("item item--hover p-3", {
        "item-active": selected,
      })}
      onClick={onClick}
    >
      <div className="flex flex-col space-y-2">
        <ItemHeader
          make={make}
          model={model}
          mainCompanyName={main_company_name}
          version={version}
        />
        <div className="flex space-x-3">
          <div className="w-1/5">
            <Picture picture_url={picture_url} make={make} model={model} />
            <PriceDisplay price={vehicle.price} />
            <PrintButtons listVehicleId={id} />
          </div>
          <div className="w-4/5">
            <DealerStateWithVatRegime
              state={vehicle.dealer_state}
              vat_regime={vat_regime}
            />
            <Specifications vehicle={vehicle} fields={summaryFields} />
            {!list_vehicle_top_feature_icons && (
              <div className="hidden sm:block">
                <Top9FeatureText
                  features={top_features_text}
                  count={features_count}
                />
              </div>
            )}
            <div>
              <Expectation {...vehicle} />
            </div>
          </div>
        </div>
      </div>

      {showDescriptions && <Descriptions />}

      {main_company_name && (
        <MainCompanyNameOnMobile mainCompanyName={main_company_name} />
      )}
    </article>
  );
};

export const WithConfigVehicleItem = withConfig(VehicleItem);
