import React from "react";
import { useTranslation } from "react-i18next";

import { VehicleVatRegimeBadge } from "../../../../../../components";
import { DealerStateTranslate } from "../../../../../../helpers/utils";

export const DealerStateWithVatRegime = ({ state, vat_regime }) => {
  const { t } = useTranslation();
  return (
    <div className="row g-pl-15 g-pt-8">
      <span className="u-label g-mr-10 !rounded-[3px] bg-highlightColor ">
        {t(DealerStateTranslate[state])}
      </span>
      <VehicleVatRegimeBadge vat_regime={vat_regime} />
    </div>
  );
};
