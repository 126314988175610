import React from "react";
import { useTranslation } from "react-i18next";

import { Field, reduxForm } from "redux-form";

import { FormFields, validators } from "../../../components";

const { TextField, PhoneNumber, Combo } = FormFields;

const inviteFields = {
  COMPANY_KEY: "company_key",
  TITLE: "title",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  MAIL: "email",
  PHONE: "telephone",
  MOBILE: "mobile",
  PASSWORD: "password",
  PASSWORD_CONFIRM: "password_confirm",
};

const validate = (values, props) => {
  const { required } = props;
  const errors = {};

  if (!required) return errors;

  const phoneError =
    validators.required(values.telephone) ||
    validators.minLength10(values.telephone);
  const mobileError =
    validators.required(values.mobile) || validators.minLength10(values.mobile);

  if (phoneError !== undefined && mobileError !== undefined) {
    errors.telephone = "Telephone or mobile is required";
    errors.mobile = "Telephone or mobile is required";
  }
  return errors;
};

const InviteForm = ({ initialValues, handleSubmit, required }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <Field
        name={inviteFields.COMPANY_KEY}
        component={TextField}
        disabled={
          initialValues.company_key !== null &&
          initialValues.company_key !== undefined &&
          initialValues.company_key.length === 16
        }
        type="text"
        label={t("Company key")}
        validate={[validators.required]}
        additionalInfo={t(
          "This is your unique company identification generated by the Used Cars Center. Find the company key in the Used Cars Center e-mail invitation."
        )}
      />

      <Field
        name={inviteFields.TITLE}
        component={Combo}
        label={t("Salutation")}
        validate={[validators.required]}
        placeholder={t("Salutation")}
        options={[
          { value: "Mr", label: t("Mr") },
          { value: "Mrs", label: t("Mrs") },
          { value: "Miss", label: t("Miss") },
        ]}
      />

      <Field
        name={inviteFields.FIRST_NAME}
        component={TextField}
        type="text"
        label={t("First name")}
        validate={[validators.required]}
      />

      <Field
        name={inviteFields.LAST_NAME}
        component={TextField}
        type="text"
        label={t("Lastname")}
        validate={[validators.required]}
      />

      <Field
        name={inviteFields.MAIL}
        component={TextField}
        type="email"
        label={t("E-mail address")}
        validate={[validators.required, validators.email]}
      />

      <Field
        name={inviteFields.PHONE}
        component={PhoneNumber}
        type="text"
        label={t("Telephone")}
        example={`${t("E.g.")} 09 360 00 00`}
      />

      <Field
        name={inviteFields.MOBILE}
        component={PhoneNumber}
        type="text"
        label={t("Mobile")}
        example={`${t("E.g.")} 0494 11 22 33`}
      />

      <Field
        name={inviteFields.PASSWORD}
        type="password"
        component={TextField}
        label={t("Password")}
        placeholder="********"
        validate={[
          validators.required,
          validators.minLength6,
          validators.hasNumber,
          validators.hasLowerCase,
          validators.hasUpperCase,
        ]}
      />

      <Field
        name={inviteFields.PASSWORD_CONFIRM}
        type="password"
        component={TextField}
        label={t("Password (confirm)")}
        placeholder="********"
        validate={[
          validators.required,
          validators.minValue6,
          validators.hasNumber,
          validators.hasLowerCase,
          validators.hasUpperCase,
        ]}
      />
    </form>
  );
};

const ConnectedComponent = reduxForm({
  form: "Invite-PersonalInfo",
  destroyOnUnmount: false,
  validate,
})(InviteForm);

export { ConnectedComponent as InviteForm };
