import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Template } from "../components";
import { withConfig } from "../config";
import { ApprovalCheck } from "../features/approval-check";
import { Lists } from "../features/lists/_web/Lists";
import { Stats } from "../features/statistics/index";
import { WarningsList } from "../features/warnings";
import { buildUrl } from "../helpers/utils";
import { routes } from "./navigation/routes";

export const Dashboard = (props) => {
  const { config } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Template>
      <div className="align-items-start flex flex-col space-y-3">
        {config.dealerships ? <ApprovalCheck /> : null}
        <WarningsList />
        <div className="flex flex-col gap-3 py-2 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <Lists
              onListSelect={(listId) =>
                navigate(buildUrl(routes.LIST, { listId: listId }))
              }
            />
          </div>
          <div className="w-full lg:w-1/2">
            <div
              className="cursor-pointer"
              onClick={() => navigate(routes.HISTORY)}
            >
              <div className="bottom-shadow mb-3 bg-white p-2.5">
                <span className="uppercase">{t("History")}</span>
              </div>
            </div>
            <Stats />
          </div>
        </div>
      </div>
    </Template>
  );
};

export const DashboardWithConfig = withConfig(Dashboard);
