import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { useDispatchWithPromise } from "src/hooks/useDispatchWithPromise";

import { updateCriteria } from "../actions";
import * as criteriaHelpers from "../functions/criteria";
import * as listVehiclesFilterSelectors from "../selectors";
import { FilterSummaryTag } from "./FilterSummaryTag";

import _ from "lodash";
import moment from "moment";

const FilterSummary = (props) => {
  const dispatchWithPromise = useDispatchWithPromise();
  const { t } = useTranslation();

  const setCriteria = (criteria) => {
    const { listId, updateCriteria } = props;
    dispatchWithPromise(updateCriteria(listId, criteria)).then(() =>
      props.onCriteriaChanged()
    );
  };

  const onDeleteMakeModel = (mm) => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeMakeModel(criteria, mm));
  };

  const onDeleteDealer = (dealer) => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeCompany(criteria, dealer));
  };

  const onDeleteState = (state) => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeState(criteria, state));
  };

  const onDeleteFuel = (fuel) => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeFuel(criteria, fuel));
  };

  const onDeleteGearbox = (gearbox) => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeGearbox(criteria, gearbox));
  };

  const onDeleteKm = () => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeKm(criteria));
  };

  const onDeleteAge = () => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeAge(criteria));
  };

  const onDeleteOfferedDt = () => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeOfferedDt(criteria));
  };

  const onDeleteExpectationdDt = () => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeExpectationDt(criteria));
  };

  const onDeleteList = () => {
    const { criteria } = props;
    setCriteria(criteriaHelpers.removeList(criteria));
  };

  const { makeModels, criteria, data } = props;

  return (
    <div className="flex flex-wrap gap-1">
      {criteria.list !== null && (
        <FilterSummaryTag
          key={`list-filter-summary-tag`}
          text={criteria.list.name}
          onClick={() => onDeleteList()}
        />
      )}

      {criteria.companies.map((id, i) => (
        <FilterSummaryTag
          key={`dealer-filter-summary-tag-${i}`}
          text={_.get(_.find(data.companies, { id }), "name")}
          onClick={() => onDeleteDealer(id)}
        />
      ))}

      {makeModels.map((mm, i) => (
        <FilterSummaryTag
          key={`mm-filter-summary-tag-${i}`}
          text={`${_.capitalize(mm.make)} ${_.capitalize(mm.model)}`}
          onClick={() => onDeleteMakeModel(mm)}
        />
      ))}

      {criteria.fuels.map((key, i) => (
        <FilterSummaryTag
          key={`fuel-filter-summary-tag-${i}`}
          text={_.get(_.find(data.fuels, { key }), "name")}
          onClick={() => onDeleteFuel(key)}
        />
      ))}

      {criteria.gearboxes.map((key, i) => (
        <FilterSummaryTag
          key={`gearbox-filter-summary-tag-${i}`}
          text={_.get(_.find(data.gearboxes, { key }), "name")}
          onClick={() => onDeleteGearbox(key)}
        />
      ))}

      {criteria.km && (criteria.km.min || criteria.km.max) && (
        <FilterSummaryTag
          key={`km-filter-summary-tag`}
          text={`${criteria.km.min} - ${criteria.km.max} km`}
          onClick={() => onDeleteKm()}
        />
      )}

      {criteria.age && criteria.age.min && criteria.age.max && (
        <FilterSummaryTag
          key={`age-filter-summary-tag`}
          text={`${criteria.age.min} - ${criteria.age.max} months`}
          onClick={() => onDeleteAge()}
        />
      )}

      {criteria.states.map((id, i) => (
        <FilterSummaryTag
          key={`state-filter-summary-tag-${i}`}
          text={_.get(_.find(data.states, { code: id }), "name")}
          onClick={() => onDeleteState(id)}
        />
      ))}

      {criteria.offeredDt_min && criteria.offeredDt_max && (
        <FilterSummaryTag
          key={`offeredDt-filter-summary-tag`}
          text={`${t("Offered")}: ${moment(criteria.offeredDt_min).format(
            "DD/MM/YYYY"
          )} - ${moment(criteria.offeredDt_max).format("DD/MM/YYYY")}`}
          onClick={() => onDeleteOfferedDt()}
        />
      )}

      {criteria.expectationDt_min && criteria.expectationDt_max && (
        <FilterSummaryTag
          key={`expectationDt-filter-summary-tag`}
          text={`${t("expectation")}: ${moment(
            criteria.expectationDt_min
          ).format("DD/MM/YYYY")} - ${moment(criteria.expectationDt_max).format(
            "DD/MM/YYYY"
          )}`}
          onClick={() => onDeleteExpectationdDt()}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { listId } = ownProps;

  return {
    makeModels: _.map(
      listVehiclesFilterSelectors.getCriteria(state, listId).makes,
      (mm) => ({
        make: mm.make,
        model: mm.model,
      })
    ),
    criteria: listVehiclesFilterSelectors.getCriteriaCopy(state, listId),
    data: listVehiclesFilterSelectors.getData(state, listId),
  };
};

const ConnectedComponent = connect(mapStateToProps, { updateCriteria })(
  FilterSummary
);

export { ConnectedComponent as FilterSummary };
