import React from "react";

import { Modal } from "../../../components/web/Modal";
import { ClearFilters } from "./ClearFilters";
import { Filter } from "./Filter";
import { FilterSummary } from "./FilterSummary";

export const FilterModal = (props) => {
  const { visible, listId, onCancel, onCriteriaChanged, onClearFilters } =
    props;
  return (
    <Modal visible={visible} onCancel={onCancel}>
      <div className="flex h-full w-full items-start justify-center">
        <div className="w-full rounded bg-white">
          <div className="sticky top-0 flex h-[40px] items-center justify-end space-x-8 bg-highlightColor p-2">
            <div>
              <ClearFilters
                listId={listId}
                onClearFilters={onClearFilters}
                onClick={onCancel}
                fontStyle={{ color: "white" }}
              />
            </div>
            <button onClick={onCancel}>
              <i className="fa fa-close block text-[24px] text-white" />
            </button>
          </div>

          <div className="h-[90dvh] overflow-auto p-2">
            {/*TODO: TBC if filter summary is useful in the modal*/}
            <FilterSummary
              listId={listId}
              onCriteriaChanged={onCriteriaChanged}
            />
            <Filter listId={listId} onCriteriaChanged={onCriteriaChanged} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
