import { fetchApprovalsService } from "./services/fetchApprovals";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  approvalCount: null,
  error: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

const approvalCheckSlice = createSlice({
  name: "approvalCheck",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApprovals.pending, setLoading);
    builder.addCase(fetchApprovals.fulfilled, setApprovalCountAndSuccess);
    builder.addCase(fetchApprovals.rejected, setError);
  },
});

const setLoading = (state) => {
  state.isLoading = true;
};

const setApprovalCountAndSuccess = (state, action) => {
  const approved = action.payload.filter((item) => item.state === "APPROVED");
  state.approvalCount = approved.length;
  state.isLoading = false;
  state.isSuccess = true;
};

const setError = (state, action) => {
  state.error = action.payload.message;
  state.isLoading = false;
  state.isError = true;
};

export default approvalCheckSlice.reducer;

export const fetchApprovals = createAsyncThunk(
  "approvalCheck/fetch",
  fetchApprovalsService
);
