import { io } from "socket.io-client";
import socketIOClient from "socket.io-client-v1";

import config from "../../config/config.json";

const { use_legacy_socket_io, socket_io_url } = config;

export let socket;

// Initialize appropriate socket.io client if socket_io_url is present in the config file.
if (socket_io_url && socket_io_url !== "") {
  const URL = socket_io_url;

  // By default, the Socket.IO client opens a connection to the server right away. To prevent this behavior
  // the autoConnect option is used. We manually call socket.connect() in the code to make the Socket.IO client connect
  // when user is authenticated and Socket.IO and socket_io_url is present in the config file.

  // Current backend is using version 1.x therefore legacy support is required
  // https://socket.io/docs/v4/client-installation/
  if (use_legacy_socket_io) {
    // Use socket.io v1
    socket = socketIOClient(URL, {
      autoConnect: false,
    });
  } else {
    socket = io(URL, {
      autoConnect: false,
    });
  }
}
