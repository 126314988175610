import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { SearchableList } from "../../../../components/index";
import { RegistrationWizardContentLayout } from "../../../../layouts/RegistrationWizardContentLayout";
import { ContactInfo } from "./ContactInfo";
import { WizardButtons } from "./WizardButtons";

import { includes } from "lodash";

const LeftSectionContent = ({ step, steps }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="text-[1.25rem] text-primary">
        {t("Step")}: {step}/{steps} : {t("Filters")}
      </h1>
      <p className="py-2">
        {t("For which brands do you want to receive prize requests?")}
      </p>
      <ContactInfo />
    </div>
  );
};

export const WizardStepFive = (props) => {
  const { step, totalSteps, filterMakes, onNext, onPrev, loading } = props;
  const data = filterMakes.map((make) => ({
    id: make.id,
    searchTerms: [make.name],
    data: make,
  }));
  const initialMakes = [
    props.filter !== null
      ? props.filter.makes
      : props.filterMakes.map((make) => make.id),
  ];
  const [selectedMakes, setSelectedMakes] = useState(...initialMakes);
  const { t } = useTranslation();

  const addRemoveMake = (id) => {
    if (includes(selectedMakes, id)) {
      removeMakes([id]);
    } else {
      addMakes([id]);
    }
  };

  const removeMakes = (makes) => {
    setSelectedMakes(
      selectedMakes.filter(function (make_id) {
        return !includes(makes, make_id);
      })
    );
  };

  const addMakes = (makes) => {
    setSelectedMakes((prevState) => [...prevState, ...makes]);
  };

  const renderItem = ({ id, data }) => (
    <button
      key={id}
      type="button"
      className="ml-1 flex space-x-1"
      onClick={() => addRemoveMake(id)}
    >
      <input
        type="checkbox"
        checked={includes(selectedMakes, id)}
        onChange={() => addRemoveMake(id)}
        className="
          disabled:border-grey-400 disabled:bg-grey-400 border-1
          peer relative mt-1 h-4 w-4 shrink-0 cursor-pointer
          appearance-none
          rounded-sm border-[#ccc]
          bg-white checked:border-0 checked:bg-primary focus:outline-none
          focus:ring-1 focus:ring-blue-100
          focus:ring-offset-0
        "
      />
      <span className="inline-block">{data.name}</span>
    </button>
  );

  return (
    <RegistrationWizardContentLayout
      leftContent={<LeftSectionContent step={step} steps={totalSteps} />}
      rightContent={
        <SearchableList
          data={data}
          renderItem={renderItem}
          renderItemContainerStyle={
            "grid grid-cols-3 gap-4 min-h-min max-h-[50dvh] overflow-auto auto-rows-min"
          }
          onSelectAll={addMakes}
          onDeSelectAll={removeMakes}
          placeholder={t("Makes") + " ..."}
        />
      }
      bottomContent={
        <WizardButtons
          currentStep={step}
          onNext={() => onNext(selectedMakes)}
          onPrevious={() => onPrev()}
        />
      }
      loading={loading}
    />
  );
};
