export const colleagueTypes = {
  FETCH_REQUEST: "COLLEAGUES_FETCH_REQUEST",
  FETCH_SUCCESS: "COLLEAGUES_FETCH_SUCCESS",
  FETCH_FAILURE: "COLLEAGUES_FETCH_FAILURE",

  INVITE_REQUEST: "COLLEAGUE_INVITE_REQUEST",
  INVITE_SUCCESS: "COLLEAGUE_INVITE_SUCCESS",
  INVITE_FAILURE: "COLLEAGUE_INVITE_FAILURE",

  CREATE_REQUEST: "COLLEAGUE_CREATE_REQUEST",
  CREATE_SUCCESS: "COLLEAGUE_CREATE_SUCCESS",
  CREATE_FAILURE: "COLLEAGUE_CREATE_FAILURE",

  LOCK_REQUEST: "COLLEAGUE_LOCK_REQUEST",
  LOCK_SUCCESS: "COLLEAGUE_LOCK_SUCCESS",
  LOCK_FAILURE: "COLLEAGUE_LOCK_FAILURE",

  UNLOCK_REQUEST: "COLLEAGUE_UNLOCK_REQUEST",
  UNLOCK_SUCCESS: "COLLEAGUE_UNLOCK_SUCCESS",
  UNLOCK_FAILURE: "COLLEAGUE_UNLOCK_FAILURE",

  UPDATE_STATE: "COLLEAGUE_UPDATE_STATE",
};
