import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { sendLocale, setLocale } from "../features/localization/actions";
import { getLocale } from "../features/localization/selectors";
import { isAuthenticated } from "../features/session";
import { setFunctionsLocale } from "../helpers/functions";
import { usePrevPropValue } from "./usePrevPropValue";

/**
 *  This hook is responsible for changing the language on the website.
 *
 * @returns {Object} - An object containing: locale - the current locale; and changeLocale - the function that changes the locale.
 *
 */
export const useLocaleManagement = () => {
  const { i18n } = useTranslation();
  const locale = useSelector(getLocale);
  const isAuthorised = useSelector(isAuthenticated);
  const dispatch = useDispatch();
  const prevIsAuthorised = usePrevPropValue(isAuthorised);

  const sendLocaleToBackend = (locale) => {
    dispatch(sendLocale(locale));
  };

  const updateI18Locale = (locale) => {
    i18n.changeLanguage(locale);
  };

  const updateStateLocale = (locale) => {
    dispatch(setLocale(locale));
  };

  // Updates the locale and dispatches related functions
  const changeLocale = (newLocale) => {
    updateI18Locale(newLocale);
    updateStateLocale(newLocale);

    if (isAuthorised && locale !== newLocale) {
      sendLocaleToBackend(newLocale);
    }

    setFunctionsLocale(newLocale);
  };

  // Locale fallback when no locale is set in redux state set to current i18n.resolvedLanguage
  useEffect(() => {
    if (!locale) {
      changeLocale(i18n.resolvedLanguage);
    }
  }, [locale]);

  // Dispatch locale request to the backend after logging in
  useEffect(() => {
    if (prevIsAuthorised === false && isAuthorised) {
      sendLocaleToBackend(locale);
    }
  }, [prevIsAuthorised, isAuthorised]);

  // Current locale in redux state and locale updater
  return { locale, changeLocale };
};
