import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { submit } from "redux-form";

import { InfoContainer, LoadingIndicator } from "../../../components";
import { Button } from "../../../components/web/Button";
import { useNavigateHelper } from "../../../hooks/useNavigateHelper";
import { fetchSettings, saveSettings } from "../actions";
import * as selectors from "../selectors";
import { Confirm } from "./Confirm";
import { SettingsForm } from "./SettingsForm";
import { settingsFields } from "./SettingsForm";

import { keys } from "lodash";

const states = {
  INPUT: "INPUT",
  OVERVIEW: "OVERVIEW",
  RESULT: "RESULT",
};

export const Unsubscribe = () => {
  const mail_settings = useSelector(selectors.getMailSettings);
  const loading = useSelector(selectors.getLoading);
  const error = useSelector(selectors.getError);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { goToDashboard } = useNavigateHelper();

  const initialState = { state: states.INPUT, data: null };
  const reducer = (state, action) => ({ ...state, ...action });
  const [state, setState] = useReducer(reducer, initialState);
  const { data } = state;

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  const onSubmitForm = (data) => {
    setState({ state: states.OVERVIEW, data: data });
  };

  const onNextClick = () => dispatch(submit("unsubscribe-settings"));

  const onConfirm = () => {
    dispatch(
      saveSettings(mail_settings.id, {
        car_by_car_mail: data.car_by_car_mail,
        offers_mail: data.offers_mail,
        won_mail: data.won_mail,
        no_mail: data.no_mail,
      })
    ).then((result) => {
      if (result.payload.success) {
        setState({ state: states.RESULT });
      }
    });
  };

  const renderLeftSideContent = () => {
    return (
      <div className="w-full lg:w-1/3">
        <h5 className="pt-2">{t("Let’s keep in touch")}</h5>
        <p className="pt-5">
          {t("Help us improve your experience with UsedCars.center.")}
        </p>
        <p>
          {t(
            "Please adjust your email preferences and we will adjust our communication accordingly."
          )}
        </p>
        <h5 className="pt-5">{t("Still want to unsubscribe entirely?")}</h5>
        <p className="pt-5">
          {t(
            "We are sad to see you go but if you change your mind, you can always change your settings under ‘profile’."
          )}
        </p>

        {state.state === states.INPUT && (
          <p className="pt-5">{t("Swipe the last slider to unsubscribe.")}</p>
        )}
      </div>
    );
  };

  const renderSettingsForm = () => {
    return (
      <div className="w-full lg:w-2/3">
        {(loading || keys(mail_settings).length === 0) && (
          <LoadingIndicator loading={loading} />
        )}

        {!loading && keys(mail_settings).length > 0 && (
          <>
            <SettingsForm
              initialValues={{
                [settingsFields.CAR_BY_CAR_MAIL]: mail_settings.car_by_car_mail,
                [settingsFields.OFFERS_MAIL]: mail_settings.offers_mail,
                [settingsFields.WON_MAIL]: mail_settings.won_mail,
                [settingsFields.NO_MAIL]: mail_settings.no_mail,
              }}
              onSubmit={onSubmitForm}
            />

            <div className="mt-6 flex w-full justify-end bg-white">
              <div className="flex">
                <Button onClick={onNextClick}>
                  {t("Next")}
                  <i className="fa fa-chevron-right ml-1 text-sm"></i>
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderSettingsOverview = () => {
    return (
      <div className="w-full lg:w-2/3">
        {loading && (
          <LoadingIndicator
            loading={loading}
            text={t("Updating email preferences")}
          />
        )}

        {!loading && (
          <>
            <div className="mx-auto w-4/6">
              <Confirm data={state.data} />
            </div>
            <div className="w-full bg-white">
              <div className="flex justify-end space-x-2">
                <Button
                  onClick={() => {
                    setState({ state: states.INPUT });
                  }}
                >
                  <i className="fa fa-chevron-left mr-1 text-sm" />
                  {t("Back")}
                </Button>

                <Button onClick={onConfirm}>{t("Confirm")}</Button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderResult = () => {
    return (
      <div className="flex w-full flex-col justify-center text-center">
        <InfoContainer
          icon="fa fa-check"
          text={<h3>{t("Confirmed.")}</h3>}
          container={true}
        />
        <p className="mb-2 text-center">
          {t("Your email preferences have been changed.")}
        </p>
        <div className="flex justify-center">
          <Button onClick={goToDashboard}>{t("Dashboard")}</Button>
        </div>
      </div>
    );
  };

  const renderError = () => {
    return (
      <div className="container-important mx-auto mt-5 w-full p-3 sm:w-1/2">
        {error.message}
      </div>
    );
  };

  return (
    <div className="my-3">
      <div className="flex flex-col justify-between bg-white p-5 lg:px-28 lg:py-20">
        {state.state !== states.RESULT && (
          <h2 className="mb-4 text-primary">{t("Unsubscribe")}</h2>
        )}
        <div className="flex flex-col justify-between space-y-5 lg:flex-row lg:space-x-20 lg:space-y-0">
          {state.state !== states.RESULT && renderLeftSideContent()}
          <>
            {state.state === states.INPUT && renderSettingsForm()}
            {state.state === states.OVERVIEW && renderSettingsOverview()}
            {state.state === states.RESULT && renderResult()}
          </>
        </div>
        {error !== null && renderError()}
      </div>
    </div>
  );
};
