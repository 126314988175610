import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { compose } from "redux";

import {
  InfoContainer,
  LoadingIndicator,
  VehicleState,
} from "../../../components";
import { withConfig } from "../../../config";
import * as interestSelectors from "../../listvehicles-no-interest/selectors";
import { toggleSwiperVisibility } from "../../picture-swiper/actions";
import { listVehicleStateClosed, listVehicleStateFetch } from "../actions";
import * as selectors from "../selectors";

const ListVehicleState = (props) => {
  const {
    listVehicleId,
    vehicle,
    stickyTop,
    toggleSwiperVisibility,
    showStickyHeader,
    disabled,
    loading,
    hasData,
    error,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    loadVehicleState(listVehicleId);
  }, [listVehicleId]);

  const loadVehicleState = (listVehicleId) => {
    const { sendListInformationByRequest } = props;
    if (listVehicleId !== 0) {
      props.listVehicleStateFetch(listVehicleId, sendListInformationByRequest);
    }
  };

  if (error !== "") {
    return <InfoContainer text={error} icon="fa fa-wrench" />;
  }

  if (loading || listVehicleId === 0 || !vehicle || !hasData)
    return (
      <div className="g-pa-10 g-mb-10 g-min-height-250 text-center">
        {(loading || !vehicle) && (
          <div className={"d-flex align-items-center justify-content-center"}>
            <LoadingIndicator
              loading={loading}
              text={t("Loading vehicle state")}
            />
          </div>
        )}

        {listVehicleId === 0 && <div>{t("No vehicle selected")}</div>}
      </div>
    );

  return (
    <VehicleState
      vehicle={vehicle}
      listVehicleId={listVehicleId}
      stickyTop={stickyTop}
      showStickyHeader={showStickyHeader}
      toggleSwiperVisibility={toggleSwiperVisibility}
      disabled={disabled}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { listVehicleId } = ownProps;

  return {
    requestedListVehicleId: listVehicleId,
    vehicle: selectors.getVehicleById(state, listVehicleId),
    disabled: interestSelectors.listVehicleDisabledByInterest(
      state,
      listVehicleId
    ),
    loading: selectors.getLoading(state, listVehicleId),
    error: selectors.getError(state, listVehicleId),
    hasData: selectors.hasData(state, listVehicleId),
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps, {
    listVehicleStateFetch,
    listVehicleStateClosed,
    toggleSwiperVisibility,
  })
)(ListVehicleState);

export { ConnectedComponent as ListVehicleState };
