import React from "react";
import { useTranslation } from "react-i18next";

import { useCountdown } from "../../../hooks/useCountdown";

export const CountDownLabel = (props) => {
  const { t } = useTranslation();
  const targetDate = props.date;
  const nowDate = new Date().getTime();
  const countDownDate = new Date(targetDate).getTime();

  const { remainingTimeStr } = useCountdown(targetDate);

  const text =
    countDownDate > nowDate
      ? `${remainingTimeStr} ${t("left")}`
      : props.showStarted
      ? t("Started")
      : t("Closed");

  return (
    <div className="flex items-center space-x-1 text-primary">
      <i className="fa fa-bell"></i>
      <span>{text}</span>
    </div>
  );
};
