import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useNavigateHelper } from "../../../../../../hooks/useNavigateHelper";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import * as selectors from "../../../selectors";
import { MainContent } from "./MainContent";
import { OfferError } from "./OfferError";
import { StateDetails } from "./StateDetails";

// TODO: Values kept as a reference for global restyling
const xxl = 1600;

export const VehiclesMainLayoutView = ({ offerId }) => {
  const selectedId = useSelector(selectors.getSelectedId);
  const error = useSelector(selectors.getError);
  const { goBack, goToDashboard } = useNavigateHelper();
  const location = useLocation();

  // Hook also automatically re-renders the view on window resize
  const [windowWidth] = useWindowSize();

  const detailsVisible = windowWidth >= xxl;

  const navigateBack = () => {
    if (location.state) {
      goBack();
    } else {
      goToDashboard();
    }
  };
  if (error) {
    return <OfferError error={error} />;
  }
  return (
    <div className="mb-5 flex gap-x-3">
      <MainContent
        detailsVisible={detailsVisible}
        goBack={navigateBack}
        offerId={offerId}
        selectedId={selectedId}
      />

      {detailsVisible ? (
        <div className="hidden 3xl:block 3xl:w-5/12">
          <StateDetails selectedId={selectedId} />
        </div>
      ) : null}
    </div>
  );
};
