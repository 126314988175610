import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../../api/endpoints";
import { entityActions } from "../../../entities/actions";
import { listVehiclesStateCleanUp } from "../../listvehiclestate";
import { mapOfferVehicle } from "./mappers";
import { normalizeOfferVehicles } from "./normalizers";
import * as selectors from "./selectors";
import { types } from "./types";

import { map } from "lodash";

export const fetchOfferVehicles = (offerId) => {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.OFFER_VEHICLE}${offerId}/`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: types.FETCH_REQUEST,
            payload: {
              offerId,
            },
          },
          {
            type: types.FETCH_SUCCESS,
            payload: (action, state, res) => onSuccess(dispatch, res, offerId),
          },
          {
            type: types.FETCH_FAILURE,
            payload: (action, state, res) => onFailure(dispatch, res, offerId),
          },
        ],
      },
    });
  };
};

const onSuccess = (dispatch, res, offerId) => {
  return res.json().then((result) => {
    const data = normalizeOfferVehicles(map(result, mapOfferVehicle));
    dispatch(entityActions.updateEntities(data.entities));

    return {
      offerId,
      vehicleIds: data.result,
    };
  });
};

const onFailure = (dispatch, res, offerId) => {
  return {
    offerId,
    message: res.statusText,
  };
};

export const vehicleSelect = (id, stateFullScreen = false) => {
  return {
    type: types.VEHICLE_SELECT,
    id,
    stateVisible: stateFullScreen,
  };
};

export const toggleVehicleState = () => {
  return {
    type: types.TOGGLE_STATE,
  };
};

export const vehiclesCleanup = () => {
  return (dispatch, getState) => {
    const state = getState();

    const vehicleIds = selectors.getVehicleIds(state);

    return Promise.all([
      dispatch({ type: types.CLEANUP }),
      dispatch(listVehiclesStateCleanUp(vehicleIds)),
      dispatch(entityActions.clearEntities([], vehicleIds)),
    ]);
  };
};
