import { createErrorMessageSelector, createLoadingSelector } from "../ui";
import { RESET_PASSWORD_REQUEST } from "./types";

const loadingSelector = createLoadingSelector([RESET_PASSWORD_REQUEST]);
const errorSelector = createErrorMessageSelector([RESET_PASSWORD_REQUEST]);

export const getLoading = (state) => {
  return loadingSelector(state);
};

export const getError = (state) => {
  return errorSelector(state);
};
