import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { submit } from "redux-form";

import { RegistrationWizardContentLayout } from "../../../../layouts/RegistrationWizardContentLayout";
import { ContactInfo } from "./ContactInfo";
import { SettingsForm } from "./forms/SettingsForm";
import { WizardButtons } from "./WizardButtons";

export const personalInfoFields = {
  SMS_ACTIVE: "sms_active",
  CAR_BY_CAR_NOTIFICATION: "car_by_car_notification",
  CAR_BY_CAR_MAIL: "car_by_car_mail",
  DAILY_DIGEST_MAIL: "daily_digest_mail",
  OFFERS_MAIL: "offers_mail",
  WON_MAIL: "won_mail",
};

const LeftSectionContent = ({ step, steps }) => {
  const { t } = useTranslation();

  return (
    <>
      <hgroup>
        <h1 className="text-[1.25rem] text-primary">
          {t("Step")}: {step}/{steps} : {t("Preferences")}
        </h1>
        <h2 className="py-2 text-base">
          {t("How do you want to receive price requests?")}
        </h2>
      </hgroup>
      <p>
        {t(
          "Choose the options that you find most convenient and do not miss out on any price request."
        )}
      </p>
      <ContactInfo />
    </>
  );
};

const WizardStepFour = (props) => {
  const {
    required,
    step,
    totalSteps,
    submit,
    onNext,
    onPrev,
    registration,
    loading,
  } = props;

  const mapDataToFields = (data) => {
    return {
      [personalInfoFields.SMS_ACTIVE]: data.sms_active,
      [personalInfoFields.CAR_BY_CAR_NOTIFICATION]:
        data.car_by_car_notification,
      [personalInfoFields.CAR_BY_CAR_MAIL]: data.car_by_car_mail,
      [personalInfoFields.DAILY_DIGEST_MAIL]: data.daily_digest_mail,
      [personalInfoFields.OFFERS_MAIL]: data.offers_mail,
      [personalInfoFields.WON_MAIL]: data.won_mail,
    };
  };

  return (
    <RegistrationWizardContentLayout
      leftContent={<LeftSectionContent step={step} steps={totalSteps} />}
      rightContent={
        <div className="mt-5 flex items-center justify-center md:mt-0">
          <SettingsForm
            initialValues={mapDataToFields(registration)}
            required={required}
            onSubmit={(values) => onNext(values)}
          />
        </div>
      }
      bottomContent={
        <WizardButtons
          currentStep={step}
          onNext={() => submit("Registration-Settings")}
          onPrevious={() => onPrev()}
        />
      }
      loading={loading}
    />
  );
};

const ConnectedComponent = connect(null, { submit })(WizardStepFour);

export { ConnectedComponent as WizardStepFour };
