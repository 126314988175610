import { getVehicles } from "../listvehicles/selectors";

import { filter } from "lodash";

export const getVehiclesToConfirm = (state, listId) => {
  return filter(
    getVehicles(state),
    (veh) =>
      veh.list_id === Number(listId) &&
      veh.basket_state &&
      veh.basket_state === "INITIAL" &&
      veh.basket_price > 0
  );
};

export const getBiddingsToConfirmCount = (state, listId) => {
  return getVehiclesToConfirm(state, listId).length;
};

export const getConfirmVisible = (state) => {
  return state.list_cart.confirmVisible;
};

export const getLoading = (state) => {
  return state.list_cart.loading;
};

export const getAccepted = (state) => {
  return state.list_cart.accepted;
};

export const getMessage = (state) => {
  return state.list_cart.message;
};
