import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

import { compose } from "redux";

import { LoadingContainer, LoadingIndicator } from "../../../components";
import { TeaserBanner } from "../../../components/web/TeaserBanner";
import { withConfig } from "../../../config";
import { listsFetch } from "../actions";
import * as selectors from "../selectors";
import { ListItem } from "./ListItem";
import { NoLists } from "./NoLists";

const Lists = (props) => {
  const { lists, loading, error } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(listsFetch());
  }, []);

  const showNoLists = lists.length === 0 && !loading;

  return (
    <>
      <div>
        <div className="bottom-shadow mb-3 bg-white p-2.5">
          <span className="uppercase">
            {t("Current sales")} : {props.count} {t("vehicles")}
          </span>
        </div>
        <LoadingIndicator loading={loading} text={t("Loading lists")} />
      </div>
      <LoadingContainer loading={loading && lists.length > 0}>
        {error && <span className="text-danger">ERROR: {error}</span>}

        {showNoLists && <NoLists />}
        <TeaserBanner />

        {lists &&
          lists.map((id) => {
            return (
              <ListItem
                key={"list" + id}
                listId={id}
                onListSelect={props.onListSelect}
              />
            );
          })}
      </LoadingContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lists: selectors.currentListsIds(state),
    count: selectors.allListsVehicleCount(state),
    loading: selectors.currentListsLoading(state),
    error: selectors.currentListsError(state),
  };
};

const ConnectedComponent = compose(withConfig, connect(mapStateToProps))(Lists);

export { ConnectedComponent as Lists };
