import { types } from "./types";

export const vehiclesReducer = (
  state = {
    loading: false,
    error: "",
    data: null,
    stateVisible: false,
    selectedId: 0,
  },
  action
) => {
  switch (action.type) {
    case types.FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        selectedId: 0,
      };
    case types.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: action.payload,
        selectedId:
          state.selectedId === 0
            ? action.payload.vehicleIds.length > 0
              ? action.payload.vehicleIds[0]
              : 0
            : state.selectedId,
      };
    case types.FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case types.VEHICLE_SELECT:
      return {
        ...state,
        selectedId: action.id,
        stateVisible: action.stateVisible,
      };
    case types.TOGGLE_STATE:
      return {
        ...state,
        stateVisible: !state.stateVisible,
      };

    case types.CLEANUP:
      return {
        loading: false,
        error: "",
        data: null,
        selectedId: 0,
        stateVisible: false,
      };
    default:
      return state;
  }
};
