import React, { useCallback, useState } from "react";

import clsx from "clsx";

import { parseDateTZ } from "../../../helpers/functions";
import {
  addLeadingZero,
  splitSecondsIntoTimeUnits,
} from "../../../helpers/utils";
import { useInterval } from "../../../hooks/useInterval";
import { ServerDate } from "../../../scripts/ServerDate";

export const ListVehicleCountDown = ({
  date,
  onExpiring,
  onExpired,
  onHiding,
  onExtended,
}) => {
  const initialTime = () =>
    splitSecondsIntoTimeUnits(
      parseDateTZ(date).diff(ServerDate.now(), "seconds", false)
    );
  const [countDown, setCountDown] = useState(initialTime);
  const [expiringCount, setExpiringCount] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  const runEverySecond = useCallback(() => {
    const now = ServerDate.now();
    const endDate = parseDateTZ(date);
    const secondsDiff = endDate.diff(now, "seconds", false);

    // Countdown timer should not be running after expiringCount > 4 i.e. when the time
    // difference is more than 4 seconds.
    if (secondsDiff < -4) {
      setIsRunning(false);
      return;
    }

    if (endDate >= now) {
      const newCountDown = splitSecondsIntoTimeUnits(secondsDiff);
      setCountDown(newCountDown);

      if (expiringCount > 0) {
        // Extend the auction
        onExtended();
      }
      setExpiringCount(0);
    } else {
      // Trigger auction ending overlay
      if (expiringCount === 0) {
        onExpiring();

        // Trigger auction has ended overlay
      } else if (expiringCount === 2) {
        onHiding();

        // Trigger auction expired
      } else if (expiringCount === 4) {
        onExpired();
      }

      setExpiringCount((prevExpiringCount) => prevExpiringCount + 1);
    }
  }, [date, expiringCount, onExtended, onExpiring, onHiding, onExpired]);

  useInterval(runEverySecond, isRunning ? 1000 : null);

  const accent =
    countDown.days === 0 &&
    countDown.hours === 0 &&
    countDown.minutes === 0 &&
    countDown.seconds < 30;

  const formattedTime = `${addLeadingZero(countDown.days)}d ${addLeadingZero(
    countDown.hours
  )} : ${addLeadingZero(countDown.minutes)} : ${addLeadingZero(
    countDown.seconds
  )}`;

  const isVisible =
    countDown.days > 0 ||
    countDown.hours > 0 ||
    countDown.minutes > 0 ||
    countDown.seconds > 0;

  const badgeStyle = clsx(
    "w-[100px] u-label badge flex justify-start items-center whitespace-nowrap",
    accent
      ? "counter-badge-highlight !text-black animate-pulse-twice"
      : "counter-badge-normal"
  );

  return isVisible ? <div className={badgeStyle}>{formattedTime}</div> : null;
};
