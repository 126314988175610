import React from "react";

import nopic from "../../../../../../img/nopic.jpeg";

export const Picture = ({ picture_url, make, model }) => {
  return (
    <div>
      {picture_url && (
        <img src={picture_url} alt={make + " " + model} className="img-fluid" />
      )}

      {!picture_url && (
        <img src={nopic} alt={make + " " + model} className="img-fluid" />
      )}
    </div>
  );
};
