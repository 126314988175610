import { useNavigate } from "react-router-dom";

import { routes } from "../_web/navigation/routes";
import { buildUrl } from "../helpers/utils";

/**
 * This hook provides reusable navigation functions.
 */
export const useNavigateHelper = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate(routes.LOGIN);
  };

  const goToRegister = () => {
    navigate(routes.REGISTER);
  };

  const goToForgot_password = () => {
    navigate(routes.FORGOT_PASSWORD);
  };

  const goToRegistration_wizard = () => {
    navigate(routes.REGISTRATION_WIZARD);
  };

  const goToDashboard = () => {
    navigate(routes.DASHBOARD);
  };

  const goToProfile = () => {
    navigate(routes.PROFILE);
  };
  const goToFilters = () => {
    navigate(routes.FILTERS);
  };

  const goToList = (listId) => {
    navigate(buildUrl(routes.LIST, { listId: listId }));
  };

  const goBackToList = (listId) => {
    navigate(buildUrl(routes.LIST, { listId: listId }));
  };

  const goToVehicleDetails = (listVehicleId) => {
    navigate(buildUrl(routes.LIST_VEHICLE, { listVehicleId: listVehicleId }));
  };

  const goToOffer = (offerId) => {
    navigate(buildUrl(routes.OFFER, { offerId: offerId }));
  };

  const goToRegistration = () => {
    navigate(routes.REGISTRATION);
  };

  const goToForgotPassword = () => {
    navigate(routes.FORGOT_PASSWORD);
  };

  const goToHistory = () => {
    navigate(routes.HISTORY);
  };

  const goToTerms = () => {
    navigate(routes.TERMS_AND_CONDITIONS);
  };

  const goToPrivacy = () => {
    navigate(routes.PRIVACY);
  };

  const goBack = () => {
    navigate(-1);
  };

  const goToLanding = () => {
    navigate(routes.ROOT);
  };

  const goToFaq = () => {
    navigate(routes.FAQ);
  };

  return {
    goToLogin,
    goToRegister,
    goToForgot_password,
    goToRegistration_wizard,
    goToDashboard,
    goToProfile,
    goToFilters,
    goToList,
    goBackToList,
    goToVehicleDetails,
    goToOffer,
    goToRegistration,
    goToForgotPassword,
    goToHistory,
    goToTerms,
    goToPrivacy,
    goBack,
    goToLanding,
    goToFaq,
  };
};
