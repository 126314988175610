import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Template } from "../components";
import { fetchProfileOnCompletedRegistration } from "../features/authentication/authenticationSlice";
import { RegistrationWizard } from "../features/registration-wizard/index";
import { updatedSessionRegistrationStep } from "../features/session/sessionSlice";
import { routes } from "./navigation/routes";

export const WithRegistrationWizard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onRegistrationComplete = async () => {
    dispatch(updatedSessionRegistrationStep(100));
    await dispatch(fetchProfileOnCompletedRegistration());
    navigate(routes.DASHBOARD);
  };

  return (
    <Template>
      <div className="mt-3">
        <RegistrationWizard
          onWizardCompleted={() => onRegistrationComplete()}
        />
      </div>
    </Template>
  );
};
